import { useEffect, useState } from 'react';

import { IDBUserFlowStepTrack } from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { format } from 'date-fns';
import { IUseGetTrackingsOnCompletedResponse, useGetTrackings } from 'hooks';
import isEmpty from 'lodash.isempty';

import { stepIdIconMap } from 'pages/goals';

import { GoalsCardEmpty } from './GoalsCardEmpty';

import { DashboardCard } from '../../components';

export const GoalsCard = () => {
  const QUESTION_DATE = 26117;
  const QUESTION_STEP_2_EDIT_YOUR_GOAl = 26519;

  const [inProgressTracking, setInProgressTracking] = useState<IDBUserFlowStepTrack>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getGoal = (tracking: IDBUserFlowStepTrack) => {
    const answer = tracking.answers.find((r) => r.contentSnippetQuestionId === QUESTION_STEP_2_EDIT_YOUR_GOAl);
    if (!answer) return '';
    return answer.text;
  };

  const getDate = (tracking: IDBUserFlowStepTrack) => {
    const answer = tracking.answers.find((r) => r.contentSnippetQuestionId === QUESTION_DATE);
    if (!answer) return '';
    return format(new Date(answer.dateAnswered), 'dd/MM/yyyy');
  };

  const onGetTrackingsCompleted = (data: IUseGetTrackingsOnCompletedResponse) => {
    const nonArchivedTrackings = data.trackings.filter((r) => !r.isMarked);

    const inProgress = nonArchivedTrackings.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (r: IDBUserFlowStepTrack) => !r.completed && r.answers.find((answer) => answer.contentSnippetQuestionId === QUESTION_DATE),
    );

    setInProgressTracking(inProgress[0]);
    setIsLoading(false);
  };

  const { handleGetTrackings } = useGetTrackings({ onCompleted: onGetTrackingsCompleted });

  useEffect(() => {
    handleGetTrackings({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: String(process.env.REACT_APP_GOAL_USER_FLOW_URL),
      includeAnswers: true,
      includeUserFlowStep: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardCard 
      cardTooltip={{ text: 'A structured goal setting activity with prompts to help you set SMART goals in any part of your life.'}} 
      href="/goals" 
      linkLabel="Goals"
    >
      {!isLoading && !isEmpty(inProgressTracking) ? (
        <div className="bg-white flex flex-col xl:flex-row items-center h-full">
          <div className="mb-2 xl:mb-0">
            <img alt="placeholder" className="w-18 h-18 mr-2" src={stepIdIconMap[inProgressTracking.userFlowStep.id]} />
          </div>
          <div className="flex flex-col xl:flex-row flex-1 items-center">
            <div className="px-6 flex-1">
              <p className="h4 weight-800 line-1 mb-2">{inProgressTracking.userFlowStep.stepName}</p>
              <p>{getGoal(inProgressTracking)}</p>
              <p className="mb-0">
                <strong>Due</strong> {getDate(inProgressTracking)}
              </p>
            </div>
            <Button linkButton={{ id: 'goals', url: '/goals' }} text="Check in" />
          </div>
        </div>
      ) : null}
      {!isLoading && isEmpty(inProgressTracking) ? <GoalsCardEmpty /> : null}
      {isLoading ? <p>loading</p> : null}
    </DashboardCard>
  );
};
