import { gql } from '@apollo/client';

const RESET_TRACKING = gql`
  mutation resetTracking($id: Int!) {
    userFlowStepTrack {
      reset(userFlowStepTrackId: $id)
    }
  }
`;

export { RESET_TRACKING };
