import { Button } from '@netfront/ui-library';

export const BookmarkCardEmpty = () => {
  return (
    <div className="bg-white p-8 border-2 border-athens rounded-lg h-full">
      <div className="flex flex-col justify-center items-center text-center h-full w-full md:w-1/2 mx-auto">
        <div className="mb-2">
          <img alt="Empty" className="max-w-20" src="/images/no-bookmark-icon.svg" />
        </div>
        <span className="color-black h5 weight-800 mb-1 block">Saved pages</span>
        <p className="mb-8">
        You haven&apos;t saved any pages yet. Once you save a page, you can find it here on your dashboard for quick access.
        </p>
        <Button linkButton={{ id: 'about-drugs', url: '/about-drugs' }} text="View resources" />
      </div>
    </div>
  );
};
