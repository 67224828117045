import { EAssetType } from '../../../../services';

export const getFileType = (contentType: string): EAssetType | undefined => {
  const [type] = contentType.split('/');

  switch (type) {
    case 'application':
      return EAssetType.Document;
    case 'image':
      return EAssetType.Image;
    case 'video':
      return EAssetType.Video;
    default:
      break;
  }
};
