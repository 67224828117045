import { getInfoAndHelpMenuData } from '../PrivateHeader';

import { IMenuItem } from '../../../interfaces';

export const PUBLIC_HEADER_MENU_ITEMS: IMenuItem[] = [
  { href: '/about', id: 'about', label: 'About', subItems: [], isHiddenWhenNoAgencyCode: false, isHiddenWhenAgencyCode: false },
  { href: '/features', id: 'features', label: 'Features', subItems: [], isHiddenWhenNoAgencyCode: false, isHiddenWhenAgencyCode: false },
  {
    href: '/get-help-now',
    id: '/get-help-now',
    label: 'Get help now',
    subItems: [],
    isHiddenWhenNoAgencyCode: false,
    isHiddenWhenAgencyCode: true,
  },
  {
    href: '/self-assessment/introduction',
    id: 'self-assessment',
    label: 'Self assessment',
    subItems: [],
    isHiddenWhenNoAgencyCode: true,
    isHiddenWhenAgencyCode: false,
  },
  {
    isHiddenWhenNoAgencyCode: true,
    isHiddenWhenAgencyCode: false,
    ...getInfoAndHelpMenuData(false),
  },
];
