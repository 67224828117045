import { useState } from 'react';

import { Dialog } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';

import { SharedPost } from '../SharedPost';
import { UserDetailsHeader } from '../UserDetailsHeader';

import { PostShareDialogProps } from './PostShareDialog.interfaces';

import { EPostType, EShareOption } from '../../../services';

export const PostShareDialog = ({ isOpen, onClose, onCancel, post: postProps, onShare }: PostShareDialogProps) => {
  const [message, setMessage] = useState<string>('');

  const { user } = useSocialContext();

  return (
    <Dialog
      additionalClassNames="c-post-share-dialog"
      isOpen={isOpen}
      title="Share post"
      onCancel={onCancel}
      onClose={onClose}
      onConfirm={() => {
        onShare({
          message,
          postId: postProps.id,
          postType: EPostType.Feed,
          shareOption: EShareOption.Everyone,
        });
      }}
    >
      <UserDetailsHeader avatar={user?.profileImage?.presignedUrl} displayName={user?.displayedName} href="/community/profile" />

      <textarea
        className="c-post-share-dialog__textarea"
        id=""
        name=""
        placeholder="Share your thoughts"
        value={message}
        onChange={({ target: { value } }) => setMessage(value)}
      ></textarea>

      <SharedPost post={{ ...postProps }} />
    </Dialog>
  );
};
