import { gql } from '@apollo/client';

const UPDATE_COMMENT_STATUS = gql`
  mutation updateStatus($commentId: Int!, $status: EEntryStatus!) {
    comment {
      updateStatus(commentId: $commentId, status: $status)
    }
  }
`;

export { UPDATE_COMMENT_STATUS };
