import { Button } from '@netfront/ui-library';

import { ISpotlight } from '../../../components/Spotlight';

const WELCOME_MODULE_STEP_ID = 218;

const ATTENTION_TRAINING_STEP_ID = 230;

const SPOTLIGHT_ITEM: ISpotlight = {
  run: true,
  steps: [
    {
      content: <p>This quick tour will take you through some key features on Stable Ground. You can always find this tour under the Profile tab.</p>,
      locale: { skip: <strong aria-label="skip">Skip</strong> },
      placement: 'center',
      target: 'body',
      title: 'Ready to explore what Stable Ground has to offer?',
    },
    {
      content: <p>
      This is where you can quickly access any part of the portal from any page you are on. Click or hover over the tabs to see more pages.  </p>,
      placement: 'top',
      target: '.spotlight-item--1',
      title: 'Navigation bar',
    },
    {
      content: <p>All your messages from the Community can be easily accessed here.</p>,
      placement: 'top',
    
      target: '.spotlight-item--2',
      title: 'Messages',
    },
    {
      content: <p>View your notifications here.</p>,
      placement: 'top',
      target: '.spotlight-item--3',
      title: 'Notifications',
    },
    {
      content: <p>Reset your password, change your profile picture, and access this tour again here. </p>,
      placement: 'top',
      target: '.spotlight-item--4',
      title: 'Profile',
    },
    {
      content: <p>The dashboard provides an overview of Stable Ground at a glance, and updates according to your activity. For more details, click the information icon next to each section.  </p>,
      placement: 'auto',
      target: '.spotlight-item--5',
      title: 'Dashboard',
    },
    {
      content: <p>If you are struggling and need help immediately, this tab shows resources that are <strong>free</strong> and available <strong>24/7</strong>. This tab is available on every page of Stable Ground. </p>,
      event: 'click',
      placement: 'left',
      target: '.spotlight-item--6',
      title: 'Get help now',
      styles: {
        options: {
          width: 300,
        },
      },
    },
    {
      content: <p>This tab allows you to provide anonymous feedback about Stable Ground. We value any feedback you can provide us to make Stable Ground better. </p>,
      placement: 'left',
      target: '.spotlight-item--7',
      title: 'Provide your feedback',
    },
    {
      content: (
        <>
          <p>
       Take our confidential self-assessment which asks about your alcohol and other drug use and mental health. We recommend this as the first step when you join Stable Ground.  
          </p>

          <div className='flex justify-center'>
            <Button 
              linkButton={{ id: 'self-assessment', url: '/self-assessment/introduction' }} 
              text='Start self-assessment' 
            />
          </div>
        </>
      ),
      hideBackButton: true,
      placement: 'top',
      showProgress: false,
      target: '.spotlight-item--8',
      title: 'Self assessment',
      locale: { 
        last: <strong aria-label="Close">Explore Stable Ground</strong> 
      },
    },
  ],
};

const DASHBOARD_PAGE_CONSTANTS = Object.freeze({
  attentionTrainingStepId: ATTENTION_TRAINING_STEP_ID,
  spotLightItem: SPOTLIGHT_ITEM,
  welcomeModuleStepId: WELCOME_MODULE_STEP_ID,
});

export const WELCOME_STEP_ID = 218;
export const MODULE_1_STEP_ID = 219;
export const MODULE_2_STEP_ID = 220;
export const MODULE_3_STEP_ID = 221;
export const MODULE_4_STEP_ID = 222;
export const MODULE_5_STEP_ID = 223;

const MODULE_STEP_IDS = [WELCOME_STEP_ID, MODULE_1_STEP_ID, MODULE_2_STEP_ID, MODULE_3_STEP_ID, MODULE_4_STEP_ID, MODULE_5_STEP_ID];

const POST_MODULE_SURVEY_MODULE_1_STEP_ID = 236;
const POST_MODULE_SURVEY_MODULE_2_STEP_ID = 237;
const POST_MODULE_SURVEY_MODULE_3_STEP_ID = 238;
const POST_MODULE_SURVEY_MODULE_4_STEP_ID = 239;
const POST_MODULE_SURVEY_MODULE_5_STEP_ID = 240;

const POST_MODULE_SURVEY_STEP_IDS = [
  POST_MODULE_SURVEY_MODULE_1_STEP_ID,
  POST_MODULE_SURVEY_MODULE_2_STEP_ID,
  POST_MODULE_SURVEY_MODULE_3_STEP_ID,
  POST_MODULE_SURVEY_MODULE_4_STEP_ID,
  POST_MODULE_SURVEY_MODULE_5_STEP_ID,
];

const POST_MODULE_SURVEY_STEP_ID_MAP = {
  [MODULE_1_STEP_ID]: POST_MODULE_SURVEY_MODULE_1_STEP_ID,
  [MODULE_2_STEP_ID]: POST_MODULE_SURVEY_MODULE_2_STEP_ID,
  [MODULE_3_STEP_ID]: POST_MODULE_SURVEY_MODULE_3_STEP_ID,
  [MODULE_4_STEP_ID]: POST_MODULE_SURVEY_MODULE_4_STEP_ID,
  [MODULE_5_STEP_ID]: POST_MODULE_SURVEY_MODULE_5_STEP_ID,
};

const QUESTIONNAIRE_DELAYED_STEP_ID = 231;
const QUESTIONNAIRE_8_WEEKS_STEP_ID = 224;
const QUESTIONNAIRE_20_WEEKS_STEP_ID = 225;

const QUESTIONNAIRE_STEP_IDS = [QUESTIONNAIRE_DELAYED_STEP_ID, QUESTIONNAIRE_8_WEEKS_STEP_ID, QUESTIONNAIRE_20_WEEKS_STEP_ID];

export { DASHBOARD_PAGE_CONSTANTS, MODULE_STEP_IDS, POST_MODULE_SURVEY_STEP_ID_MAP, POST_MODULE_SURVEY_STEP_IDS, QUESTIONNAIRE_STEP_IDS };
