import { IMenuItem } from '../../../interfaces';

export const getInfoAndHelpMenuData = (isPrivate: boolean): IMenuItem => ({
  id: 'getting-help',
  label: 'Info & help',
  subItems: [
    ({ ...isPrivate && { description: 'Try our online program with interactive activities', href: '/program', id: 'program', label: 'Steady program', subItems: [] } } as IMenuItem),
    {
      description: 'Read evidence-based fact sheets',
      href: '/about-drugs',
      id: 'about-drugs',
      label: 'About drugs',
    },
    {
      description: 'Learn about support and treatment options',
      href: '/support-options',
      id: 'support-options',
      label: 'Support options',
    },

    {
      className: 'bg-accent p-4 rounded color-black-important',
      description: 'Contact urgent support services',
      href: '/get-help-now',
      id: 'get-help-now',
      label: 'Get help now',
    },
  ],
})


export const PRVIATE_HEADER_MENU_ITEMS: IMenuItem[] = [
  { href: '/dashboard', id: 'dashboard', label: 'Dashboard', subItems: [] },
  {
    id: 'self-assessment',
    label: 'Self assessment',
    subItems: [
      {
        description: 'Complete a new self-assessment',
        href: '/self-assessment/introduction',
        id: 'new-self-assessment',
        label: 'New self-assessment',
      },
      {
        description: 'Review your latest self-assessment results and personalised feedback',
        href: '/feedback/recent-results',
        id: 'recent-results',
        label: 'Recent results',
      },
      {
        description: 'Track your self-assessment results over time',
        href: '/feedback/results-overtime',
        id: 'results-over-time',
        label: 'Results over time',
      },
      {
        description: 'View and download your personalised GP letter',
        href: '/feedback/gp-letter',
        id: 'gp-letter',
        label: 'GP letter',
      },
    ],
  },
  {
    description: 'Read evidence-based fact sheets',
    href: '/about-drugs',
    id: 'about-drugs',
    label: 'About drugs',
  },
  {
    description: 'Learn about support and treatment options',
    href: '/support-options',
    id: 'support-options',
    label: 'Support options',
  },
  { 
    description: 'Try our online program with interactive activities', 
    href: '/program', 
    id: 'program', 
    label: 'Steady program', 
  },
  { href: '/community', id: 'social', label: 'Community' },
  {
    id: 'resources',
    label: 'Resources',
    subItems: [
      {
        description: 'Distractions for when you feel a craving',
        href: '/distraction-games',
        id: 'distraction-games',
        label: 'Distraction games',
        subItems: [],
      },
      {
        description: 'Create your own goals using our guided prompts',
        href: '/goals',
        id: 'goal-setting',
        label: 'Goal setting',
        subItems: [],
      },
      {
        description: 'Log your daily activities and mood',
        href: '/mood-and-activity-tracker',
        id: 'calendar',
        label: 'Mood and activity tracker',
        subItems: [],
      },
      {
        description: 'Check-in weekly to keep track of how you are going',
        href: '/checkin',
        id: 'check-in',
        label: 'Check-in',
        subItems: [],
      },
      {
        className: 'bg-accent p-4 rounded color-black-important',
        description: 'Contact urgent support services',
        href: '/get-help-now',
        id: 'get-help-now',
        label: 'Get help now',
      },
    ],
  },
];
