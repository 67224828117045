import { Avatar } from '@netfront/ui-library';
import { getValidClassNames } from 'utils';

import { ConversationMessageProps } from './ConversationMessage.interfaces';

const ConversationMessage = ({
  avatarImage,
  displayName,
  isSentByUser = false,
  readStatus,
  shouldShowAvatar = true,
  text,
}: ConversationMessageProps) => {
  return (
    <div
      className={getValidClassNames({
        'c-conversation-message': true,
        'c-conversation-message--from-user': isSentByUser,
      })}
    >
      <Avatar
        additionalClassNames={getValidClassNames({
          'c-avatar--none': !shouldShowAvatar,
        })}
        {...(avatarImage && { image: <img alt={displayName} src={avatarImage} title={displayName} /> })}
        size="small"
        title={displayName}
      />

      <div className="c-conversation-message__body">
        {shouldShowAvatar && <span className="c-conversation-message__display-name">{displayName}</span>}
        <div className="c-conversation-message__message" dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
        {readStatus && <span className="c-conversation-message__read-status">{readStatus}</span>}
      </div>
    </div>
  );
};

export { ConversationMessage };
