import { gql } from '@apollo/client';

import { IDBCommentGraphTypeFragment } from './comment.fragment';
import { IDBPostPollGraphTypeFragment } from './postPoll.fragment';
import { UserGraphTypeFragment } from './user.fragment';

export const PostGraphTypeFragmentDoc = gql`
  fragment postGraphType on PostGraphType {
    id
    assets {
      postId
      assetId
      contentType
      fileName
      presignedUrl
      s3Key
    }
    author {
      ...user
    }
    comments {
      edges {
        cursor
        node {
          ...commentGraphType
        }
      }
    }
    commentCount
    communityId
    createdDate
    createdDateTime
    feeling
    hasRelated
    message
    pinned
    ...poll
    relateCount
    reports {
      type
    }
    shareOption
    status
    tags
    topic {
      id
      title
      description
      postsCount
    }
    updatedDate
  }

  ${UserGraphTypeFragment}
  ${IDBCommentGraphTypeFragment}
  ${IDBPostPollGraphTypeFragment}
`;
