import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_STEP_WITH_PAGES } from './useGetUserFlowStepWithPages.graphql';
import {
  IGetUserFlowStepWithPagesQueryGraphQLResponse,
  IHandleGetUserFlowStepWithPagesParams,
  IUseGetUserFlowStepWithPages,
  IUseGetUserFlowStepWithPagesOptions,
} from './useGetUserFlowStepWithPages.interfaces';

const useGetUserFlowStepWithPages = (options?: IUseGetUserFlowStepWithPagesOptions): IUseGetUserFlowStepWithPages => {
  const { fetchPolicy, onCompleted, onError, product, token } = options ?? ({} as IUseGetUserFlowStepWithPagesOptions);

  const [executeGetUserFlowStepWithPages, { loading: isLoading }] = useEkardoLazyQuery<IGetUserFlowStepWithPagesQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStep: { getStepByUrl: step },
        } = data;

        onCompleted({
          userFlowStep: step,
        });
      },
      onError,
    },
    product,
    query: GET_STEP_WITH_PAGES,
    token,
  });

  const handleGetUserFlowStepWithPages = async ({ projectId, stepUrl, userFlowUrl }: IHandleGetUserFlowStepWithPagesParams) => {
    await executeGetUserFlowStepWithPages({
      variables: {
        projectId,
        stepUrl,
        userFlowUrl,
      },
    });
  };

  return {
    handleGetUserFlowStepWithPages,
    isLoading,
  };
};

export { useGetUserFlowStepWithPages };
