import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  IToolBoxActivitiesContentSnippet,
  IToolBoxContentPage,
  IToolBoxUserFlowStep,
  IUseToolBoxActivitiesOnCompletedResponse,
  useToolBoxActivities,
} from '@netfront/ekardo-content-library';
import { Select } from '@netfront/ui-library';
import { CaretRightIcon, Link, ToolboxPageLayout , Skeleton } from 'components';
import { first } from 'lodash';
import uniqBy from 'lodash.uniqby';

import { useProtectedRoute } from '../../../hooks/useProtectedRoute';
import { useToast } from '../../../hooks/useToast';

const PAGE_TITLE = 'Activities';

const ActivitiesPage = () => {
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();

  const [activitiesContentSnippets, setActivitiesContentSnippets] = useState<IToolBoxActivitiesContentSnippet[]>();
  const [activityContentPages, setActivityContentPages] = useState<IToolBoxContentPage[]>([]);
  const [allUserFlowSteps, setAllUserFlowSteps] = useState<IToolBoxUserFlowStep[]>([]);
  const [selectedModule, setSelectedModule] = useState<IToolBoxUserFlowStep>();

  const handleModuleClick = (step?: IToolBoxUserFlowStep) => {
    if (!step) {
      return;
    }

    setSelectedModule(step);
  };

  const handleGetToolBoxActivitiesCompleted = ({
    toolboxData: { contentPages, contentSnippets, userFlowSteps },
  }: IUseToolBoxActivitiesOnCompletedResponse) => {
    const contentUserFlowSteps = userFlowSteps.filter(({ type }) => type === 'CONTENT');

    const activitySnippets = contentSnippets.filter(({ form, app }) => form ?? app);

    setActivitiesContentSnippets(uniqBy(activitySnippets, 'contentPageId'));
    setActivityContentPages(contentPages);
    setAllUserFlowSteps(contentUserFlowSteps);

    if (contentUserFlowSteps.length) {
      setSelectedModule(first(contentUserFlowSteps));
    }

    if (!contentSnippets.length) {
      return;
    }
  };

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleGetToolBoxActivities, isLoading, isReady } = useToolBoxActivities({
    onCompleted: handleGetToolBoxActivitiesCompleted,
    onError: handleApolloError,
    projectId: String(process.env.REACT_APP_PROJECT_ID),
  });

  useEffect(() => {
    if (!(isAuthenticated && isReady)) {
      return;
    }

    handleGetToolBoxActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isReady]);

  return (
    <ToolboxPageLayout meta={{ seoDescription: 'Activities found in the program', seoTitle: PAGE_TITLE }} title={PAGE_TITLE}>
      {!isLoading && (
        <div className="ek-section py-4">
          <div className="w-full md:w-1/5">
            <Select
              additionalClassNames="bg-white"
              id="module"
              labelText="Select module"
              name="module"
              options={allUserFlowSteps.map(({ id, stepName }) => ({ id, name: stepName, value: id }))}
              value={selectedModule?.id}
              onChange={({ target }) => handleModuleClick(allUserFlowSteps.find(({ id }) => parseInt(target.value) === id))}
            />
          </div>
        </div>
      )}

      {isLoading && (
        <div className="ek-section">
          <Skeleton borderRadius="10px" height="3rem" width="100%" />
        </div>
      )}
      {!isLoading &&
        activitiesContentSnippets &&
        activitiesContentSnippets.length &&
        activitiesContentSnippets
          .filter(({ userFlowStepId }) => selectedModule?.id === userFlowStepId)
          .map(({ contentPageId, id }) => {
            const pageUrl = activityContentPages.find(({ id: pageId }) => pageId === contentPageId);

            return (
              <Link
                key={id}
                className="flex items-center bg-white p-4 color-blue-700 rounded mb-4 weight-600 transition-200 hover:shadow-xl"
                href={`/dashboard/${String(selectedModule?.url)}/${String(pageUrl?.url)}`}
              >
                <div className="flex items-center justify-center rounded-circle p-2 bg-blue-200 color-primary mr-4">
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <span className="flex-1 mb-0">{pageUrl?.title}</span>
                <div className="flex items-center color-primary">
                  <CaretRightIcon />
                </div>
              </Link>
            );
          })}
    </ToolboxPageLayout>
  );
};

export { ActivitiesPage };
