import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_TRACKINGS } from './useGetTrackings.graphql';
import {
  IHandleGetTrackingsParams,
  IGetTrackingsQueryGraphQLResponse,
  IUseGetTrackings,
  IUseGetTrackingsOptions,
} from './useGetTrackings.interfaces';

const useGetTrackings = (options?: IUseGetTrackingsOptions): IUseGetTrackings => {
  const { fetchPolicy, onCompleted, onError, product, token } = options ?? ({} as IUseGetTrackingsOptions);

  const [executeGetTrackings, { loading: isLoading }] = useEkardoLazyQuery<IGetTrackingsQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStepTrack: { getTrackings: trackings },
        } = data;

        onCompleted({
          trackings,
        });
      },
      onError,
    },
    product,
    query: GET_TRACKINGS,
    token,
  });

  const handleGetTrackings = async ({
    projectId,
    userFlowUrl,
    includeAnswers,
    includeUserFlowStep,
    userFlowStepUrls,
  }: IHandleGetTrackingsParams) => {
    await executeGetTrackings({
      variables: {
        projectId,
        userFlowUrl,
        includeAnswers,
        includeUserFlowStep,
        userFlowStepUrls,
      },
    });
  };

  return {
    handleGetTrackings,
    isLoading,
  };
};

export { useGetTrackings };
