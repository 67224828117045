import { gql } from '@apollo/client';

const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($settings: [EUserSettings]!) {
    user {
      updateSettings(inputType: $settings) {
        id
      }
    }
  }
`;

export { UPDATE_USER_SETTINGS };
