import { gql } from '@apollo/client';

const GET_SUM_CLUSTER_QUERY = gql`
  query useGetSumCluster($clusterId: Int!, $trackingId: Int!) {
    report {
      sumCluster(clusterId: $clusterId, trackingId: $trackingId)
    }
  }
`;

export { GET_SUM_CLUSTER_QUERY };
