import { MobileMenuTriggerProps } from './MobileMenuTrigger.interfaces';

import { getValidClassNames } from '../../../utils';
import { Button } from '../../Button';
import { CrossIcon, MenuIcon } from '../../Icons';

const MobileMenuTrigger = ({ additionalClassNames, isOpen, onPress }: MobileMenuTriggerProps) => {
  return (
    <Button
      aria-expanded={isOpen}
      className={getValidClassNames({
        'c-menu__trigger--mobile color-secondary items-center bg-transparent border-none flex justify-center pointer p-0 color-white': true,
        [`${String(additionalClassNames)}`]: Boolean(additionalClassNames),
      })}
      aria-haspopup
      onPress={onPress}
    >
      <span className="sr-only">{`${isOpen ? 'Close' : 'Open'} side navigation`}</span>
      {isOpen ? <CrossIcon /> : <MenuIcon />}
    </Button>
  );
};

export { MobileMenuTrigger };
