import { ProgressSlideProps } from './ProgressSlide.interfaces';

import { getValidClassNames } from '../../utils';

const ProgressSlide = ({ additionalClassNames, percentage = 0 }: ProgressSlideProps) => {
  return (
    <div
      className={getValidClassNames({
        'h-2 w-full relative bg-athens': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
      title={`${percentage}% completed`}
    >
      <span className="sr-only">{percentage}% completed</span>
      <div className="absolute h-full w-4 bg-primary transition-300" style={{ width: `${percentage}%` }} />
    </div>
  );
};

export { ProgressSlide };
