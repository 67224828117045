import React, { useRef } from 'react';

import { useOutsideClick, useToggle } from '@netfront/common-library';
import { usePreventScroll } from '@react-aria/overlays';
import classNames from 'classnames';
import { Skeleton } from 'components';
import { useMediaQuery } from 'react-responsive';


import { Button } from '../Button';
import { CaretLeftIcon, CaretRightIcon } from '../Icons';

import { ContentNavigationDrawerProps } from './ContentNavigationDrawer.interfaces';

import { useIsMounted } from '../../hooks';

const ContentNavigationDrawer = ({ children, isLoading, onChange }: React.PropsWithChildren<ContentNavigationDrawerProps>) => {
  const drawerRef = useRef(null);

  const { isMounted } = useIsMounted();
  const { isToggled: isDrawerOpen, toggle: toggleDrawer } = useToggle();
  const isLargerDevice = useMediaQuery({ query: '(min-width: 48em)' });

  usePreventScroll({ isDisabled: !isDrawerOpen });

  useOutsideClick(drawerRef, () => {
    if (isDrawerOpen) {
      toggleDrawer();
    }
  });

  const onToggle = () => {
    toggleDrawer();

    if (onChange) {
      onChange(isDrawerOpen);
    }
  };

  return isMounted ? (
    <aside
      ref={drawerRef}
      className={classNames('bg-white index-1 flex flex-col max-w-98 w-4/5 transition-100 no-print', {
        '-translate-x-100': !isDrawerOpen,
        'fixed top-0 pt-23  h-screen': !isLargerDevice,
        'md:pt-0 md:h-auto md:relative md:translate-x-0': isLargerDevice,
      })}
    >
      {!isLargerDevice && (
        <div className="relative bg-white flex items-center p-4">
          <div className="absolute -right-2 top-12 translate-x-100">
            <Button
              aria-expanded={isDrawerOpen || isLargerDevice}
              className="bg-white rounded-full w-8 h-8 flex items-center justify-center border border-grey-100 shadow pointer md:none"
              aria-haspopup
              onPress={onToggle}
            >
              <span className="sr-only">{isDrawerOpen ? 'Close menu' : 'Open menu'}</span>
              {isDrawerOpen ? <CaretLeftIcon /> : <CaretRightIcon />}
            </Button>
          </div>
        </div>
      )}

      <ol aria-hidden={!isLargerDevice && !isDrawerOpen} className="bg-white flex-1 m-0 p-4 overflow-y-auto">
        {isLoading ? (
          <div className="mt-8">
            <Skeleton height="1rem" width="20%" />
            <Skeleton height="2rem" width="30%" />

            <Skeleton height="2.5rem" width="100%" />
            <Skeleton height="2.5rem" width="100%" />
            <Skeleton height="2.5rem" width="100%" />
          </div>
        ) : (
          children
        )}
      </ol>
    </aside>
  ) : null;
};

export { ContentNavigationDrawer };
