import { IGetReportsOptions, ReportsQueryResult } from './useGetReports.interface';

import { bonoboClient } from '../../apollo';
import { useGetReportsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetReports = (options?: IGetReportsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetReportsOptions);

  const [getReports, { loading: isLoading, error, fetchMore, refetch }] = useGetReportsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data.report?.getReports as unknown as ReportsQueryResult);
    },
    onError,
  });

  return { getReports, isLoading, error, fetchMore, refetch };
};
