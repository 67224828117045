import { IAdditionalClassNames } from '@netfront/ui-library';

export enum ESelfAssessmentIndicatorTheme {
  blue = 'blue',
  yellow = 'yellow',
}

export interface SelfAssessmentIndicatorValue {
  label?: string;
  value?: number;
}

export interface SelfAssessmentIndicatorProps extends IAdditionalClassNames {
  isLoading?: boolean;
  max?: number;
  min?: number;
  theme?: ESelfAssessmentIndicatorTheme;
  value?: number;
  values?: SelfAssessmentIndicatorValue[];
}
