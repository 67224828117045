import { Dispatch, SetStateAction } from "react";

import { Input } from "@netfront/ui-library";
import NextLink from 'next/link';


export interface CodeOfConductProps {
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
}

export const CodeOfConduct = ({ phoneNumber, setPhoneNumber }: CodeOfConductProps) => {
  return (
    <>
      <p>
        The Stable Ground Community is a space where you can talk to people who work in similar areas to you and encourage and support one another. The Community is one part of the Stable Ground portal which provides resources to help NSW public service employees manage their use of alcohol or other drugs.
      </p>
      <p className="mb-8">
        To use the Community, you must agree to the below guidelines. These guidelines are intended to ensure that the Stable Ground Community functions as it should and that it is a secure and comfortable environment where you can provide and receive advice, support, and encouragement.
      </p>

      <p className="h5 color-secondary weight-600">1. The purpose of this site </p>
      <p>
        The Community is primarily a space for peer support. While content will be monitored, and sometimes created, by our Moderators, it is not a counselling, crisis or one-on-one support service. The Moderators can assist with directing you to other services, but they can’t provide counselling. The website will also list the details of support services if you’re in crisis and need to {` `} 
        <NextLink href='/get-help-now' target='_blank' rel="noreferrer"><span className='color-primary weight-600'>get help now</span></NextLink>.
      </p>

      <p>
        In cases where our Moderators believe you may be in immediate harm, we may try to check up on you by contacting you. The best way we could do this is by calling a mobile phone number. If you wish, you may input your number here:
      </p>
      <Input
        additionalClassNames="mb-4"
        id="phoneNumber"
        labelText="Mobile number (optional)"
        name="phoneNumber"
        type="text"
        value={phoneNumber}
        onChange={({ currentTarget }) => setPhoneNumber(currentTarget.value)} />
      <p>
        This will not be used for any other purpose other than attempting to contact you if we believe you are in immediate harm.
      </p>

      <p className="h5 color-secondary weight-600">2. Protect yours and others anonymity </p>
      <p>
        We suggest that the best way to use this site is to stay anonymous. When you sign up, we provide you with a pseudonym. You can also make a customised profile graphic to go along with this username. When you make posts, it is important to try and protect your identity so try not to say anything that makes you identifiable, such as your name or place of work. It is equally important that you do not disclose identifiable information about other people.
        Despite this, it’s not against the rules for you to disclose information that may mean people can tell who you are. We may just check with you (by private message) whether you meant to reveal this information.
      </p>

      <p>
        To help keep your information protected, this site works in a similar way as a closed Facebook group. This means people in the general public cannot see posts on this group as they need to provide a verification code to confirm their employment with one of the participating agencies. Also, posts in the Community won’t come up in internet searches.
      </p>


      <p className="h5 color-secondary weight-600">3. Respect other users</p>
      <p>
        A good general rule to follow is to treat others as you would like to be treated. Respect other people in the Community’s perspectives, opinions and experiences. Don’t post anything offensive, including hate speech or content that may make people uncomfortable.
        While you can remain anonymous, this doesn’t mean that your actions don’t have consequences. If you do disagree with someone else, be respectful and conscious of the other person’s emotions and experiences. You can try to think about whether you would say a comment or post in real life, to see if it’s okay to post it. Connecting with others in a respectful way through a platform like this can be very rewarding. It also may encourage others to share their experiences.
      </p>


      <p className="h5 color-secondary weight-600">4. Put yourself out there</p>
      <p>
        Share your story! While we do recommend users stay anonymous, we do encourage you to share your experiences. It can be hard to talk about personal things, but we find that people who are open and honest can feel less alone or isolated. This is particularly true when speaking to people who are similar to you and may understand what you’re going through.
      </p>

      <p className="h5 color-secondary weight-600">  5. Be mindful about the content you post</p>
      <p>
        While it’s good to be open, please be conscious about the content that you’re posting. We ask that you never post graphic details about traumatic events you may have experienced. Discussing your own trauma can have unpredictable effects on your emotions, and we want to make sure this is done in the right environment which may not be online. Discussing trauma may also affect the emotions of others and even cause them to relive their own difficult experiences.

      </p>

    </>
  );
};
