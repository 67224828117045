import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_SUM_CLUSTER_QUERY } from './useGetSumCluster.graphql';
import {
  IHandleGetSumClusterParams,
  IGetSumClusterQueryGraphQLResponse,
  IUseGetSumCluster,
  IUseGetSumClusterOptions,
} from './useGetSumCluster.interfaces';

const useGetSumCluster = (options?: IUseGetSumClusterOptions): IUseGetSumCluster => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetSumClusterOptions);

  const [executeGetSumCluster, { loading: isLoading }] = useEkardoLazyQuery<IGetSumClusterQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          report: { sumCluster },
        } = data;

        onCompleted({
          sumCluster,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_SUM_CLUSTER_QUERY,
    token,
  });

  const handleGetSumCluster = async ({ clusterId, trackingId }: IHandleGetSumClusterParams) => {
    await executeGetSumCluster({
      variables: {
        clusterId,
        trackingId,
      },
    });
  };

  return {
    handleGetSumCluster,
    isLoading,
  };
};

export { useGetSumCluster };
