import { useEffect, useState } from 'react';

import { IUserFlowStepTrack, useGetUserFlowStepTracksWithAnswers } from '@netfront/ekardo-content-library';

export const useGetLastSelfAssessment = () => {
  const [lastSelfAssessment, setLastSelfAssessment] = useState<IUserFlowStepTrack>();
  const [isFirstAttempt, setIsFirstAttempt] = useState<boolean>(false);

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: ({ userFlowStepTracks }) => {
      const sortedCompletedTrackings = [...userFlowStepTracks].filter(({ isCompleted }) => isCompleted).sort((a, b) => b.id - a.id);

      setIsFirstAttempt(sortedCompletedTrackings.length === 0);
      setLastSelfAssessment(sortedCompletedTrackings[0]);
    },
  });

  useEffect(() => {
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['self-assessment'],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lastSelfAssessment,
    isFirstAttempt,
  };
};
