import { NavigationButtonProps } from './NavigationButton.interfaces';

import { Button, CaretLeftIcon, CaretRightIcon } from '../../components';

const NavigationButton = ({ direction, isLoading, ...rest }: NavigationButtonProps) => {
  return !isLoading ? (
    <Button
      className={`bg-white color-white border border-athens flex justify-center items-center p-4 pointer rounded transition-200 hover:shadow-xl ${
        direction === 'NEXT' ? 'text-right ml-auto pl-6 js-next' : 'text-left pr-6 js-prev'
      }`}
      {...rest}
    >
      {direction === 'PREVIOUS' && (
        <div className="flex items-center color-primary">
          <CaretLeftIcon additionalClassNames="h-5 w-5" />
          <span className="mb-0 ml-2 weight-600">Prev</span>
        </div>
      )}

      {direction === 'RESTART' && (
        <div className="flex items-center color-primary">
          <span className="mb-0 ml-2 weight-600">Return to start</span>
        </div>
      )}

      {direction === 'NEXT' && (
        <div className="flex items-center color-primary">
          <span className="mb-0 mr-2 weight-600">Next</span>
          <CaretRightIcon />
        </div>
      )}
    </Button>
  ) : null;
};

export { NavigationButton };
