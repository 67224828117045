import { useToggle } from '@netfront/common-library';

import { CaretDownIcon, MobileMenuItemProps } from '../../components';
import { getValidClassNames } from '../../utils';

export const MobileMenuItem = ({ additionalClassNames, label, href, subItems }: MobileMenuItemProps) => {
  const { isToggled: isSubMenuToggled, toggle: toggleSubMenu } = useToggle();

  return (
    <li
      className={getValidClassNames({
        'bg-white color-secondary w-full weight-600 border-b border-athens mb-0 ml-0': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
    >
      <div className="flex">
        {subItems && subItems.length ? (
          <button
            className={getValidClassNames({
              'bg-transparent color-inherit border-none rounded bg-primary flex items-center p-4 px-8 transition-200 pointer w-full': true,
            })}
            onClick={toggleSubMenu}
          >
            <span className="color-secondary inline-flex mb-0 weight-600 flex-1">{label}</span>
            <div
              className={getValidClassNames({
                'flex items-center justify-center': true,
                'rotate-180': isSubMenuToggled,
              })}
            >
              <CaretDownIcon />
            </div>
          </button>
        ) : (
          <a className="color-secondary p-4 px-8 flex-1" href={href}>
            {label}
          </a>
        )}
      </div>
      {subItems && subItems.length && isSubMenuToggled ? (
        <ul className="ml-0">
          {subItems.map(({ id: subMenuId, label: subMenuLabel, href: subMenuHref, subItems: subMenuItems }) => (
            <MobileMenuItem
              key={`mobile-sub-menu-${subMenuId}`}
              additionalClassNames="pl-6"
              href={subMenuHref}
              id={subMenuId}
              label={subMenuLabel}
              subItems={subMenuItems}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};
