import { Breadcrumbs, IBreadcrumbItem } from '@netfront/ui-library';
import Cookies from 'js-cookie';
import kebabCase from 'lodash.kebabcase';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { capitalizeFirstLetter } from 'utils';

import { ToolboxPageLayoutProps } from './ToolboxPageLayout.interfaces';

import { BreadcrumbItem, BreadcrumbItemDashboardPageLink, BreadcrumbItemLink, ContentHeader, HelpButton, Page } from '../../../components';

const ToolboxPageLayout = ({ children, chapterTitle = 'Toolbox', isSpotLightTourLinkVisible, items, ...rest }: ToolboxPageLayoutProps) => {
  const { asPath } = useRouter();
  const { className, title } = rest;
  const pageClassNames = `bg-blue-100 bg-logo py-16 ${className ? className : ''}`;

  const routes = asPath.split('/').filter(({ length }) => length > 0);

  const breadCrumbItems: IBreadcrumbItem[] =
    items ??
    [
      {
        content: <BreadcrumbItemDashboardPageLink />,
        key: 'dashboard',
      },
    ].concat(
      routes.map((route, key) => ({
        content:
          key === routes.length - 1 ? (
            <BreadcrumbItem text={title} />
          ) : (
            <BreadcrumbItemLink
              content={capitalizeFirstLetter(capitalizeFirstLetter(route))}
              href={`${routes[key - 1] ? `/${routes[key - 1]}` : ''}/${route}`}
            />
          ),
        key: kebabCase(route),
      })),
    );

  const handleSetSpotlightCookie = () => {
    Cookies.set('spotlightTour', 'display');
  };

  return (
    <Page {...rest} className={pageClassNames} hasPrivateLayout>
      <div className="container-md flex items-center justify-between pb-8">
        <div className="flex items-center">
          {isSpotLightTourLinkVisible && (
            <button className="c-spotlight-link" onClick={handleSetSpotlightCookie}>
              <NextLink href="/dashboard">Dashboard tour</NextLink>
            </button>
          )}
          <div className="flex-1">
            <HelpButton />
          </div>
        </div>
        <Breadcrumbs items={breadCrumbItems} />
      </div>
      <div className="container container-md">
        <ContentHeader chapterTitle={chapterTitle} pageTitle={title} />
        {children}
      </div>
    </Page>
  );
};

export { ToolboxPageLayout };
