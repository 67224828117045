import { useEffect, useState } from 'react';

import { IDBUserFlowStepTrack } from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { useAccessFollowup } from 'hooks';

import { useEvaluationPrompt } from './EvaluationQuestionnairePrompt.hooks';

export const EvaluationQuestionnairePrompt = () => {
  
  const START_PROMPT = 'Thanks for taking an interest in participating in the research study for Stable Ground. Get started with the online survey here.';
  
  const CONTINUE_PROMPT = "It looks like you started the online evaluation but didn't finish it. Jump back in now to complete the online survey."

  const [shouldShowPrompt, setShouldShowPrompt] = useState<boolean>(false);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const { handleCanAccessFollowup } = useAccessFollowup({
    onShouldStart: () => {
      setShouldShowPrompt(true)
    },
    onShouldContinue: () => {
      setShouldShowPrompt(true)
      setIsInProgress(true)
    },
    onIsCompleted: () => {
      setShouldShowPrompt(false)
    }
  });

  const { executeEvaluationPrompt } = useEvaluationPrompt({
    onCompleted: ({ consent, evaluation }) => {   
      handleCanAccessFollowup({
        initialisingStep: consent as IDBUserFlowStepTrack,
        followUpStep: evaluation as IDBUserFlowStepTrack
      });
    }
  });

  useEffect(() => {
    executeEvaluationPrompt();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shouldShowPrompt ? (
    <div className="flex flex-col xl:flex-row flex-1 items-center ek-emphasise mb-10">
      <img alt="Speech bubble" className="w-26 h-26 block mx-auto" src="/images/feedback-icon.svg" />

      <div className="px-6 flex-1">
        <span className=" h6 weight-600 mb-1 block">
          {isInProgress ? CONTINUE_PROMPT : START_PROMPT}
        </span>
        <p className="h3 mb-4 xl:mb-0"></p>
      </div>

      <Button 
        linkButton={{
           id: 'current', 
           url: String('/console/evaluation') 
        }} 
        text={isInProgress ? 'Continue' : 'Start'} 
        variant="secondary" 
      />
    </div>
  ) : null;
};
