import { useEffect, useState } from "react";

import { IDBUserFlowStepTrack } from "@netfront/ekardo-content-library";
import { IOpenEvaluationMonth, getMonthTimePoint, useGetTrackings } from "hooks";
import last from "lodash.last";
import { isDaysBetween } from "utils";

interface IUseEvaluationPromptCompleted {
  consent?: IDBUserFlowStepTrack;
  evaluation?: IDBUserFlowStepTrack;
  followUpTimePoint?: IOpenEvaluationMonth;
  hasConsented: boolean;
  isEvaluationOpen: boolean;
}

interface IUseEvaluationPromptParams {
  onCompleted: (results: IUseEvaluationPromptCompleted) => void;
  onError?: () => void;
};


export const useEvaluationPrompt = ({ onCompleted, onError }: IUseEvaluationPromptParams) => {
  const [consentTracking, setConsentTracking] = useState<IDBUserFlowStepTrack>()

  const { handleGetTrackings: handleGetTrackingsConsent } = useGetTrackings({ 
    onCompleted: ({ trackings }) => {
      setConsentTracking(trackings[0])
    },
    onError
  });

  const { handleGetTrackings: handleGetTrackingsEvaluationQuestionnaire } = useGetTrackings({
    onCompleted: ({ trackings }) => {
      const lastestTracking = last(trackings);
      const consentDate = new Date(String(consentTracking?.completed));


      const isWithinDateRange = isDaysBetween(consentDate, 0, 14) || isDaysBetween(consentDate, 90, 104) || 
      isDaysBetween(consentDate, 180 , 194) ||
      isDaysBetween(consentDate, 270 , 284) ||
      isDaysBetween(consentDate, 360  , 374);

      if(!isWithinDateRange) return;

      if(lastestTracking?.isCompleted) {
        onCompleted({
          consent: consentTracking,
          evaluation: lastestTracking,
          isEvaluationOpen: false,
          hasConsented: true,
          followUpTimePoint: getMonthTimePoint(consentDate)
        });
        
        return;
      }

      onCompleted({
        consent: consentTracking,
        evaluation: lastestTracking,
        hasConsented: true,
        isEvaluationOpen: true,
        followUpTimePoint: getMonthTimePoint(consentDate)
      })
    }
  });

  const executeEvaluationPrompt = () => {
    handleGetTrackingsConsent({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      includeAnswers: true,
      includeUserFlowStep: true,
      userFlowStepUrls: ['consent'],
    });
  }

  useEffect(() => {
    if(!consentTracking) {
      return;
    };
    
    handleGetTrackingsEvaluationQuestionnaire({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['evaluation'],
      includeAnswers: false,
      includeUserFlowStep: false,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentTracking])


  return {
    executeEvaluationPrompt
  }

};