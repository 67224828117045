import { Button } from '@netfront/ui-library';

export const CalendarCardEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center h-full w-full md:w-1/2 mx-auto">
      <div className="mb-2">
        <img alt="Empty" className="max-w-20" src="/images/no-calendar-entry-icon.svg" />
      </div>
      <span className="color-black h5 weight-800 mb-1 block">No activities yet.</span>
      <p className="mb-8">You don’t have anything scheduled for today. Jump across to your calendar to schedule some activities.</p>
      <Button linkButton={{ id: 'calendar', url: '/mood-and-activity-tracker' }} text="View Mood and activity tracker" />
    </div>
  );
};
