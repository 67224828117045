import { useState } from 'react';

import { Button } from '@netfront/ui-library';

import { AgencySelectionSectionCardProps } from './AgencySelectionSection.interfaces';

import { getValidClassNames } from '../../utils';

export const AgencySelectionSectionCard = ({
  activeImage,
  inactiveImage,
  label,
  onAgencyClick,
  selectedAgency,
  type,
}: AgencySelectionSectionCardProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  return (
    <div className="w-full md:w-1/2 lg:w-1/2 p-4">
      <div
        className={getValidClassNames({
          'bg-white border-2 border-athens h-full rounded p-6 px-8 flex flex-col items-center text-center transition-200': true,
          'border-primary shadow-xl scale-1': isHovered || type === selectedAgency,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <img alt={`${label} resources`} className="h-36 mb-8" src={isHovered || type === selectedAgency ? activeImage : inactiveImage} />
        <h2 className="h3 color-secondary">{label}</h2>
        <Button
          additionalClassNames="mt-auto w-full"
          text={type === selectedAgency ? 'Selected' : 'Select agency'}
          variant={type === selectedAgency ? 'primary' : 'secondary'}
          onClick={() => onAgencyClick(type)}
        />
      </div>
    </div>
  );
};
