import { ApolloError } from '@apollo/client';
import { useSendContactForm } from '@netfront/gelada-identity-library';
import { Button, Input, Textarea } from '@netfront/ui-library';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { BreadcrumbHomeLink, BreadcrumbLink, IContactFormValues, InformationBanner, SingleFormPage } from '../../../components';
import { useHasPrivateLayout, useToast } from '../../../hooks';

const ContactPage = () => {
  const PAGE_TITLE = 'Contact us';

  const { handleToastError, handleToastSuccess } = useToast();
  const { asPath, push } = useRouter();
  const { hasPrivateLayout } = useHasPrivateLayout();

  const sendContactFormCompleted = async (message: string, email: string, firstname: string, lastname: string) => {
    if (isEmpty(email)) {
      handleToastError({
        error: new ApolloError({ errorMessage: 'Please provide a valid email' }),
      });
      return;
    }

    handleToastSuccess({
      message: 'Thank you for your message! We will be in touch shortly.',
    });

    await handleSendContactForm({
      firstName: firstname,
      lastName: lastname,
      message: message,
      sender: email,
      confirmationEmailPlaceholders: [{ key: 'firstName', value: firstname }],
    });

    push('/').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const { handleSendContactForm, isLoading: isFormSubmitting } = useSendContactForm();

  const formik = useFormik<IContactFormValues>({
    initialValues: {
      email: '',
      message: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().label('Email').email().required('Email is required'),
      message: yup.string().label('Message').required('Message is required'),
    }),
    onSubmit: async ({ email, message }: IContactFormValues) => {
      await sendContactFormCompleted(message, email, '', '');
    }
  });

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href={hasPrivateLayout ? '/dashboard' : '/'} />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      className='pt-0'
      hasPrivateLayout={hasPrivateLayout}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
    >

      <InformationBanner>
        <p className='mb-2'>We would love to hear any feedback you have regarding Stable Ground below.</p>
        <p className='mb-0'>If you have a question for our team, please be sure to include your email so that we may respond, or email us at <NextLink href="mailto:info@stableground.org.au" target="_blank" rel="noreferrer"><span className='color-primary weight-600'>info@stableground.org.au</span></NextLink>.</p>
      </InformationBanner>

      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <Input id="email" labelText="Email" name="email" type="text" value={formik.values.email} onChange={formik.handleChange} />
          {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
        </div>

        <div className="mb-4">
          <Textarea id='message' labelText='Message' name='message' value={formik.values.message} onChange={formik.handleChange} />
          {formik.touched.message && formik.errors.message ? <div>{formik.errors.message}</div> : null}
        </div>

        <div className='text-center'>
          <Button
            isLoading={isFormSubmitting}
            text="Send"
            type="submit"
            variant="secondary"
          />
        </div>
      </form>
    </SingleFormPage>
  );
};

export { ContactPage };
