import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Checkbox, Dialog, Select, Textarea } from '@netfront/ui-library';
import { BreadcrumbLink, Page } from 'components';
import { useSocialContext } from 'contexts';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { DBCommunity, DBCommunityConnection } from '../../../../interfaces';
import {
  CommunityMembersQueryResult,
  ECommunityRole,
  EConnectionStatus,
  useCreateConversations,
  useGetCommunitiesByUser,
  useGetCommunityUsers,
} from '../../../../services';
import { GetUserCommunitiesQueryResult } from '../../../../services/hooks';
import { EmptyMessage, ListCard, ListCardSkeleton, SearchInput, getWelcomeTag, useSearchInput } from '../../../Social';

const NewMessagesPage = () => {
  const PAGE_TITLE = 'New messsage';

  const {
    asPath,
    push,
    query: { community: communityParam },
  } = useRouter();
  const { user: loggedUser } = useSocialContext();
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();
  const { isToggled: isBulkMessageDialogOpen, toggle: toggleIsBulkMessageDialogOpen } = useToggle();

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isSelectUsersChecked, setIsSelectUsersChecked] = useState<boolean>(false);
  const [isSelectAllUsersChecked, setIsSelectAllUsersChecked] = useState<boolean>(false);
  const [bulkMessage, setBulkMessage] = useState<string>('');
  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);

  const [activeCommunityId, setActiveCommunityId] = useState<number>();
  const [users, setUsers] = useState<DBCommunityConnection[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[]) => {
    const returnedCommunities = communities.map(({ node }) => node);
    setUserCommunities(returnedCommunities);
    setActiveCommunityId(communityParam ? Number(communityParam) : returnedCommunities[0].id)
  };

  const handleCreateConversationsCompleted = () => {
    setSelectedUsers([]);
    toggleIsBulkMessageDialogOpen();
    push('/community/messages');
  };

  const handleGetCommunityUsersCompleted = (members: CommunityMembersQueryResult[]) => {
    const userRole = members.map(({ node }) => node).find(({ user }) => user?.id === loggedUser?.id)?.role;

    const isModerator = userRole === ECommunityRole.Moderator || userRole === ECommunityRole.Owner;

    const moderators = members
      .map(({ node }) => node)
      .filter(({ role }) => role === ECommunityRole.Moderator);

    const allMembers = members.map(({ node }) => node).filter(({ user }) => user?.id !== loggedUser?.id);

    setUsers(isModerator ? allMembers : moderators);

    setIsSelectAllUsersChecked(false);
    setIsLoading(false);
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: getUserCommunitiesCompleted,
  });

  const { getCommunityUsers } = useGetCommunityUsers({
    onCompleted: handleGetCommunityUsersCompleted,
  });

  const { createConversations } = useCreateConversations({
    onCompleted: handleCreateConversationsCompleted,
  });

  useEffect(() => {
    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
        shouldIncludeUserConnection: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(!activeCommunityId) return;

    setIsLoading(true);

    getCommunityUsers({
      variables: {
        communityId: activeCommunityId,
        shouldIncludeUser: true,
        status: EConnectionStatus.Accepted,
      },
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCommunityId])

  const usersWithSearchFilter = users.filter(({ user }) =>
    searchValue && isSearchActive ? String(user?.displayedName).toLowerCase().includes(searchValue.toLowerCase()) : user,
  );

  const moderators = usersWithSearchFilter.filter(({ role }) => role === ECommunityRole.Moderator).sort((a, b) => a.user?.isOnline && !b.user?.isOnline ? -1 : !a.user?.isOnline && b.user?.isOnline ? 1 : 0);

  const communityMembers = usersWithSearchFilter.filter(({ role }) => role === ECommunityRole.Member).sort((a, b) => a.user?.hasInteracted && !b.user?.hasInteracted ? -1 : !a.user?.hasInteracted && b.user?.hasInteracted ? 1 : 0);

  const communitiesWithModeratorRole = userCommunities.filter(({ userConnection }) => userConnection?.role === ECommunityRole.Moderator || userConnection?.role === ECommunityRole.Owner)


  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community">Community</BreadcrumbLink>, key: 'Community' },
          { content: <BreadcrumbLink href="/community/messages">Messages</BreadcrumbLink>, key: 'Messages' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `Create new conversation`,
        seoTitle: PAGE_TITLE,
      }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-new-messages-page__container">
        <div className="c-new-messages-page__search">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search messages"
            name="search"
            placeholder="Search users to message"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />
        </div>

        <section className="c-connections-page__section">

          {communityMembers.length > 0 && (
            <div className="c-new-messages-page__actions">
              <div>
                <div className="c-new-messages-page__checkboxes">
                  <Checkbox
                    id="selectUsers"
                    isChecked={isSelectUsersChecked}
                    labelText={`${isSelectUsersChecked && selectedUsers.length ? `${selectedUsers.length} selected` : 'Select users'}`}
                    name="selectUsers"
                    value="selectUsers"
                    onChange={({ target: { checked } }) => {
                      setIsSelectUsersChecked(checked);
                      setSelectedUsers([]);
                    }}
                  />
                  <Checkbox
                    id="allUsers"
                    isChecked={isSelectAllUsersChecked}
                    labelText={`Select all ${usersWithSearchFilter.length}`}
                    name="allUsers"
                    value="allUsers"
                    onChange={({ target: { checked } }) => {
                      setIsSelectAllUsersChecked(checked);
                      setIsSelectUsersChecked(checked);
                      setSelectedUsers(checked ? usersWithSearchFilter.map(({ userId }) => userId) : []);
                    }}
                  />
                </div>
              </div>
              <Select
                id="actions"
                isDisabled={selectedUsers.length === 0}
                labelDefaultText="Bulk actions"
                labelText="Bulk actions"
                name="actions"
                options={[{ id: 1, name: 'Send message', value: 'message' }]}
                isLabelHidden
                onChange={({ target: { value } }) => {
                  if (value === 'message') {
                    toggleIsBulkMessageDialogOpen();
                  }
                }}
              />
              <Dialog
                isOpen={isBulkMessageDialogOpen}
                title="Send bulk message"
                onClose={toggleIsBulkMessageDialogOpen}
                onConfirm={() => {
                  createConversations({
                    variables: {
                      message: bulkMessage,
                      receivers: selectedUsers,
                    },
                  });
                }}
              >
                <Textarea
                  id="bulkMessage"
                  labelText="Message"
                  name="bulkMessage"
                  value={bulkMessage}
                  isLabelHidden
                  onChange={({ target: { value } }) => setBulkMessage(value)}
                />
              </Dialog>


              {communitiesWithModeratorRole.length > 1 && activeCommunityId && (
                <div className='ml-auto'>
                  <Select
                    additionalClassNames='ml-auto'
                    id="moderator-communities"
                    labelText="Filter users by community"
                    name="moderator-communities"
                    options={communitiesWithModeratorRole.map(({ id, title }) => ({ id, name: title, value: String(id) }))}
                    value={String(activeCommunityId)}
                    onChange={({ target: { value } }) => {
                      setActiveCommunityId(Number(value))
                      push(`/community/messages/new?community=${value}`)
                    }}
                  />
                </div>
              )}

            </div>
          )}

          {isLoading ? (
            <>
              <ListCardSkeleton />
              <ListCardSkeleton />
            </>
          ) : null}

          {!isLoading && moderators.length !== 0 ? (
            <div className="mb-8">
              <h2 className="h6 mb-4">Moderators</h2>
              {moderators.map((communityConnection, key) => {
                return (
                  <ListCard
                    key={`${communityConnection.id}-${key}`}
                    avatarImage={communityConnection.user?.profileImage?.presignedUrl}
                    avatarTitle={communityConnection.user?.displayedName}
                    {...(isSelectUsersChecked && {
                      checkBox: {
                        id: `message-user-${String(communityConnection.user?.id)}`,
                        labelText: 'Select user',
                        name: 'select',
                        isChecked: selectedUsers.includes(Number(communityConnection.user?.id)),
                        onChange: ({ target: { checked, value } }) => {
                          if (!checked) {
                            setIsSelectAllUsersChecked(false);
                          }
                          setSelectedUsers(
                            checked ? [...selectedUsers, Number(value)] : selectedUsers.filter((userId) => userId !== Number(value)),
                          );
                        },
                        value: String(communityConnection.user?.id),
                        isLabelHidden: true,
                      },
                    })}
                    description="Send message"
                    displayName={communityConnection.user?.displayedName}
                    isOnline={communityConnection.user?.isOnline}
                    tag={communityConnection.user?.isOnline ? 'Online' : ''}
                    hasArrow
                    isModerator
                    {...(!isSelectUsersChecked && { href: `/community/messages/${String(communityConnection.user?.key)}` })}
                  />
                );
              })}
            </div>
          ) : null}

          {!isLoading && communityMembers.length !== 0 ? (
            <div className="mb-4">
              <h2 className="h6 mb-4">Members</h2>
              {communityMembers.map((communityConnection, key) => {
                return (
                  <ListCard
                    key={`${communityConnection.id}-${key}`}
                    avatarImage={communityConnection.user?.profileImage?.presignedUrl}
                    avatarTitle={communityConnection.user?.displayedName}
                    {...(isSelectUsersChecked && {
                      checkBox: {
                        id: `message-user-${String(communityConnection.user?.id)}`,
                        labelText: 'Select user',
                        name: 'select',
                        isChecked: selectedUsers.includes(Number(communityConnection.user?.id)),
                        onChange: ({ target: { checked, value } }) => {
                          if (!checked) {
                            setIsSelectAllUsersChecked(false);
                          }
                          setSelectedUsers(
                            checked ? [...selectedUsers, Number(value)] : selectedUsers.filter((userId) => userId !== Number(value)),
                          );
                        },
                        value: String(communityConnection.user?.id),
                        isLabelHidden: true,
                      },
                    })}
                    description="Send message"
                    displayName={communityConnection.user?.displayedName}
                    isOnline={communityConnection.user?.hasInteracted}
                    tag={getWelcomeTag(new Date(String(communityConnection.updatedDate))) ? getWelcomeTag(new Date(String(communityConnection.updatedDate))) : communityConnection.user?.hasInteracted ? 'Online' : ''}
                    hasArrow
                    {...(!isSelectUsersChecked && { href: `/community/messages/${String(communityConnection.user?.key)}` })}
                  />
                );
              })}
            </div>
          ) : null}

          {!isLoading && users.length === 0 && <EmptyMessage message="No users available" />}
        </section>
      </div>
    </Page>
  );
};

export { NewMessagesPage };
