import { ChangeEvent } from 'react';

import { IAdditionalClassNames, InputProps } from '@netfront/ui-library';

/**
 * Todo - Export to more global file location - common-library
 */
export enum ESize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

export interface ISearchInputEventHandlers {
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
  onSearchSubmit: (value: string) => void;
}

export interface ISearchInputState {
  isSearchActive?: boolean;
}

export interface IUseSearchInput extends ISearchInputEventHandlers, ISearchInputState {
  searchValue?: string;
}

export interface IUseSearchInputParams {
  initialSearchValue?: string;
  isDefaultSearchActive?: boolean;
}

export interface SearchFilterProps extends IAdditionalClassNames, Omit<InputProps, 'onChange'>, ISearchInputState {
  onClear: () => void;
  onSearch: (value: string) => void;
  size?: ESize;
}
