import { useEffect, useState } from 'react';

import {
  IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse,
  IUserFlowStepTrack,
  useGetUserFlowStepTracksWithAnswers,
} from '@netfront/ekardo-content-library';
import { isWithinInterval, sub, subWeeks } from 'date-fns';

import { CheckinCardEmpty } from './CheckinCardEmpty';

import { CheckinChart, DashboardCard, DashboardCardSkeleton } from '../../components';

export const CheckinCard = () => {
  const [userFlowStepTracks, setUserFlowStepTracks] = useState<IUserFlowStepTrack[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onGetUserFlowStepTracksWithAnswersCompleted = (data: IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse) => {
    const { userFlowStepTracks: trackings } = data;
    setUserFlowStepTracks([...trackings].filter(({ isCompleted }) => isCompleted));
    setIsLoading(false);
  };

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: onGetUserFlowStepTracksWithAnswersCompleted,
    onError: () => setIsLoading(false),
  });

  useEffect(() => {
    const start = sub(new Date(), { weeks: 4 });
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'checkin',
      start,
      end: new Date(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasDataInLastWeek = Boolean(
    userFlowStepTracks.filter(({ completedLongDate }) =>
      isWithinInterval(new Date(completedLongDate), {
        start: subWeeks(new Date(), 1),
        end: new Date(),
      }),
    ),
  );

  return (
    <DashboardCard 
      cardTooltip={{ text: 'Use this weekly check-in activity to keep track of your substance use.' }}
      href="/checkin" 
      linkLabel="Check-in"
    >
      {!isLoading && userFlowStepTracks.length && hasDataInLastWeek ? (
        <div className="flex flex-col h-full justify-center">
          <span className="color-black h5 weight-800 mb-6 block">Last month</span>
          <CheckinChart userFlowStepTracks={userFlowStepTracks.reverse()} displayAllCategories />
        </div>
      ) : null}
      {(!isLoading && !userFlowStepTracks.length) || (!isLoading && !hasDataInLastWeek) ? <CheckinCardEmpty /> : null}
      {isLoading ? <DashboardCardSkeleton direction="column" /> : null}
    </DashboardCard>
  );
};
