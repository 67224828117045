import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { RESET_TRACKING } from './useResetTracking.graphql';
import {
  IHandleResetTrackingParams,
  IResetTrackingMutationGraphQLResponse,
  IResetTrackingMutationVariables,
  IUseResetTrackingOptions,
  IUseResetTracking,
} from './useResetTracking.interfaces';

/**@todo Move to library */

const useResetTracking = (options?: IUseResetTrackingOptions): IUseResetTracking => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseResetTrackingOptions);

  const [executeResetTracking, { loading: isLoading }] = useEkardoMutation<
  IResetTrackingMutationGraphQLResponse,
  IResetTrackingMutationVariables
  >({
    mutation: RESET_TRACKING,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStepTrack: { reset: isReset },
        } = data;

        onCompleted({
          result: isReset,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleResetTracking = async ({ id }: IHandleResetTrackingParams) => {
    await executeResetTracking({
      variables: {
        id,
      },
    });
  };

  return {
    handleResetTracking,
    isLoading,
  };
};

export { useResetTracking };
