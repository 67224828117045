import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getAccessTokenCookie } from '@netfront/common-library';
import { IResetPasswordOnCompletedResponse, useResetPassword, ResetPasswordForm } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { BreadcrumbLink, BUTTON_CLASSES, InformationBanner, Link, SingleFormPage } from '../../../components';
import { useGetRootBreadcrumbItems, useToast } from '../../../hooks';

const ResetPasswordPage = () => {
  const PAGE_TITLE = 'Reset password';

  const { asPath, query } = useRouter();
  const { code } = query;
  const accessToken = getAccessTokenCookie();

  const [isResetPasswordCompleted, setIsResetPasswordCompleted] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const rootBreadcrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: Boolean(accessToken),
  });

  const handleResetPasswordCompleted = ({ isCompleted }: IResetPasswordOnCompletedResponse) => {
    setIsResetPasswordCompleted(isCompleted);
  };

  const handleResetPasswordError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleResetPassword, isLoading = false } = useResetPassword({
    onCompleted: handleResetPasswordCompleted,
    onError: handleResetPasswordError,
  });

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [...rootBreadcrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
    >
      <InformationBanner title="Change your password">
        <p className="mb-0">Receive a reset link</p>
      </InformationBanner>

      {!isResetPasswordCompleted ? (
        <ResetPasswordForm
          buttonClassName={BUTTON_CLASSES.default}
          isSubmitting={isLoading}
          onResetPassword={(password) =>
            handleResetPassword({
              code: String(code),
              newPassword: password,
            })
          }
        />
      ) : (
        <>
          <div className="mb-8 md:mb-8">Your password has been successfully reset.</div>

          <Link href="/login" theme="default">
            Return to login
          </Link>
        </>
      )}
    </SingleFormPage>
  );
};

export { ResetPasswordPage };
