import { useAuthentication } from '@netfront/gelada-identity-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { ResourceCardProps } from './ResourceCard.interfaces';

import { HeartIcon } from '../../components';
import { getValidClassNames } from '../../utils';

const ResourceCard = ({
  contentPageId,
  description,
  href,
  image,
  subHeading,
  title,
  isLiked,
  onLikeClicked,
  onUnlikeClicked,
}: ResourceCardProps) => {
  const { isAuthenticated } = useAuthentication();
  const { push } = useRouter();

  return (
    <NextLink href={href}>
      <span className="bg-white color-black border-3 border-primary color-inherit flex flex-col rounded overflow-hidden h-full">
        <div className="p-4">
          <div className="flex">
            <span className="color-primary text-sm weight-600 flex-1">{subHeading}</span>
            <button
              className={getValidClassNames({
                'p-0 pointer rounded-circle flex items-center justify-center border-2 p-1 w-8 h-8': true,
                'border-primary': Boolean(!isLiked),
                'bg-pink-300 border-pink-300': Boolean(isLiked),
              })}
              onClick={(event) => {
                event.preventDefault();
                if (!isAuthenticated) {
                  push('/login');
                  return;
                }
                if (isLiked) {
                  onUnlikeClicked?.(contentPageId);
                } else {
                  onLikeClicked?.(contentPageId);
                }
              }}
            >
              <span className="sr-only">{isLiked ? 'Remove page bookmark' : 'Bookmark this page'}</span>
              <HeartIcon
                additionalClassNames={getValidClassNames({
                  'w-4 h-4': true,
                  'color-white': Boolean(isLiked),
                  'color-white stroke-primary': Boolean(!isLiked),
                })}
              />
            </button>
          </div>
          <span className="block h6 color-secondary weight-600 mb-1">{title}</span>
          <p className="color-black text-sm line-1.2">{description}</p>
        </div>
        {image && (
          <div className="p-4 pt-0 mt-auto">
            <img alt={title} className="block h-36 mx-auto" src={image} />
          </div>
        )}
      </span>
    </NextLink>
  );
};

export { ResourceCard };
