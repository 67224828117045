import { useEffect, useState } from 'react';

import { Dialog } from '@netfront/ui-library';

import { ListCard, ListCardSkeleton } from '../ListCard';
import { RelateIcon } from '../RelateIcon';

import { PostRelatesDialogProps } from './PostRelatesDialog.interfaces';

import { DBPostRelate } from '../../../interfaces';
import { ERelateType, GetRelatesQueryResult, useGetPostRelates } from '../../../services';

const PostRelatesDialog = ({ postId, isOpen, onClose }: PostRelatesDialogProps) => {
  const [postRelates, setPostRelates] = useState<DBPostRelate[]>([]);

  const handleGetPostRelatesCompleted = (relates: GetRelatesQueryResult[]) => {
    setPostRelates(relates.map(({ node }) => node));
  };

  const { getPostRelates, isLoading } = useGetPostRelates({
    onCompleted: handleGetPostRelatesCompleted,
  });

  useEffect(() => {
    if (isOpen) {
      getPostRelates({
        variables: {
          postId,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const postRelateCounts = postRelates
    .map(({ type }) => type as ERelateType)
    .reduce((types, type) => {
      const count: number = types[type] || 0;
      types[type] = count + 1;
      return types;
    }, {});

  const canRenderRelates = !isLoading && postRelates.length;

  return isOpen ? (
    <Dialog additionalClassNames="c-post-relate-dialog" title="Post relates" isOpen onClose={onClose}>
      <div className="c-post-relate-dialog__header">
        {Object.keys(postRelateCounts).map((type, key) => (
          <div key={key} className="c-post-relate-dialog__count">
            <RelateIcon relateType={type as ERelateType} />
            <span>{postRelateCounts[type]}</span>
          </div>
        ))}
      </div>
      {canRenderRelates &&
        postRelates.map(({ id, type, user }) => (
          <ListCard
            key={id}
            avatarImage={user?.profileImage?.presignedUrl}
            avatarTitle={user?.displayedName}
            displayName={user?.displayedName}
            href={`/community/profile/${String(user?.key)}`}
          >
            <RelateIcon relateType={type as ERelateType} />
          </ListCard>
        ))}

      {isLoading && [...Array(2)].map((_, key) => <ListCardSkeleton key={key} />)}
    </Dialog>
  ) : null;
};

export { PostRelatesDialog };
