import { useEffect, useState } from 'react';

import { IDBUserFlowStepTrack, useEndUserFlowStep, useToast } from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { format } from 'date-fns';
import {
  IGetContentPagesOnCompletedResponse,
  IUseGetTrackingsOnCompletedResponse,
  useDefineIsMarked,
  useGetContentPages,
  useGetRootBreadcrumbItems,
  useGetTrackings,
  useResetTracking,
} from 'hooks';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { BreadcrumbLink, InformationBanner, NoData, Page, TickIcon } from '../../components';

export const stepIdIconMap = {
  256: '/images/alcohol-icon.svg',
  257: '/images/mental-health-icon.svg',
  258: '/images/lifestyle-icon.svg',
  259: '/images/work-school-icon.svg',
  260: '/images/relationship-icon.svg',
  261: '/images/finance-icon.svg',
  262: '/images/gambling-icon.svg',
};

const GoalsPage: NextPage = () => {
  const PAGE_TITLE = 'Goal setting';
  const ADD_GOAL_URL = '/goals/add';
  const QUESTION_DATE = 26117;
  const QUESTION_STEP_2_EDIT_YOUR_GOAl = 26519;

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { asPath, push } = useRouter();
  const { handleToastSuccess } = useToast();

  const [inProgressTrackings, setInProgressTrackings] = useState<IDBUserFlowStepTrack[]>([]);
  const [completedTrackings, setCompletedTrackings] = useState<IDBUserFlowStepTrack[]>([]);
  const [draftTrackings, setDraftTrackings] = useState<IDBUserFlowStepTrack[]>([]);
  const [selectedGoal, setSelectedGoal] = useState<[number, string]>();

  const fetchTrackings = () => {
    handleGetTrackings({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: String(process.env.REACT_APP_GOAL_USER_FLOW_URL),
      includeAnswers: true,
      includeUserFlowStep: true,
    });
  };

  useEffect(() => {
    fetchTrackings();
  }, []);

  const onTrackingReset = () => {
    fetchTrackings();
    handleToastSuccess({ message: 'Goal reset' });
  };

  const onDefineIsMarkedCompleted = () => {
    fetchTrackings();
    handleToastSuccess({ message: 'Goal deleted' });
  };

  const onGetTrackingsCompleted = (data: IUseGetTrackingsOnCompletedResponse) => {
    const nonArchivedTrackings = data.trackings.filter((r) => !r.isMarked);
    const inProgress = nonArchivedTrackings.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (r: IDBUserFlowStepTrack) => !r.completed && r.answers.find((answer) => answer.contentSnippetQuestionId === QUESTION_DATE),
    );

    const completed = nonArchivedTrackings.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (r: IDBUserFlowStepTrack) => r.completed,
    );

    const drafts = nonArchivedTrackings.filter(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (r: IDBUserFlowStepTrack) => !r.completed && !r.answers.find((answer) => answer.contentSnippetQuestionId === QUESTION_DATE),
    );

    setCompletedTrackings(completed);
    setInProgressTrackings(inProgress);
    setDraftTrackings(drafts);
  };

  const onEndUserFlowStepCompleted = () => {
    fetchTrackings();
    handleToastSuccess({ message: 'Goal completed' });
  };

  const getGoal = (tracking: IDBUserFlowStepTrack) => {
    const answer = tracking.answers.find((r) => r.contentSnippetQuestionId === QUESTION_STEP_2_EDIT_YOUR_GOAl);
    if (!answer) return '';
    return answer.text;
  };

  const getDate = (tracking: IDBUserFlowStepTrack) => {
    const answer = tracking.answers.find((r) => r.contentSnippetQuestionId === QUESTION_DATE);
    if (!answer) return '';
    return format(new Date(answer.dateAnswered), 'dd/MM/yyyy');
  };

  const onDeleteClick = (userFlowStepTrackingId: number) => {
    handleDefineIsMarked({ trackingId: userFlowStepTrackingId, isMarked: true });
  };

  const onCompleteClick = (userFlowStepTrackingId: number) => {
    handleEndUserFlowStep({ userFlowStepTrackId: userFlowStepTrackingId });
  };

  const onGetContentPagesCompleted = (data: IGetContentPagesOnCompletedResponse) => {
    const { contentPages } = data;
    const { edges } = contentPages;
    const nodes = edges.map((r) => r.node);
    nodes.sort((a, b) => a.sort - b.sort);
    const [firstPage] = nodes;
    const { url } = firstPage;
    if (!selectedGoal) return;
    const [userFlowStepTrackingId, userFlowStepUrl] = selectedGoal;
    push(`/goals/${url}?userFlowStepTrackingId=${userFlowStepTrackingId}&userFlowStepUrl=${userFlowStepUrl}`, `/goals/${url}`);
  };

  const onReviewClick = (userFlowStepTracking: IDBUserFlowStepTrack) => {
    const {
      id: userFlowStepTrackingId,
      userFlowStep: { url: userFlowStepUrl, contentGroupId },
    } = userFlowStepTracking;

    if (!contentGroupId) return;
    setSelectedGoal([userFlowStepTrackingId, userFlowStepUrl]);
    handleGetContentPages({ projectId: String(process.env.REACT_APP_PROJECT_ID), contentGroupId: contentGroupId });
  };

  const onEditClick = (userFlowStepTracking: IDBUserFlowStepTrack) => {
    const {
      id: userFlowStepTrackingId,
      userFlowStep: { lastViewedContentPage, url: userFlowStepUrl },
    } = userFlowStepTracking;

    if (!lastViewedContentPage) return;

    const { url: contentPageUrl } = lastViewedContentPage;
    push(
      `/goals/${contentPageUrl}?userFlowStepTrackingId=${userFlowStepTrackingId}&userFlowStepUrl=${userFlowStepUrl}`,
      `/goals/${contentPageUrl}`,
    );
  };

  const onAddNewClick = () => {
    push(ADD_GOAL_URL);
  };

  const { handleResetTracking, isLoading: isResetTrackingLoading = false } = useResetTracking({ onCompleted: onTrackingReset });
  const { handleDefineIsMarked, isLoading: isDefineIsMarkedLoading = false } = useDefineIsMarked({
    onCompleted: onDefineIsMarkedCompleted,
  });

  const { handleGetTrackings, isLoading: isGetTrackingsLoading = false } = useGetTrackings({ onCompleted: onGetTrackingsCompleted });
  const { handleEndUserFlowStep } = useEndUserFlowStep({
    onCompleted: onEndUserFlowStepCompleted,
  });
  const { handleGetContentPages, isLoading: isGetContentPagesLoading = false } = useGetContentPages({
    onCompleted: onGetContentPagesCompleted,
  });

  const isLoading = isGetTrackingsLoading || isDefineIsMarkedLoading || isGetContentPagesLoading || isResetTrackingLoading;
  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      isPreloaderVisible={isLoading}
      meta={{
        seoDescription: 'Use our structured goal setting activity to create personal SMART goals.',
        seoTitle: 'Goal setting – Resources| Stable Ground',
      }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="container pb-12">
        <InformationBanner additionalClassNames='items-start'>
          <h2 className="h5">Create, update and review your goals.</h2>
          <p>
            Sometimes your life may feel a bit unsteady. This feeling could be related to something happening in your job, your home or
            social life, or you may just feel a bit rocky. If you feel this way, that’s okay. Everyone experiences feelings like these.
            Setting some personal goals is one way that can help you feel a bit more in control of your life and what you want that life to
            look like.
          </p>
          <p className="mb-8">
            In this activity, you will be taken through some questions and prompts that could help you create and reach your own personal
            goals.
          </p>

      
        </InformationBanner>

        <div className='bg-white p-8 rounded mb-8'>
          <img 
            alt='Goals' 
            className='block mx-auto rounded max-w-98' 
            src='/images/goal setting image.png' 
          />
        </div>

        <div className="mb-4 text-right">
          <Button text="+ New goal" variant="secondary" onClick={onAddNewClick} />
        </div>

        {inProgressTrackings.length === 0 && completedTrackings.length === 0 && draftTrackings.length === 0 ? (
          <NoData message="It looks like you haven't entered any goals yet, select the add new goal button above" title="No goals added" />
        ) : null}

        <div className="mb-8">
          <div className="flex items-baseline mb-4">
            <div aria-hidden="true" className="h-4 w-4 rounded-circle border-4 border-accent mr-3 -mt-2px" />
            <h2 className="h3 color-secondary mb-0">In progress</h2>
          </div>

          {inProgressTrackings.length ? (
            <div className="none md:flex mb-3">
              <div className="flex w-1/4 weight-800 px-6">Type</div>
              <div className="flex w-1/4 weight-800 px-6">Goal</div>
              <div className="flex w-1/4 weight-800 px-6">Date</div>
              <div className="flex w-1/4 weight-800 px-6">Options</div>
            </div>
          ) : null}

          {inProgressTrackings.map((tracking) => {
            const { id, userFlowStep } = tracking;
            return (
              <div key={id} className="bg-white border-2 border-athens rounded flex flex-col md:flex-row mb-2">
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <img alt="placeholder" className="w-16 h-16 mr-6" src={stepIdIconMap[userFlowStep.id]} />
                  <p className="h6 color-secondary weight-800 mb-0">{userFlowStep.stepName}</p>
                </div>
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <p className=" mb-0">
                    <span className="weight-800 inline-block mr-2 mb-0 md:none">Goal:</span>
                    {getGoal(tracking)}
                  </p>
                </div>
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <p className="mb-0">
                    <span className="weight-800 inline-block mr-2 mb-0 md:none">Date:</span>
                    {getDate(tracking)}
                  </p>
                </div>
                <div className="flex flex items-center justify-center p-6 w-full md:w-1/4 gap-10">
                  <Button text="Complete" onClick={() => onCompleteClick(id)} />
                  <Button text="Delete" variant="danger" onClick={() => onDeleteClick(id)} />
                </div>
              </div>
            );
          })}
        </div>

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <div
              aria-hidden="true"
              className="color-white h-4 w-4 rounded-circle bg-primary mr-3 -mt-3px flex items-center justify-center p-3px"
            >
              <TickIcon />
            </div>
            <h2 className="h3 color-primary mb-0">Complete</h2>
          </div>

          {completedTrackings.length ? (
            <div className="none md:flex mb-3">
              <div className="flex w-1/4 weight-800 px-6">Type</div>
              <div className="flex w-1/4 weight-800 px-6">Goal</div>
              <div className="flex w-1/4 weight-800 px-6">Date</div>
              <div className="flex w-1/4 weight-800 px-6">Options</div>
            </div>
          ) : null}

          {completedTrackings.map((tracking) => {
            const { id, userFlowStep } = tracking;
            return (
              <div key={id} className="bg-white border-2 border-athens rounded flex flex-col md:flex-row mb-2">
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <img alt="placeholder" className="w-16 h-16 mr-6" src={stepIdIconMap[userFlowStep.id]} />
                  <p className="h6 color-secondary weight-800 mb-0">{userFlowStep.stepName}</p>
                </div>
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <p className=" mb-0">
                    <span className="weight-800 inline-block mr-2 mb-0 md:none">Goal:</span>
                    {getGoal(tracking)}
                  </p>
                </div>
                <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                  <p className="mb-0">
                    <span className="weight-800 inline-block mr-2 mb-0 md:none">Date:</span>
                    {getDate(tracking)}
                  </p>
                </div>
                <div className="flex flex items-center justify-center p-6 w-full md:w-1/4 gap-10">
                  <Button text="Review" variant="primary" onClick={() => onReviewClick(tracking)} />
                  <Button text="Reset" variant="secondary" onClick={() => handleResetTracking({ id: tracking.id })} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="mb-8">
          <div className="flex items-center mb-4">
            <div
              aria-hidden="true"
              className="color-white h-4 w-4 rounded-circle bg-secondary mr-3 -mt-2px flex items-center justify-center p-3px"
            >
              <span className="block mb-0 weight-800 -mt-3px">-</span>
            </div>
            <h2 className="h3 color-secondary mb-0">Draft</h2>
          </div>

          {draftTrackings.length ? (
            <div className="none md:flex mb-3">
              <div className="flex w-1/4 weight-800 px-6">Type</div>
              <div className="flex w-1/4 weight-800 px-6">Goal</div>
              <div className="flex w-1/4 weight-800 px-6">Date</div>
              <div className="flex w-1/4 weight-800 px-6">Options</div>
            </div>
          ) : null}
          {draftTrackings.map((r) => (
            <div key={r.id} className="bg-white border-2 border-athens rounded flex flex-col md:flex-row mb-2">
              <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                <img alt="placeholder" className="w-16 h-16 mr-6" src={stepIdIconMap[r.userFlowStep.id]} />
                <p className="h6 color-secondary weight-800 mb-0">{r.userFlowStep.stepName}</p>
              </div>
              <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                <p className=" mb-0">
                  <span className="weight-800 inline-block mr-2 mb-0 md:none">Goal:</span>
                  {getGoal(r)}
                </p>
              </div>
              <div className="flex flex items-center p-6 w-full md:w-1/4 border-r-2 border-athens">
                <p className="mb-0">
                  <span className="weight-800 inline-block mr-2 mb-0 md:none">Date:</span>
                  {getDate(r)}
                </p>
              </div>
              <div className="flex flex items-center justify-center p-6 w-full md:w-1/4 gap-10">
                <Button text="Edit" variant="secondary" onClick={() => onEditClick(r)} />
                <Button text="Delete" variant="danger" onClick={() => onDeleteClick(r.id)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default GoalsPage;
