import { useState } from 'react';

import { getAccessTokenCookie, useToggle } from '@netfront/common-library';
import { MultiLineTextSnippet, RatingSnippet } from '@netfront/ekardo-content-library';
import { Button, Dialog, Label } from '@netfront/ui-library';
import { useCreateFeedback, useCurrentAgency, useToast } from 'hooks';

export const Feedback = () => {
  const accessToken = getAccessTokenCookie();
  const { getAgency } = useCurrentAgency();

  const { handleToastSuccess } = useToast();
  const { isToggled: isFeedbackDialogOpen, toggle: toggleFeedbackDialog } = useToggle();

  const [rateValue, setRateValue] = useState<number>();
  const [additionalFeedback, setAdditionalFeedback] = useState<string>('');

  const shouldShowFeedbackFeature = Boolean(accessToken) || Boolean(getAgency());
  const { handleCreateFeedback, isLoading: isAddingFeedbackLoading } = useCreateFeedback({
    onCompleted() {
      handleToastSuccess({ message: 'Thanks for sharing your feedback.' });
      setRateValue(undefined);
      setAdditionalFeedback('');
      toggleFeedbackDialog();
    },
  });

  return shouldShowFeedbackFeature ? (
    <>
      <Button additionalClassNames="c-feedback-button rounded-t spotlight-item--7" size="small" text="Provide your feedback" variant="primary" onClick={toggleFeedbackDialog} />
      <Dialog
        additionalClassNames="c-feedback-dialog"
        confirmText="Submit feedback"
        isOpen={isFeedbackDialogOpen}
        title="Provide your feedback"
        onClose={toggleFeedbackDialog}
        onConfirm={() => {
          handleCreateFeedback({
            content: additionalFeedback,
            projectId: String(process.env.REACT_APP_PROJECT_ID),
            rate: rateValue ?? 0,
            url: window.location.href,
          });
        }}
      >
        <Label forId="rating" labelText="Overall, how satisfied are you with the website?" />
        <RatingSnippet
          id="rating"
          name="rating"
          rateValue={Number(rateValue)}
          onClick={(value) => {
            setRateValue(value);
          }}
        />

        <Label forId="feedback" labelText="Please share any additional feedback" />
        <MultiLineTextSnippet
          id="feedback"
          isDisabled={isAddingFeedbackLoading}
          value={additionalFeedback}
          onChange={(value) => {
            setAdditionalFeedback(value);
          }}
        />
      </Dialog>
    </>
  ) : null;
};
