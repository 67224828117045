import { useEffect } from 'react';

import { useAuthentication } from '@netfront/gelada-identity-library';
import { Breadcrumbs, Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import { useIsMounted } from 'hooks';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { IMeta, PageProps } from './Page.interfaces';

import { Feedback, GetHelpButton, PageHeading, ProgressSlide } from '../../../components';
import { getValidClassNames } from '../../../utils';
import { PrivateFooter, PublicFooter } from '../../Footer';
import { PrivateHeader, PublicHeader } from '../../Header';

const Page = ({
  bodyClassName,
  breadcrumbs,
  children,
  className,
  footer,
  hasPrivateLayout = false,
  header,
  isPreloaderVisible = false,
  meta,
  pageHeading,
  pageHeadingContainerClassNames,
  percentageSlider,
  shouldExcludeFooter,
  title,
}: PageProps) => {
  const { push } = useRouter();
  const { isMounted } = useIsMounted()
  const { seoDescription: seoDescriptionProp, seoTitle: seoTitleProp } = meta ?? ({} as IMeta);

  const seoTitle = `${seoTitleProp ? seoTitleProp : title}`;
  const seoDescription = seoDescriptionProp ? seoDescriptionProp : seoTitleProp ? seoTitleProp : title;

  const { isAuthenticated, accessToken } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated && hasPrivateLayout) {
      push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return isMounted ? (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta content={seoDescription} name="description" />
        <meta content={seoTitle} property="og:title" />
        <meta content={seoDescription} property="og:description" />
        <meta content="/logo-icon.jpg" property="og:image" />
        <link href={`${window.location.origin}${window.location.pathname}`} rel="canonical" />
      </Head>
      <div className={cx('flex flex-col min-height-screen relative', bodyClassName)}>
        {header ? header : hasPrivateLayout && Boolean(accessToken) ? <PrivateHeader /> : <PublicHeader />}
        <img alt="Diamond pattern" aria-hidden={true} className="fixed right-0 translate-x-50 top-0" src="/images/diamond-icon.svg" />
        {breadcrumbs && (
          <div className="relative c-breadcrumbs__container none md:block">
            <div className="container">
              <Breadcrumbs {...breadcrumbs} />
            </div>
          </div>
        )}
        {percentageSlider && <ProgressSlide percentage={percentageSlider.percentage} />}
        <div className="flex flex-col flex-1 relative">
          {pageHeading && (
            <PageHeading
              additionalClassNames={getValidClassNames({
                [String(pageHeadingContainerClassNames)]: Boolean(pageHeadingContainerClassNames),
              })}
              title={pageHeading}
            />
          )}
          <Spinner isLoading={isPreloaderVisible} />
          <main
            className={getValidClassNames({
              'relative flex-1': true,
              [String(className)]: Boolean(className),
            })}
          >
            {children}
          </main>
        </div>
        <Feedback />
        <GetHelpButton />
        {shouldExcludeFooter ? null : footer ? footer : hasPrivateLayout ? <PrivateFooter /> : <PublicFooter />}
      </div>
    </>
  ) : null;
};

export { Page };
