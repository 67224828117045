import { useEffect, useState } from 'react';

import { IDBQuestionAnswer } from '@netfront/ekardo-content-library';
import { LineChart } from '@netfront/ui-library';
import { format, isAfter, subMonths } from 'date-fns';

import {
  ALCOHOL_QUESTION_ID,
  AMPHETAMINE_QUESTION_ID,
  CANNABIS_QUESTION_ID,
  COCAINE_QUESTION_ID,
  HALLUCINOGENS_QUESTION_ID,
  INHALANTS_QUESTION_ID,
  OPIODS_QUESTION_ID,
  SEDATIVES_QUESTION_ID,
  DAYS_ALCOHOL_QUESTION_ID,
  DAYS_AMPHETAMINE_QUESTION_ID,
  DAYS_CANNABIS_QUESTION_ID,
  DAYS_COCAINE_QUESTION_ID,
  DAYS_HALLUCINOGENS_QUESTION_ID,
  DAYS_INHALANTS_QUESTION_ID,
  DAYS_OPIODS_QUESTION_ID,
  DAYS_SEDATIVES_QUESTION_ID,
  OTHER_QUESTION_ID,
  DAYS_OTHER_QUESTION_ID,
} from './CheckinChart.constants';
import { Category, CheckinChartProps, IDatapoint } from './CheckinChart.interfaces';

import { EAssistItem, InformationBanner, SUBSTANCE_THEME_KEYS } from '../../components';
import { getValidClassNames } from '../../utils';

export const CheckinChart = ({
  additionalClassNames,
  shouldIncludeTabs,
  userFlowStepTracks,
  displayAllCategories = false,
}: CheckinChartProps) => {
  const [dataPoints, setDataPoints] = useState<IDatapoint[]>([]);
  const [isDisplayingQuantity, setIsDisplayingQuantity] = useState<boolean>(true);
  const [visibleCategories, setVisibleCategories] = useState<Category>('ALCOHOL');

  const toggleCategory = (category: Category) => {
    if (visibleCategories != category) {
      setVisibleCategories(category);
    }
  };

  const datasets = [
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.alcohol],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.alcohol],
      data: dataPoints.map((r) => r.alcoholData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'ALCOHOL',
      max: Math.max(...dataPoints.map((r) => r.alcoholData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.amphetamine],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.amphetamine],
      data: dataPoints.map((r) => r.amphetamineData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'AMPHETAMINE',
      max: Math.max(...dataPoints.map((r) => r.amphetamineData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.cannabis],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.cannabis],
      data: dataPoints.map((r) => r.cannabisData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'CANNABIS',
      max: Math.max(...dataPoints.map((r) => r.cannabisData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.hallucinogens],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.hallucinogens],
      data: dataPoints.map((r) => r.hallucinogensData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'HALLUCINOGENS',
      max: Math.max(...dataPoints.map((r) => r.hallucinogensData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.inhalants],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.inhalants],
      data: dataPoints.map((r) => r.inhalantsData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'INHALANTS',
      max: Math.max(...dataPoints.map((r) => r.inhalantsData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.opioids],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.opioids],
      data: dataPoints.map((r) => r.opiodsData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'OPIODS',
      max: Math.max(...dataPoints.map((r) => r.opiodsData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.sedatives],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.sedatives],
      data: dataPoints.map((r) => r.sedativeData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'SEDATIVES',
      max: Math.max(...dataPoints.map((r) => r.sedativeData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.cocaine],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.cocaine],
      data: dataPoints.map((r) => r.cocaineData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'COCAINE',
      max: Math.max(...dataPoints.map((r) => r.cocaineData)),
    },
    {
      backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.other],
      borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.other],
      data: dataPoints.map((r) => r.otherData),
      label: isDisplayingQuantity ? 'Quantity' : 'Days',
      category: 'OTHER',
      max: Math.max(...dataPoints.map((r) => r.otherData)),
    },
  ];

  const getClass = (category: Category) => {
    return `category ${visibleCategories === category ? 'active' : 'inactive'}`;
  };

  useEffect(() => {
    const answers = userFlowStepTracks.map((r) => {
      return { date: r.createdLongDate, answers: r.answers };
    });

    const mappedAnswers = answers.map((r) => {
      if (isDisplayingQuantity) {
        return {
          label: r.date,
          date: new Date(r.date),
          alcoholData: getAnswer(ALCOHOL_QUESTION_ID, r.answers),
          amphetamineData: getAnswer(AMPHETAMINE_QUESTION_ID, r.answers),
          cannabisData: getAnswer(CANNABIS_QUESTION_ID, r.answers),
          cocaineData: getAnswer(COCAINE_QUESTION_ID, r.answers),
          hallucinogensData: getAnswer(HALLUCINOGENS_QUESTION_ID, r.answers),
          inhalantsData: getAnswer(INHALANTS_QUESTION_ID, r.answers),
          opiodsData: getAnswer(OPIODS_QUESTION_ID, r.answers),
          otherData: getAnswer(OTHER_QUESTION_ID, r.answers),
          sedativeData: getAnswer(SEDATIVES_QUESTION_ID, r.answers),
        };
      } else {
        return {
          label: r.date,
          date: new Date(r.date),
          alcoholData: getAnswer(DAYS_ALCOHOL_QUESTION_ID, r.answers),
          amphetamineData: getAnswer(DAYS_AMPHETAMINE_QUESTION_ID, r.answers),
          cannabisData: getAnswer(DAYS_CANNABIS_QUESTION_ID, r.answers),
          cocaineData: getAnswer(DAYS_COCAINE_QUESTION_ID, r.answers),
          hallucinogensData: getAnswer(DAYS_HALLUCINOGENS_QUESTION_ID, r.answers),
          inhalantsData: getAnswer(DAYS_INHALANTS_QUESTION_ID, r.answers),
          opiodsData: getAnswer(DAYS_OPIODS_QUESTION_ID, r.answers),
          otherData: getAnswer(DAYS_OTHER_QUESTION_ID, r.answers),
          sedativeData: getAnswer(DAYS_SEDATIVES_QUESTION_ID, r.answers),
        };
      }
    }).sort(function (a, b) {
      return a.date.getTime() - b.date.getTime();
    }).filter(({ date }) => isAfter(date, subMonths(new Date(), 1)));
    
    setDataPoints(mappedAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFlowStepTracks, isDisplayingQuantity]);

  const getAnswer = (questionId: number, answers: IDBQuestionAnswer[]) => {
    const answer = answers.find((r) => r.contentSnippetQuestion?.id === questionId);
    if (!answer) return 0;
    return getValue(answer);
  };

  const getValue = (answer: IDBQuestionAnswer) => {
    const value = answer.value || answer.numberAnswered || Number(answer.text);
    if (Number.isNaN(value)) return 0;
    return value;
  };

  const maxYAxis = () => {
    if (displayAllCategories) return 0;
    if (!isDisplayingQuantity) return 7;
    const maxValue = datasets.filter((r) => visibleCategories === (r.category as Category))[0].max * 2;
    if (maxValue === 0) return 1;
    let flooredValue = Math.floor(maxValue / 10);
    if (flooredValue === 0) flooredValue = 1;
    let roundedValue = flooredValue * 10;

    if (roundedValue % 2 !== 0) {
      roundedValue += 2;
    }

    return roundedValue;
  };

  return (
    <div
      className={getValidClassNames({
        'c-checkin__chart': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
    >
      <InformationBanner>
        <p className="mb-0">
          Thank you for completing your weekly check-in. You will see a graph below that shows your recent use of alcohol and/or other
          drugs. Go to the Check-in page for more detail.
        </p>
      </InformationBanner>
      {shouldIncludeTabs ? (
        <>
          <ul className="category-container">
            <li className={`category ${isDisplayingQuantity ? 'active' : ''}`} onClick={() => setIsDisplayingQuantity(true)}>
              Quantity
            </li>
            <li className={`category ${!isDisplayingQuantity ? 'active' : ''}`} onClick={() => setIsDisplayingQuantity(false)}>
              Days of the week used
            </li>
          </ul>
          <ul className="category-container">
            <li className={getClass('ALCOHOL')} onClick={() => toggleCategory('ALCOHOL')}>
              Alcohol
            </li>
            <li className={getClass('CANNABIS')} onClick={() => toggleCategory('CANNABIS')}>
              Cannabis
            </li>
            <li className={getClass('COCAINE')} onClick={() => toggleCategory('COCAINE')}>
              Cocaine
            </li>
            <li className={getClass('AMPHETAMINE')} onClick={() => toggleCategory('AMPHETAMINE')}>
              Amphetamine
            </li>
            <li className={getClass('INHALANTS')} onClick={() => toggleCategory('INHALANTS')}>
              Inhalants
            </li>
            <li className={getClass('SEDATIVES')} onClick={() => toggleCategory('SEDATIVES')}>
              Sedatives
            </li>
            <li className={getClass('HALLUCINOGENS')} onClick={() => toggleCategory('HALLUCINOGENS')}>
              Hallucinogens
            </li>
            <li className={getClass('OPIODS')} onClick={() => toggleCategory('OPIODS')}>
              Opioids
            </li>
            <li className={getClass('OTHER')} onClick={() => toggleCategory('OTHER')}>
              Other
            </li>
          </ul>
        </>
      ) : null}

      <LineChart
        datasets={displayAllCategories ? datasets : datasets.filter((r) => visibleCategories == (r.category as Category))}
        labels={dataPoints.map((r) => format(new Date(r.label), 'dd/MM/yyyy'))}
        options={{
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
            },
            y: {
              beginAtZero: true,
              suggestedMax: maxYAxis(),
              title: {
                display: true,
                text: isDisplayingQuantity ? 'Quantity' : 'Number of days used',
              },
            },
          },
        }}
      ></LineChart>
    </div>
  );
};
