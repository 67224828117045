import { IFooterMenu } from './Footer.interfaces';

export const FOOTER_MENU_ITEMS_PRIVATE: IFooterMenu[] = [
  {
    title: 'Stable Ground',
    items: [
      {
        href: '/about',
        id: 'about',
        label: 'About',
      },
      // {
      //   href: '/about-drugs',
      //   id: 'about-drugs',
      //   label: 'About drugs',
      // },
      {
        href: '/contact-us',
        id: 'contact',
        label: 'Contact',
      },
      {
        href: '/faq',
        id: 'faq',
        label: 'FAQ',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      { href: '/terms-and-conditions', id: 'terms-and-conditions', label: 'Terms & conditions' },
    ],
  },
];
