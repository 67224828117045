import { IAdditionalClassNames } from '@netfront/ui-library';

export const CaretLeftIcon = ({ additionalClassNames }: IAdditionalClassNames) => (
  <svg
    className={additionalClassNames}
    fill="none"
    stroke="currentColor"
    strokeWidth={4}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 19l-7-7 7-7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
