import { IMarkAllNotificationSeenOption } from './useMarkAllNotificationSeen.interface';

import { howlerClient as client } from '../../apollo';
import { useMarkAllNotificationSeenMutation } from '../../howler';

export const useMarkAllNotificationSeen = (options?: IMarkAllNotificationSeenOption) => {
  const { onCompleted, onError } = options ?? ({} as IMarkAllNotificationSeenOption);

  const [markAllNotificationSeen, { loading: isLoading }] = useMarkAllNotificationSeenMutation({
    client,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return {
    markAllNotificationSeen,
    isLoading,
  };
};
