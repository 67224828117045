import { usePublicGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_SMART_CODE } from './useGetSmartCode.graphql';
import {
  IGetSmartCodeQueryGraphQLResponse,
  IGetSmartCodeQueryVariables,
  IHandleGetSmartCodeParams,
  IUseGetSmartCodeOptions,
} from './useGetSmartCode.interfaces';

const useGetSmartCode = (options?: IUseGetSmartCodeOptions) => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetSmartCodeOptions);

  const [executeGetPaginatedGroups, { loading: isLoading }] = usePublicGeladaLazyQuery<
    IGetSmartCodeQueryGraphQLResponse,
    IGetSmartCodeQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const { getSmartCode } = data;

        onCompleted({
          smartCode: getSmartCode,
        });
      },
      onError,
    },
    query: GET_SMART_CODE,
  });

  const handleGetSmartCode = async ({ code, projectId }: IHandleGetSmartCodeParams) => {
    await executeGetPaginatedGroups({
      variables: {
        code,
        projectId,
      },
    });
  };

  return {
    handleGetSmartCode,
    isLoading,
  };
};

export { useGetSmartCode };
