import { gql } from '@apollo/client';

const CREATE_FEEDBACK = gql`
  mutation createFeedback($content: String!, $rate: Decimal, $projectId: String!, $url: String) {
    feedback {
      addFeedback(request: { projectId: $projectId, rate: $rate, content: $content, url: $url }, isAnonymous: true) {
        id
      }
    }
  }
`;

export { CREATE_FEEDBACK };
