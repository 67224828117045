import { useEffect } from 'react';

import { Input } from '@netfront/ui-library';
import { useIsMounted } from 'hooks';

import { DisplayNameGeneratorInputProps } from './DisplayNameGeneratorInput.interfaces';

import { generateDisplayName } from '../../utils';

const DisplayNameGeneratorInput = ({
  shouldGenerateSuggestionOnMount = false,
  onGenerate,
  ...inputProps
}: DisplayNameGeneratorInputProps) => {
  const { isMounted } = useIsMounted();

  useEffect(() => {
    if (!shouldGenerateSuggestionOnMount) {
      return;
    }

    if (isMounted && onGenerate) {
      onGenerate(generateDisplayName());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  return (
    <div className="c-display-name-generator flex items-center">
      <Input {...inputProps} isReadOnly />
      {onGenerate && (
        <button
          className="bg-primary w-13 h-13 rounded color-white flex items-center justify-center pointer"
          type="button"
          disabled={inputProps.isDisabled}
          onClick={() => onGenerate(generateDisplayName())}
        >
          <svg
            className="w-7 h-7"
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export { DisplayNameGeneratorInput };
