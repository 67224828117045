import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getTransformedUserFlowSteps, IUserFlowStep, IDBUserFlowStep } from '@netfront/ekardo-content-library';
import { IDBContentGroup } from 'interfaces';
import { sortBy } from 'lodash';
import { useRouter } from 'next/router';

import { AppStoreLinks, BreadcrumbLink, CurrentModuleCard, ModuleCard, Page, useGetCurrentModule } from '../../../components';
import {
  IUseGetUserFlowByUrlOnCompletedResponse,
  useGetAgencyUserFlowUrl,
  useGetRootBreadcrumbItems,
  useGetUserFlowByUrl,
  useProtectedRoute,
  useToast,
} from '../../../hooks';

const ProgramPage = () => {
  const PAGE_TITLE = 'Steady program';

  const { asPath } = useRouter();
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();
  const { hasStartedUserflow, lastViewedStep } = useGetCurrentModule();
  const { userFlowUrl: USERFLOW_URL } = useGetAgencyUserFlowUrl();

  const [moduleSteps, setModuleSteps] = useState<IUserFlowStep[]>([]);
  const [quickLink, setQuickLink] = useState<IUserFlowStep>();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetUserFlowByUrlCompleted = ({ userFlow }: IUseGetUserFlowByUrlOnCompletedResponse) => {
    const transformedModuleUserFlowSteps = getTransformedUserFlowSteps(userFlow.userFlowSteps as unknown as IDBUserFlowStep[], 'program');
    const sortedUserFlowSteps = sortBy(transformedModuleUserFlowSteps, 'sort');
    setModuleSteps(sortedUserFlowSteps);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetUserFlowByUrl({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      url: USERFLOW_URL,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { handleGetUserFlowByUrl } = useGetUserFlowByUrl({
    onCompleted: handleGetUserFlowByUrlCompleted,
    onError: handleApolloError,
  });


  const nextModuleQuickLink = (modules: IUserFlowStep[], lastViewed?: IUserFlowStep): IUserFlowStep | undefined => {
    if (!lastViewed) return;

    const currentModuleIndex = modules.findIndex(({ id }) => id === lastViewed.id);
    const nextAvailableModule = modules.find(({ percentageCompleted }) => percentageCompleted !== 100)
    return lastViewed.percentageCompleted === 100 ? modules[currentModuleIndex + 1] || nextAvailableModule || lastViewed : lastViewed
  }

  useEffect(() => {
    if (!moduleSteps.length || !lastViewedStep) return;
    setQuickLink(nextModuleQuickLink(moduleSteps, lastViewedStep));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleSteps, lastViewedStep])

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription:
          'An interactive and guided program for when you’re feeling a bit unsteady. Learn skills to change your alcohol or drug use.',
        seoTitle: 'Steady program – Resources | Stable Ground',
      }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="container container-md pb-10">
        <h2 className="h4 weight-800 color-secondary">Quicklinks</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-4">
            {quickLink && (
              <CurrentModuleCard
                description={String(quickLink.description)}
                hasStartedUserflow={hasStartedUserflow}
                moduleId={quickLink.id}
                percentageCompleted={quickLink.percentageCompleted}
                status={quickLink.stepName}
                title={quickLink.stepName}
                url={
                  quickLink.lastViewedContentPage
                    ? `/program/${quickLink.url}/${String(quickLink.lastViewedContentPage.url)}`
                    : `/program/${quickLink.url}/${String(quickLink.firstContentPage?.url)}`

                }
              />
            )}
          </div>

          <div className="w-full md:w-1/2 px-4 mb-4">
            <div className="bg-white p-10 border-2 border-athens rounded-lg flex flex-col justify-center h-full">
              <h3 className="color-black mb-5">Download the app</h3>
              <AppStoreLinks />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-athens py-12 md:py-18">
        <div className="container container-md">
          <h2 className="h4 weight-800 color-secondary">Modules</h2>
          <div className="flex flex-wrap -mx-2">
            {moduleSteps.map(({ id, description, isComplete, lastViewedContentPage, percentageCompleted, stepName, contentGroup, url }) => {
              const { estimatedTime} = contentGroup ?? ({} as IDBContentGroup);

              const moduleLink = `/program/${url}/${lastViewedContentPage ? lastViewedContentPage.url : String(contentGroup?.contentPages[0].url)}`

              return (
                <div key={`program-${id}`} className="w-full md:w-1/2 px-2 mb-4">
                  <ModuleCard
                    description={description as string}
                    estimatedTime={estimatedTime}
                    isComplete={isComplete}
                    isInProgress={!!lastViewedContentPage && !isComplete}
                    percentageCompleted={percentageCompleted}
                    title={stepName}
                    url={moduleLink}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Page>
  );
};

export { ProgramPage };
