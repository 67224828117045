import { useRef } from 'react';

import { Textarea } from '@netfront/ui-library';

import { EmojiSelector, getMessageWithEmojis } from '../EmojiSelector';

import { CommentBoxProps } from './CommentBox.interfaces';

export const CommentBox = ({ placeholder, id, labelText, name, value, onChange }: CommentBoxProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  return (
    <div className="c-comment-box">
      <Textarea
        ref={textareaRef}
        id={id}
        labelText={labelText}
        name={name}
        placeholder={placeholder}
        value={value}
        isLabelHidden
        onChange={({ currentTarget: { value: commentValue } }) => onChange(commentValue)}
      />
      <EmojiSelector
        onSelect={(emoji) => {
          onChange(getMessageWithEmojis(textareaRef.current, emoji));
        }}
      />
    </div>
  );
};
