import { Button } from '@netfront/ui-library';

export const GoalsCardEmpty = () => {
  return (
    <div className="flex flex-col xl:flex-row items-center h-full">
      <div className="mb-2 xl:mb-0">
        <img alt="Exclamation mark" className="max-w-20" src="/images/no-goal-icon.svg" />
      </div>

      <div className="flex flex-col xl:flex-row flex-1 items-center">
        <div className="px-6 flex-1">
          <span className="color-black h5 weight-800 mb-0 block">No goals yet.</span>
          <p className="mb-4 xl:mb-0">Get started by setting your first goal.</p>
        </div>
        <Button linkButton={{ id: 'goals', url: '/goals' }} text="+ Create goal" />
      </div>
    </div>
  );
};
