import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useUpdatePassword, useUpdateUser } from '@netfront/gelada-identity-library';
import { Button, Input, PasswordInput, ToggleSwitch } from '@netfront/ui-library';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { AvatarBuilder } from 'components/AvatarBuilder/AvatarBuilder';

import { IProfileFormValues } from './ProfilePage.interfaces';

import { BreadcrumbLink, DisplayNameGeneratorInput, InformationBanner, SingleFormPage } from '../../../components';
import { useSocialContext } from '../../../contexts';
import { useGetRootBreadcrumbItems, useProtectedRoute, useToast, useUpdateUserSettings } from '../../../hooks';
import { EUserSettings, useUpdateUserForumName } from '../../../services';

interface IUpdateUserDetails extends Pick<IProfileFormValues, 'displayName' | 'email'> {
  currentPassword?: string;
  newPassword?: string;
}

const ProfilePage = () => {
  const PAGE_TITLE = 'Profile';
  const { asPath } = useRouter();

  const [updatedDetails, setUpdatedDetails] = useState<IUpdateUserDetails>({
    displayName: '',
    email: '',
    currentPassword: '',
    newPassword: '',
  });

  const [notificationSettings, setNotificationSettings] = useState<Array<EUserSettings>>([]);

  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError, handleToastSuccess } = useToast();
  const { user, updateUser } = useSocialContext();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { handleUpdateUser } = useUpdateUser({
    onCompleted: () => {
      setIsFormSubmitting(false);

      handleToastSuccess({
        message: 'You have been sent an email to confirm the update',
      });
    },
    onError: (error) => {
      handleApolloError(error);
      setIsFormSubmitting(false);
    },
  });

  const { handleUpdateUserSettings } = useUpdateUserSettings({
    onCompleted: () => {
      setIsFormSubmitting(false);

      handleToastSuccess({
        message: 'Your notification settings have been updated',
      });
    },
  });

  const { handleUpdateUserForumName } = useUpdateUserForumName({
    onCompleted: () => {
      if (!user) {
        return;
      }

      setIsFormSubmitting(false);

      updateUser({
        ...user,
        displayedName: updatedDetails.displayName,
      });

      handleToastSuccess({
        message: 'Your display name has been updated successfully',
      });
    },
    onError: (error) => {
      handleApolloError(error);
      setIsFormSubmitting(false);
    },
  });

  const { handleUpdatePassword } = useUpdatePassword({
    onCompleted: () => {
      setIsFormSubmitting(false);

      handleToastSuccess({
        message: 'Your password has been updated successfully',
      });
    },
    onError: (error) => {
      handleApolloError(error);
      setIsFormSubmitting(false);
    },
  });

  const toggleNotificationSettings = (setting: EUserSettings) => {
    if (notificationSettings.includes(setting)) {
      setNotificationSettings(notificationSettings.filter((r) => r !== setting));
    } else {
      setNotificationSettings([...notificationSettings, setting]);
    }
  };

  const formik = useFormik<IProfileFormValues>({
    initialValues: {
      currentPassword: '',
      displayName: '',
      email: '',
      newPassword: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().label('Email').email().required('Email is required'),
      displayName: yup.string().label('Display name').required('Display name is required'),
    }),
    enableReinitialize: true,
    onSubmit: (values: IProfileFormValues) => {
      if (!user) {
        return;
      }

      let isProcessingChange = false;
      setIsFormSubmitting(true);

      if (values.displayName !== user.displayedName) {
        isProcessingChange = true;
        handleUpdateUserForumName({
          forumName: values.displayName,
        });

        setUpdatedDetails({
          ...updatedDetails,
          displayName: values.displayName,
        });
      }

      if (values.currentPassword && values.newPassword) {
        isProcessingChange = true;
        handleUpdatePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        });

        // run update password mutation
      }

      if (values.email !== user.email) {
        isProcessingChange = true;
        handleUpdateUser({
          email: values.email,
          firstName: '',
          lastName: '',
        });
      }

      if (notificationSettings != user.settings) {
        isProcessingChange = true;
        handleUpdateUserSettings({ settings: notificationSettings });
      }

      if (!isProcessingChange) {
        setIsFormSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user) {
      return;
    }

    formik.setFieldValue('email', user.email, false);
    formik.setFieldValue('displayName', user.displayedName, false);
    setNotificationSettings(user.settings ?? []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      containerSize="sm"
      meta={{ seoDescription: PAGE_TITLE, seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <InformationBanner>
        <p className="mb-0">Personalise your profile and update your details</p>
      </InformationBanner>
      <AvatarBuilder />

      {user && (
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <Input id="email" labelText="Email" name="email" type="text" value={formik.values.email} onChange={formik.handleChange} />
            {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
          </div>

          <div className="mb-4">
            <PasswordInput id="currentPassword" labelText="Current password" name="currentPassword" onChange={formik.handleChange} />
          </div>

          <div className="mb-8">
            <PasswordInput id="newPassword" labelText="New password" name="newPassword" onChange={formik.handleChange} />
          </div>

          <InformationBanner title="Why do I need a display name?">
            <p className="mb-0">Anonymous name used in the online community.</p>
          </InformationBanner>

          <div className="mb-8">
            <label className="block mb-2" htmlFor="displayName">
              Display name
            </label>

            <DisplayNameGeneratorInput
              additionalClassNames="mr-2 pb-0"
              id="displayName"
              labelText="Display name"
              name="displayName"
              type="text"
              value={formik.values.displayName}
              isLabelHidden
              shouldGenerateSuggestionOnMount
              onChange={formik.handleChange}
              isDisabled={String(formik.values.displayName).toLowerCase() !== 'anonymous'}
              onGenerate={(displayName) => formik.setFieldValue('displayName', displayName)}
            />
            {formik.touched.displayName && formik.errors.displayName ? <div>{formik.errors.displayName}</div> : null}
          </div>
          <span className='mb-2 block'>Notification settings</span>
          <div className="bg-white border-2 border-athens p-5 mb-6 rounded notifications-settings-container">
            <ToggleSwitch
              id="notification-switch-commented-post"
              isChecked={notificationSettings.includes(EUserSettings.CommentedPost)}
              labelText="Receive an email when someone comments on your post."
              onChange={() => toggleNotificationSettings(EUserSettings.CommentedPost)}
            />
            <ToggleSwitch
              id="notification-switch-message-received"
              isChecked={notificationSettings.includes(EUserSettings.MessageReceive)}
              labelText="Receive an email when you receive a new message."
              onChange={() => toggleNotificationSettings(EUserSettings.MessageReceive)}
            />
            <ToggleSwitch
              id="notification-switch-commented-comment"
              isChecked={notificationSettings.includes(EUserSettings.CommentedComment)}
              labelText="Receive an email when someone comments on your comment."
              onChange={() => toggleNotificationSettings(EUserSettings.CommentedComment)}
            />
            <ToggleSwitch
              id="notification-switch-liked-comment"
              isChecked={notificationSettings.includes(EUserSettings.LikedComment)}
              labelText="Receive an email when someone likes your comment."
              onChange={() => toggleNotificationSettings(EUserSettings.LikedComment)}
            />
            <ToggleSwitch
              id="notification-switch-liked-wall-post"
              isChecked={notificationSettings.includes(EUserSettings.LikedWallPost)}
              labelText="Receive an email when someone likes your post."
              onChange={() => toggleNotificationSettings(EUserSettings.LikedWallPost)}
            />
          </div>
          <div className="text-right">
            <Button isLoading={isFormSubmitting} text="Save" type="submit" variant="secondary" />
          </div>
        </form>
      )}
    </SingleFormPage>
  );
};

export { ProfilePage };
