const FIRST_WORD_OPTIONS = [
  'Mountain',
  'Tawny',
  'Snowy',
  'Saltwater',
  'Diamond',
  'Freshwater',
  'Tree',
  'Eastern',
  'Tawny',
  'Laughing',
  'Striped',
  'Flying',
  'Desert',
  'Snowy',
  'Tasmanian',
  'Rainforest',
  'River',
  'Sea',
  'Rock',
  'Forest',
  'Native',
  'Spotted',
  'Coastal',
  'Paperbark',
  'Wollemi',
  'Ironbark',
  'Fern',
  'Reef',
  'Western',
  'Northern',
  'Southern',
  'Central',
  'Savannah',
  'Sandy',
  'Island',
  'Red',
  'Blue',
  'Green',
  'Yellow',
  'Purple',
  'Orange',
  'Pink',
  'Brown',
  'Grey',
  'Black',
  'White',
  'Teal',
  'Magenta',
  'Navy',
  'Turquoise',
  'Lavender',
  'Maroon',
  'Olive',
  'Gold',
  'Silver',
  'Lilac',
  'Indigo',
  'Coral',
  'Charcoal',
  'Aqua',
  'Beige',
  'Crimson',
  'Ivory',
  'Ruby',
  'Sapphire',
  'Violet',
  'Bronze',
  'Copper',
  'Jade',
  'Cobalt',
];

const SECOND_WORD_OPTIONS = [
  'Wallaby',
  'Possum',
  'Echidna',
  'Quoll',
  'Bat',
  'Wombat',
  'Platypus',
  'Dingo',
  'Quokka',
  'Numbat',
  'Possum',
  'Bilby',
  'Bandicoot',
  'Crocodile',
  'Glider',
  'Bilby',
  'Sealion',
  'Thylacine',
  'Taipan',
  'Goanna',
  'Dolphin',
  'Kookaburra',
  'Cockatoo',
  'Lorikeet',
  'Finch',
  'Owl',
  'Swan',
  'Emu',
  'Cassowary',
  'Eagle',
  'Galah',
  'Swan',
  'Pelican',
  'Seagull',
  'Magpie',
  'Rosella',
  'Brolga',
  'Currawong',
  'Brushturkey',
  'Lyrebird',
  'Ibis',
  'Penguin',
  'Frogmouth',
  'Wattle',
  'Waratah',
  'Banksia',
  'Bluegum',
  'Eucalypt',
  'Anenome',
  'Coral',
  'Flametree',
  'Orchid',
  'Gumnut',
  'Snowgum',
];

export const generateDisplayName = () => {
  const firstWord = FIRST_WORD_OPTIONS[Math.floor(Math.random() * FIRST_WORD_OPTIONS.length)];
  const secondWord = SECOND_WORD_OPTIONS[Math.floor(Math.random() * SECOND_WORD_OPTIONS.length)];

  return `${firstWord} ${secondWord}`;
};
