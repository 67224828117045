import { useEffect, useState } from 'react';

import { useGetLatestUserFlowStepTracking } from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { differenceInDays } from 'date-fns';

import { SelfAssessmentPromptProps } from './SelfAssessmentPrompt.interfaces';

import { CalendarIcon, SELF_ASSESSMENT_USERFLOW_STEP_ID } from '../../components';

export const SelfAssessmentPrompt = ({ daysElapsed, shouldQuery = false }: SelfAssessmentPromptProps) => {
  const [days, setDays] = useState<number>(0);

  const { handleGetLatestUserFlowStepTracking } = useGetLatestUserFlowStepTracking({
    onCompleted: ({ userFlowStepTrack }) =>
      setDays(userFlowStepTrack.isCompleted ? differenceInDays(new Date(), new Date(userFlowStepTrack.completedLongDate)) : 0),
  });

  useEffect(() => {
    if (shouldQuery) {
      handleGetLatestUserFlowStepTracking({
        userFlowStepId: SELF_ASSESSMENT_USERFLOW_STEP_ID,
      });

      return;
    }

    setDays(Number(daysElapsed));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysElapsed, shouldQuery]);

  return days === 0 ? null : (
    <div className="flex items-center">
      <div className="flex border-2 border-primary rounded bg-white mr-2 overflow-hidden">
        <div className="flex items-center bg-primary color-white p-3 rounded-r-circle">
          <CalendarIcon />
        </div>
        <div className="p-3 pr-6">
          <p className="mb-0">
            Its been {days} {days === 1 ? 'day' : 'days'} since your last self-assessment
          </p>
        </div>
      </div>

      <Button linkButton={{ id: 'self-assessment', url: '/self-assessment/introduction' }} text="Take self-assessment" variant="primary" />
    </div>
  );
};
