import { bonoboClient } from 'services';

import { IToggleAlumniOptions } from './useToggleAlumni.interface';

import { useToggleAlumniMutation } from '../../bonobo/generated.graphql';

export const useToggleAlumni = (options?: IToggleAlumniOptions) => {
  const { onCompleted, onError } = options ?? ({} as IToggleAlumniOptions);

  const [toggleAlumni, { loading: isLoading, error }] = useToggleAlumniMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { toggleAlumni, isLoading, error };
};
