/* eslint-disable @typescript-eslint/indent */

import { useHowlerLazyQuery } from 'hooks/useHowlerLazyQuery';

import { GET_ANNOUNCE_QUERY } from './useGetAnnounce.graphql';
import {
  IGetAnnounceQueryGraphQLResponse,
  IGetAnnounceQueryRequest,
  IGetAnnounceQueryVariables,
  IUseGetAnnounce,
  IUseGetAnnounceOptions,
} from './useGetAnnounce.interfaces';

const useGetAnnounce = (options?: IUseGetAnnounceOptions): IUseGetAnnounce => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetAnnounceOptions);

  const [executeGetAnnounce, { loading: isLoading }] = useHowlerLazyQuery<IGetAnnounceQueryGraphQLResponse, IGetAnnounceQueryVariables>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ announce: { byLocation } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          announce: byLocation,
        });
      },
      onError,
    },
    query: query ?? GET_ANNOUNCE_QUERY,
    token,
  });

  const handleGetAnnounce = async ({ location }: IGetAnnounceQueryRequest) => {
    await executeGetAnnounce({
      variables: {
        location,
        projectId: String(process.env.REACT_APP_PROJECT_ID),
      },
    });
  };

  return {
    handleGetAnnounce,
    isLoading,
  };
};

export { useGetAnnounce };
