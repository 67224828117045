import { Skeleton } from 'components';

import { DashboardCardSkeletonProps } from './DashboardCard.interfaces';

export const DashboardCardSkeleton = ({ direction }: DashboardCardSkeletonProps) => {
  return direction ? (
    <div className="flex flex-col text-center">
      <Skeleton borderRadius="50%" height="6rem" width="6rem" />
      <Skeleton borderRadius="10px" height="1rem" width="40%" />
      <Skeleton borderRadius="10px" height="3rem" width="30%" />
    </div>
  ) : (
    <></>
  );
};
