import { useEffect, useState } from 'react';

import { useSocialContext } from 'contexts';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { Page } from 'components/Pages';

import { Announce, BreadcrumbLink } from '../../../../components';
import { useGetRootBreadcrumbItems } from '../../../../hooks';
import { DBCommunity, DBCommunityConnection } from '../../../../interfaces';
import {
  CommunityGraphType,
  ECommunityRole,
  EConnectionStatus,
  useGetCommunitiesOfUser
} from '../../../../services';
import { formatDate } from '../../../../utils';
import { EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const CommunitiesPage = () => {
  const PAGE_TITLE = 'Communities';
  const { asPath } = useRouter();
  
  const { user: loggedUser } = useSocialContext();

  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);

  const [isLoadingUserCommunities, setIsLoadingUserCommunities] = useState<boolean>(true);

  const getUserCommunitiesCompleted = (connections: DBCommunityConnection[]) => {
    setUserCommunities(connections.map(({ community }) => community));
    setIsLoadingUserCommunities(false);
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getCommunitiesOfUser } = useGetCommunitiesOfUser({
    onCompleted: getUserCommunitiesCompleted,
  });

  useEffect(() => {
    if(!loggedUser?.id) return;
    getCommunitiesOfUser({
      variables: {
        userId: loggedUser.id,
        shouldIncludeProfileImage: true,
        shouldIncludeUserConnection: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  useEffect(() => {
    if (isSearchActive) {
      getCommunitiesOfUser({
        variables: {
          userId: loggedUser?.id as number,
          shouldIncludeProfileImage: true,
          shouldIncludeUserConnection: true,
        },
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive, searchValue, loggedUser]);

  const acceptedCommunities = userCommunities.filter(({ userConnection, title }) => userConnection?.status === EConnectionStatus.Accepted && title.includes(searchValue ?? ''));
  const canRenderCommunityPosts = !isLoadingUserCommunities && acceptedCommunities.length > 0;
  const hasNoCommunityPosts = !isLoadingUserCommunities && acceptedCommunities.length === 0;

  const suggestedCommunities = userCommunities.filter(({ userConnection, title }) => userConnection?.status === EConnectionStatus.Alumni && title.includes(searchValue ?? '') ).slice(0, 5);

  const canRenderAllSuggestedCommunities = !isLoadingUserCommunities && !isEmpty(suggestedCommunities);
  const hasNoSuggestedCommunities = !isLoadingUserCommunities && isEmpty(suggestedCommunities);


  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{ seoDescription: 'View all your communities', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <Announce location="community" />
      <div className="c-communities-page__container">
        <SearchInput
          additionalClassNames="c-communities-page__search"
          id="search"
          isSearchActive={isSearchActive}
          labelText="Search communities"
          name="search"
          placeholder="Search communities"
          type="text"
          isLabelHidden
          onClear={() => {
            onSearchClear();
            getCommunitiesOfUser({
              variables: {
                userId: loggedUser?.id as number,
                shouldIncludeProfileImage: true,
                shouldIncludeUserConnection: true,
              },
            });
          }}
          onSearch={onSearchSubmit}
        />

        <h1>Connected communities ({acceptedCommunities.length})</h1>

        {isLoadingUserCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderCommunityPosts &&
          acceptedCommunities.map(({ description, id, key, title, profileImage, userConnection }) => {
            const roleTag = userConnection?.role !== ECommunityRole.Member ? 'Moderator' : '';

            return (
              <ListCard
                key={id}
                avatarImage={profileImage?.presignedUrl}
                avatarTitle={title}
                description={
                  userConnection?.createdDate
                    ? `${userConnection.role === ECommunityRole.Owner ? 'Created' : 'Joined'} ${formatDate(
                      new Date(userConnection.createdDate),
                    )}`
                    : description
                }
                displayName={title}
                href={`/community/communities/${key}`}
                tag={roleTag}
                hasArrow
              />
            );
          })}

        {hasNoCommunityPosts && <EmptyMessage message="No connected communities" />}

        <div className="c-communities-page__suggestions">
          <h2>Suggested communities ({suggestedCommunities.length ?? 0})</h2>
        </div>

        {isLoadingUserCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderAllSuggestedCommunities &&
          suggestedCommunities &&
          suggestedCommunities.map((node) => {
            const community = node as CommunityGraphType;
            return (
              <ListCard
                key={community.id}
                avatarImage={community.profileImage?.presignedUrl}
                avatarTitle={community.title}
                description={
                  community.userConnection?.createdDate
                    ? `Joined ${formatDate(new Date(community.userConnection.createdDate))}`
                    : community.description
                }
                displayName={community.title}
                href={`/community/communities/${String(community.key)}`}
                hasArrow
              />
            );
          })}

        {hasNoSuggestedCommunities && <EmptyMessage message="No communities available" />}
      </div>
    </Page>
  );
};

export { CommunitiesPage };
