import React from 'react';

import cx from 'classnames';
import validateColor from 'validate-color';

import { ColorPickerPreviewProps } from './ColorPickerPreview.types';


const ColorPickerPreview = ({ additionalClassNames, selectedColor, onClick }: ColorPickerPreviewProps) => (
  <>
    <span className="h-hide-visually">Current color is ${selectedColor}.</span>
    <button
      className={cx('c-color-picker-preview__button', additionalClassNames)}
      style={{ backgroundColor: validateColor(selectedColor) ? selectedColor : 'rgba(75, 194, 202, 1)' }}
      type="button"
      onClick={onClick}
    >
      <span className="h-hide-visually">Pick color</span>
    </button>
  </>
);

export { ColorPickerPreview };
