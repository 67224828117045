import { RegexMatchResult, TriggerData } from './Post.interfaces';

import { PostGraphType } from '../../../services';

export const updateFeedOnPostRelate = (posts: PostGraphType[], hasRelated: boolean, postId: number) => {
  const updatedFeed = posts.map((post: PostGraphType) => {
    if (post.id === postId) {
      return {
        ...post,
        hasRelated,
        relateCount: hasRelated ? Number(post.relateCount) + 1 : Number(post.relateCount) - 1,
      };
    }

    return post;
  });

  return updatedFeed;
};

export const updateFeedOnVotePoll = (posts: PostGraphType[], userId: number, postId: number, itemId: number) => {
  const updatedFeed = posts.map((post: PostGraphType) => {
    if (post.id === postId) {
      return {
        ...post,
        poll: {
          ...post.poll,
          userVote: {
            pollItemId: itemId,
          },
          items: post.poll?.items?.map((item) => {
            if (item.id === itemId) {
              return {
                ...item,
                votes: [...item.votes, { pollItemId: itemId, userId }],
                votesCount: item.votesCount + 1,
              };
            }

            return item;
          }),
        },
      };
    }
    return post;
  }) as PostGraphType[];

  return updatedFeed;
};

export const addHashTagMarkupToMessage = (message: string) => {
  return message
    .split(' ')
    .map((word) =>
      word.startsWith('#') && word.length >= 2
        ? `<span class="c-post-message--highlight"><a href="?hashtag=${word.split('#')[1]}">${word}</a></span>`
        : word,
    )
    .join(' ');
};

const getMentionDataFromRegExMatchResult = ([, original, trigger, name, id]: RegexMatchResult): TriggerData => ({
  original,
  trigger,
  name,
  id,
});

const getMentionDataFromRegExMatch = (matchPlainText: string): TriggerData | null => {
  const triggerRegEx = /({([^{^}]*)}\[([^[]*)]\(([^(^)]*)\))/i;

  const regexExecResult = triggerRegEx.exec(matchPlainText) as RegexMatchResult | null;
  return regexExecResult ? getMentionDataFromRegExMatchResult(regexExecResult) : null;
};

const addMentionsMarkupToMessage = (message: string) => {
  let updatedMessage = message;

  const singleGroupTriggerRegEx = /({[^{^}]*}\[[^[]*]\([^(^)]*\))/gi;
  const dividedValueByRegex = message.split(singleGroupTriggerRegEx);
  dividedValueByRegex.forEach((text) => {
    if (getMentionDataFromRegExMatch(text) === null) return;

    const mentionData = getMentionDataFromRegExMatch(text) as TriggerData;
    const href = `/community/profile/${mentionData.id}`;
    updatedMessage = updatedMessage.replace(mentionData.original, `<a class="c-post__mention" href='${href}'>@${mentionData.name}</a>`);
  });

  return updatedMessage;
};

const addLinksToMessage = (message: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const linkedMessage = message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
  return linkedMessage;
};

export const getMessageMarkup = (message: string, isModerator: boolean) => {
  const messageWithHashtags = addHashTagMarkupToMessage(message);
  const messageWithMentions = addMentionsMarkupToMessage(messageWithHashtags);
  if (isModerator) {
    const messageWithLinks = addLinksToMessage(messageWithMentions);
    return messageWithLinks;
  }
  return messageWithMentions;
};
