import { MutationTuple, OperationVariables } from '@apollo/client';
import { getApolloLink, useApolloMutation, useCookie } from '@netfront/common-library';

import { IUseCapuchinMutationOptions } from './useCapuchinMutation.interface';

export const useCapuchinMutation = <TData, TVariables extends OperationVariables = OperationVariables>({
  apolloLink,
  mutation,
  product,
  projectId,
  options,
  token,
}: IUseCapuchinMutationOptions<TData, TVariables>): MutationTuple<TData, TVariables> => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'capuchin',
    product,
    projectId,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloMutation({
    apiName: 'capuchin',
    apolloLink: apolloLink ?? authenticationLink,
    mutation,
    options,
  });
};
