
import { Skeleton } from 'components';

import { ProgressCircle } from '../ProgressCircle';

import { ContentHeaderProps } from './ContentHeader.interfaces';

const ContentHeader = ({ chapterTitle, isLoading, pageTitle, percentageCompleted }: ContentHeaderProps) => {
  return (
    <div className="flex bg-white rounded-md p-6 mb-4 border border-athens">
      <div className="flex-1 mr-4">
        {isLoading ? (
          <Skeleton height="1.5rem" width="10rem" />
        ) : chapterTitle ? (
          <span className="block color-accent h5 mb-2 weight-800">{chapterTitle}</span>
        ) : null}

        {isLoading ? <Skeleton height="1.7rem" width="40%" /> : pageTitle ? <h2 className="color-secondary h2 mb-0">{pageTitle}</h2> : null}
      </div>
      {isLoading ? (
        <Skeleton height="3rem" width="3rem" circle />
      ) : (
        typeof percentageCompleted === 'number' && <ProgressCircle percentageCompleted={Math.round(percentageCompleted)} />
      )}
    </div>
  );
};

export { ContentHeader };
