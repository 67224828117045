import { gql } from '@apollo/client';

const UPDATE_COMMUNITY_STATUS = gql`
  mutation updateStatus($userId: Int!, $communityId: Int!, $status: EConnectionStatus!) {
    communityConnection {
      updateStatus(userId: $userId, communityId: $communityId, status: $status)
    }
  }
`;

export { UPDATE_COMMUNITY_STATUS };
