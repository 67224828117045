import { IDBContentPage } from '@netfront/ekardo-content-library';

export const getFlattenContentPages = (contentPages: IDBContentPage[]): IDBContentPage[] => {
  let children = [] as IDBContentPage[];

  return contentPages
    .map((contentPage) => {
      if (Boolean(contentPage.contentPageChildren) && contentPage.contentPageChildren.length) {
        children = [...children, ...contentPage.contentPageChildren] as IDBContentPage[];
      }
      return contentPage;
    })
    .concat(children.length ? getFlattenContentPages(children) : children);
};

export const handleImportantSliderValue = (pageId: number, callback: (value: string) => void) => {
  setTimeout(() => {
    const importantSection = document.querySelector('.importance-slider');
    if(!importantSection) return;

    const sliderInput = importantSection.querySelector('.c-range-input__input') as HTMLInputElement;
    callback(String(sliderInput.value));

    document.addEventListener('click', () => {
      const section = document.querySelector('.importance-slider');
      if(!section) return;
      const input = section.querySelector('.c-range-input__input') as HTMLInputElement;
      callback(String(input.value));

    });
  }, 1000)

};

export const handleConfidenceSliderValue = (pageId: number, callback: (value: string) => void) => {
  setTimeout(() => {
    const confidenceSection = document.querySelector('.confidence-slider');
    if(!confidenceSection) return;

    const sliderInput = confidenceSection.querySelector('.c-range-input__input') as HTMLInputElement;
    callback(String(sliderInput.value));

    document.addEventListener('click', () => {
      const section = document.querySelector('.confidence-slider');
      if(!section) return;
      const input = section.querySelector('.c-range-input__input') as HTMLInputElement;
      callback(String(input.value));
    });
  }, 1000)

};
