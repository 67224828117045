import { IconCross, IconDocument } from '../Icons';

import { DocumentPreviewerProps } from './DocumentPreviewer.interfaces';

const DocumentPreviewer = ({ file, onCancel }: DocumentPreviewerProps) => {
  return (
    <div className="bg-grey-50 flex items-center border border-athens rounded p-3 color-secondary">
      <IconDocument additionalClassNames="w-5 h-5" />
      <span className="block mb-0 ml-2 mr-4">{file.name}</span>
      <button className="flex items-center justify-center mb-0 p-0 pointer" onClick={onCancel}>
        <IconCross additionalClassNames="w-5 h-5" />
      </button>
    </div>
  );
};

export { DocumentPreviewer };
