import { Link } from '../Link';

import { HelpButtonProps } from './HelpButton.interfaces';
import styles from './HelpButton.module.css';

const HelpButton = ({ className, isCompact = false, isFixed = false }: HelpButtonProps) => (
  <Link
    className={`${styles['c-help-button']} ${String(className)} ${isFixed ? styles['c-help-button__fixed'] : ''} ${
      isCompact ? styles['c-help-button--compact'] : ''
    }`}
    href="/get-help-now"
    target="_blank"
  >
    <div className={styles['c-help-button__icon']}>
      <img alt="Crisis support" src="/images/iconquer/crisis-icon.svg" />
    </div>
    <span className={styles['c-help-button__text']}>Crisis support</span>
  </Link>
);

export { HelpButton };
