import { Skeleton } from 'components';
import { getValidClassNames } from 'utils';

const ConversationMessageSkeleton = () => {
  return (
    <>
      <div
        className={getValidClassNames({
          'c-conversation-message c-conversation-message--skeleton': true,
        })}
      >
        <Skeleton additionalClassNames="c-conversation-message__avatar--skeleton" />
        <div className="c-conversation-message__body--skeleton">
          <Skeleton height=".5rem" width="4rem" />
          <Skeleton height=".85rem" width="80%" />
        </div>
      </div>
      <div
        className={getValidClassNames({
          'c-conversation-message c-conversation-message--from-user c-conversation-message--skeleton': true,
        })}
      >
        <Skeleton additionalClassNames="c-conversation-message__avatar--skeleton" />

        <div className="c-conversation-message__body--skeleton">
          <Skeleton height=".5rem" width="4rem" />
          <Skeleton height=".85rem" width="80%" />
        </div>
      </div>
    </>
  );
};

export { ConversationMessageSkeleton };
