import { useBonoboMutation } from 'hooks/useBonoboMutation';

import { UPDATE_USER_SETTINGS } from './useUpdateUserSettings.graphql';
import {
  IHandleUpdateUserSettingsParams,
  IUpdateUserSettingsMutationGraphQLResponse,
  IUpdateUserSettingsMutationVariables,
  IUseUpdateUserSettingsOptions,
  IUseUpdateUserSettings,
} from './useUpdateUserSettings.interfaces';

const useUpdateUserSettings = (options?: IUseUpdateUserSettingsOptions): IUseUpdateUserSettings => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseUpdateUserSettingsOptions);

  const [executeUpdateUserSettings, { loading: isLoading }] = useBonoboMutation<
    IUpdateUserSettingsMutationGraphQLResponse,
    IUpdateUserSettingsMutationVariables
  >({
    mutation: UPDATE_USER_SETTINGS,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: {
            updateSettings: { id },
          },
        } = data;

        onCompleted({
          result: Boolean(id),
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateUserSettings = async (request: IHandleUpdateUserSettingsParams) => {
    await executeUpdateUserSettings({
      variables: request,
    });
  };

  return {
    handleUpdateUserSettings,
    isLoading,
  };
};

export { useUpdateUserSettings };
