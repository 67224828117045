import { AVATAR_PATTERNS } from './patterns';

export enum EAvatarPattern {
  'horizontal-line' = 'horizontal-line',
  'vertical-line' = 'vertical-line',
  'horizontal-thin-line' = 'horizontal-thin-line',
  'vertical-thin-line' = 'vertical-thin-line',
  'dashed-square-horizontal' = 'dashed-square-horizontal',
  'dashed-square-vertical' = 'dashed-square-vertical',
  'dashed-rounded-horizontal' = 'dashed-rounded-horizontal',
  'dashed-rounded-vertical' = 'dashed-rounded-vertical',
  'dots-small' = 'dots-small',
  'dots-medium' = 'dots-medium',
  'dots-large' = 'dots-large',
  'stitch' = 'stitch',
  'square-medium' = 'square-medium',
  'square-large' = 'square-large',
  'square-outline-medium' = 'square-outline-medium',
  'square-outline-large' = 'square-outline-large',
  'circle-thin' = 'circle-thin',
  'circle-thick' = 'circle-thick',
  'wave-horizontal-large' = 'wave-horizontal-large',
  'wave-vertical-large' = 'wave-vertical-large',
  'wave-vertical-small' = 'wave-vertical-small',
  'zig-zag-horizontal-small' = 'zig-zag-horizontal-small',
  'zig-zag-horizontal-large' = 'zig-zag-horizontal-large',
  'zig-zag-vertical-small' = 'zig-zag-vertical-small',
  'zig-zag-vertical-large' = 'zig-zag-vertical-large',
}

export const AvatarPattern = ({
  backgroundColor,
  color,
  middleColor,
  pattern,
  text,
}: {
  backgroundColor: string;
  color: string;
  middleColor: string;
  pattern: EAvatarPattern;
  text: string;
}) => {
  const Pattern = AVATAR_PATTERNS[pattern];

  return (
    <svg
      color={color}
      height="100%"
      id="js-avatar-pattern"
      style={{ backgroundColor }}
      viewBox="0 0 221 221"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g color={color} data-pattern={pattern}>
        {Pattern}
      </g>
      <text
        dominantBaseline="middle"
        fill={middleColor}
        fontFamily="freight-sans-pro-bold, freight-sans-pro, sans-serif"
        fontSize="80px"
        fontWeight="800"
        text-anchor="middle"
        x="50%"
        y="50%"
      >
        {text}
      </text>
    </svg>
  );
};
