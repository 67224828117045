import { ERelateType } from '../../../services';

const POST_RELATE_ICONS = {
  LIKE: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Like</span>👍
    </div>
  ),
  RELATE: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Relate</span>🤝
    </div>
  ),
  UNDERSTAND: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Understand</span>❤️️
    </div>
  ),
  FELT_LIKE_THAT_TOO: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Felt like that too</span>✋
    </div>
  ),
  LAUGHT_OUT_LOUD: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Laught out loud</span>😂
    </div>
  ),
  THANK_YOU_FOR_SHARING: () => (
    <div className="c-relate-icon">
      <span className="h-hide-visually">Thanks for sharing</span>🙌
    </div>
  ),
};

const POST_RELATE_LABELS: Record<ERelateType, string> = {
  FELT_LIKE_THAT_TOO: 'I felt like that too',
  LAUGHT_OUT_LOUD: 'Laughing out loud',
  LIKE: 'Like',
  RELATE: 'Relate',
  THANK_YOU_FOR_SHARING: 'Thank you for sharing',
  UNDERSTAND: 'I understand',
};

export { POST_RELATE_ICONS, POST_RELATE_LABELS };
