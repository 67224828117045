import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  CMSContentPage,
  DBContentPageNavigationDirectionType,
  getUpdatedContentGroupingElementsFromQuestionActionConditions,
  IContentPage,
  IHandleGetNextPageParams,
  IHandleGetPreviousPageParams,
  IUseContentPageNavigationOnCompletedResponse,
  IUseContentPageOnCompletedResponse,
  IUseGetUserFlowStepContentPagesOnCompletedResponse,
  IUseStartContentPageCompletionOnCompletedResponse,
  IUseStartUserFlowStepUsingUrlsOnCompletedResponse,
  Toolkit,
  useContentPage,
  useContentPageContext,
  useContentPageNavigation,
  useGetUserFlowStepContentPages,
  useTracking,
} from '@netfront/ekardo-content-library';
import { Skeleton , ContentHeader, ContentNavigationDrawer, ContentNavigationLink, Page, NavigationButton } from 'components';
import { useRouter } from 'next/router';

import { useProtectedRoute, useToast } from '../../../hooks';

const COMPLETED_STEP_REDIRECT = '/questionnaire/baseline/participant-questionnaire';

const EligibilityPage = () => {
  const { handleToastError } = useToast();
  const { dispatch } = useContentPageContext();
  const { push, query } = useRouter();

  const { isAuthenticated } = useProtectedRoute();

  const { contentPageUrl, userFlowStepUrl = 'eligibility', userFlowUrl = 'dashboard' } = query;

  const [contentPageCompletionId, setContentPageCompletionId] = useState<number>();
  const [contentPageId, setContentPageId] = useState<number>();
  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [lastContentPageInSession, setLastContentPageInSession] = useState<IContentPage>();
  const [hasContentPageData, setHasContentPageData] = useState<boolean>(false);
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState<boolean>(false);
  const [isLastContentPageInSession, setIsLastContentPageInSession] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [moduleTitle, setModuleTitle] = useState<string>('');
  const [nextContentPageUrl, setNextContentPageUrl] = useState<string>('');
  const [stepPercentageCompleted, setStepPercentageCompleted] = useState<number>(0);
  const [userFlowStepTrackId, setUserFlowStepTrackId] = useState<number>();

  const handleEndContentPageCompletionCompleted = () => {
    setContentPageCompletionId(undefined);

    setContentPages((previousContentPages) =>
      previousContentPages.map((page) => {
        const { id } = page;

        if (id !== contentPageId) {
          return page;
        }

        return {
          ...page,
          isCompleted: true,
        };
      }),
    );

    if (!isLastContentPageInSession) {
      push(`/${String(userFlowStepUrl)}/${String(nextContentPageUrl)}`).catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    handleEndUserFlowStep({
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleEndContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleEndUserFlowStepCompleted = () => {
    push(COMPLETED_STEP_REDIRECT).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleEndUserFlowStepError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetContentPageCompleted = (data: IUseContentPageOnCompletedResponse) => {
    const { contentPage: currentContentPage } = data;

    const { id: returnedContentPageId } = currentContentPage;

    const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } =
      getUpdatedContentGroupingElementsFromQuestionActionConditions(currentContentPage);

    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      contentPageConfigurationNavigation: { enableBackButton = true, enableNextButton = true },
    } = contentPages.find(({ id }) => id === returnedContentPageId) as IContentPage;

    const currentContentPageIndex = contentPages.findIndex(({ id }) => id === returnedContentPageId);
    const isLastContentPage = Boolean(lastContentPageInSession && lastContentPageInSession.id === returnedContentPageId);
    const percentageCompleted = Math.ceil((currentContentPageIndex / contentPages.length) * 100);

    setContentPage(currentContentPage);
    setContentPageId(returnedContentPageId);
    setIsBackButtonEnabled(enableBackButton);
    setIsLastContentPageInSession(isLastContentPage);
    setIsNextButtonEnabled(enableNextButton);
    setStepPercentageCompleted(percentageCompleted);

    dispatch({
      payload: {
        contentPage: currentContentPage,
      },
      type: 'loadContentPage',
    });

    dispatch({
      payload: {
        contentSectionContainers,
        contentSections,
        contentSnippets,
        sectionGroups,
      },
      type: 'applyQuestionActions',
    });

    handleStartContentPageCompletion({
      contentPageId: returnedContentPageId,
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleGetContentPageError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetNextOrPreviousPage = (direction: DBContentPageNavigationDirectionType) => {
    setHasContentPageData(false);

    const params = {
      contentPageId: Number(contentPageId),
      userFlowStepTrackId: Number(userFlowStepTrackId),
    };

    if (direction === 'NEXT') {
      handleGetNextPage(params as IHandleGetNextPageParams);
      return;
    }

    handleGetPreviousPage(params as IHandleGetPreviousPageParams);
  };

  const handleGetNextOrPreviousContentPageCompleted = (data: IUseContentPageNavigationOnCompletedResponse) => {
    const { nextOrPreviousContentPage } = data;

    if (nextOrPreviousContentPage) {
      setNextContentPageUrl(nextOrPreviousContentPage.url);
    }

    handleEndContentPageCompletion({
      contentPageCompletionId: Number(contentPageCompletionId),
    });
  };

  const handleGetNextOrPreviousContentPageError = (error: ApolloError) => {
    setHasContentPageData(true);

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetUserFlowStepContentPagesCompleted = (data: IUseGetUserFlowStepContentPagesOnCompletedResponse) => {
    const {
      userFlowStep: { contentGroup, lastContentPage },
    } = data;
    const { contentPages: groupContentPages = [], title = '' } = contentGroup ?? {};

    setModuleTitle(title);
    setContentPages(groupContentPages);
    setLastContentPageInSession(lastContentPage);
  };

  const handleGetUserFlowStepContentPagesError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartContentPageCompletionCompleted = (data: IUseStartContentPageCompletionOnCompletedResponse) => {
    const {
      contentPageCompletion: { id },
    } = data;

    setContentPageCompletionId(id);
    setHasContentPageData(true);
  };

  const handleStartContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartUserFlowStepUsingUrlsCompleted = (data: IUseStartUserFlowStepUsingUrlsOnCompletedResponse) => {
    const { userFlowStepTrackId: returnedUserFlowStepTrackId } = data;

    setUserFlowStepTrackId(returnedUserFlowStepTrackId);

    handleGetUserFlowStepContentPages({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      topLevelOnly: false,
      userFlowStepTrackId: returnedUserFlowStepTrackId,
      userFlowStepUrl: String(userFlowStepUrl),
      userFlowUrl: String(userFlowUrl),
    });
  };

  const handleStartUserFlowStepUsingUrlsError = (error: ApolloError) => {
    push(`/consent/important-note`).catch(() =>
      handleToastError({
        error: error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const { handleGetContentPage } = useContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetContentPageCompleted,
    onError: handleGetContentPageError,
  });

  const { handleGetNextPage, handleGetPreviousPage } = useContentPageNavigation({
    onCompleted: handleGetNextOrPreviousContentPageCompleted,
    onError: handleGetNextOrPreviousContentPageError,
  });

  const {
    handleEndContentPageCompletion,
    handleEndUserFlowStep,
    handleStartContentPageCompletion,
    handleStartUserFlowStepUsingUrls,
    // isEndContentPageCompletionLoading = false,
    // isEndUserFlowStepLoading = false,
    // isStartContentPageCompletionLoading = false,
    // isStartUserFlowStepUsingUrlsLoading = false,
  } = useTracking({
    onEndContentPageCompletionCompleted: handleEndContentPageCompletionCompleted,
    onEndContentPageCompletionError: handleEndContentPageCompletionError,
    onEndUserFlowStepCompleted: handleEndUserFlowStepCompleted,
    onEndUserFlowStepError: handleEndUserFlowStepError,
    onStartContentPageCompletionCompleted: handleStartContentPageCompletionCompleted,
    onStartContentPageCompletionError: handleStartContentPageCompletionError,
    onStartUserFlowStepUsingUrlsCompleted: handleStartUserFlowStepUsingUrlsCompleted,
    onStartUserFlowStepUsingUrlsError: handleStartUserFlowStepUsingUrlsError,
  });

  const { handleGetUserFlowStepContentPages } = useGetUserFlowStepContentPages({
    onCompleted: handleGetUserFlowStepContentPagesCompleted,
    onError: handleGetUserFlowStepContentPagesError,
  });

  // ====================================

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleStartUserFlowStepUsingUrls({
      userFlowUrl: String(userFlowUrl),
      userFlowStepUrl: String(userFlowStepUrl),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!(contentPageUrl && userFlowStepTrackId && contentPages.length)) {
      return;
    }

    const { id } = contentPages.find(({ url }) => url === contentPageUrl) as IContentPage;

    handleGetContentPage({
      contentPageId: id,
      userFlowStepTrackId: Number(userFlowStepTrackId),
      withAnswers: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPageUrl, userFlowStepTrackId, contentPages.length]);

  return (
    <Page
      bodyClassName="mb-18 md:mb-0"
      className="flex"
      meta={{ seoDescription: contentPage?.seoDescription, seoTitle: contentPage?.seoTitle }}
      title={contentPage ? contentPage.title : 'Eligibility'}
    >
      <>
        <ContentNavigationDrawer isLoading={!hasContentPageData}>
          {moduleTitle && (
            <>
              <span className="color-primary block mt-8">{moduleTitle}</span>
              <p className="h3 color-blue-700 mb-6">Menu</p>
            </>
          )}

          {contentPages.map(({ id, isCompleted = false, title, url }) => {
            const fullHref = `/${String(userFlowStepUrl)}/${String(url)}`;
            const isActive = id === contentPageId;
            const isDisabled = !isCompleted;

            return (
              <ContentNavigationLink
                key={`content-navigation-link-${id}`}
                href={fullHref}
                isActive={isActive}
                isCompleted={isCompleted}
                isDisabled={isDisabled}
                text={title}
              />
            );
          })}
        </ContentNavigationDrawer>

        <article className="md:w-3/5 mx-auto px-8 py-12 w-full min-height-4/5">
          <Toolkit contentPageId={Number(contentPageId)} />

          <ContentHeader
            chapterTitle={moduleTitle}
            isLoading={!hasContentPageData}
            pageTitle={contentPage?.title}
            percentageCompleted={stepPercentageCompleted}
          />

          {hasContentPageData ? (
            <CMSContentPage userFlowStepTrackId={Number(userFlowStepTrackId)} />
          ) : (
            <div className="ek-section">
              <Skeleton height="5rem" width="100%" />
            </div>
          )}

          <div className="items-center flex mt-12 no-print">
            {!isLastContentPageInSession ? (
              <>
                <NavigationButton
                  direction={'PREVIOUS'}
                  isDisabled={!isBackButtonEnabled}
                  isLoading={!hasContentPageData}
                  moduleTitle={moduleTitle}
                  pageTitle={contentPages[contentPages.findIndex((i) => i.url === contentPageUrl) - 1]?.title}
                  onPress={() => handleGetNextOrPreviousPage('PREVIOUS')}
                />

                <NavigationButton
                  direction={'NEXT'}
                  isDisabled={!isNextButtonEnabled}
                  isLoading={!hasContentPageData}
                  moduleTitle={moduleTitle}
                  pageTitle={contentPages[contentPages.findIndex((i) => i.url === contentPageUrl) + 1]?.title}
                  onPress={() => handleGetNextOrPreviousPage('NEXT')}
                />
              </>
            ) : (
              <NavigationButton
                direction={'NEXT'}
                isDisabled={!isNextButtonEnabled}
                isLoading={!hasContentPageData}
                pageTitle={`Finish ${moduleTitle.toLowerCase()}`}
                onPress={() => handleGetNextOrPreviousPage('NEXT')}
              />
            )}
          </div>
        </article>
      </>
    </Page>
  );
};

export { EligibilityPage };
