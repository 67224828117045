import { gql } from '@apollo/client';

const GET_STEP_WITH_PAGES = gql`
  query getStep($stepUrl: String!, $projectId: String!, $userFlowUrl: String!) {
    userFlowStep {
      getStepByUrl(url: $stepUrl, projectId: $projectId, userFlowUrl: $userFlowUrl) {
        id
        url
        contentGroup {
          contentPages {
            id
            url
          }
        }
      }
    }
  }
`;

export { GET_STEP_WITH_PAGES };
