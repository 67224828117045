import { useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { SettingsButton } from '@netfront/ui-library';
import { getValidClassNames } from 'utils';

import { DropdownMenu } from '../DropdownMenu';
import { IconCamera } from '../Icons';
import { OnlineStatus } from '../OnlineStatus';

import { PageBannerProps } from './PageBanner.interfaces';

const PageBanner = ({
  bannerImage,
  canEdit,
  children,
  description,
  isActive,
  onAvatarClick,
  onBannerClick,
  options,
  profileImage,
  title,
}: PageBannerProps) => {

  const { isToggled: isOptionsMenuOpen, toggle: toggleOptionsMenu } = useToggle();

  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(String(description).length < 160);

  const toggleDescription = () => setIsDescriptionOpen(!isDescriptionOpen);

  return (
    <>
      <div className="c-page-banner" style={{ backgroundImage: bannerImage ? `url(${bannerImage})` : 'none' }}>
        {canEdit && onBannerClick && (
          <div className="c-page-banner__blanket">
            <button className="c-page-banner__cover-button" onClick={onBannerClick}>
              <IconCamera /> Change cover
            </button>
          </div>
        )}
      </div>
      <div className="c-page-banner__card">
        <div className="c-page-banner__image" style={{ backgroundImage: profileImage ? `url(${profileImage})` : 'none' }}>
          {!profileImage && String(title)[0]}
          {canEdit && (
            <button className="c-page-banner__avatar-edit c-page-banner__blanket" title="Edit profile" onClick={onAvatarClick}>
              <IconCamera />
            </button>
          )}
          {isActive && <OnlineStatus size="lg" />}
        </div>

        {options && options.length && (
          <div className='c-page-banner__settings'>
           
            <DropdownMenu  
              additionalClassNames="c-notifications-menu ml-auto spotlight-item--2"
              dropdownId="notifications"
              isDisplayContent={isOptionsMenuOpen}
              trigger={ <SettingsButton supportiveText="Additional options" onClick={toggleOptionsMenu} />}
              onDisplayContent={() => toggleOptionsMenu()}
            >
              <ul>
                {options.map(({ label, action }, index) => (
                  <li key={index}>
                    <button className='px-4 py-2 text-left w-full pointer hover:bg-grey-50' onClick={action}>{label}</button>
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          </div>
        )}

        <div className="c-page-banner__inner c-page-banner__inner--spacer">
          <h1 className="c-page-banner__title">{title}</h1>
          <p
            className={getValidClassNames({
              'c-page-banner__description': true,
              'c-page-banner__description--minimal': !isDescriptionOpen,
            })}
          >
            {description}
          </p>
          {String(description).length > 160 && (
            <button className="c-page-banner__description-toggle" onClick={toggleDescription}>{`Read ${
              isDescriptionOpen ? 'less' : 'more'
            }`}</button>
          )}
        </div>

        <div className="c-page-banner__inner">{children}</div>
      </div>
    </>
  );
};

export { PageBanner };
