import { useEffect, useState } from 'react';

import { Button, Dialog, ToggleSwitch } from '@netfront/ui-library';
import { BreadcrumbLink, CommunityFeedHeader, Page } from 'components';
import { useSocialContext } from 'contexts';
import { useGetRootBreadcrumbItems, useUpdateCommunityStatus } from 'hooks';
import { useRouter } from 'next/router';

import { DBCommunity } from '../../../../interfaces';
import {
  CommunityMembersQueryResult,
  ECommunityRole,
  EConnectionStatus,
  ESearchPrivacy,
  useGetCommunity,
  useGetCommunityUsers,
} from '../../../../services';
import {
  CommunityModeratorButtons,
  EmptyMessage,
  IconLock,
  ListCard,
  ListCardSkeleton,
  PageBanner,
  SearchInput,
  getWelcomeTag,
  useSearchInput,
} from '../../../Social';

const CommunityMembersPage = () => {
  const PAGE_TITLE = 'Members';

  const {
    asPath,
    query: { communityKey },
  } = useRouter();

  const { user: loggedUser } = useSocialContext();

  const { isSearchActive, onSearchClear, onSearchSubmit } = useSearchInput();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<DBCommunity>();
  const [allMembers, setAllMembers] = useState<CommunityMembersQueryResult[]>([]);

  const isLoggedUser = (id: number): boolean => id === Number(loggedUser?.id);

  const isLoggedUserModerator = loggedUser?.activeCommunities && loggedUser.activeCommunities.edges?.find(({ node }) => node?.id === community?.id)?.node?.userConnection?.role === ECommunityRole.Moderator;

  const closeConnectDialog = () => {
    setIsConnectDialogOpen(false);
  };

  const onConnectRequest = () => {
    setIsConnected(true);
    closeConnectDialog();
  };

  const getCommunityCompleted = (returnedCommunity: DBCommunity) => {
    const { userConnection } = returnedCommunity;

    if (userConnection) {
      setIsConnected(userConnection.status === EConnectionStatus.Accepted);
    }

    setCommunity(returnedCommunity);
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getCommunity } = useGetCommunity({
    onCompleted: getCommunityCompleted,
  });

  const { getCommunityUsers, isLoading: isGetCommunityUsersLoading } = useGetCommunityUsers({
    onCompleted: setAllMembers,
  });

  const { handleUpdateStatus } = useUpdateCommunityStatus();

  useEffect(() => {
    if (community || !communityKey) {
      return;
    }

    getCommunity({ variables: { key: String(communityKey), shouldIncludeUserConnection: true } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityKey, isConnected]);

  useEffect(() => {
    if (!community) return;

    if (isConnected) {
      getCommunityUsers({
        variables: {
          communityId: Number(community.id),
          shouldIncludeUser: true,
          status: [EConnectionStatus.Accepted, EConnectionStatus.Blocked],
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, isConnected]);

  const moderators = allMembers.filter(({ node }) => node.role === ECommunityRole.Moderator).sort((a, b) => a.node.user?.isOnline && !b.node.user?.isOnline ? -1 : !a.node.user?.isOnline && b.node.user?.isOnline ? 1 : 0);

  const communityMembers = allMembers.filter(({ node }) => node.role === ECommunityRole.Member).sort((a, b) => a.node.user?.hasInteracted && !b.node.user?.hasInteracted ? -1 : !a.node.user?.hasInteracted && b.node.user?.hasInteracted ? 1 : 0);

  const updateUsersStatus = (userId: number, status: EConnectionStatus) => {
    setAllMembers(allMembers.map(({ cursor, node }) => ({
      cursor,
      node: node.userId === userId ? {
        ...node,
        status: status
      } : node
    })))
  }

  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community/communities">Communities</BreadcrumbLink>, key: 'Communities' },
          {
            content: <BreadcrumbLink href={`/community/communities/${String(communityKey)}`}>{String(community? community.title : 'Community')}</BreadcrumbLink>,
            key: String(community?.title),
          },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `View members in ${String(community?.title)}`,
        seoTitle: `Members in ${community?.title ?? ''}`,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {community && (
        <>
          <PageBanner
            bannerImage={community.bannerImage?.presignedUrl}
            profileImage={community.profileImage?.presignedUrl}
            title={community.title}
          >
            {isConnected ? (
              <CommunityModeratorButtons 
                community={community} 
              />
            ) : (
              <Button text="Connect" onClick={() => setIsConnectDialogOpen(true)} />
            )}
          </PageBanner>

          <div className="c-community-page__container">
            {isConnected ? (
              <div>
                <CommunityFeedHeader community={community} />

                <SearchInput
                  id="members"
                  isSearchActive={isSearchActive}
                  labelText="Search members"
                  name="members"
                  placeholder="Search members"
                  type="text"
                  isLabelHidden
                  onClear={() => {
                    getCommunityUsers({
                      variables: {
                        communityId: Number(community.id),
                        shouldIncludeUser: true,
                        status: EConnectionStatus.Accepted,
                      },
                    });

                    onSearchClear();
                  }}
                  onSearch={(value) => {
                    getCommunityUsers({
                      variables: {
                        communityId: Number(community.id),
                        shouldIncludeUser: true,
                        status: EConnectionStatus.Accepted,
                        filter: value,
                        searchPrivacy: ESearchPrivacy.IncludeDisplayedName,
                      },
                    });

                    onSearchSubmit(value);
                  }}
                />

                {isGetCommunityUsersLoading && [...Array(3)].map((_, key) => <ListCardSkeleton key={key} />)}

                {!isGetCommunityUsersLoading && Boolean(allMembers.length) && (
                  <div className="mb-8">
                    <h2 className="h6 mb-4">Moderators ({moderators.length})</h2>
                    {moderators.map(({ node: { id, user } }) => (
                      <ListCard
                        key={id}
                        avatarImage={user?.profileImage?.presignedUrl}
                        avatarTitle={user?.displayedName}
                        description="View profile"
                        displayName={user?.displayedName}
                        href={isLoggedUser(Number(user?.id)) ? '/community/profile' : `/community/profile/${String(user?.key)}`}
                        isOnline={isLoggedUser(Number(user?.id)) ? loggedUser?.isOnline : user?.isOnline}
                        tag={isLoggedUser(Number(user?.id)) ? (loggedUser?.isOnline ? 'Online' : '') : user?.isOnline ? 'Online' : ''}
                        hasArrow
                        isModerator
                      ></ListCard>
                    ))}
                  </div>
                )}

                {!isGetCommunityUsersLoading && Boolean(allMembers.length) && (
                  <>
                    <h2 className="h6 mb-4">Members ({communityMembers.length})</h2>
                    {communityMembers.map(({ node: { id, user, updatedDate, status } }) => (
                      <div key={`community-member-${id}`} className='c-community-member-card'>
                        <ListCard
                          key={id}
                          avatarImage={user?.profileImage?.presignedUrl}
                          avatarTitle={user?.displayedName}
                          description="View profile"
                          displayName={user?.displayedName}
                          href={isLoggedUser(Number(user?.id)) ? '/community/profile' : `/community/profile/${String(user?.key)}`}
                          isOnline={user?.hasInteracted}
                          tag={getWelcomeTag(new Date(String(updatedDate))) ? getWelcomeTag(new Date(String(updatedDate))) : user?.hasInteracted ? 'Online' : ''}
                          hasArrow
                        />
                        {isLoggedUserModerator && (
                          <ToggleSwitch 
                            additionalClassNames='c-community-member-card__toggle' 
                            id={String(id)}
                            isChecked={status === EConnectionStatus.Blocked}
                            labelText='Block user'
                            onChange={() => {
                              const newStatus = status === EConnectionStatus.Blocked ? EConnectionStatus.Accepted : EConnectionStatus.Blocked;
                              handleUpdateStatus({ communityId: community.id, userId: Number(user?.id), status: newStatus })

                              updateUsersStatus(Number(user?.id), newStatus);
                            }}
                          />
                        )}
                        
                      </div>
                    ))}
                  </>
                )}
                {!isGetCommunityUsersLoading && Boolean(!allMembers.length) && <EmptyMessage message="No members found" />}
              </div>
            ) : (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(community.title)}'s feed`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${community.title}`}
            onCancel={closeConnectDialog}
            onClose={closeConnectDialog}
            onConfirm={onConnectRequest}
          >
            <p>Are you sure you want to connect with {community.title}?</p>
          </Dialog>
        </>
      )}
    </Page>
  );
};

export { CommunityMembersPage };
