import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_USER_FLOW_BY_URL } from './useGetUserFlowByUrl.graphql';
import {
  IGetUserFlowByUrlQueryGraphQLResponse,
  IHandleGetUserFlowByUrlParams,
  IUseGetUserFlowByUrl,
  IUseGetUserFlowByUrlOptions,
} from './useGetUserFlowByUrl.interfaces';

const useGetUserFlowByUrl = (options?: IUseGetUserFlowByUrlOptions): IUseGetUserFlowByUrl => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetUserFlowByUrlOptions);

  const [executeGetUserFlowByUrl, { loading: isLoading }] = useEkardoLazyQuery<IGetUserFlowByUrlQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlow: { getUserFlowByUrl: userFlow },
        } = data;

        onCompleted({
          userFlow: {
            ...userFlow,
          },
        });
      },
      onError,
    },
    product,
    query: query ?? GET_USER_FLOW_BY_URL,
    token,
  });

  const handleGetUserFlowByUrl = async ({ projectId, url }: IHandleGetUserFlowByUrlParams) => {
    await executeGetUserFlowByUrl({
      variables: {
        projectId,
        url,
      },
    });
  };

  return {
    handleGetUserFlowByUrl,
    isLoading,
  };
};

export { useGetUserFlowByUrl };
