import { Skeleton } from 'components';

const PostSkeleton = () => {
  return (
    <div className="c-post">
      <div className="c-post-user">
        <div className="c-post-user__avatar">
          <Skeleton additionalClassNames="c-post-user__avatar--skeletion" />
        </div>

        <div className="c-post-user__details">
          <span className="c-post-user__display-name">
            <Skeleton additionalClassNames="c-post-user__display-name--skeleton" />
          </span>
          <span className="c-post-user__date">
            <Skeleton additionalClassNames="c-post-user__date--skeleton" />
          </span>
        </div>
      </div>

      <div className="c-post-message">
        <Skeleton additionalClassNames="c-post-message--skeleton" />
        <Skeleton additionalClassNames="c-post-message--skeleton" />
      </div>
    </div>
  );
};

export { PostSkeleton };
