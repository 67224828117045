
import { useBonoboMutation } from 'hooks/useBonoboMutation';

import { UPDATE_STATUS } from './useUpdatePostStatus.graphql';
import {
  IHandleUpdatePostStatusParams,
  IUpdatePostStatusMutationGraphQLResponse,
  IUpdatePostStatusMutationVariables,
  IUseUpdatePostStatusOptions,
  IUseUpdatePostStatus,
} from './useUpdatePostStatus.interfaces';

const useUpdatePostStatus = (options?: IUseUpdatePostStatusOptions): IUseUpdatePostStatus => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseUpdatePostStatusOptions);

  const [executeUpdatePostStatus, { loading: isLoading }] = useBonoboMutation<
  IUpdatePostStatusMutationGraphQLResponse,
  IUpdatePostStatusMutationVariables
  >({
    mutation: UPDATE_STATUS,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: { updateStatus: isUpdated },
        } = data;

        onCompleted({
          result: isUpdated,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdatePostStatus = async (request: IHandleUpdatePostStatusParams) => {
    await executeUpdatePostStatus({
      variables: request,
    });
  };

  return {
    handleUpdatePostStatus,
    isLoading,
  };
};

export { useUpdatePostStatus };
