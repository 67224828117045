import { useEffect, useState } from 'react';

import {
  IDBQuestionAnswer,
  IDBQuestionAnswerMatchResponseMatch,
  IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse,
  IUserFlowStepTrack,
  useGetUserFlowStepTracksWithAnswers,
} from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { format, getYear } from 'date-fns';

import { ACTIVITY_QUESTION, getFirstAndLastDatesOfMonth, MOOD_QUESTION } from 'pages/mood-and-activity-tracker';

import { CalendarCardEmpty } from './CalendarCardEmpty';

import { DashboardCard, DashboardCardSkeleton } from '../../components';

export interface ICalendarDailyEntries {
  afternoon?: IDBQuestionAnswerMatchResponseMatch;
  evening?: IDBQuestionAnswerMatchResponseMatch;
  mood?: IDBQuestionAnswer;
  morning?: IDBQuestionAnswerMatchResponseMatch;
}

export const CalendarCard = () => {
  const todaysDate = new Date();

  const [todaysEntries, setTodaysEntries] = useState<ICalendarDailyEntries>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onGetUserFlowStepTracksWithAnswersCompleted = (data: IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse) => {
    const { userFlowStepTracks: trackings } = data;

    setTodaysEntries(getTodaysEntries(trackings));
    setIsLoading(false);
  };

  const getTodaysEntries = (trackings?: IUserFlowStepTrack[]) => {
    if (!trackings || !trackings.length) return;

    const tracking = trackings.find(({ instanceDate }) => instanceDate === todaysDate.toISOString().split('T')[0]);

    const answer = tracking?.answers.find((r) => r.contentSnippetQuestion?.id === ACTIVITY_QUESTION);
    const { answers } = answer ?? {};
    const morning = answers?.find((r) => r.definedPosition === 0);
    const afternoon = answers?.find((r) => r.definedPosition === 1);
    const evening = answers?.find((r) => r.definedPosition === 2);

    const mood = tracking?.answers.find((r) => r.contentSnippetQuestion?.id === MOOD_QUESTION);

    return {
      morning,
      afternoon,
      evening,
      mood,
    };
  };

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: onGetUserFlowStepTracksWithAnswersCompleted,
  });

  useEffect(() => {
    const { firstDate: start, lastDate: end } = getFirstAndLastDatesOfMonth(todaysDate);
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'calendar',
      start,
      end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmpty = (object) => {
    if (!object) return true;
    return Object.values(object).every((x) => x === null || x === '' || x === undefined);
  };

  return (
    <DashboardCard 
      cardTooltip={{ text: 'Use this tool to keep track of your mood and schedule activities into your life. ' }}
      href="/mood-and-activity-tracker" 
      linkLabel="My Mood and activity tracker"
    >
      {!isLoading && !isEmpty(todaysEntries) ? (
        <div className="flex flex-col justify-center items-center">
          <div className="bg-primary rounded-circle w-12 h-12 flex justify-center items-center mb-3">
            <span className="h5 weight-800 mb-0 color-white mt block">{format(new Date(todaysDate), 'd')}</span>
          </div>
          <span className="color-black h5 weight-800 mb-6 block">
            {format(new Date(todaysDate), 'MMMM')} {getYear(new Date(todaysDate))}
          </span>

          <div className="mb-4 w-full">
            {todaysEntries && (
              <div className="w-full">
                {todaysEntries.morning && (
                  <div className="pt-2 bg-whisper rounded w-full flex flex-col justify-center items-center mb-2">
                    <img alt="Morning entry" className="w-6 h-6" src="/images/morning-icon.svg" />
                    <span className="mb-0">{todaysEntries.morning.questionResponse?.label}</span>
                  </div>
                )}
              </div>
            )}

            {todaysEntries && (
              <div className="w-full">
                {todaysEntries.afternoon && (
                  <div className="pt-2 bg-whisper rounded w-full flex flex-col justify-center items-center mb-2">
                    <img alt="Afternoon entry" className="w-6 h-6" src="/images/day-icon.svg" />

                    <span className="mb-0">{todaysEntries.afternoon.questionResponse?.label}</span>
                  </div>
                )}
              </div>
            )}

            {todaysEntries && (
              <div className="w-full">
                {todaysEntries.evening && (
                  <div className="pt-2 bg-whisper rounded w-full flex flex-col justify-center items-center mb-2">
                    <img alt="Evening entry" className="w-6 h-6" src="/images/afternoon-icon.svg" />

                    <span className="mb-0">{todaysEntries.evening.questionResponse?.label}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <Button linkButton={{ id: 'calendar', url: '/mood-and-activity-tracker' }} text="View Mood and activity tracker" />
        </div>
      ) : null}

      {!isLoading && isEmpty(todaysEntries) ? <CalendarCardEmpty /> : null}

      {isLoading ? <DashboardCardSkeleton direction="column" /> : null}
    </DashboardCard>
  );
};
