export const SELF_ASSESSMENT_BEGINNING_LINK = '/self-assessment/introduction';
export const SELF_ASSESSMENT_ASSIST_BEGINNING_PAGE_ID = 124870;

export const SELF_ASSESSMENT_USERFLOW_STEP_ID = 255;

export const QUESTION_1_2_CONTENT_SNIPPET_QUESTION_IDS = [25747, 25748, 25750, 25751, 25752, 25753, 25754, 25755, 25756, 25780];

export const QUESTION_1_2_RESPONSES_RADIO_NEVER_ID = 3392;

export const CONTENT_PAGE_ID_QUESTION_1_2 = 124871;

export const CONTENT_PAGE_ID_QUESTION_3_1 = 124884;


export const smokingQuestionIds = [25783, 25795, 25807, 25819, 25831];
export const alcoholQuestionIds = [25785, 25796, 25809, 25821, 25833];
export const cannabisQuestionIds = [25786, 25798, 25810, 25822, 25834];
export const cocaineQuestionIds = [25787, 25799, 25811, 25823, 25835];
export const amphetamineQuestionIds = [25788, 25800, 25812, 25824, 25836];
export const inhalantsQuestionIds = [25789, 25801, 25813, 25825, 25837];
export const sedativesQuestionIds = [25790, 25802, 25814, 25826, 25838];
export const hallucinogensQuestionIds = [25791, 25803, 25815, 25827, 25839];
export const opioidQuestionIds = [25792, 25804, 25816, 25828, 25840];
export const otherQuestionIds = [25793, 25805, 25817, 25829, 25841];

export const SELF_ASSESSMENT_INSTRUCTION_PAGE_IDS = [124868, 124869];
