import { gql } from '@apollo/client';

const GET_EMBARGO_POSTS_COUNT = gql`
  query getPostsCount($communityId: Int!) {
    post {
      embargoedCount(communityId: $communityId)
    }
  }
`;

export { GET_EMBARGO_POSTS_COUNT };
