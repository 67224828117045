import { useEffect, useState } from 'react';

import { ApolloError, gql } from '@apollo/client';
import { getAccessTokenCookie, getTemporaryTokenCookie } from '@netfront/common-library';
import {
  CMSContentPage,
  CONTENT_PAGE_CONTEXT_GRAPHQL,
  DBContentPageNavigationDirectionType,
  getUpdatedContentGroupingElementsFromQuestionActionConditions,
  IContentPage,
  IDBQuestionAnswer,
  IHandleGetNextPageParams,
  IHandleGetPreviousPageParams,
  ISetUserAnswerMutationGraphQLResponse,
  ISetUserAnswerTextMutationVariables,
  IUseContentPageNavigationOnCompletedResponse,
  IUseContentPageOnCompletedResponse,
  IUseGetAnswersOnCompletedResponse,
  IUseGetUserFlowStepContentPagesOnCompletedResponse,
  IUseStartContentPageCompletionOnCompletedResponse,
  IUseStartUserFlowStepUsingUrlsOnCompletedResponse,
  useContentPage,
  useContentPageContext,
  useContentPageNavigation,
  useEkardoMutation,
  useGetAnswers,
  useGetUserFlowStepContentPages,
  useTracking,
} from '@netfront/ekardo-content-library';
import { useGetPaginatedGroupsForUser, useUser } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { add, addMinutes, differenceInDays, format, isPast } from 'date-fns';
import first from 'lodash.first';
import { useRouter } from 'next/router';

import {
  Skeleton,
  alcoholQuestionIds,
  amphetamineQuestionIds,
  BreadcrumbLink,
  cannabisQuestionIds,
  cocaineQuestionIds,
  CONTENT_PAGE_ID_QUESTION_1_2,
  CONTENT_PAGE_ID_QUESTION_3_1,
  hallucinogensQuestionIds,
  hasSelectedAllAssitItems,
  InformationBanner,
  inhalantsQuestionIds,
  NavigationButton,
  opioidQuestionIds,
  otherQuestionIds,
  Page,
  sedativesQuestionIds,
  SELF_ASSESSMENT_ASSIST_BEGINNING_PAGE_ID,
  SELF_ASSESSMENT_BEGINNING_LINK,
  SELF_ASSESSMENT_INSTRUCTION_PAGE_IDS,
  shouldSkipAssistSection,
  smokingQuestionIds,
  useGetLastSelfAssessment,
} from '../../../components';
import {
  EAgency,
  ICheckboxSelectedAnswers,
  IUseGetTrackingsOnCompletedResponse,
  useCurrentAgency,
  useGetRootBreadcrumbItems,
  useGetTrackings,
  useHasPrivateLayout,
  useSendInternalNotificationToTheCurrentUser,
  useSendMailToTheCurrentUser,
  useSetUserAnswerCheckbox,
  useToast,
} from '../../../hooks';

const SelfAssessmentPage = () => {
  const PAGE_TITLE = 'Self assessment';

  const { asPath, push, query } = useRouter();
  const { dispatch } = useContentPageContext();

  const { handleToastError, handleToastCustomError } = useToast();
  const accessToken = getAccessTokenCookie();
  const { hasPrivateLayout } = useHasPrivateLayout();
  const temporaryToken = getTemporaryTokenCookie();
  const { getAgency, getSmartCode } = useCurrentAgency();

  const { contentPageUrl, trackingId: trackingIdParam, shouldIgnoreLimitedAttempts } = query;

  const userFlowUrl = 'self-assessment';
  const userFlowStepUrl = 'self-assessment';
  const SUBSTANCE_QUESTION_ID = 25741;
  const SUBSTANCE_QUESTION_PAGE_ID = 124870;
  const SUBSTANCE_FORM_ID = 25744;

  const [userFlowStepTrackId, setUserFlowStepTrackId] = useState<number>();
  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [lastContentPageInSession, setLastContentPageInSession] = useState<IContentPage>();
  const [contentPageId, setContentPageId] = useState<number>();
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [isLastContentPageInSession, setIsLastContentPageInSession] = useState<boolean>(false);
  const [stepPercentageCompleted, setStepPercentageCompleted] = useState<number>(0);
  const [contentPageCompletionId, setContentPageCompletionId] = useState<number>();
  const [hasContentPageData, setHasContentPageData] = useState<boolean>(false);
  const [nextContentPageUrl, setNextContentPageUrl] = useState<string>('');
  const [shouldLimitAttempt, setShouldLimitAttempt] = useState<boolean>(false);
  const [navigationDirection, setNavigationDirection] = useState<DBContentPageNavigationDirectionType>('NEXT');

  const user = useUser().getUser();

  const getAgencyPlaceholder = () => {
    return getAgency() ? getAgency() === EAgency.communities ? 'Corrective Services NSW' : String(getAgency()) : 'your agency';
  };

  const GetUserFlowStepContentPages = (trackingId: number) => {
    handleGetUserFlowStepContentPages({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      topLevelOnly: false,
      userFlowStepTrackId: trackingId,
      userFlowStepUrl: userFlowStepUrl,
      userFlowUrl: userFlowUrl,
    });
  };
  const { handleSetUserAnswerCheckbox } = useSetUserAnswerCheckbox({
    onCompleted() {
      if (!userFlowStepTrackId) return;
      GetUserFlowStepContentPages(userFlowStepTrackId);
    },
  });

  const onSendMailToTheCurrentUserCompleted = () => {
    push(trackingIdParam ? '/feedback/results-overtime' : '/self-assessment/results').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleStartUserFlowStepUsingUrlsCompleted = (data: IUseStartUserFlowStepUsingUrlsOnCompletedResponse) => {
    const { userFlowStepTrackId: returnedUserFlowStepTrackId } = data;

    const trackingId = trackingIdParam ? Number(trackingIdParam) : Number(returnedUserFlowStepTrackId);

    setUserFlowStepTrackId(trackingId);

    if (lastSelfAssessment) {
      const { answers } = lastSelfAssessment;

      const answer = answers.find((a) => a.contentSnippetQuestion?.id === SUBSTANCE_QUESTION_ID);

      if (answer && answer.selectedAnswers.length > 0) {
        const checkboxesSelected = answer.selectedAnswers.map((r) => {
          const selected: ICheckboxSelectedAnswers = { questionResponseCheckboxId: r.questionResponseCheckboxId };
          return selected;
        });

        handleSetUserAnswerCheckbox({
          contentSnippetFormId: SUBSTANCE_FORM_ID,
          contentSnippetQuestionId: SUBSTANCE_QUESTION_ID,
          selectedAnswers: checkboxesSelected,
          contentPageId: SUBSTANCE_QUESTION_PAGE_ID,
          status: 'COMPLETE',
          userFlowStepTrackId: trackingId,
        });
        return;
      }
    }

    GetUserFlowStepContentPages(trackingId);
  };

  const handleStartUserFlowStepUsingUrlsError = (error: ApolloError) => {
    push(`/self-assessment/limit`).catch(() =>
      handleToastError({
        error: error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleGetUserFlowStepContentPagesCompleted = (data: IUseGetUserFlowStepContentPagesOnCompletedResponse) => {
    const {
      userFlowStep: { contentGroup, lastContentPage },
    } = data;
    const { contentPages: groupContentPages = [] } = contentGroup ?? {};

    setContentPages(groupContentPages);
    setLastContentPageInSession(lastContentPage);
  };

  const handleGetUserFlowStepContentPagesError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetContentPageCompleted = (data: IUseContentPageOnCompletedResponse) => {
    const { contentPage: currentContentPage } = data;

    const { id: returnedContentPageId } = currentContentPage;

    const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } =
      getUpdatedContentGroupingElementsFromQuestionActionConditions(currentContentPage);

    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      contentPageConfigurationNavigation: { enableBackButton = true, enableNextButton = true },
    } = contentPages.find(({ id }) => id === returnedContentPageId) as IContentPage;

    const currentContentPageIndex = contentPages.findIndex(({ id }) => id === returnedContentPageId);
    const isLastContentPage = Boolean(lastContentPageInSession && lastContentPageInSession.id === returnedContentPageId);
    const percentageCompleted = Math.ceil((currentContentPageIndex / contentPages.length) * 100);

    setContentPageId(returnedContentPageId);
    setIsBackButtonEnabled(enableBackButton);
    setIsLastContentPageInSession(isLastContentPage);
    setIsNextButtonEnabled(enableNextButton);
    setStepPercentageCompleted(percentageCompleted);

    dispatch({
      payload: {
        contentPage: currentContentPage,
      },
      type: 'loadContentPage',
    });

    dispatch({
      payload: {
        contentSectionContainers,
        contentSections,
        contentSnippets,
        sectionGroups,
      },
      type: 'applyQuestionActions',
    });

    handleStartContentPageCompletion({
      contentPageId: returnedContentPageId,
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleGetContentPageError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartContentPageCompletionCompleted = (data: IUseStartContentPageCompletionOnCompletedResponse) => {
    const {
      contentPageCompletion: { id },
    } = data;

    setContentPageCompletionId(id);
    setHasContentPageData(true);

    handleGetAnswers({
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleStartContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleEndContentPageCompletionCompleted = async () => {
    setContentPageCompletionId(undefined);

    setContentPages((previousContentPages) =>
      previousContentPages.map((page) => {
        const { id } = page;

        if (id !== contentPageId) {
          return page;
        }

        return {
          ...page,
          isCompleted: true,
        };
      }),
    );

    if (contentPageId === CONTENT_PAGE_ID_QUESTION_1_2) {
      handleGetSubstanceAnswers({
        userFlowStepTrackId: Number(userFlowStepTrackId),
      });

      return;
    }

    if (contentPageId === CONTENT_PAGE_ID_QUESTION_3_1 && navigationDirection === 'PREVIOUS') {
      handleGetSubstanceAnswers({
        userFlowStepTrackId: Number(userFlowStepTrackId),
      });

      return;
    }

    if (isLastContentPageInSession && navigationDirection === 'NEXT') {
      await handleEndUserFlowStep({
        userFlowStepTrackId: Number(userFlowStepTrackId),
      });

      return;
    }

    push(`/${String(userFlowUrl)}/${String(nextContentPageUrl)}`).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleEndContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetNextOrPreviousContentPageCompleted = (data: IUseContentPageNavigationOnCompletedResponse) => {
    const { nextOrPreviousContentPage } = data;

    if (nextOrPreviousContentPage) {
      setNextContentPageUrl(
        trackingIdParam ? `${nextOrPreviousContentPage.url}?trackingId=${String(userFlowStepTrackId)}` : nextOrPreviousContentPage.url,
      );
    }

    handleEndContentPageCompletion({
      contentPageCompletionId: Number(contentPageCompletionId),
    });
  };

  const handleGetNextOrPreviousContentPageError = () => {
    setHasContentPageData(true);

    handleToastCustomError({
      message: 'Please answer all questions on this page before proceeding',
    });
  };

  const handleGetNextOrPreviousPage = (direction: DBContentPageNavigationDirectionType) => {
    setNavigationDirection(direction);

    const hasSelectedAllAvailableAssitItems = hasSelectedAllAssitItems(Number(contentPageId));

    if (!hasSelectedAllAvailableAssitItems && direction === 'NEXT') {
      handleToastCustomError({
        message: 'Please answer all questions on this page before proceeding',
      });

      return;
    }

    setHasContentPageData(false);

    const params = {
      contentPageId: Number(contentPageId),
      userFlowStepTrackId: Number(userFlowStepTrackId),
    };

    if (direction === 'NEXT') {
      handleGetNextPage(params as IHandleGetNextPageParams);
      return;
    }

    handleGetPreviousPage(params as IHandleGetPreviousPageParams);
  };

  const handleEndUserFlowStepCompleted = () => {
    if (!accessToken) {
      push('/self-assessment/results').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    handleGetTrackingsSelfAssessment({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      includeAnswers: true,
      includeUserFlowStep: true,
      userFlowStepUrls: ['self-assessment'],
    });
  };

  const INTERESTED_QUESTION_ID = 28689;
  const INTERESTED_RESPONSE_ID = 4226;

  const onGetTrackingsSelfAssessmentCompleted = (data: IUseGetTrackingsOnCompletedResponse) => {
    const { trackings } = data;
    if (trackings.length === 0) return;
    const interestedAnswer = trackings.find((tracking) =>
      tracking.answers.find(
        (answer) => answer.contentSnippetQuestionId === INTERESTED_QUESTION_ID && answer.questionResponseRadioId === INTERESTED_RESPONSE_ID,
      ),
    );
    const date = addMinutes(new Date(), 120);
    if (interestedAnswer) {
      const INTERESTED_EMAIL_TEMPLATE_ID = 12844;
      const INTERNAL_NOTIFICATION_TEMPLATE_ID = 12845;

      handleSendInternalNotificationToTheCurrentUser({
        templateId: INTERNAL_NOTIFICATION_TEMPLATE_ID,
      });

      handleSendMailToTheCurrentUser({
        subject: 'Stable Ground - Evaluation questionnaire',
        templateId: INTERESTED_EMAIL_TEMPLATE_ID,
        date,
      });
    }
  };

  const [executeSetUserAnswerSingleLineText] = useEkardoMutation<ISetUserAnswerMutationGraphQLResponse, ISetUserAnswerTextMutationVariables>({
    mutation: CONTENT_PAGE_CONTEXT_GRAPHQL.mutations.setUserAnswerSingleLineText,
  });

  const { handleGetTrackings: handleGetTrackingsSelfAssessment } = useGetTrackings({ onCompleted: onGetTrackingsSelfAssessmentCompleted });

  const handleEndUserFlowStepError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const shouldShowAssitItem = (answers: IDBQuestionAnswer[], questionId: number) => {
    const hasAnswered = answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === questionId);
    if (!hasAnswered) {
      return false;
    }

    return answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === questionId)?.questionResponseRadioId !== 3392;
  };

  const handleShowAssistItem = (questionIds: number[], shouldShow: boolean) => {
    questionIds.forEach((questionId) => {
      const questions = document.querySelectorAll(`.c-table__tbody-tr:has(.c-question-snippet__question-${questionId})`);

      if (!shouldShow) {
        return questions.forEach((question) => {
          return question.classList.add('none');
        });
      }
    });
  };

  const handleGetAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { answers } = data;

    const shouldShowSmoking = shouldShowAssitItem(answers, 25747);
    const shouldShowAlcohol = shouldShowAssitItem(answers, 25748);
    const shouldShowCannabis = shouldShowAssitItem(answers, 25750);
    const shouldShowCocaine = shouldShowAssitItem(answers, 25751);
    const shouldShowAmphetamine = shouldShowAssitItem(answers, 25752);
    const shouldShowInhalants = shouldShowAssitItem(answers, 25753);
    const shouldShowSedatives = shouldShowAssitItem(answers, 25754);
    const shouldShowHallucinogens = shouldShowAssitItem(answers, 25755);
    const shouldShowOpioids = shouldShowAssitItem(answers, 25756);
    const shouldShowOther = shouldShowAssitItem(answers, 25780);

    const otherSubstanceResponse = answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === 25743)?.text;

    dispatch({
      payload: {
        placeholders: {
          '[OTHER_SUBSTANCE]': String(otherSubstanceResponse),
          '[AGENCY_PLACEHOLDER]': getAgencyPlaceholder(),
        },
      },
      type: 'savePlaceholders',
    });

    handleShowAssistItem(smokingQuestionIds, shouldShowSmoking);
    handleShowAssistItem(alcoholQuestionIds, shouldShowAlcohol);
    handleShowAssistItem(cannabisQuestionIds, shouldShowCannabis);
    handleShowAssistItem(cocaineQuestionIds, shouldShowCocaine);
    handleShowAssistItem(amphetamineQuestionIds, shouldShowAmphetamine);
    handleShowAssistItem(inhalantsQuestionIds, shouldShowInhalants);
    handleShowAssistItem(sedativesQuestionIds, shouldShowSedatives);
    handleShowAssistItem(hallucinogensQuestionIds, shouldShowHallucinogens);
    handleShowAssistItem(opioidQuestionIds, shouldShowOpioids);
    handleShowAssistItem(otherQuestionIds, shouldShowOther);

    answerAgencyCode(answers);
  };

  const answerAgencyCode = (answers: IDBQuestionAnswer[]) => {
    const agencyCodeQuestion = answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === 34379);

    if(agencyCodeQuestion) return;

    if(getSmartCode()) {
      executeSetUserAnswerSingleLineText({
        variables: {
          contentSnippetFormId: 25744,
          contentSnippetQuestionId: 34379,
          text: String(getSmartCode()),
          contentPageId: 124870,
          userFlowStepTrackId,
        }
      })

      return;
    } 

    if(user) {
      handleGetPaginatedGroupsForUser({
        userId: Number(user.id)
      });
    }
   
  }


  const handleGetSubstanceAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { answers } = data;

    if (navigationDirection === 'PREVIOUS' && contentPageId === CONTENT_PAGE_ID_QUESTION_3_1) {
      if (shouldSkipAssistSection(answers)) {
        push(`/${String(userFlowUrl)}/question-1-1`);
        return;
      }
    }

    if (navigationDirection === 'PREVIOUS') {
      push(`/${String(userFlowUrl)}/${String(nextContentPageUrl)}`);
      return;
    }

    push(
      shouldSkipAssistSection(answers)
        ? `/self-assessment/question-3-1${trackingIdParam ? `?trackingId=${String(userFlowStepTrackId)}` : ''}`
        : `/${String(userFlowUrl)}/${String(nextContentPageUrl)}`,
    ).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const rootBreadcrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: Boolean(accessToken),
  });

  const { handleGetNextPage, handleGetPreviousPage } = useContentPageNavigation({
    onCompleted: handleGetNextOrPreviousContentPageCompleted,
    onError: handleGetNextOrPreviousContentPageError,
  });

  const { handleSendInternalNotificationToTheCurrentUser } = useSendInternalNotificationToTheCurrentUser({});

  const { handleSendMailToTheCurrentUser } = useSendMailToTheCurrentUser({
    onCompleted: onSendMailToTheCurrentUserCompleted,
  });

  const { handleEndContentPageCompletion, handleEndUserFlowStep, handleStartContentPageCompletion, handleStartUserFlowStepUsingUrls } =
    useTracking({
      onEndContentPageCompletionCompleted: handleEndContentPageCompletionCompleted,
      onEndContentPageCompletionError: handleEndContentPageCompletionError,
      onEndUserFlowStepCompleted: handleEndUserFlowStepCompleted,
      onEndUserFlowStepError: handleEndUserFlowStepError,
      onStartContentPageCompletionCompleted: handleStartContentPageCompletionCompleted,
      onStartContentPageCompletionError: handleStartContentPageCompletionError,
      onStartUserFlowStepUsingUrlsCompleted: handleStartUserFlowStepUsingUrlsCompleted,
      onStartUserFlowStepUsingUrlsError: handleStartUserFlowStepUsingUrlsError,
      token: accessToken ?? temporaryToken,
    });

  const { handleGetUserFlowStepContentPages } = useGetUserFlowStepContentPages({
    onCompleted: handleGetUserFlowStepContentPagesCompleted,
    onError: handleGetUserFlowStepContentPagesError,
  });

  const { handleGetContentPage } = useContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetContentPageCompleted,
    onError: handleGetContentPageError,
  });

  const { handleGetAnswers } = useGetAnswers({
    onCompleted: handleGetAnswersCompleted,
  });

  const { handleGetAnswers: handleGetSubstanceAnswers } = useGetAnswers({
    onCompleted: handleGetSubstanceAnswersCompleted,
  });

  
  const { handleGetPaginatedGroupsForUser } = useGetPaginatedGroupsForUser({
    onCompleted: ({ groupConnection }) => {
      const MEMBER_USERGROUP_ID = 47;
      const code = groupConnection.edges[0].node.smartCodes.find(({ userType }) => userType !== null && userType.id === MEMBER_USERGROUP_ID)?.code;
      
      if(!code) return;

      executeSetUserAnswerSingleLineText({
        variables: {
          contentSnippetFormId: 25744,
          contentSnippetQuestionId: 34379,
          text: code,
          contentPageId: 124870,
          userFlowStepTrackId,
        }
      })
    },
    query: gql`
      query getPaginatedGroupsForUser($userId: Int!) {
        group {
          getPaginatedGroupsForUser: getGroupsForUser(userId: $userId) {
            edges {
              node {
                smartCodes {
                  code
                  userType {
                    id
                  }
                }
              }
            }
          }
        }
      }
    `
  });

  const { isFirstAttempt, lastSelfAssessment } = useGetLastSelfAssessment();

  useEffect(() => {
    if (!temporaryToken) {
      push(`/authenticate?requestedRoute=${SELF_ASSESSMENT_BEGINNING_LINK}&token=temporary`);
      return;
    }

    const hasThreeMonthsElapsed = isPast(
      add(new Date(lastSelfAssessment ? lastSelfAssessment.completedLongDate : new Date()), { months: 3 }),
    );

    if (shouldIgnoreLimitedAttempts === 'true' || hasThreeMonthsElapsed || isFirstAttempt) {
      setShouldLimitAttempt(false);

      handleStartUserFlowStepUsingUrls({
        userFlowUrl: String(userFlowUrl),
        userFlowStepUrl: String(userFlowStepUrl),
      });

      return;
    }

    setShouldLimitAttempt(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryToken, lastSelfAssessment, isFirstAttempt]);

  useEffect(() => {
    if (!(contentPageUrl && userFlowStepTrackId && contentPages.length)) {
      return;
    }

    const { id } = contentPages.find(({ url }) => url === contentPageUrl) as IContentPage;

    handleGetContentPage({
      contentPageId: id,
      userFlowStepTrackId: Number(userFlowStepTrackId),
      withAnswers: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPageUrl, userFlowStepTrackId, contentPages.length]);

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadcrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      className="pt-8"
      hasPrivateLayout={hasPrivateLayout && Boolean(accessToken)}
      meta={{
        seoDescription: 'Complete a new self-assessment to learn how your alcohol or other drug use is impacting you',
        seoTitle: 'New self-assessment – Self-assessment | Stable Ground ',
      }}
      percentageSlider={{ percentage: stepPercentageCompleted }}
      title={PAGE_TITLE}
      shouldExcludeFooter
    >
      <div className="container">
        {shouldLimitAttempt ? (
          <>
            <InformationBanner>
              <p className="mb-0">
                {lastSelfAssessment
                  ? `You completed your self-assessment ${differenceInDays(
                    new Date(),
                    new Date(lastSelfAssessment.completedLongDate),
                  )} days ago. We recommend you complete the self-assessment every 3 months. For the most accurate results, please come back on the ${String(
                    format(add(new Date(lastSelfAssessment.completedLongDate), { months: 3 }), 'dd/MM/yyyy'),
                  )}. In the mean time, you can use the weekly check in to track how you're going.
              If you are still interested in doing the self-assessment, you can re-do it by clicking the button below.`
                  : `You completed a screening in last 3 months. Please come back shortly to complete the self-asessment. In the mean time, you can use the weekly check-in to track how you're going. You can redo the self-assessment if you wish.`}
              </p>
            </InformationBanner>
            <div className="text-center">
              <Button
                additionalClassNames="mr-2"
                isLoading={Boolean(shouldIgnoreLimitedAttempts)}
                linkButton={{ id: 'redo', url: '/self-assessment/introduction?shouldIgnoreLimitedAttempts=true' }}
                text="Redo self-assessment"
                variant="primary"
              />
              <Button linkButton={{ id: 'checkin', url: '/checkin' }} text="Go to checkin" variant="secondary" />
            </div>
          </>
        ) : hasContentPageData ? (
          <div className={trackingIdParam ? 'events-none' : ''}>
            <CMSContentPage userFlowStepTrackId={Number(userFlowStepTrackId)} />
          </div>
        ) : (
          <div className="ek-section">
            <Skeleton height="5rem" width="100%" />
          </div>
        )}

        <div className="items-center flex mb-8 no-print">
          {contentPageId !== first(contentPages)?.id && (
            <NavigationButton
              direction={'PREVIOUS'}
              isDisabled={!isBackButtonEnabled}
              isLoading={!hasContentPageData}
              onPress={() => handleGetNextOrPreviousPage('PREVIOUS')}
            />
          )}

          {!SELF_ASSESSMENT_INSTRUCTION_PAGE_IDS.includes(Number(contentPageId)) &&
            contentPageId !== SELF_ASSESSMENT_ASSIST_BEGINNING_PAGE_ID && (
            <div className="ml-4">
              <NavigationButton
                direction={'RESTART'}
                isDisabled={!isBackButtonEnabled}
                isLoading={!hasContentPageData}
                onPress={() => push('/self-assessment/question-1-1')}
              />
            </div>
          )}

          <NavigationButton
            direction={'NEXT'}
            isDisabled={!isNextButtonEnabled}
            isLoading={!hasContentPageData}
            onPress={() => handleGetNextOrPreviousPage('NEXT')}
          />
        </div>
      </div>
    </Page>
  );
};

export { SelfAssessmentPage };
