import {
  CLUSTER_ID_ALCOHOL,
  CLUSTER_ID_AMPHETAMINE,
  CLUSTER_ID_CANNABIS,
  CLUSTER_ID_COCAINE,
  CLUSTER_ID_HALLUCINOGENS,
  CLUSTER_ID_INHALANTS,
  CLUSTER_ID_OPIOIDS,
  CLUSTER_ID_OTHER,
  CLUSTER_ID_SEDATIVES,
  CLUSTER_ID_SMOKING,
  EAssistItem,
} from '../SelfAssessmentResultsPage';

export const SUBSTANCE_THEME_KEYS: Record<EAssistItem, string> = {
  alcohol: '#2251ac',
  amphetamine: '#43127D',
  cannabis: '#FEB800',
  cocaine: '#FF5700',
  hallucinogens: '#85C0E8',
  inhalants: '#FAC694',
  opioids: '#ba00ff',
  other: '#ff0000',
  sedatives: '#cc00af',
  smoking: '#00aeae',
};

export const RESULT_ITEMS_MAP = [
  { clusterId: CLUSTER_ID_ALCOHOL, item: EAssistItem.alcohol, theme: '#2251ac' },
  { clusterId: CLUSTER_ID_AMPHETAMINE, item: EAssistItem.amphetamine, theme: '#43127D' },
  { clusterId: CLUSTER_ID_CANNABIS, item: EAssistItem.cannabis, theme: '#FEB800' },
  { clusterId: CLUSTER_ID_COCAINE, item: EAssistItem.cocaine, theme: '#FF5700' },
  { clusterId: CLUSTER_ID_HALLUCINOGENS, item: EAssistItem.hallucinogens, theme: '#85C0E8' },
  { clusterId: CLUSTER_ID_INHALANTS, item: EAssistItem.inhalants, theme: '#FAC694' },
  { clusterId: CLUSTER_ID_OPIOIDS, item: EAssistItem.opioids, theme: '#ba00ff' },
  { clusterId: CLUSTER_ID_SEDATIVES, item: EAssistItem.sedatives, theme: '#cc00af' },
  { clusterId: CLUSTER_ID_SMOKING, item: EAssistItem.smoking, theme: '#00aeae' },
  { clusterId: CLUSTER_ID_OTHER, item: EAssistItem.other, theme: '#ff0000' },
];
