import { useEffect, useState, Fragment } from 'react';

import { getAccessTokenCookie, useToggle } from '@netfront/common-library';
import { Button } from '@netfront/ui-library';
import { getValidClassNames } from 'utils';

import { PUBLIC_HEADER_MENU_ITEMS } from './PublicHeader.constants';

import { Header, Link, MenuDropdown, MobileMenuTrigger, MobileMenu, CaretDownIcon } from '../../../components';
import { useCurrentAgency, useGetRegisterUrl, useIsMounted } from '../../../hooks';
import { IMenuItem } from '../../../interfaces';

const PublicHeader = () => {
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState<boolean>(false);
  const [selectedDropDown, setSelectedDropDown] = useState<IMenuItem>();
  const { getAgency } = useCurrentAgency();
  const [isAgencySelected, setIsAgencySelected] = useState<boolean>(false);
  const token = getAccessTokenCookie();
  const { registerUrl } = useGetRegisterUrl();

  const { isMounted } = useIsMounted();

  const { isToggled: isSubMenuToggled, toggle: toggleSubMenu } = useToggle();

  const [activeMenuItemId, setActiveMenuItemId] = useState<string>();

  const onMouseEnter = (id: string) => {
    setActiveMenuItemId(id);
    toggleSubMenu();
  };

  const checkCookie = () => {
    const agency = getAgency();
    if (agency) {
      setIsAgencySelected(true);
      return;
    } else {
      setTimeout(() => checkCookie(), 1000);
    }
  };

  const handleMenuClose = () => {
    setIsDropDownMenuOpen(false);
    setSelectedDropDown(undefined);
  };

  useEffect(() => {
    checkCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseLeave = (event: React.MouseEvent) => {
    const menuLink = event.target as HTMLElement;

    const bounds = menuLink.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    const w = bounds.width;

    if (!isSubMenuToggled) {
      return;
    }

    if (x < 0) {
      toggleSubMenu();
    } else if (x > w) {
      toggleSubMenu();
    } else if (y < 0) {
      toggleSubMenu();
    }
  };

  const headerItems = isAgencySelected
    ? PUBLIC_HEADER_MENU_ITEMS.filter((r) => !r.isHiddenWhenAgencyCode)
    : PUBLIC_HEADER_MENU_ITEMS.filter((r) => !r.isHiddenWhenNoAgencyCode);

  return (
    <>
      <Header logo={{ href: '/' }}>
        <div className="items-center container-lg flex">
          <MobileMenuTrigger additionalClassNames="ml-auto lg:none" isOpen={isSubMenuToggled} onPress={toggleSubMenu} />

          <nav aria-label="Main navigation" className="none lg:flex justify-center flex-1">
            <ul className="m-0 flex items-center">
              {headerItems.map(({ href, id, label, subItems }) => (
                <Fragment key={`menuitem-${id}`}>
                  <li
                    className="color-secondary hover:color-primary m-0 flex items-center px-2 mx-2"
                    {...(subItems && subItems.length && { onMouseEnter: () => onMouseEnter(id) })}
                    {...(subItems && subItems.length && { onMouseLeave })}
                  >
                    <a className="color-inherit weight-600 mr-1 text-center line-1.2" href={href}>
                      {label}
                    </a>
                    {Boolean(subItems?.length) && (
                      <button
                        className={getValidClassNames({
                          'bg-transparent color-inherit border-none rounded bg-primary flex items-center justify-center p-0 transition-200 pointer':
                            true,
                          'rotate-180': isSubMenuToggled,
                        })}
                        onClick={toggleSubMenu}
                      >
                        <span className="sr-only">Hover to toggle menu</span>

                        <CaretDownIcon />
                      </button>
                    )}
                  </li>
                  {activeMenuItemId === id && isSubMenuToggled && subItems?.length ? (
                    <div
                      key={`submenu-${id}`}
                      className="bg-white py-6 absolute w-full left-0 top-full border-b-10 border-t border-t-athens border-b-secondary"
                      onMouseLeave={toggleSubMenu}
                    >
                      <div className="container animate__fadeIn">
                        {/* <p className="h5 color-secondary weight-800">{label}</p> */}
                        <div className="flex items-start">
                          {subItems.map(({ className, description, href: subHref, label: subLabel }) => (
                            <a
                              key={`submenu-item-${id}`}
                              className={getValidClassNames({
                                'color-primary w-1/4 mr-8 p-4 px-6 flex flex-col justify-center': true,
                                [String(className)]: Boolean(className),
                              })}
                              href={String(subHref)}
                            >
                              <p className="text-underline weight-600 mb-1">{subLabel}</p>
                              <p className="color-secondary text-sm">{description}</p>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </ul>
          </nav>

          <div className="ml-auto none lg:flex">
            {isMounted &&
              (token ? (
                <div aria-label="Return to dashboard" className="flex items-center">
                  <Button
                    additionalClassNames="ml-4"
                    linkButton={{ id: 'return', url: '/dashboard' }}
                    text="Return to dashboard"
                    variant="cta"
                  />
                </div>
              ) : (
                <div aria-label="Login and registation" className="flex items-center">
                  <Button additionalClassNames="ml-4" linkButton={{ id: 'login', url: '/login' }} text="Login" variant="secondary" />
                  <Button additionalClassNames="ml-4" linkButton={{ id: 'register', url: registerUrl }} text="Register" variant="cta" />
                </div>
              ))}
          </div>
        </div>

        {isDropDownMenuOpen && selectedDropDown && (
          <MenuDropdown className="none lg:flex top-full" onOpenChange={handleMenuClose}>
            <div className="container-md py-12">
              <ul className="flex m-0">
                {selectedDropDown.subItems?.map(({ description, href, id, label }) => (
                  <li key={id} className="flex flex-col mr-24">
                    <span className="font-body weight-800 h6 mb-6">{label}</span>
                    <Link className="color-accent font-body text-underline weight-700" href={String(href)}>
                      {description}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </MenuDropdown>
        )}
      </Header>
      {isSubMenuToggled && <MobileMenu />}
    </>
  );
};

export { PublicHeader };
