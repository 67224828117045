import { useBonoboMutation } from 'hooks/useBonoboMutation';

import { UPDATE_COMMENT_STATUS } from './useUpdateCommentStatus.graphql';
import {
  IHandleUpdateCommentStatusParams,
  IUpdateCommentStatusMutationGraphQLResponse,
  IUpdateCommentStatusMutationVariables,
  IUseUpdateCommentStatusOptions,
  IUseUpdateCommentStatus,
} from './useUpdateCommentStatus.interfaces';

const useUpdateCommentStatus = (options?: IUseUpdateCommentStatusOptions): IUseUpdateCommentStatus => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseUpdateCommentStatusOptions);

  const [executeUpdateCommentStatus, { loading: isLoading }] = useBonoboMutation<
    IUpdateCommentStatusMutationGraphQLResponse,
    IUpdateCommentStatusMutationVariables
  >({
    mutation: UPDATE_COMMENT_STATUS,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          comment: { updateStatus: isUpdated },
        } = data;

        onCompleted({
          result: isUpdated,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateCommentStatus = async (request: IHandleUpdateCommentStatusParams) => {
    await executeUpdateCommentStatus({
      variables: request,
    });
  };

  return {
    handleUpdateCommentStatus,
    isLoading,
  };
};

export { useUpdateCommentStatus };
