import { useEffect, useState } from 'react';

import {
  IDBQuestionAnswerCheckboxResponseCheckbox,
  IUseGetAnswersOnCompletedResponse,
  useGetAnswers,
} from '@netfront/ekardo-content-library';

import {
  ASSIST_SUBSTANCE_CHECKLIST_IDS,
  EAssistItem,
  IAssistScore,
  QUESTION_ID_ASSIST_SUBSTANCES,
  K10_SUPPLEMENTARY_QUESTION_1_ID,
  K10_SUPPLEMENTARY_QUESTION_2_ID,
  CLUSTER_ID_SMOKING,
  CLUSTER_ID_ALCOHOL,
  getSubstanceRiskCategory,
  getMaxKey,
  CLUSTER_ID_K10,
  CLUSTER_ID_OTHER,
  CLUSTER_ID_OPIOIDS,
  CLUSTER_ID_HALLUCINOGENS,
  CLUSTER_ID_SEDATIVES,
  CLUSTER_ID_INHALANTS,
  CLUSTER_ID_AMPHETAMINE,
  CLUSTER_ID_COCAINE,
  CLUSTER_ID_CANNABIS,
  RTC_QUESTION_ID,
  getK10DistressCategory,
  EK10DistressCategory,
  ERiskCategory,
} from '../../components';
import { useGetSumCluster } from '../../hooks';

export const useGetSelfAssessmentResults = ({ trackingId }: { trackingId: number }) => {
  const [substanceSelections, setSubstanceSelections] = useState<EAssistItem[]>([]);
  const [k10Score, setK10Score] = useState<number>();
  const [k10SupplementaryScore, setK10SupplementaryScore] = useState<number>();
  const [rtcScore, setRtcScore] = useState<number>();
  const [assistScores, setAssistScores] = useState<IAssistScore>({
    alcohol: null,
    amphetamine: null,
    cannabis: null,
    cocaine: null,
    hallucinogens: null,
    inhalants: null,
    opioids: null,
    other: null,
    sedatives: null,
    smoking: null,
  });

  const handleGetAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { answers } = data;

    const assistSubstanceSelections =
      answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === QUESTION_ID_ASSIST_SUBSTANCES)?.selectedAnswers ??
      ([] as IDBQuestionAnswerCheckboxResponseCheckbox[]);

    setSubstanceSelections(
      assistSubstanceSelections
        .filter(({ questionResponseCheckboxId }) => ASSIST_SUBSTANCE_CHECKLIST_IDS[questionResponseCheckboxId])
        .map(({ questionResponseCheckboxId }) => ASSIST_SUBSTANCE_CHECKLIST_IDS[questionResponseCheckboxId] as EAssistItem),
    );

    setK10SupplementaryScore(
      Number(answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === K10_SUPPLEMENTARY_QUESTION_1_ID)?.numberAnswered) +
        Number(
          answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === K10_SUPPLEMENTARY_QUESTION_2_ID)?.numberAnswered,
        ),
    );

    setRtcScore(Number(answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === RTC_QUESTION_ID)?.value));
  };

  const { handleGetAnswers } = useGetAnswers({
    onCompleted: handleGetAnswersCompleted,
  });

  const { handleGetSumCluster: handleGetSmokingClusterSum, isLoading: isSmokingLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        smoking: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetAlcoholClusterSum, isLoading: isAlcoholLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        alcohol: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetCannabisClusterSum, isLoading: isCannabisLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        cannabis: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetCocaineClusterSum, isLoading: isCocaineLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        cocaine: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetAmphetamineClusterSum, isLoading: isAmphetamineLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        amphetamine: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetInhalantsClusterSum, isLoading: isInhalantsLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        inhalants: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetSedativesClusterSum, isLoading: isSedativesLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        sedatives: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetHallucinogensClusterSum, isLoading: isHallucinogensLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        hallucinogens: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetOpioidsClusterSum, isLoading: isOpioidsLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        opioids: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetOtherClusterSum, isLoading: isOtherLoading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setAssistScores({
        ...assistScores,
        other: sumCluster,
      });
    },
  });

  const { handleGetSumCluster: handleGetK10ClusterSum, isLoading: isK10Loading = true } = useGetSumCluster({
    onCompleted: ({ sumCluster }) => {
      setK10Score(sumCluster);
    },
  });

  useEffect(() => {
    if (!trackingId) return;

    handleGetAnswers({
      userFlowStepTrackId: Number(trackingId),
    });

    handleGetSmokingClusterSum({
      clusterId: CLUSTER_ID_SMOKING,
      trackingId: Number(trackingId),
    });

    handleGetAlcoholClusterSum({
      clusterId: CLUSTER_ID_ALCOHOL,
      trackingId: Number(trackingId),
    });

    handleGetCannabisClusterSum({
      clusterId: CLUSTER_ID_CANNABIS,
      trackingId: Number(trackingId),
    });

    handleGetCocaineClusterSum({
      clusterId: CLUSTER_ID_COCAINE,
      trackingId: Number(trackingId),
    });

    handleGetAmphetamineClusterSum({
      clusterId: CLUSTER_ID_AMPHETAMINE,
      trackingId: Number(trackingId),
    });

    handleGetInhalantsClusterSum({
      clusterId: CLUSTER_ID_INHALANTS,
      trackingId: Number(trackingId),
    });

    handleGetSedativesClusterSum({
      clusterId: CLUSTER_ID_SEDATIVES,
      trackingId: Number(trackingId),
    });

    handleGetHallucinogensClusterSum({
      clusterId: CLUSTER_ID_HALLUCINOGENS,
      trackingId: Number(trackingId),
    });

    handleGetOpioidsClusterSum({
      clusterId: CLUSTER_ID_OPIOIDS,
      trackingId: Number(trackingId),
    });

    handleGetOtherClusterSum({
      clusterId: CLUSTER_ID_OTHER,
      trackingId: Number(trackingId),
    });

    handleGetK10ClusterSum({
      clusterId: CLUSTER_ID_K10,
      trackingId: Number(trackingId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  const allSubstancesExcludingSmoking = substanceSelections.filter((substance) => substance !== EAssistItem.smoking);

  // Removes smoking as it is reffered independently
  const isPolySubstanceUse = allSubstancesExcludingSmoking.length > 1;

  const isAssistLoading =
    isSmokingLoading ||
    isAlcoholLoading ||
    isCannabisLoading ||
    isCocaineLoading ||
    isAmphetamineLoading ||
    isInhalantsLoading ||
    isSedativesLoading ||
    isHallucinogensLoading ||
    isOpioidsLoading ||
    isOtherLoading;

  const getMaxAssistCategory = (items): string => {
    let highestKey = 0;
    let maxCategory = ERiskCategory.no;

    const categoryMap = {
      [ERiskCategory.no]: 0,
      [ERiskCategory.low]: 1,
      [ERiskCategory.moderate]: 2,
      [ERiskCategory.high]: 3,
    };

    Object.keys(items).forEach((substance: EAssistItem) => {
      const category = getSubstanceRiskCategory(substance, Number(items[substance])) as ERiskCategory;
      maxCategory = categoryMap[category] > highestKey ? category : maxCategory;
      highestKey = categoryMap[category] > highestKey ? categoryMap[category] : highestKey;
    });

    return String(maxCategory);
  };

  // const maxAssistCategory = getSubstanceRiskCategory(getMaxKey(assistScores) as EAssistItem, assistScores[getMaxKey(assistScores)]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { smoking, ...rest } = assistScores;

  const assistScoresExcludingSmoking = rest;

  const maxAssistCategory = getMaxAssistCategory(assistScoresExcludingSmoking);

  const mentalHealthRiskCategory = k10Score
    ? `${String(getK10DistressCategory(k10Score))} ${
      getK10DistressCategory(k10Score) === EK10DistressCategory.very ? 'high' : ''
    }`
    : '';

  const substanceRiskCategory = maxAssistCategory ? `${maxAssistCategory} risk` : '';

  const assistResults = allSubstancesExcludingSmoking
    .map((substance) => ({
      label: substance,
      value: assistScoresExcludingSmoking[substance],
    }))
    .filter(({ value }) => value !== 0);

  return {
    assistResults,
    allSubstancesExcludingSmoking,
    isPolySubstanceUse,
    maxAssistCategory,
    assistScoresExcludingSmoking,
    isAssistLoading,
    isK10Loading,
    k10Score,
    k10SupplementaryScore,
    assistScores,
    rtcScore,
    mentalHealthRiskCategory,
    substanceRiskCategory,
    substanceSelections,
    maxAssistItem: {
      item: getMaxKey(assistScores),
      score: assistScores[getMaxKey(assistScores)],
    },
  };
};
