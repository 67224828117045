import { IGetSubCommentsOptions, SubCommentsQueryResult } from './useGetSubComments.interface';

import { bonoboClient } from '../../apollo';
import { useGetSubCommentsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetSubComments = (options?: IGetSubCommentsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetSubCommentsOptions);

  const [getSubComments, { loading: isLoading, error, fetchMore, refetch }] = useGetSubCommentsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data.comment?.getSubComments as unknown as SubCommentsQueryResult);
    },
    onError,
  });

  return { getSubComments, isLoading, error, fetchMore, refetch };
};