import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  IToolBoxContentPage,
  IToolBoxMediaContentSnippet,
  IToolBoxUserFlowStep,
  IUseToolBoxMediaOnCompletedResponse,
  useToolBoxMedia,
} from '@netfront/ekardo-content-library';
import { Select } from '@netfront/ui-library';
import { CaretRightIcon, Link, ToolboxPageLayout } from 'components';
import uniqBy from 'lodash.uniqby';

import { useProtectedRoute } from '../../../hooks/useProtectedRoute';
import { useToast } from '../../../hooks/useToast';

const PAGE_TITLE = 'Documents';

const MediaDocumentsPage = () => {
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();

  const [allDocuments, setAllDocuments] = useState<IToolBoxMediaContentSnippet[]>([]);
  const [allUserFlowSteps, setAllUserFlowSteps] = useState<IToolBoxUserFlowStep[]>([]);
  const [allContentPages, setAllContentPages] = useState<IToolBoxContentPage[]>();
  const [selectedModule, setSelectedModule] = useState<number>();

  const handleModuleClick = (id: number) => {
    setSelectedModule(id);
  };

  const handleGetToolBoxMediaCompleted = ({
    toolboxData: { contentPages, contentSnippets, userFlowSteps },
  }: IUseToolBoxMediaOnCompletedResponse) => {
    setAllDocuments(uniqBy(contentSnippets, ({ contentPageId }) => contentPageId));
    setAllUserFlowSteps(userFlowSteps);
    setAllContentPages(contentPages);

    if (userFlowSteps.length) {
      setSelectedModule(userFlowSteps[0].id);
    }
  };

  const handleGetToolBoxMediaError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetToolBoxMedia, isLoading } = useToolBoxMedia({
    mediaType: 'documents',
    onCompleted: handleGetToolBoxMediaCompleted,
    onError: handleGetToolBoxMediaError,
    projectId: String(process.env.REACT_APP_PROJECT_ID),
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetToolBoxMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <ToolboxPageLayout meta={{ seoDescription: 'Documents', seoTitle: PAGE_TITLE }} title={PAGE_TITLE}>
      {!isLoading && allDocuments.length ? (
        <>
          <div className="ek-section py-4">
            <div className="w-full md:w-1/5">
              <Select
                additionalClassNames="bg-white"
                id="module"
                labelText="Select module"
                name="module"
                options={allUserFlowSteps.map(({ id, stepName }) => ({ id, name: stepName, value: id }))}
                value={selectedModule}
                onChange={({ target }) => handleModuleClick(parseInt(target.value))}
              />
            </div>
          </div>

          <div className="flex flex-col">
            {allDocuments
              .filter(({ userFlowStepId }) => userFlowStepId === selectedModule)
              .map(({ contentPageId, id, userFlowStepId }) => {
                const page = allContentPages?.find(({ id: pageId }) => pageId === contentPageId);
                const stepUrl = allUserFlowSteps.find(({ id: stepId }) => stepId === userFlowStepId)?.url;

                return (
                  <Link
                    key={id}
                    className="flex items-center bg-white p-4 color-blue-700 rounded mb-4 weight-600 transition-200 hover:shadow-xl"
                    href={`/dashboard/${String(stepUrl)}/${String(page?.url)}`}
                  >
                    <div className="flex items-center justify-center rounded-circle p-2 bg-blue-200 color-primary mr-4">
                      <svg
                        className="h-5 w-5"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="flex-1 mb-0">{page?.title}</span>
                    <div className="flex items-center color-primary">
                      <CaretRightIcon />
                    </div>
                  </Link>
                );
              })}
          </div>
        </>
      ) : (
        <div className="ek-section">
          <p>No documents have been viewed yet</p>
        </div>
      )}
    </ToolboxPageLayout>
  );
};

export { MediaDocumentsPage };
