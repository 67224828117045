import { EAssistItem } from './SelfAssessmentResultsPage.interfaces';

export const QUESTION_ID_ASSIST_SUBSTANCES = 25741;

export const ASSIST_SUBSTANCE_CHECKLIST_IDS = {
  3377: EAssistItem.smoking,
  3378: EAssistItem.alcohol,
  3379: EAssistItem.cannabis,
  3380: EAssistItem.cocaine,
  3381: EAssistItem.amphetamine,
  3382: EAssistItem.inhalants,
  3383: EAssistItem.sedatives,
  3384: EAssistItem.hallucinogens,
  3385: EAssistItem.opioids,
  3386: EAssistItem.other,
};

export const CLUSTER_ID_SMOKING = 40;
export const CLUSTER_ID_ALCOHOL = 41;
export const CLUSTER_ID_CANNABIS = 42;
export const CLUSTER_ID_COCAINE = 43;
export const CLUSTER_ID_AMPHETAMINE = 44;
export const CLUSTER_ID_INHALANTS = 45;
export const CLUSTER_ID_SEDATIVES = 46;
export const CLUSTER_ID_HALLUCINOGENS = 47;
export const CLUSTER_ID_OPIOIDS = 48;
export const CLUSTER_ID_OTHER = 49;
export const CLUSTER_ID_K10 = 50;

export const CLUSTER_IDS = [
  CLUSTER_ID_K10,
  CLUSTER_ID_ALCOHOL,
  CLUSTER_ID_AMPHETAMINE,
  CLUSTER_ID_CANNABIS,
  CLUSTER_ID_COCAINE,
  CLUSTER_ID_HALLUCINOGENS,
  CLUSTER_ID_INHALANTS,
  CLUSTER_ID_OPIOIDS,
  CLUSTER_ID_OTHER,
  CLUSTER_ID_SEDATIVES,
  CLUSTER_ID_SMOKING,
];

export const RTC_QUESTION_ID = 25843;

export const K10_SUPPLEMENTARY_QUESTION_1_ID = 25845;
export const K10_SUPPLEMENTARY_QUESTION_2_ID = 25846;
