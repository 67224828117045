import { IconNotification } from '../Icons';

import { NotificationsProps } from './Notifications.interfaces';

const Notifications = ({ count }: NotificationsProps) => {
  return (
    <div className="c-notifications">
      <div className="c-notifications__icon">
        <IconNotification />
      </div>
      {Boolean(count) && <div className="c-notifications__count">{count}</div>}
      <span className="h-hide-visually">{`${Number(count) > 0 ? String(count) : 'No'} new notifications`}</span>
    </div>
  );
};

export { Notifications };
