import { useCallback, useRef } from 'react';

import { useOutsideClick } from '@netfront/common-library';
import cx from 'classnames';

import { MenuDropDownProps } from './MenuDropdown.interfaces';

const MenuDropdown = ({ children, className = '', isSpotlightTourCompleted = false, onOpenChange }: MenuDropDownProps) => {
  const menuRef = useRef(null);

  useOutsideClick(
    menuRef,
    useCallback(() => {
      if (!isSpotlightTourCompleted) {
        return;
      }

      if (onOpenChange) {
        onOpenChange(false);
      }
    }, [onOpenChange]),
  );

  return (
    <div
      ref={menuRef}
      className={cx('absolute', 'bg-white', 'w-full', {
        [className]: className,
      })}
      style={{ paddingTop: '120px' }}
    >
      {children}
    </div>
  );
};

export { MenuDropdown };
