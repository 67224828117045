
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_BOOKMARKS_FOR_USER_QUERY } from './useGetBookmarksForUser.graphql';
import {
  IGetBookmarksForUserQueryGraphQLResponse,
  IGetBookmarksForUserQueryVariables,
  IHandleGetBookmarksForUserParams,
  IUseGetBookmarksForUser,
  IUseGetBookmarksForUserOptions,
} from './useGetBookmarksForUser.interfaces';



const useGetBookmarksForUser = (options?: IUseGetBookmarksForUserOptions): IUseGetBookmarksForUser => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetBookmarksForUserOptions);

  const [executeGetBookmarksForUser, { loading: isLoading }] = useEkardoLazyQuery<
    IGetBookmarksForUserQueryGraphQLResponse,
    IGetBookmarksForUserQueryVariables
  >({
    query: query ?? GET_BOOKMARKS_FOR_USER_QUERY,
    options: {
      fetchPolicy: fetchPolicy ?? 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          bookmark: { getBookmarksForUser: bookmarks },
        } = data;

        onCompleted({
          bookmarks,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleGetBookmarksForUser = async (params?: IHandleGetBookmarksForUserParams) => {
    const { shouldIncludeContentPage = false } = params ?? {};

    await executeGetBookmarksForUser({
      variables: {
        shouldIncludeContentPage,
      },
    });
  };

  return {
    handleGetBookmarksForUser,
    isLoading,
  };
};

export { useGetBookmarksForUser };
