export const incrementSelfAssessmentPrompt = () => {
  if (localStorage.getItem('selfAssessmentPrompt')) {
    let count = Number(localStorage.getItem('selfAssessmentPrompt'));

    if (count < 3) {
      count++;
      localStorage.setItem('selfAssessmentPrompt', String(count));
    } 
  } else {
    localStorage.setItem('selfAssessmentPrompt', String(1));
  }
}