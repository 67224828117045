import { Accept } from 'react-dropzone';

export const DROPZONE_FILE_PATHS: Accept = {
  'image/*': ['.png', '.gif', '.jpeg', '.jpg', '.avif', '.bmp', '.ico', '.svg', '.tif', '.tiff', '.webp'],
  'audio/*': ['.aac', '.mid', '.midi', '.mp3', '.oga', '.opus', '.wav', '.weba', '.3gp', '.3g2', '.m4a'],
  'video/*': ['.avi', '.mp4', '.mpeg', '.odv', '.ts', '.webm', '.3gp', '.3g2', '.mov'],
  'text/*': ['.css', '.csv', '.htm', '.html', '.ics', '.js', '.mjs', '.txt', '.xml'],
  'font/*': ['.otf', '.ttf', '.woff', '.woff2'],
  'application/x-abiword': ['.abw'],
  'application/x-freearc': ['.arc'],
  'application/vnd.amazon.ebook': ['.azw'],
  'application/octet-stream': ['.bin'],
  'application/x-bzip': ['.bz'],
  'application/x-bzip2': ['.bz2'],
  'application/x-cdf': ['.cda'],
  'application/x-csh': ['.csh'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-fontobject': ['.eot'],
  'application/epub+zip': ['.epub'],
  'application/gzip': ['.gz'],
  'application/java-archive': ['.jar'],
  'application/json': ['.json'],
  'application/ld+json': ['.jsonld'],
  'application/vnd.apple.installer+xml': ['.mpkg'],
  'application/vnd.oasis.opendocument.presentation': ['.odp'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
  'application/vnd.oasis.opendocument.text': ['.odt'],
  'application/ogg': ['.ogx'],
  'application/pdf': ['.pdf'],
  'application/x-httpd-php': ['.php'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.rar': ['.rar'],
  'application/rtf': ['.rtf'],
  'application/x-sh': ['.sh'],
  'application/x-tar': ['.tar'],
  'application/vnd.visio': ['.vsd'],
  'application/xhtml+xml': ['.xhtml'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/xml': ['.xml'],
  'application/vnd.mozilla.xul+xml': ['.xul'],
  'application/zip': ['.zip'],
  'application/x-7z-compressed': ['.7z'],
};
