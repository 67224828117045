import React from 'react';

import cx from 'classnames';
// eslint-disable-next-line import/no-named-default
import { default as SkeletonComponent } from 'react-loading-skeleton';

import { SkeletonProps } from './Skeleton.types';

const Skeleton: React.FC<SkeletonProps> = ({
  additionalClassNames,
  baseColor = '#E5F7F9',
  count,
  borderRadius,
  circle: isCircle,
  containerClassName,
  height,
  highlightColor = '#fff',
  width,
}) => {
  const skeletonClassNames = cx('c-skeleton', additionalClassNames);

  return (
    <SkeletonComponent
      baseColor={baseColor}
      borderRadius={borderRadius}
      circle={isCircle}
      className={skeletonClassNames}
      containerClassName={containerClassName}
      count={count}
      height={height}
      highlightColor={highlightColor}
      inline={true}
      width={width}
    />
  );
};

export { Skeleton };
