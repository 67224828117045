import { ADD_CUSTOM_EVENT } from './useAddCustomEvent.graphql';
import {
  IHandleAddCustomEventParams,
  IAddCustomEventMutationGraphQLResponse,
  IAddCustomEventMutationVariables,
  IUseAddCustomEventOptions,
  IUseAddCustomEvent,
} from './useAddCustomEvent.interfaces';
import { useCapuchinMutation } from 'hooks/useCapuchin';

const useAddCustomEvent = (options?: IUseAddCustomEventOptions): IUseAddCustomEvent => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseAddCustomEventOptions);

  const [executeAddCustomEvent, { loading: isLoading }] = useCapuchinMutation<IAddCustomEventMutationGraphQLResponse, IAddCustomEventMutationVariables>({
    mutation: mutation ?? ADD_CUSTOM_EVENT,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          events: { addCustom },
        } = data;

        onCompleted({
          addCustom,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleAddCustomEvent = async ({ projectId, type, metadata }: IHandleAddCustomEventParams) => {
    await executeAddCustomEvent({
      variables: {
        projectId, type, metadata
      },
    });
  };

  return {
    handleAddCustomEvent,
    isLoading,
  };
};

export { useAddCustomEvent };
