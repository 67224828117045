import { NoDataProps } from './NoData.interfaces';

import { InformationBanner } from '../../components';

export const NoData = ({ children, image, message, title }: NoDataProps) => {
  return (
    <div aria-label="No data message" className="py-6">
      {image ? image : (
        <img alt="No data" className="mb-6 mx-auto block max-w-40" src="/images/program-icon.svg" />
      )}
      <InformationBanner theme="hint" title={title ? title : 'No entries'}>
        <p className="mb-0">{message ? message : "It looks like you haven't entered any information yet, select add new below"}</p>
        {children}
      </InformationBanner>
    </div>
  );
};
