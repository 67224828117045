import { DBCommunityConnection } from 'interfaces';

import { IGetCommunitiesOfUserOption } from './useGetCommunitiesOfUser.interface';

import { bonoboClient } from '../../apollo';
import { useGetCommunitiesOfUserLazyQuery } from '../../bonobo/generated.graphql';

export const useGetCommunitiesOfUser = (options?: IGetCommunitiesOfUserOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommunitiesOfUserOption);

  const [getCommunitiesOfUser, { loading: isLoading, error, refetch }] = useGetCommunitiesOfUserLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.communityConnection?.ofUser as DBCommunityConnection[]);
    },
    onError,
  });

  return { getCommunitiesOfUser, isLoading, error, refetch };
};
