import { useEffect, useState } from 'react';

import { Button } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';
import { useGetEmbargoPostsCount } from 'hooks';
import { ECommunityRole, EConnectionStatus, EEntryStatus, useGetCommunityPosts, useGetCommunityUsers, useGetReports } from 'services';

import { CommunityMenu } from '../CommunityMenu';
import { ModeratorInformation } from '../ModeratorInformationButton';

import { CommunityModeratorButtonsProps } from './CommunityModeratorButtons.interfaces';

export const CommunityModeratorButtons = ({ community }: CommunityModeratorButtonsProps) => {
  const { user: loggedUser } = useSocialContext();

  const isLoggedUserModerator = loggedUser?.activeCommunities && loggedUser.activeCommunities.edges?.find(({ node }) => node?.id === community.id)?.node?.userConnection?.role === ECommunityRole.Moderator;


  const [messageUrl, setMessageUrl] = useState<string>(`/community/messages/new?community=${String(community.id)}`);
  const [membersCount, setMembersCount] = useState<number>();
  const [embargoedPostsCount, setEmbargoedPostsCount] = useState<number>(0);
  const [reportedPostsCount, setReportedPostsCount] = useState<number>(0);
  const [unpublishedPostsCount, setUnpublishedPostsCount] = useState<number>(0);


  const { handleGetEmbargoPostsCount } = useGetEmbargoPostsCount({
    onCompleted(data) {
      const { count } = data;
      setEmbargoedPostsCount(count);
    },
  });

  const {getReports} = useGetReports({
    onCompleted: (data) => {
      setReportedPostsCount([...data.edges.filter(({ node }) => node.post?.status !== EEntryStatus.Active)].length);
    }
  })

  const { handleGetCommunityPosts } = useGetCommunityPosts({
    onCompleted: ({ communityPosts }) => {
      setUnpublishedPostsCount([...communityPosts.filter(({ node }) => node?.status === EEntryStatus.Unpublished)].length);
    }
  });

  const { getCommunityUsers } = useGetCommunityUsers({
    onCompleted: (members) => {
      setMembersCount(members.length);
      if(community.userConnection?.role === ECommunityRole.Moderator) return;
      
      const onlineModerator = members
        .filter(({ node }) => node.role === ECommunityRole.Moderator)
        .find(({ node }) => node.user?.isOnline);
      if (onlineModerator) {
        setMessageUrl(`/community/messages/${String(onlineModerator.node.user?.key)}`);
      }
    },
  });

  useEffect(() => {
    // if (community.userConnection?.role === ECommunityRole.Member) {
    getCommunityUsers({
      variables: {
        communityId: Number(community.id),
        shouldIncludeUser: true,
        status: EConnectionStatus.Accepted,
      },
    });

    handleGetEmbargoPostsCount({ communityId: Number(community.id) });
    getReports({variables: {  communityId: Number(community.id) }});
    handleGetCommunityPosts({ communityId: Number(community.id) });
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community]);

  return (
    <>
      <div className="flex flex-col justify-center md:flex-row mb-6">
        <Button
          additionalClassNames="nowrap mb-3 md:mb-0"
          linkButton={{ id: 'message', url: messageUrl }}
          size="small"
          text={`Message ${isLoggedUserModerator ? 'members' : 'moderators'}`}
        />

        <ModeratorInformation />
      </div>
   
      <CommunityMenu
        embargoedPostsCount={embargoedPostsCount}
        isModerator={community.userConnection?.role === ECommunityRole.Moderator || community.userConnection?.role === ECommunityRole.Owner}
        memberCount={membersCount}
        reportedPostsCount={reportedPostsCount}
        unpublishedPostsCount={unpublishedPostsCount}
      />
    </>
  );
};
