import { useToggle } from '@netfront/common-library';
import { useUpdateUser } from '@netfront/gelada-identity-library';
import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { ProfileDetailsDialog } from 'components/Social/ProfileDetailsDialog';

import { useSocialContext } from '../../../../contexts';
import { DBAsset } from '../../../../interfaces';
import {
  EAssetType,
  useCreateUserAsset,
  useSetIsOnline,
  useUpdateUserAbout,
  useUpdateUserForumName,
  useUpdateUserImages,
} from '../../../../services';
import { Feed, PageBanner, ProfileAvatarDialog, ProfileCoverDialog, UserConnections } from '../../../Social';

const SocialProfilePage = () => {
  const { asPath, push } = useRouter();
  const { user, updateUser } = useSocialContext();
  const { isToggled: isProfileAvatarDialogOpen, toggle: toggleProfileAvatarDialog } = useToggle();
  const { isToggled: isProfileCoverDialogOpen, toggle: toggleProfileCoverDialog } = useToggle();
  const { isToggled: isProfileDialogOpen, toggle: toggleProfileDialogOpen } = useToggle();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { createUserAsset } = useCreateUserAsset();
  const { updateUserImages } = useUpdateUserImages();
  const { handleUpdateUser, isLoading: isUpdateUserLoading = false } = useUpdateUser();
  const { updateUserAbout, isUpdateUserAboutLoading = false } = useUpdateUserAbout();
  const { handleUpdateUserForumName, isLoading: isUpdateUserForumNameLoading = false } = useUpdateUserForumName();
  const { updateIsOnline, isSetIsOnlineLoading = false } = useSetIsOnline();

  const PAGE_TITLE = user?.displayedName ?? 'Profile';

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription: `Your profile`,
        seoTitle: PAGE_TITLE,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {!isEmpty(user) && (
        <>
          <PageBanner
            description={user.about}
            isActive={user.isOnline}
            profileImage={user.profileImage?.presignedUrl}
            title={user.displayedName}
            canEdit
            onAvatarClick={() => push('/user/profile')}
          >
            <UserConnections communitiesCount={user.activeCommunities.totalCount} connectionsCount={user.connections.totalCount} />
          </PageBanner>
          <div className="c-profile-page__container">
            <Feed authorId={Number(user.id)} status="ACTIVE" />
          </div>

          <ProfileDetailsDialog
            about={user.about}
            displayedName={user.displayedName}
            firstName={user.firstName}
            isOnline={user.isOnline}
            isOpen={isProfileDialogOpen}
            isSubmitting={isUpdateUserLoading || isUpdateUserAboutLoading || isUpdateUserForumNameLoading || isSetIsOnlineLoading}
            lastName={user.lastName}
            onClose={toggleProfileDialogOpen}
            onConfirm={(details) => {
              if (user.displayedName !== details.displayedName) {
                handleUpdateUserForumName({
                  forumName: details.displayedName,
                });
              }

              updateUserAbout({
                variables: {
                  about: details.about ?? '',
                },
              });

              updateIsOnline({
                variables: {
                  isOnline: Boolean(details.isOnline),
                },
              });

              handleUpdateUser({
                email: String(user.email),
                firstName: String(details.firstName),
                lastName: String(details.lastName),
              }).then(() => {
                updateUser({
                  ...user,
                  about: details.about,
                  displayedName: details.displayedName,
                  firstName: details.firstName,
                  lastName: details.lastName,
                  isOnline: details.isOnline,
                });
                toggleProfileDialogOpen();
              });
            }}
          />

          <ProfileAvatarDialog
            isOpen={isProfileAvatarDialogOpen}
            title="Change your profile avatar"
            onClose={toggleProfileAvatarDialog}
            onConfirm={(imageFile) => {
              createUserAsset({
                variables: {
                  alt: user.displayedName,
                  contentType: imageFile.type,
                  fileName: imageFile.name,
                  fileSizeInBytes: imageFile.size,
                  type: EAssetType.Image,
                  shouldIncludeAsset: true,
                },
              }).then(async ({ data }) => {
                const assetId = data?.asset?.createUserAsset?.asset?.assetId;
                const presignedUrl = data?.asset?.createUserAsset?.asset?.presignedUrl;
                const signedUrl = data?.asset?.createUserAsset?.signedUrl;

                await fetch(signedUrl as string, {
                  method: 'PUT',
                  body: imageFile,
                }).then(() => {
                  updateUserImages({
                    variables: {
                      profileImage: assetId,
                    },
                  }).then(() => {
                    updateUser({
                      ...user,
                      profileImage: {
                        ...user.profileImage,
                        presignedUrl,
                      } as DBAsset,
                    });

                    toggleProfileAvatarDialog();
                  });
                });
              });
            }}
          />

          <ProfileCoverDialog
            isOpen={isProfileCoverDialogOpen}
            title="Change your profile cover image"
            onClose={toggleProfileCoverDialog}
            onConfirm={(imageFile) => {
              createUserAsset({
                variables: {
                  alt: user.displayedName,
                  contentType: imageFile.type,
                  fileName: imageFile.name,
                  fileSizeInBytes: imageFile.size,
                  type: EAssetType.Image,
                  shouldIncludeAsset: true,
                },
              }).then(async ({ data }) => {
                const assetId = data?.asset?.createUserAsset?.asset?.assetId;
                const presignedUrl = data?.asset?.createUserAsset?.asset?.presignedUrl;
                const signedUrl = data?.asset?.createUserAsset?.signedUrl;

                await fetch(signedUrl as string, {
                  method: 'PUT',
                  body: imageFile,
                }).then(() => {
                  updateUserImages({
                    variables: {
                      coverImage: assetId,
                    },
                  }).then(() => {
                    updateUser({
                      ...user,
                      coverImage: {
                        ...user.coverImage,
                        presignedUrl,
                      } as DBAsset,
                    });

                    toggleProfileCoverDialog();
                  });
                });
              });
            }}
          />
        </>
      )}
    </Page>
  );
};

export { SocialProfilePage };
