import { INotification } from 'contexts';
import { DBMetadata, DBMetadataType } from 'interfaces';
import { ERelateType } from 'services';

import { EVALUATION_QUESTIONNAIRE_CONTENT } from './NotificationsPage.constants';

const relateMessage = {
  FeltLikeThatToo: 'felt like that to with your',
  LaughtOutLoud: 'laughed out loud to your',
  Like: 'liked your',
  Relate: 'related to your',
  Understand: 'understood your',
  ThankYouForSharing: 'thanked you for sharing your',
};

export const getNotificationMessage = (type?: DBMetadataType, relateType?: ERelateType) => {
  switch (type) {
    case 'SubComment':
      return 'commented on your comment';
    case 'CommentPost':
      return 'commented on your post';
    case 'RelateComment':
      return `${String(relateMessage[relateType as ERelateType])} comment`;
    case 'RelatePost':
      return `${String(relateMessage[relateType as ERelateType])} post`;
    case 'UserConnectionRequest':
      return 'sent you a connection request';
    case 'SensitiveContent':
      return 'A sensitive content has been flagged';
    default:
      return '';
  }
};

export const getNotificationLink = (notification: INotification, guid?: string, parsedMetadata?: DBMetadata) => {
  if (parsedMetadata) return getLinkWithMetadata(parsedMetadata.type, parsedMetadata.postId, guid);
  const { content } = notification;
  if (content === EVALUATION_QUESTIONNAIRE_CONTENT) return '/console/consent';

  return '';
};

const getLinkWithMetadata = (type?: DBMetadataType, postId?: number, guid?: string) => {
  const notifcationParam = `?notification=${String(guid)}`;
  const postRedirect = `/community/post/${Number(postId)}${notifcationParam}`;


  switch (type) {
    case 'CommentPost':
      return postRedirect;
    case 'SubComment':
      return postRedirect;
    case 'RelateComment':
      return postRedirect;
    case 'RelatePost':
      return postRedirect;
    case 'UserConnectionRequest':
      return `/community/connections${notifcationParam}`;
    default:
      return '/community';
  }
};
