import { gql } from '@apollo/client';

const GET_STEP_PROGRESSION = gql`
query getStepProgression(
  $projectId: String!
  $stepUrl: String!
  $userFlowUrl: String!
) {
  userFlowStep {
    getStepByUrl(url: $stepUrl, projectId: $projectId, userFlowUrl: $userFlowUrl) {
      percentageCompleted
    }
  }
}

`;
export { GET_STEP_PROGRESSION };
