import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { SET_USER_ANSWER_CHECKBOX } from './useSetUserAnswerCheckbox.graphql';
import {
  IHandleSetUserAnswerCheckboxParams,
  ISetUserAnswerCheckboxMutationGraphQLResponse,
  ISetUserAnswerCheckboxMutationVariables,
  IUseSetUserAnswerCheckbox,
  IUseSetUserAnswerCheckboxOptions,
} from './useSetUserAnswerCheckbox.interfaces';

const useSetUserAnswerCheckbox = (options?: IUseSetUserAnswerCheckboxOptions): IUseSetUserAnswerCheckbox => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSetUserAnswerCheckboxOptions);

  const [executeSetUserAnswerCheckbox, { loading: isLoading }] = useEkardoMutation<
    ISetUserAnswerCheckboxMutationGraphQLResponse,
    ISetUserAnswerCheckboxMutationVariables
  >({
    mutation: mutation ?? SET_USER_ANSWER_CHECKBOX,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAnswer: { setUserAnswer },
        } = data;

        onCompleted({
          setUserAnswer,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSetUserAnswerCheckbox = async ({
    contentPageId,
    contentSnippetFormId,
    contentSnippetQuestionId,
    status,
    selectedAnswers,
    userFlowStepTrackId,
  }: IHandleSetUserAnswerCheckboxParams) => {
    await executeSetUserAnswerCheckbox({
      variables: {
        contentPageId,
        contentSnippetFormId,
        contentSnippetQuestionId,
        status,
        selectedAnswers,
        userFlowStepTrackId,
      },
    });
  };

  return {
    handleSetUserAnswerCheckbox,
    isLoading,
  };
};

export { useSetUserAnswerCheckbox };
