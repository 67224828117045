import { useEffect } from 'react';

import { Button } from '@netfront/ui-library';
import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { INotification, useSocialContext } from '../../../../contexts';
import { useMarkANotificationSeen } from '../../../../services';
import { Feed } from '../../../Social';

const PostPage = () => {
  const PAGE_TITLE = 'Post';

  const {
    asPath,
    query: { postId, notification },
  } = useRouter();

  const { notifications, updateNotifications } = useSocialContext();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { markANotificationSeen } = useMarkANotificationSeen({
    onCompleted: () => {
      updateNotifications(
        notifications.map((message) => (message.guid === notification ? ({ ...message, seen: true } as INotification) : message)),
      );
    },
  });

  useEffect(() => {
    if (!notification) return;

    markANotificationSeen({
      variables: {
        ipAddress: '',
        notificationGuid: String(notification),
        userAgent: '',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription: 'Share and interact with other Stable Ground members in this anonymous, confidential online community',
        seoTitle: 'Community | Stable Ground',
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-post-page__container pb-12">
        <div className='flex items-center gap-4 mb-8'>
          <h1 className="h2 color-primary mb-0 flex-1">Post</h1>
          <Button
            linkButton={{ id: 'posts', url: '/community' }}
            size='small'
            text='See more posts'
          />
        </div>
        {postId && <Feed postId={Number(postId)} status="ACTIVE" />}
      </div>
    </Page>
  );
};

export { PostPage };
