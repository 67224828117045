import { EAgency, useCurrentAgency } from "hooks";

export const useGetAgencyUserFlowUrl = (): { userFlowUrl: string } => {
  const { getAgency } = useCurrentAgency();
  const userAgency = getAgency();

  const agencyUserflowUrl = {
    [EAgency.ambulance]: 'ambulance-program',
    [EAgency.communities]: 'community-and-justice-program',
    [EAgency.fire]: 'fire-and-rescue-program',
    [EAgency.police]: 'police-program'
  };

  const userFlowUrl = agencyUserflowUrl[userAgency as EAgency]

  return {
    userFlowUrl
  }
};