import { gql } from '@apollo/client';

const DEFINE_IS_MARKED = gql`
  mutation defineIsMarked($trackingId: Int!, $isMarked: Boolean!) {
    userFlowStepTrack {
      defineIsMarked(trackingId: $trackingId, isMarked: $isMarked) {
        id
      }
    }
  }
`;

export { DEFINE_IS_MARKED };
