import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { CREATE_FEEDBACK } from './useCreateFeedback.graphql';
import {
  IHandleCreateFeedbackParams,
  ICreateFeedbackMutationGraphQLResponse,
  ICreateFeedbackMutationVariables,
  IUseCreateFeedbackOptions,
  IUseCreateFeedback,
} from './useCreateFeedback.interfaces';

/**@todo Move to library */

const useCreateFeedback = (options?: IUseCreateFeedbackOptions): IUseCreateFeedback => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseCreateFeedbackOptions);

  const [executeCreateFeedback, { loading: isLoading }] = useEkardoMutation<
    ICreateFeedbackMutationGraphQLResponse,
    ICreateFeedbackMutationVariables
  >({
    mutation: mutation ?? CREATE_FEEDBACK,
    options: {
      onCompleted: () => {
        if (!onCompleted) {
          return;
        }

        onCompleted({});
      },
      onError,
    },
    product,
    token,
  });

  const handleCreateFeedback = async ({ content, projectId, rate, url }: IHandleCreateFeedbackParams) => {
    await executeCreateFeedback({
      variables: {
        content,
        projectId,
        rate,
        url,
      },
    });
  };

  return {
    handleCreateFeedback,
    isLoading,
  };
};

export { useCreateFeedback };
