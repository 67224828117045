import cx from 'classnames';

import { TickIcon } from '../Icons';

import { StatusIconProps } from './StatusIcon.interfaces';

const StatusIcon = ({ isActive, isCompleted, isInProgress }: StatusIconProps) => {
  return (
    <div
      className={cx('items-center', 'border-2', 'color-white', 'flex', 'h-5', 'justify-center', 'radius-circle', 'w-5', {
        'bg-primary': isCompleted && !isActive,
        'border-primary': isInProgress ?? isCompleted,
        'border-athens': !isCompleted && !isInProgress,
        'in-progress': isInProgress,
        isCompleted: isCompleted,
        'bg-white color-primary': isActive,
      })}
      role="presentation"
    >
      <TickIcon />
    </div>
  );
};

export { StatusIcon };
