import { ReactNode } from 'react';

import { EAvatarPattern } from '../AvatarPattern';

export const AVATAR_PATTERNS: Record<EAvatarPattern, ReactNode> = {
  'horizontal-line': (
    <>
      <rect
        data-name="Rectangle 4375"
        fill="currentColor"
        height="10"
        id="Rectangle_4375"
        transform="translate(0 221.367)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4376"
        fill="currentColor"
        height="10"
        id="Rectangle_4376"
        transform="translate(0 201.242)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4377"
        fill="currentColor"
        height="10"
        id="Rectangle_4377"
        transform="translate(0 181.119)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4378"
        fill="currentColor"
        height="10"
        id="Rectangle_4378"
        transform="translate(0 160.996)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4379"
        fill="currentColor"
        height="10"
        id="Rectangle_4379"
        transform="translate(0 140.871)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4380"
        fill="currentColor"
        height="10"
        id="Rectangle_4380"
        transform="translate(0 120.746)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4381"
        fill="currentColor"
        height="10"
        id="Rectangle_4381"
        transform="translate(0 100.621)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4382"
        fill="currentColor"
        height="10"
        id="Rectangle_4382"
        transform="translate(0 80.496)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4383"
        fill="currentColor"
        height="10"
        id="Rectangle_4383"
        transform="translate(0 60.371)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4384"
        fill="currentColor"
        height="10"
        id="Rectangle_4384"
        transform="translate(0 40.248)"
        width="242.594"
      />
      <rect
        data-name="Rectangle 4385"
        fill="currentColor"
        height="10"
        id="Rectangle_4385"
        transform="translate(0 20.123)"
        width="242.594"
      />
      <rect data-name="Rectangle 4386" fill="currentColor" height="10" id="Rectangle_4386" width="242.594" />
    </>
  ),
  'horizontal-thin-line': (
    <>
      <rect
        data-name="Rectangle 4399"
        fill="currentColor"
        height="5"
        id="Rectangle_4399"
        transform="translate(0 221.367)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4400"
        fill="currentColor"
        height="5"
        id="Rectangle_4400"
        transform="translate(0 201.242)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4401"
        fill="currentColor"
        height="5"
        id="Rectangle_4401"
        transform="translate(0 181.119)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4402"
        fill="currentColor"
        height="5"
        id="Rectangle_4402"
        transform="translate(0 160.996)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4403"
        fill="currentColor"
        height="5"
        id="Rectangle_4403"
        transform="translate(0 140.871)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4404"
        fill="currentColor"
        height="5"
        id="Rectangle_4404"
        transform="translate(0 120.746)"
        width="242.593"
      />
      <rect
        data-name="Rectangle 4405"
        fill="currentColor"
        height="5"
        id="Rectangle_4405"
        transform="translate(0 100.621)"
        width="242.593"
      />
      <rect data-name="Rectangle 4406" fill="currentColor" height="5" id="Rectangle_4406" transform="translate(0 80.496)" width="242.593" />
      <rect data-name="Rectangle 4407" fill="currentColor" height="5" id="Rectangle_4407" transform="translate(0 60.371)" width="242.593" />
      <rect data-name="Rectangle 4408" fill="currentColor" height="5" id="Rectangle_4408" transform="translate(0 40.248)" width="242.593" />
      <rect data-name="Rectangle 4409" fill="currentColor" height="5" id="Rectangle_4409" transform="translate(0 20.123)" width="242.593" />
      <rect data-name="Rectangle 4410" fill="currentColor" height="5" id="Rectangle_4410" width="242.593" />
    </>
  ),
  'vertical-line': (
    <>
      <rect data-name="Rectangle 4387" fill="currentColor" height="242.593" id="Rectangle_4387" width="10" />
      <rect data-name="Rectangle 4388" fill="currentColor" height="242.593" id="Rectangle_4388" transform="translate(20.125)" width="10" />
      <rect data-name="Rectangle 4389" fill="currentColor" height="242.593" id="Rectangle_4389" transform="translate(40.249)" width="10" />
      <rect data-name="Rectangle 4390" fill="currentColor" height="242.593" id="Rectangle_4390" transform="translate(60.374)" width="10" />
      <rect data-name="Rectangle 4391" fill="currentColor" height="242.593" id="Rectangle_4391" transform="translate(80.498)" width="10" />
      <rect data-name="Rectangle 4392" fill="currentColor" height="242.593" id="Rectangle_4392" transform="translate(100.623)" width="10" />
      <rect data-name="Rectangle 4393" fill="currentColor" height="242.593" id="Rectangle_4393" transform="translate(120.747)" width="10" />
      <rect data-name="Rectangle 4394" fill="currentColor" height="242.593" id="Rectangle_4394" transform="translate(140.871)" width="10" />
      <rect data-name="Rectangle 4395" fill="currentColor" height="242.593" id="Rectangle_4395" transform="translate(160.996)" width="10" />
      <rect data-name="Rectangle 4396" fill="currentColor" height="242.593" id="Rectangle_4396" transform="translate(181.12)" width="10" />
      <rect data-name="Rectangle 4397" fill="currentColor" height="242.593" id="Rectangle_4397" transform="translate(201.245)" width="10" />
      <rect
        data-name="Rectangle 4398"
        fill="currentColor"
        height="242.593"
        id="Rectangle_4398"
        transform="translate(221.369)"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      />
    </>
  ),
  'vertical-thin-line': (
    <>
      <rect data-name="Rectangle 4411" fill="currentColor" height="242.593" id="Rectangle_4411" width="5" />
      <rect data-name="Rectangle 4412" fill="currentColor" height="242.593" id="Rectangle_4412" transform="translate(20.124)" width="5" />
      <rect data-name="Rectangle 4413" fill="currentColor" height="242.593" id="Rectangle_4413" transform="translate(40.249)" width="5" />
      <rect data-name="Rectangle 4414" fill="currentColor" height="242.593" id="Rectangle_4414" transform="translate(60.373)" width="5" />
      <rect data-name="Rectangle 4415" fill="currentColor" height="242.593" id="Rectangle_4415" transform="translate(80.498)" width="5" />
      <rect data-name="Rectangle 4416" fill="currentColor" height="242.593" id="Rectangle_4416" transform="translate(100.622)" width="5" />
      <rect data-name="Rectangle 4417" fill="currentColor" height="242.593" id="Rectangle_4417" transform="translate(120.746)" width="5" />
      <rect data-name="Rectangle 4418" fill="currentColor" height="242.593" id="Rectangle_4418" transform="translate(140.871)" width="5" />
      <rect data-name="Rectangle 4419" fill="currentColor" height="242.593" id="Rectangle_4419" transform="translate(160.995)" width="5" />
      <rect data-name="Rectangle 4420" fill="currentColor" height="242.593" id="Rectangle_4420" transform="translate(181.12)" width="5" />
      <rect data-name="Rectangle 4421" fill="currentColor" height="242.593" id="Rectangle_4421" transform="translate(201.244)" width="5" />
      <rect data-name="Rectangle 4422" fill="currentColor" height="242.593" id="Rectangle_4422" transform="translate(221.369)" width="5" />
    </>
  ),
  'dashed-square-horizontal': (
    <>
      <path
        d="M224.457,231.369H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45982"
        fill="currentColor"
        id="Path_45982"
      />
      <rect
        data-name="Rectangle 4319"
        fill="currentColor"
        height="10"
        id="Rectangle_4319"
        transform="translate(236.594 221.367)"
        width="6"
      />
      <rect data-name="Rectangle 4320" fill="currentColor" height="10" id="Rectangle_4320" transform="translate(0 201.242)" width="6" />
      <path
        d="M224.457,211.244H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45983"
        fill="currentColor"
        id="Path_45983"
      />
      <rect
        data-name="Rectangle 4321"
        fill="currentColor"
        height="10"
        id="Rectangle_4321"
        transform="translate(236.594 201.242)"
        width="6"
      />
      <rect data-name="Rectangle 4322" fill="currentColor" height="10" id="Rectangle_4322" transform="translate(0 181.119)" width="6" />
      <path
        d="M224.457,191.12H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45984"
        fill="currentColor"
        id="Path_45984"
      />
      <rect
        data-name="Rectangle 4323"
        fill="currentColor"
        height="10"
        id="Rectangle_4323"
        transform="translate(236.594 181.119)"
        width="6"
      />
      <rect data-name="Rectangle 4324" fill="currentColor" height="10" id="Rectangle_4324" transform="translate(0 160.994)" width="6" />
      <path
        d="M224.457,170.995H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45985"
        fill="currentColor"
        id="Path_45985"
      />
      <rect
        data-name="Rectangle 4325"
        fill="currentColor"
        height="10"
        id="Rectangle_4325"
        transform="translate(236.594 160.994)"
        width="6"
      />
      <rect data-name="Rectangle 4326" fill="currentColor" height="10" id="Rectangle_4326" transform="translate(0 140.871)" width="6" />
      <path
        d="M224.457,150.871H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45986"
        fill="currentColor"
        id="Path_45986"
      />
      <rect
        data-name="Rectangle 4327"
        fill="currentColor"
        height="10"
        id="Rectangle_4327"
        transform="translate(236.594 140.871)"
        width="6"
      />
      <rect data-name="Rectangle 4328" fill="currentColor" height="10" id="Rectangle_4328" transform="translate(0 120.746)" width="6" />
      <path
        d="M224.457,130.747H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45987"
        fill="currentColor"
        id="Path_45987"
      />
      <rect
        data-name="Rectangle 4329"
        fill="currentColor"
        height="10"
        id="Rectangle_4329"
        transform="translate(236.594 120.746)"
        width="6"
      />
      <rect data-name="Rectangle 4330" fill="currentColor" height="10" id="Rectangle_4330" transform="translate(0 100.621)" width="6" />
      <path
        d="M224.457,110.622H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45988"
        fill="currentColor"
        id="Path_45988"
      />
      <rect
        data-name="Rectangle 4331"
        fill="currentColor"
        height="10"
        id="Rectangle_4331"
        transform="translate(236.594 100.621)"
        width="6"
      />
      <rect data-name="Rectangle 4332" fill="currentColor" height="10" id="Rectangle_4332" transform="translate(0 80.496)" width="6" />
      <path
        d="M224.457,90.5H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45989"
        fill="currentColor"
        id="Path_45989"
      />
      <rect
        data-name="Rectangle 4333"
        fill="currentColor"
        height="10"
        id="Rectangle_4333"
        transform="translate(236.594 80.496)"
        width="6"
      />
      <rect data-name="Rectangle 4334" fill="currentColor" height="10" id="Rectangle_4334" transform="translate(0 60.371)" width="6" />
      <path
        d="M224.457,70.373H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45990"
        fill="currentColor"
        id="Path_45990"
      />
      <rect
        data-name="Rectangle 4335"
        fill="currentColor"
        height="10"
        id="Rectangle_4335"
        transform="translate(236.594 60.371)"
        width="6"
      />
      <rect data-name="Rectangle 4336" fill="currentColor" height="10" id="Rectangle_4336" transform="translate(0 40.248)" width="6" />
      <path
        d="M224.457,50.249H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45991"
        fill="currentColor"
        id="Path_45991"
      />
      <rect
        data-name="Rectangle 4337"
        fill="currentColor"
        height="10"
        id="Rectangle_4337"
        transform="translate(236.594 40.248)"
        width="6"
      />
      <rect data-name="Rectangle 4338" fill="currentColor" height="10" id="Rectangle_4338" transform="translate(0 20.123)" width="6" />
      <path
        d="M224.457,30.125H212.32v-10h12.137Zm-24.273,0H188.048v-10h12.136Zm-24.272,0H163.774v-10h12.137Zm-24.273,0H139.5v-10h12.136Zm-24.272,0H115.229v-10h12.137v10Zm-24.273,0H90.956v-10h12.136Zm-24.272,0H66.683v-10H78.819v10Zm-24.273,0H42.41v-10H54.546Zm-24.272,0H18.137v-10H30.273Z"
        data-name="Path 45992"
        fill="currentColor"
        id="Path_45992"
      />
      <rect
        data-name="Rectangle 4339"
        fill="currentColor"
        height="10"
        id="Rectangle_4339"
        transform="translate(236.594 20.123)"
        width="6"
      />
      <rect data-name="Rectangle 4340" fill="currentColor" height="10" id="Rectangle_4340" width="6" />
      <path
        d="M224.457,10H212.32V0h12.137Zm-24.273,0H188.048V0h12.136Zm-24.272,0H163.774V0h12.137Zm-24.273,0H139.5V0h12.136Zm-24.272,0H115.229V0h12.137V10Zm-24.273,0H90.956V0h12.136ZM78.819,10H66.683V0H78.819V10ZM54.546,10H42.41V0H54.546ZM30.273,10H18.137V0H30.273Z"
        data-name="Path 45993"
        fill="currentColor"
        id="Path_45993"
      />
      <rect data-name="Rectangle 4341" fill="currentColor" height="10" id="Rectangle_4341" transform="translate(236.594)" width="6" />
    </>
  ),
  'dashed-square-vertical': (
    <>
      <rect data-name="Rectangle 4342" fill="currentColor" height="6" id="Rectangle_4342" width="10" />
      <path
        d="M10,224.457H0V212.32H10Zm0-24.273H0V188.047H10Zm0-24.273H0V163.774H10Zm0-24.273H0V139.5H10Zm0-24.273H0V115.229H10Zm0-24.273H0V90.956H10ZM10,78.82H0V66.683H10Zm0-24.273H0V42.41H10Zm0-24.273H0V18.137H10Z"
        data-name="Path 45994"
        fill="currentColor"
        id="Path_45994"
      />
      <rect data-name="Rectangle 4343" fill="currentColor" height="6" id="Rectangle_4343" transform="translate(0 236.594)" width="10" />
      <rect data-name="Rectangle 4344" fill="currentColor" height="6" id="Rectangle_4344" transform="translate(20.124)" width="10" />
      <path
        d="M30.124,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 45995"
        fill="currentColor"
        id="Path_45995"
      />
      <rect
        data-name="Rectangle 4345"
        fill="currentColor"
        height="6"
        id="Rectangle_4345"
        transform="translate(20.124 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4346" fill="currentColor" height="6" id="Rectangle_4346" transform="translate(40.249)" width="10" />
      <path
        d="M50.249,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 45996"
        fill="currentColor"
        id="Path_45996"
      />
      <rect
        data-name="Rectangle 4347"
        fill="currentColor"
        height="6"
        id="Rectangle_4347"
        transform="translate(40.249 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4348" fill="currentColor" height="6" id="Rectangle_4348" transform="translate(60.373)" width="10" />
      <path
        d="M70.373,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 45997"
        fill="currentColor"
        id="Path_45997"
      />
      <rect
        data-name="Rectangle 4349"
        fill="currentColor"
        height="6"
        id="Rectangle_4349"
        transform="translate(60.373 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4350" fill="currentColor" height="6" id="Rectangle_4350" transform="translate(80.498)" width="10" />
      <path
        d="M90.5,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 45998"
        fill="currentColor"
        id="Path_45998"
      />
      <rect
        data-name="Rectangle 4351"
        fill="currentColor"
        height="6"
        id="Rectangle_4351"
        transform="translate(80.498 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4352" fill="currentColor" height="6" id="Rectangle_4352" transform="translate(100.622)" width="10" />
      <path
        d="M110.622,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 45999"
        fill="currentColor"
        id="Path_45999"
      />
      <rect
        data-name="Rectangle 4353"
        fill="currentColor"
        height="6"
        id="Rectangle_4353"
        transform="translate(100.622 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4354" fill="currentColor" height="6" id="Rectangle_4354" transform="translate(120.747)" width="10" />
      <path
        d="M130.747,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46000"
        fill="currentColor"
        id="Path_46000"
      />
      <rect
        data-name="Rectangle 4355"
        fill="currentColor"
        height="6"
        id="Rectangle_4355"
        transform="translate(120.747 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4356" fill="currentColor" height="6" id="Rectangle_4356" transform="translate(140.871)" width="10" />
      <path
        d="M150.871,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46001"
        fill="currentColor"
        id="Path_46001"
      />
      <rect
        data-name="Rectangle 4357"
        fill="currentColor"
        height="6"
        id="Rectangle_4357"
        transform="translate(140.871 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4358" fill="currentColor" height="6" id="Rectangle_4358" transform="translate(160.995)" width="10" />
      <path
        d="M170.995,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46002"
        fill="currentColor"
        id="Path_46002"
      />
      <rect
        data-name="Rectangle 4359"
        fill="currentColor"
        height="6"
        id="Rectangle_4359"
        transform="translate(160.995 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4360" fill="currentColor" height="6" id="Rectangle_4360" transform="translate(181.12)" width="10" />
      <path
        d="M191.12,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46003"
        fill="currentColor"
        id="Path_46003"
      />
      <rect
        data-name="Rectangle 4361"
        fill="currentColor"
        height="6"
        id="Rectangle_4361"
        transform="translate(181.12 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4362" fill="currentColor" height="6" id="Rectangle_4362" transform="translate(201.244)" width="10" />
      <path
        d="M211.244,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46004"
        fill="currentColor"
        id="Path_46004"
      />
      <rect
        data-name="Rectangle 4363"
        fill="currentColor"
        height="6"
        id="Rectangle_4363"
        transform="translate(201.244 236.594)"
        width="10"
      />
      <rect data-name="Rectangle 4364" fill="currentColor" height="6" id="Rectangle_4364" transform="translate(221.369)" width="10" />
      <path
        d="M231.369,224.457h-10V212.32h10Zm0-24.273h-10V188.047h10Zm0-24.273h-10V163.774h10Zm0-24.273h-10V139.5h10Zm0-24.273h-10V115.229h10Zm0-24.273h-10V90.956h10Zm0-24.273h-10V66.683h10Zm0-24.273h-10V42.41h10Zm0-24.273h-10V18.137h10Z"
        data-name="Path 46005"
        fill="currentColor"
        id="Path_46005"
      />
      <rect
        data-name="Rectangle 4365"
        fill="currentColor"
        height="6"
        id="Rectangle_4365"
        transform="translate(221.369 236.594)"
        width="10"
      />
    </>
  ),
  'dashed-rounded-horizontal': (
    <>
      <path d="M4.5,226.369h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45838" fill="currentColor" id="Path_45838" />
      <path
        d="M232.9,226.369h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,1,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45839"
        fill="currentColor"
        id="Path_45839"
      />
      <path d="M245.093,226.369h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45840" fill="currentColor" id="Path_45840" />
      <path d="M4.5,206.244h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45841" fill="currentColor" id="Path_45841" />
      <path
        d="M232.9,206.244h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,1,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45842"
        fill="currentColor"
        id="Path_45842"
      />
      <path d="M245.093,206.244h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45843" fill="currentColor" id="Path_45843" />
      <path d="M4.5,186.12h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45844" fill="currentColor" id="Path_45844" />
      <path
        d="M232.9,186.12h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,1,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,1,1,0-5H118.7a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45845"
        fill="currentColor"
        id="Path_45845"
      />
      <path d="M245.093,186.12h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,1,1,0,5Z" data-name="Path 45846" fill="currentColor" id="Path_45846" />
      <path d="M4.5,166h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45847" fill="currentColor" id="Path_45847" />
      <path
        d="M232.9,166h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM175.8,166h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5ZM118.7,166H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM61.6,166H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45848"
        fill="currentColor"
        id="Path_45848"
      />
      <path d="M245.093,166h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45849" fill="currentColor" id="Path_45849" />
      <path d="M4.5,145.871h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45850" fill="currentColor" id="Path_45850" />
      <path
        d="M232.9,145.871h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45851"
        fill="currentColor"
        id="Path_45851"
      />
      <path d="M245.093,145.871h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45852" fill="currentColor" id="Path_45852" />
      <path d="M4.5,125.747h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45853" fill="currentColor" id="Path_45853" />
      <path
        d="M232.9,125.747h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,1,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,1,1,0,5Z"
        data-name="Path 45854"
        fill="currentColor"
        id="Path_45854"
      />
      <path d="M245.093,125.747h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45855" fill="currentColor" id="Path_45855" />
      <path d="M4.5,105.622h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45856" fill="currentColor" id="Path_45856" />
      <path
        d="M232.9,105.622h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,1,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45857"
        fill="currentColor"
        id="Path_45857"
      />
      <path d="M245.093,105.622h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45858" fill="currentColor" id="Path_45858" />
      <path d="M4.5,85.5h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45859" fill="currentColor" id="Path_45859" />
      <path
        d="M232.9,85.5h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,1,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,1,1,0-5H118.7a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM61.6,85.5H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45860"
        fill="currentColor"
        id="Path_45860"
      />
      <path d="M245.093,85.5h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,1,1,0,5Z" data-name="Path 45861" fill="currentColor" id="Path_45861" />
      <path d="M4.5,65.373h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45862" fill="currentColor" id="Path_45862" />
      <path
        d="M232.9,65.373h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,1,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,1,1,0-5H118.7a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,1,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45863"
        fill="currentColor"
        id="Path_45863"
      />
      <path d="M245.093,65.373h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,1,1,0,5Z" data-name="Path 45864" fill="currentColor" id="Path_45864" />
      <path d="M4.5,45.249h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45865" fill="currentColor" id="Path_45865" />
      <path
        d="M232.9,45.249h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45866"
        fill="currentColor"
        id="Path_45866"
      />
      <path d="M245.093,45.249h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,1,1,0,5Z" data-name="Path 45867" fill="currentColor" id="Path_45867" />
      <path d="M4.5,25.125h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45868" fill="currentColor" id="Path_45868" />
      <path
        d="M232.9,25.125h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5Zm-14.275,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H114.62a2.5,2.5,0,1,1,0-5H118.7a2.5,2.5,0,1,1,0,5Zm-14.274,0h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5Zm-14.275,0H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Zm-14.274,0H28.97a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5Zm-14.275,0H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45869"
        fill="currentColor"
        id="Path_45869"
      />
      <path d="M245.093,25.125h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,1,1,0,5Z" data-name="Path 45870" fill="currentColor" id="Path_45870" />
      <path d="M4.5,5h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45871" fill="currentColor" id="Path_45871" />
      <path
        d="M232.9,5h-4.078a2.5,2.5,0,0,1,0-5H232.9a2.5,2.5,0,0,1,0,5ZM218.622,5h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM204.347,5h-4.079a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5ZM190.072,5h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM175.8,5h-4.079a2.5,2.5,0,0,1,0-5H175.8a2.5,2.5,0,0,1,0,5ZM161.522,5h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM147.248,5h-4.078a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM132.973,5h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5ZM118.7,5H114.62a2.5,2.5,0,0,1,0-5H118.7a2.5,2.5,0,0,1,0,5ZM104.423,5h-4.079a2.5,2.5,0,1,1,0-5h4.079a2.5,2.5,0,0,1,0,5ZM90.148,5H86.07a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM75.874,5H71.8a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM61.6,5H57.52a2.5,2.5,0,0,1,0-5H61.6a2.5,2.5,0,0,1,0,5ZM47.324,5H43.246a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5ZM33.049,5H28.97a2.5,2.5,0,0,1,0-5h4.079a2.5,2.5,0,0,1,0,5ZM18.774,5H14.7a2.5,2.5,0,0,1,0-5h4.078a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45872"
        fill="currentColor"
        id="Path_45872"
      />
      <path d="M245.093,5h-2a2.5,2.5,0,0,1,0-5h2a2.5,2.5,0,0,1,0,5Z" data-name="Path 45873" fill="currentColor" id="Path_45873" />
    </>
  ),
  'dashed-rounded-vertical': (
    <>
      <path
        d="M2.5,7A2.5,2.5,0,0,1,0,4.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,2.5,7Z"
        data-name="Path 45874"
        fill="currentColor"
        id="Path_45874"
      />
      <path
        d="M2.5,235.4A2.5,2.5,0,0,1,0,232.9v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,2.5,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,2.5,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,192.572Zm0-14.274A2.5,2.5,0,0,1,0,175.8v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,2.5,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,2.5,135.473Zm0-14.275A2.5,2.5,0,0,1,0,118.7V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,2.5,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,2.5,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,78.374ZM2.5,64.1A2.5,2.5,0,0,1,0,61.6V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,2.5,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,2.5,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,2.5,21.274Z"
        data-name="Path 45875"
        fill="currentColor"
        id="Path_45875"
      />
      <path
        d="M2.5,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,2.5,247.593Z"
        data-name="Path 45876"
        fill="currentColor"
        id="Path_45876"
      />
      <path
        d="M22.625,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,22.625,7Z"
        data-name="Path 45877"
        fill="currentColor"
        id="Path_45877"
      />
      <path
        d="M22.625,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,22.625,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,22.625,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,22.625,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,22.625,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,1,1,5,0V118.7A2.5,2.5,0,0,1,22.625,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,22.625,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,22.625,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,22.625,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,22.625,21.274Z"
        data-name="Path 45878"
        fill="currentColor"
        id="Path_45878"
      />
      <path
        d="M22.625,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,22.625,247.593Z"
        data-name="Path 45879"
        fill="currentColor"
        id="Path_45879"
      />
      <path
        d="M42.748,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,42.748,7Z"
        data-name="Path 45880"
        fill="currentColor"
        id="Path_45880"
      />
      <path
        d="M42.748,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,42.748,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,42.748,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,42.748,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,42.748,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,42.748,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,1,1,5,0V118.7A2.5,2.5,0,0,1,42.748,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,42.748,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,42.748,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,42.748,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,42.748,21.274Z"
        data-name="Path 45881"
        fill="currentColor"
        id="Path_45881"
      />
      <path
        d="M42.748,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,42.748,247.593Z"
        data-name="Path 45882"
        fill="currentColor"
        id="Path_45882"
      />
      <path
        d="M62.873,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,62.873,7Z"
        data-name="Path 45883"
        fill="currentColor"
        id="Path_45883"
      />
      <path
        d="M62.873,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,62.873,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,62.873,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,62.873,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,62.873,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,62.873,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,1,1,5,0V118.7A2.5,2.5,0,0,1,62.873,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,62.873,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,62.873,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,62.873,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,62.873,21.274Z"
        data-name="Path 45884"
        fill="currentColor"
        id="Path_45884"
      />
      <path
        d="M62.873,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,62.873,247.593Z"
        data-name="Path 45885"
        fill="currentColor"
        id="Path_45885"
      />
      <path
        d="M83,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,83,7Z"
        data-name="Path 45886"
        fill="currentColor"
        id="Path_45886"
      />
      <path
        d="M83,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,83,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,83,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,83,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,83,192.572ZM83,178.3a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,83,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,83,135.473ZM83,121.2a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,1,1,5,0V118.7A2.5,2.5,0,0,1,83,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,83,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,78.374ZM83,64.1a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,83,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,83,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,83,21.274Z"
        data-name="Path 45887"
        fill="currentColor"
        id="Path_45887"
      />
      <path
        d="M83,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,83,247.593Z"
        data-name="Path 45888"
        fill="currentColor"
        id="Path_45888"
      />
      <path
        d="M103.122,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,103.122,7Z"
        data-name="Path 45889"
        fill="currentColor"
        id="Path_45889"
      />
      <path
        d="M103.122,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,103.122,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,103.122,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,103.122,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,103.122,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,103.122,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,103.122,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,103.122,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,103.122,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,103.122,21.274Z"
        data-name="Path 45890"
        fill="currentColor"
        id="Path_45890"
      />
      <path
        d="M103.122,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,103.122,247.593Z"
        data-name="Path 45891"
        fill="currentColor"
        id="Path_45891"
      />
      <path
        d="M123.246,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,123.246,7Z"
        data-name="Path 45892"
        fill="currentColor"
        id="Path_45892"
      />
      <path
        d="M123.246,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,123.246,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,123.246,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,123.246,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,123.246,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,123.246,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,123.246,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,123.246,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,123.246,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,123.246,21.274Z"
        data-name="Path 45893"
        fill="currentColor"
        id="Path_45893"
      />
      <path
        d="M123.246,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,123.246,247.593Z"
        data-name="Path 45894"
        fill="currentColor"
        id="Path_45894"
      />
      <path
        d="M143.371,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,143.371,7Z"
        data-name="Path 45895"
        fill="currentColor"
        id="Path_45895"
      />
      <path
        d="M143.371,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,143.371,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,143.371,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,143.371,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,143.371,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,143.371,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,143.371,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,143.371,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,143.371,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,143.371,21.274Z"
        data-name="Path 45896"
        fill="currentColor"
        id="Path_45896"
      />
      <path
        d="M143.371,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,143.371,247.593Z"
        data-name="Path 45897"
        fill="currentColor"
        id="Path_45897"
      />
      <path
        d="M163.495,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,163.495,7Z"
        data-name="Path 45898"
        fill="currentColor"
        id="Path_45898"
      />
      <path
        d="M163.495,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,163.495,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,163.495,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,163.495,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,163.495,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,163.495,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,1,1,5,0v4.078A2.5,2.5,0,0,1,163.495,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,163.495,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,163.495,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,1,1,5,0V118.7A2.5,2.5,0,0,1,163.495,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,163.495,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,163.495,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,163.495,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,163.495,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,163.495,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,163.495,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,163.495,21.274Z"
        data-name="Path 45899"
        fill="currentColor"
        id="Path_45899"
      />
      <path
        d="M163.495,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,163.495,247.593Z"
        data-name="Path 45900"
        fill="currentColor"
        id="Path_45900"
      />
      <path
        d="M183.619,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,183.619,7Z"
        data-name="Path 45901"
        fill="currentColor"
        id="Path_45901"
      />
      <path
        d="M183.619,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,183.619,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,183.619,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,183.619,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,183.619,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,183.619,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,183.619,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,183.619,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,183.619,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,183.619,21.274Z"
        data-name="Path 45902"
        fill="currentColor"
        id="Path_45902"
      />
      <path
        d="M183.619,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,183.619,247.593Z"
        data-name="Path 45903"
        fill="currentColor"
        id="Path_45903"
      />
      <path
        d="M203.744,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,203.744,7Z"
        data-name="Path 45904"
        fill="currentColor"
        id="Path_45904"
      />
      <path
        d="M203.744,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,203.744,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,203.744,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,203.744,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,203.744,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,203.744,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,203.744,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,203.744,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,203.744,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,203.744,21.274Z"
        data-name="Path 45905"
        fill="currentColor"
        id="Path_45905"
      />
      <path
        d="M203.744,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,203.744,247.593Z"
        data-name="Path 45906"
        fill="currentColor"
        id="Path_45906"
      />
      <path
        d="M223.869,7a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,223.869,7Z"
        data-name="Path 45907"
        fill="currentColor"
        id="Path_45907"
      />
      <path
        d="M223.869,235.4a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0V232.9A2.5,2.5,0,0,1,223.869,235.4Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,221.122Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,223.869,206.847Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,192.572Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0V175.8A2.5,2.5,0,0,1,223.869,178.3Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,164.022Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.078a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,149.748Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,223.869,135.473Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V114.62a2.5,2.5,0,0,1,5,0V118.7A2.5,2.5,0,0,1,223.869,121.2Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5v-4.079a2.5,2.5,0,0,1,5,0v4.079A2.5,2.5,0,0,1,223.869,106.923Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V86.07a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,92.648Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V71.8a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,78.374Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V57.52a2.5,2.5,0,0,1,5,0V61.6A2.5,2.5,0,0,1,223.869,64.1Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V43.246a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,49.824Zm0-14.274a2.5,2.5,0,0,1-2.5-2.5V28.97a2.5,2.5,0,1,1,5,0v4.079A2.5,2.5,0,0,1,223.869,35.549Zm0-14.275a2.5,2.5,0,0,1-2.5-2.5V14.7a2.5,2.5,0,0,1,5,0v4.078A2.5,2.5,0,0,1,223.869,21.274Z"
        data-name="Path 45908"
        fill="currentColor"
        id="Path_45908"
      />
      <path
        d="M223.869,247.593a2.5,2.5,0,0,1-2.5-2.5v-2a2.5,2.5,0,0,1,5,0v2A2.5,2.5,0,0,1,223.869,247.593Z"
        data-name="Path 45909"
        fill="currentColor"
        id="Path_45909"
      />
    </>
  ),
  'dots-small': (
    <>
      <path d="M3,226.369H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45910" fill="currentColor" id="Path_45910" />
      <path
        d="M210.98,226.369h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,1,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,1,1,0,5Z"
        data-name="Path 45911"
        fill="currentColor"
        id="Path_45911"
      />
      <path d="M245.094,226.369h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45912" fill="currentColor" id="Path_45912" />
      <path d="M3,206.244H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45913" fill="currentColor" id="Path_45913" />
      <path
        d="M210.98,206.244h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,1,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45914"
        fill="currentColor"
        id="Path_45914"
      />
      <path d="M245.094,206.244h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45915" fill="currentColor" id="Path_45915" />
      <path d="M3,186.12H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45916" fill="currentColor" id="Path_45916" />
      <path
        d="M210.98,186.12h-1.051a2.5,2.5,0,1,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,1,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,1,1,0-5h1.05a2.5,2.5,0,1,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45917"
        fill="currentColor"
        id="Path_45917"
      />
      <path d="M245.094,186.12h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45918" fill="currentColor" id="Path_45918" />
      <path d="M3,166H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45919" fill="currentColor" id="Path_45919" />
      <path
        d="M210.98,166h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45920"
        fill="currentColor"
        id="Path_45920"
      />
      <path d="M245.094,166h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45921" fill="currentColor" id="Path_45921" />
      <path d="M3,145.871H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45922" fill="currentColor" id="Path_45922" />
      <path
        d="M210.98,145.871h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45923"
        fill="currentColor"
        id="Path_45923"
      />
      <path d="M245.094,145.871h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45924" fill="currentColor" id="Path_45924" />
      <path d="M3,125.747H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45925" fill="currentColor" id="Path_45925" />
      <path
        d="M210.98,125.747h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,1,1,0,5Z"
        data-name="Path 45926"
        fill="currentColor"
        id="Path_45926"
      />
      <path d="M245.094,125.747h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45927" fill="currentColor" id="Path_45927" />
      <path d="M3,105.622H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45928" fill="currentColor" id="Path_45928" />
      <path
        d="M210.98,105.622h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,1,1,0,5Z"
        data-name="Path 45929"
        fill="currentColor"
        id="Path_45929"
      />
      <path d="M245.094,105.622h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45930" fill="currentColor" id="Path_45930" />
      <path d="M3,85.5H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45931" fill="currentColor" id="Path_45931" />
      <path
        d="M210.98,85.5h-1.051a2.5,2.5,0,1,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,1,1,0-5h1.05a2.5,2.5,0,1,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45932"
        fill="currentColor"
        id="Path_45932"
      />
      <path d="M245.094,85.5h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45933" fill="currentColor" id="Path_45933" />
      <path d="M3,65.373H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45934" fill="currentColor" id="Path_45934" />
      <path
        d="M210.98,65.373h-1.051a2.5,2.5,0,1,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,1,1,0-5h1.05a2.5,2.5,0,1,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45935"
        fill="currentColor"
        id="Path_45935"
      />
      <path d="M245.094,65.373h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45936" fill="currentColor" id="Path_45936" />
      <path d="M3,45.249H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45937" fill="currentColor" id="Path_45937" />
      <path
        d="M210.98,45.249h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45938"
        fill="currentColor"
        id="Path_45938"
      />
      <path d="M245.094,45.249h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45939" fill="currentColor" id="Path_45939" />
      <path d="M3,25.125H2.5a2.5,2.5,0,0,1,0-5H3a2.5,2.5,0,0,1,0,5Z" data-name="Path 45940" fill="currentColor" id="Path_45940" />
      <path
        d="M210.98,25.125h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.664,0H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5Zm-34.663,0h-1.05a2.5,2.5,0,1,1,0-5h1.05a2.5,2.5,0,1,1,0,5Zm-34.663,0H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Zm-34.663,0H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45941"
        fill="currentColor"
        id="Path_45941"
      />
      <path d="M245.094,25.125h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45942" fill="currentColor" id="Path_45942" />
      <path d="M3,5H2.5a2.5,2.5,0,0,1,0-5H3A2.5,2.5,0,0,1,3,5Z" data-name="Path 45943" fill="currentColor" id="Path_45943" />
      <path
        d="M210.98,5h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5ZM176.317,5h-1.051a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5ZM141.653,5H140.6a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5ZM106.99,5h-1.05a2.5,2.5,0,0,1,0-5h1.05a2.5,2.5,0,0,1,0,5ZM72.327,5H71.276a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5ZM37.663,5H36.613a2.5,2.5,0,0,1,0-5h1.051a2.5,2.5,0,0,1,0,5Z"
        data-name="Path 45944"
        fill="currentColor"
        id="Path_45944"
      />
      <path d="M245.094,5h-.5a2.5,2.5,0,0,1,0-5h.5a2.5,2.5,0,0,1,0,5Z" data-name="Path 45945" fill="currentColor" id="Path_45945" />
    </>
  ),
  'dots-medium': (
    <>
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152" r="25.5" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-2" r="25.5" transform="translate(67)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-3" r="25.5" transform="translate(134)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-4" r="25.5" transform="translate(201)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-5" r="25.5" transform="translate(0 61)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-6" r="25.5" transform="translate(67 61)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-7" r="25.5" transform="translate(134 61)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-8" r="25.5" transform="translate(201 61)" />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-9" r="25.5" transform="translate(0 122)" />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-10"
        r="25.5"
        transform="translate(67 122)"
      />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-11"
        r="25.5"
        transform="translate(134 122)"
      />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-12"
        r="25.5"
        transform="translate(201 122)"
      />
      <circle cx="25.5" cy="25.5" data-name="Ellipse 1152" fill="currentColor" id="Ellipse_1152-13" r="25.5" transform="translate(0 183)" />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-14"
        r="25.5"
        transform="translate(67 183)"
      />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-15"
        r="25.5"
        transform="translate(134 183)"
      />
      <circle
        cx="25.5"
        cy="25.5"
        data-name="Ellipse 1152"
        fill="currentColor"
        id="Ellipse_1152-16"
        r="25.5"
        transform="translate(201 183)"
      />
    </>
  ),
  'dots-large': (
    <>
      <circle cx="56.5" cy="56.5" data-name="Ellipse 1153" fill="currentColor" id="Ellipse_1153" r="56.5" />
      <circle cx="56.5" cy="56.5" data-name="Ellipse 1156" fill="currentColor" id="Ellipse_1156" r="56.5" transform="translate(0 126)" />
      <circle cx="56.5" cy="56.5" data-name="Ellipse 1154" fill="currentColor" id="Ellipse_1154" r="56.5" transform="translate(139)" />
      <circle cx="56.5" cy="56.5" data-name="Ellipse 1155" fill="currentColor" id="Ellipse_1155" r="56.5" transform="translate(139 126)" />
    </>
  ),
  stitch: (
    <>
      <path
        d="M242.594,31.674v-22h-9.675V0h-22V9.674H192.67V0h-22V9.674H152.421V0h-22V9.674H112.173V0h-22V9.674H71.924V0h-22V9.674H31.675V0h-22V9.674H0v22H9.675V49.923H0v22H9.675V90.172H0v22H9.675v18.248H0v22H9.675V170.67H0v22H9.675v18.249H0v22H9.675v9.675h22v-9.675H49.924v9.675h22v-9.675H90.173v9.675h22v-9.675h18.248v9.675h22v-9.675H170.67v9.675h22v-9.675h18.249v9.675h22v-9.675h9.675v-22h-9.675V192.67h9.675v-22h-9.675V152.421h9.675v-22h-9.675V112.172h9.675v-22h-9.675V71.923h9.675v-22h-9.675V31.674h9.675Zm-71.924,0V49.923H152.421V31.674H170.67Zm-58.5,58.5V71.923h18.248V90.172H112.173Zm18.248,22v18.248H112.173V112.172h18.248Zm-40.248-22H71.924V71.923H90.173Zm0,22v18.248H71.924V112.172H90.173Zm0,40.248V170.67H71.924V152.421Zm22,0h18.248V170.67H112.173Zm40.248,0H170.67V170.67H152.421Zm0-22V112.172H170.67v18.249Zm0-40.249V71.923H170.67V90.172Zm-22-58.5V49.923H112.173V31.674Zm-40.248,0V49.923H71.924V31.674Zm-58.5,0H49.924V49.923H31.675Zm0,40.249H49.924V90.172H31.675Zm0,40.249H49.924v18.248H31.675V112.172Zm0,40.248H49.924V170.67H31.675V152.421Zm0,58.5V192.67H49.924v18.249Zm40.249,0V192.67H90.173v18.249Zm40.249,0V192.67h18.248v18.249H112.173Zm40.248,0V192.67H170.67v18.249Zm58.5,0H192.67V192.67h18.249Zm0-40.249H192.67V152.421h18.249Zm0-40.249H192.67V112.172h18.249Zm0-40.249H192.67V71.923h18.249Zm0-40.249H192.67V31.674h18.249Z"
        data-name="Path 46006"
        fill="currentColor"
        id="Path_46006"
      />
    </>
  ),
  'square-medium': (
    <>
      <rect
        data-name="Rectangle 4366"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4366"
        transform="translate(0 173.064)"
        width="78.57"
      />
      <rect
        data-name="Rectangle 4367"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4367"
        transform="translate(84.81 173.064)"
        width="78.57"
      />
      <rect
        data-name="Rectangle 4368"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4368"
        transform="translate(169.619 173.064)"
        width="78.57"
      />
      <rect
        data-name="Rectangle 4369"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4369"
        transform="translate(0 86.531)"
        width="78.57"
      />
      <rect
        data-name="Rectangle 4370"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4370"
        transform="translate(84.81 86.531)"
        width="78.57"
      />
      <rect
        data-name="Rectangle 4371"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4371"
        transform="translate(169.619 86.531)"
        width="78.57"
      />
      <rect data-name="Rectangle 4372" fill="currentColor" height="78.57" id="Rectangle_4372" width="78.57" />
      <rect data-name="Rectangle 4373" fill="currentColor" height="78.57" id="Rectangle_4373" transform="translate(84.81)" width="78.57" />
      <rect
        data-name="Rectangle 4374"
        fill="currentColor"
        height="78.57"
        id="Rectangle_4374"
        transform="translate(169.619)"
        width="78.57"
      />
    </>
  ),
  'square-large': (
    <>
      <rect data-name="Rectangle 4423" fill="currentColor" height="119" id="Rectangle_4423" width="119" />
      <rect data-name="Rectangle 4426" fill="currentColor" height="119" id="Rectangle_4426" transform="translate(0 132)" width="119" />
      <rect data-name="Rectangle 4424" fill="currentColor" height="119" id="Rectangle_4424" transform="translate(130)" width="119" />
      <rect data-name="Rectangle 4425" fill="currentColor" height="119" id="Rectangle_4425" transform="translate(130 132)" width="119" />
    </>
  ),
  'square-outline-medium': (
    <>
      <path d="M72.57,179.064v66.57H6v-66.57H72.57m6-6H0v78.57H78.57v-78.57Z" data-name="Path 45828" fill="currentColor" id="Path_45828" />
      <path
        d="M157.379,179.064v66.57H90.809v-66.57h66.57m6-6H84.809v78.57h78.57v-78.57Z"
        data-name="Path 45829"
        fill="currentColor"
        id="Path_45829"
      />
      <path
        d="M242.189,179.064v66.57h-66.57v-66.57h66.57m6-6h-78.57v78.57h78.57v-78.57Z"
        data-name="Path 45830"
        fill="currentColor"
        id="Path_45830"
      />
      <path d="M72.57,92.532V159.1H6V92.532H72.57m6-6H0V165.1H78.57V86.532Z" data-name="Path 45831" fill="currentColor" id="Path_45831" />
      <path
        d="M157.379,92.532V159.1H90.809V92.532h66.57m6-6H84.809V165.1h78.57V86.532Z"
        data-name="Path 45832"
        fill="currentColor"
        id="Path_45832"
      />
      <path
        d="M242.189,92.532V159.1h-66.57V92.532h66.57m6-6h-78.57V165.1h78.57V86.532Z"
        data-name="Path 45833"
        fill="currentColor"
        id="Path_45833"
      />
      <path d="M72.57,6V72.57H6V6H72.57m6-6H0V78.57H78.57V0Z" data-name="Path 45834" fill="currentColor" id="Path_45834" />
      <path d="M157.379,6V72.57H90.809V6h66.57m6-6H84.809V78.57h78.57V0Z" data-name="Path 45835" fill="currentColor" id="Path_45835" />
      <path d="M242.189,6V72.57h-66.57V6h66.57m6-6h-78.57V78.57h78.57V0Z" data-name="Path 45836" fill="currentColor" id="Path_45836" />
    </>
  ),
  'square-outline-large': (
    <>
      <g data-name="Rectangle 4423" fill="none" id="Rectangle_4423" stroke="currentColor" stroke-width="6">
        <rect height="119" stroke="none" width="119" />
        <rect fill="none" height="113" width="113" x="3" y="3" />
      </g>
      <g data-name="Rectangle 4426" fill="none" id="Rectangle_4426" stroke="currentColor" stroke-width="6" transform="translate(0 132)">
        <rect height="119" stroke="none" width="119" />
        <rect fill="none" height="113" width="113" x="3" y="3" />
      </g>
      <g data-name="Rectangle 4424" fill="none" id="Rectangle_4424" stroke="currentColor" stroke-width="6" transform="translate(130)">
        <rect height="119" stroke="none" width="119" />
        <rect fill="none" height="113" width="113" x="3" y="3" />
      </g>
      <g data-name="Rectangle 4425" fill="none" id="Rectangle_4425" stroke="currentColor" stroke-width="6" transform="translate(130 132)">
        <rect height="119" stroke="none" width="119" />
        <rect fill="none" height="113" width="113" x="3" y="3" />
      </g>
    </>
  ),
  'circle-thin': (
    <>
      <g id="Ellipse_1031">
        <path
          d="M113.075,226.151A113.076,113.076,0,1,1,226.151,113.075,113.2,113.2,0,0,1,113.075,226.151ZM113.075,5A108.076,108.076,0,1,0,221.151,113.075,108.2,108.2,0,0,0,113.075,5Z"
          data-name="Path 45818"
          fill="currentColor"
          id="Path_45818"
        />
      </g>
      <path
        d="M113.075,216.2A103.12,103.12,0,1,1,216.2,113.075,103.237,103.237,0,0,1,113.075,216.2Zm0-201.4a98.277,98.277,0,1,0,98.277,98.276A98.388,98.388,0,0,0,113.075,14.8Z"
        data-name="Path 45819"
        fill="currentColor"
        id="Path_45819"
      />
      <path
        d="M113.075,206.242a93.166,93.166,0,1,1,93.167-93.167,93.272,93.272,0,0,1-93.167,93.167Zm0-181.646a88.479,88.479,0,1,0,88.48,88.479A88.579,88.579,0,0,0,113.075,24.6Z"
        data-name="Path 45820"
        fill="currentColor"
        id="Path_45820"
      />
      <path
        d="M113.075,196.287a83.212,83.212,0,1,1,83.212-83.212,83.307,83.307,0,0,1-83.212,83.212Zm0-161.893a78.681,78.681,0,1,0,78.681,78.681,78.77,78.77,0,0,0-78.681-78.681Z"
        data-name="Path 45821"
        fill="currentColor"
        id="Path_45821"
      />
      <path
        d="M113.075,186.333a73.257,73.257,0,1,1,73.258-73.258A73.34,73.34,0,0,1,113.075,186.333Zm0-142.14a68.882,68.882,0,1,0,68.883,68.882,68.96,68.96,0,0,0-68.883-68.882Z"
        data-name="Path 45822"
        fill="currentColor"
        id="Path_45822"
      />
      <path
        d="M113.075,176.379a63.3,63.3,0,1,1,63.3-63.3A63.375,63.375,0,0,1,113.075,176.379Zm0-122.388a59.085,59.085,0,1,0,59.085,59.084,59.151,59.151,0,0,0-59.085-59.084Z"
        data-name="Path 45823"
        fill="currentColor"
        id="Path_45823"
      />
      <path
        d="M113.075,166.423a53.348,53.348,0,1,1,53.348-53.348A53.408,53.408,0,0,1,113.075,166.423Zm0-102.633a49.286,49.286,0,1,0,49.287,49.285A49.341,49.341,0,0,0,113.075,63.79Z"
        data-name="Path 45824"
        fill="currentColor"
        id="Path_45824"
      />
      <path
        d="M113.075,156.469a43.393,43.393,0,1,1,43.394-43.394A43.442,43.442,0,0,1,113.075,156.469Zm0-82.882a39.489,39.489,0,1,0,39.489,39.488,39.534,39.534,0,0,0-39.489-39.488Z"
        data-name="Path 45825"
        fill="currentColor"
        id="Path_45825"
      />
      <path
        d="M113.075,146.514a33.438,33.438,0,1,1,33.438-33.438,33.476,33.476,0,0,1-33.438,33.438Zm0-63.128a29.69,29.69,0,1,0,29.69,29.689,29.723,29.723,0,0,0-29.69-29.689Z"
        data-name="Path 45826"
        fill="currentColor"
        id="Path_45826"
      />
      <g id="Ellipse_1031-2">
        <path
          d="M113.075,136.56a23.484,23.484,0,1,1,23.484-23.484,23.51,23.51,0,0,1-23.484,23.484Zm0-43.376a19.892,19.892,0,1,0,19.893,19.892,19.915,19.915,0,0,0-19.893-19.892Z"
          data-name="Path 45827"
          fill="currentColor"
          id="Path_45827"
        />
      </g>
    </>
  ),
  'circle-thick': (
    <>
      <path
        d="M110.576,0A110.576,110.576,0,1,0,221.151,110.575,110.575,110.575,0,0,0,110.576,0Zm0,201.135a90.56,90.56,0,1,1,90.56-90.56,90.56,90.56,0,0,1-90.56,90.56Z"
        data-name="Path 45815"
        fill="currentColor"
        id="Path_45815"
      />
      <path
        d="M110.576,43.6a66.979,66.979,0,1,0,66.979,66.979A66.979,66.979,0,0,0,110.576,43.6Zm0,117.114a50.135,50.135,0,1,1,50.135-50.135,50.135,50.135,0,0,1-50.135,50.135Z"
        data-name="Path 45816"
        fill="currentColor"
        id="Path_45816"
      />
      <path
        d="M110.576,80.389a30.187,30.187,0,1,0,30.186,30.187,30.187,30.187,0,0,0-30.186-30.187Zm0,48.131a17.945,17.945,0,1,1,17.945-17.945A17.945,17.945,0,0,1,110.576,128.52Z"
        data-name="Path 45817"
        fill="currentColor"
        id="Path_45817"
      />
    </>
  ),
  'wave-horizontal-large': (
    <>
      <path
        d="M242.594,265.184c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232S177.79,235.226,171.93,244.6c-6.325,10.119-12.865,20.582-26.381,20.582S125.494,254.72,119.17,244.6c-5.859-9.375-11.395-18.231-22.138-18.231S80.754,235.226,74.895,244.6c-6.324,10.119-12.863,20.583-26.379,20.583S28.462,254.72,22.138,244.6C16.279,235.226,10.743,226.37,0,226.37v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232s16.279-8.856,22.139-18.232c6.324-10.119,12.863-20.582,26.378-20.582s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232c6.325-10.118,12.865-20.582,26.382-20.582s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232Z"
        data-name="Path 46007"
        fill="currentColor"
        id="Path_46007"
      />
      <path
        d="M242.594,245.06c-13.517,0-20.057-10.464-26.382-20.583-5.86-9.376-11.4-18.232-22.141-18.232S177.79,215.1,171.93,224.478C165.6,234.6,159.065,245.06,145.549,245.06S125.494,234.6,119.17,224.477c-5.859-9.376-11.395-18.232-22.138-18.232S80.754,215.1,74.895,224.477C68.571,234.6,62.031,245.06,48.516,245.06S28.462,234.6,22.138,224.477C16.279,215.1,10.743,206.245,0,206.245v-5c13.515,0,20.054,10.464,26.378,20.583,5.859,9.376,11.395,18.232,22.138,18.232S64.8,231.2,70.655,221.827c6.324-10.118,12.863-20.582,26.378-20.582s20.054,10.464,26.378,20.582c5.859,9.377,11.394,18.233,22.139,18.233s16.28-8.856,22.141-18.232c6.325-10.119,12.865-20.583,26.382-20.583s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233v5Z"
        data-name="Path 46008"
        fill="currentColor"
        id="Path_46008"
      />
      <path
        d="M242.594,224.935c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232s-16.281,8.856-22.142,18.232c-6.325,10.119-12.865,20.582-26.381,20.582s-20.055-10.464-26.379-20.583c-5.859-9.375-11.395-18.231-22.138-18.231s-16.278,8.856-22.138,18.231c-6.324,10.119-12.863,20.583-26.379,20.583s-20.054-10.464-26.378-20.582C16.279,194.977,10.743,186.121,0,186.121v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232S64.8,211.079,70.655,201.7c6.324-10.119,12.863-20.582,26.378-20.582s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232c6.325-10.118,12.865-20.582,26.382-20.582s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232Z"
        data-name="Path 46009"
        fill="currentColor"
        id="Path_46009"
      />
      <path
        d="M242.594,204.811c-13.517,0-20.057-10.464-26.382-20.583-5.86-9.376-11.4-18.232-22.141-18.232s-16.281,8.856-22.142,18.233c-6.325,10.118-12.865,20.582-26.381,20.582s-20.055-10.464-26.379-20.583C113.311,174.852,107.776,166,97.032,166s-16.278,8.856-22.138,18.232c-6.324,10.119-12.863,20.583-26.379,20.583s-20.054-10.464-26.378-20.583C16.279,174.852,10.743,166,0,166v-5c13.515,0,20.054,10.464,26.378,20.583,5.859,9.376,11.395,18.232,22.138,18.232s16.279-8.856,22.139-18.233C76.979,171.459,83.518,161,97.032,161s20.054,10.464,26.378,20.582c5.859,9.377,11.394,18.233,22.139,18.233s16.28-8.856,22.141-18.232C174.015,171.459,180.555,161,194.071,161s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233v5Z"
        data-name="Path 46010"
        fill="currentColor"
        id="Path_46010"
      />
      <path
        d="M242.594,184.686c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232S177.79,154.728,171.93,164.1c-6.325,10.119-12.865,20.582-26.381,20.582S125.494,174.222,119.17,164.1c-5.859-9.375-11.395-18.231-22.138-18.231S80.754,154.728,74.895,164.1c-6.324,10.119-12.863,20.583-26.379,20.583S28.462,174.222,22.138,164.1C16.279,154.728,10.743,145.872,0,145.872v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232S64.8,170.83,70.655,161.454c6.324-10.119,12.863-20.582,26.378-20.582s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232c6.325-10.118,12.865-20.582,26.382-20.582s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232Z"
        data-name="Path 46011"
        fill="currentColor"
        id="Path_46011"
      />
      <path
        d="M242.594,164.562c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232S177.79,134.6,171.93,143.98c-6.325,10.119-12.865,20.582-26.381,20.582S125.494,154.1,119.17,143.979c-5.859-9.375-11.395-18.231-22.138-18.231S80.754,134.6,74.895,143.979c-6.324,10.119-12.863,20.583-26.379,20.583S28.462,154.1,22.138,143.98C16.279,134.6,10.743,125.748,0,125.748v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232S64.8,150.706,70.655,141.33c6.324-10.119,12.863-20.582,26.378-20.582s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232c6.325-10.118,12.865-20.582,26.382-20.582s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232v5Z"
        data-name="Path 46012"
        fill="currentColor"
        id="Path_46012"
      />
      <path
        d="M242.594,144.438c-13.517,0-20.057-10.464-26.382-20.583-5.86-9.376-11.4-18.232-22.141-18.232s-16.281,8.856-22.142,18.233c-6.325,10.118-12.865,20.582-26.381,20.582s-20.055-10.464-26.379-20.583c-5.859-9.376-11.395-18.232-22.138-18.232s-16.278,8.856-22.138,18.232c-6.324,10.119-12.863,20.583-26.379,20.583s-20.054-10.464-26.378-20.583C16.279,114.479,10.743,105.623,0,105.623v-5c13.515,0,20.054,10.464,26.378,20.583,5.859,9.376,11.395,18.232,22.138,18.232S64.8,130.582,70.655,121.2c6.324-10.118,12.863-20.582,26.378-20.582s20.054,10.464,26.378,20.582c5.859,9.377,11.394,18.233,22.139,18.233s16.28-8.856,22.141-18.232c6.325-10.119,12.865-20.583,26.382-20.583s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233Z"
        data-name="Path 46013"
        fill="currentColor"
        id="Path_46013"
      />
      <path
        d="M242.594,124.313c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232s-16.281,8.856-22.142,18.232c-6.325,10.119-12.865,20.582-26.381,20.582s-20.055-10.464-26.379-20.583C113.311,94.355,107.776,85.5,97.032,85.5S80.754,94.355,74.895,103.73c-6.324,10.119-12.863,20.583-26.379,20.583s-20.054-10.464-26.378-20.582C16.279,94.355,10.743,85.5,0,85.5v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232s16.279-8.856,22.139-18.232C76.979,90.962,83.518,80.5,97.032,80.5s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232C174.015,90.962,180.555,80.5,194.071,80.5s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232Z"
        data-name="Path 46014"
        fill="currentColor"
        id="Path_46014"
      />
      <path
        d="M242.594,104.188c-13.517,0-20.057-10.464-26.382-20.583-5.86-9.376-11.4-18.232-22.141-18.232S177.79,74.229,171.93,83.606c-6.325,10.118-12.865,20.582-26.381,20.582S125.494,93.724,119.17,83.605c-5.859-9.376-11.395-18.232-22.138-18.232S80.754,74.229,74.895,83.605c-6.324,10.119-12.863,20.583-26.379,20.583S28.462,93.724,22.138,83.605C16.279,74.229,10.743,65.373,0,65.373v-5c13.515,0,20.054,10.464,26.378,20.583,5.859,9.376,11.395,18.232,22.138,18.232S64.8,90.332,70.655,80.955c6.324-10.118,12.863-20.582,26.378-20.582s20.054,10.464,26.378,20.582c5.859,9.377,11.394,18.233,22.139,18.233s16.28-8.856,22.141-18.232c6.325-10.119,12.865-20.583,26.382-20.583s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233Z"
        data-name="Path 46015"
        fill="currentColor"
        id="Path_46015"
      />
      <path
        d="M242.594,84.063c-13.517,0-20.057-10.464-26.382-20.582-5.86-9.376-11.4-18.232-22.141-18.232S177.79,54.106,171.93,63.481C165.6,73.6,159.065,84.063,145.549,84.063S125.494,73.6,119.17,63.48c-5.859-9.375-11.395-18.231-22.138-18.231S80.754,54.106,74.895,63.48C68.571,73.6,62.031,84.063,48.516,84.063S28.462,73.6,22.138,63.481C16.279,54.106,10.743,45.249,0,45.249v-5c13.515,0,20.054,10.464,26.378,20.582,5.859,9.376,11.395,18.232,22.138,18.232S64.8,70.207,70.655,60.831c6.324-10.119,12.863-20.582,26.378-20.582s20.054,10.463,26.378,20.582c5.859,9.376,11.394,18.232,22.139,18.232s16.28-8.856,22.141-18.232c6.325-10.118,12.865-20.582,26.382-20.582s20.056,10.463,26.381,20.582c5.86,9.376,11.4,18.232,22.142,18.232Z"
        data-name="Path 46016"
        fill="currentColor"
        id="Path_46016"
      />
      <path
        d="M242.594,63.939c-13.517,0-20.057-10.464-26.382-20.583-5.86-9.376-11.4-18.232-22.141-18.232S177.79,33.98,171.93,43.357c-6.325,10.118-12.865,20.582-26.381,20.582S125.494,53.476,119.17,43.356c-5.859-9.376-11.395-18.232-22.138-18.232S80.754,33.98,74.895,43.356C68.571,53.475,62.031,63.939,48.516,63.939S28.462,53.476,22.138,43.356C16.278,33.98,10.743,25.124,0,25.124v-5c13.515,0,20.054,10.464,26.378,20.583,5.859,9.376,11.395,18.232,22.138,18.232s16.279-8.856,22.139-18.233c6.324-10.118,12.863-20.582,26.378-20.582s20.054,10.464,26.378,20.582c5.859,9.377,11.395,18.233,22.139,18.233s16.28-8.856,22.141-18.232c6.325-10.119,12.865-20.583,26.382-20.583s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233v5Z"
        data-name="Path 46017"
        fill="currentColor"
        id="Path_46017"
      />
      <path
        d="M242.594,43.815c-13.517,0-20.057-10.464-26.382-20.583C210.352,13.856,204.817,5,194.072,5S177.79,13.856,171.93,23.233c-6.325,10.118-12.865,20.582-26.381,20.582S125.494,33.352,119.17,23.232C113.311,13.856,107.776,5,97.032,5S80.754,13.856,74.895,23.232C68.571,33.351,62.031,43.815,48.516,43.815S28.462,33.352,22.138,23.232C16.278,13.856,10.743,5,0,5V0C13.515,0,20.054,10.464,26.378,20.583c5.859,9.376,11.395,18.232,22.138,18.232s16.279-8.856,22.139-18.233C76.979,10.464,83.518,0,97.032,0s20.054,10.464,26.378,20.582c5.859,9.377,11.394,18.233,22.139,18.233s16.28-8.856,22.141-18.232C174.015,10.464,180.555,0,194.071,0s20.056,10.464,26.381,20.582c5.86,9.377,11.4,18.233,22.142,18.233v5Z"
        data-name="Path 46018"
        fill="currentColor"
        id="Path_46018"
      />
    </>
  ),
  'wave-vertical-large': (
    <>
      <path
        d="M5,242.594H0c0-13.517,10.464-20.057,20.583-26.382,9.376-5.86,18.232-11.4,18.232-22.141S29.959,177.79,20.582,171.93C10.464,165.6,0,159.065,0,145.549S10.464,125.5,20.583,119.171c9.376-5.859,18.232-11.395,18.232-22.138S29.959,80.755,20.583,74.895C10.464,68.57,0,62.03,0,48.516S10.464,28.462,20.583,22.138C29.959,16.278,38.815,10.743,38.815,0h5c0,13.515-10.464,20.054-20.583,26.378C13.856,32.237,5,37.772,5,48.516s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.583,26.379s-10.464,20.054-20.583,26.378C13.856,129.27,5,134.806,5,145.549s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.583,26.382s-10.464,20.056-20.582,26.381C13.856,226.312,5,231.849,5,242.594Z"
        data-name="Path 46019"
        fill="currentColor"
        id="Path_46019"
      />
      <path
        d="M25.125,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141S50.083,177.79,40.707,171.93c-10.119-6.325-20.582-12.865-20.582-26.381S30.589,125.5,40.707,119.171c9.376-5.859,18.232-11.395,18.232-22.138S50.083,80.755,40.707,74.895C30.588,68.571,20.125,62.03,20.125,48.516S30.589,28.462,40.707,22.138C50.083,16.279,58.939,10.743,58.939,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.582,12.864,20.582,26.379s-10.464,20.054-20.582,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382s-10.463,20.056-20.582,26.381c-9.376,5.86-18.232,11.4-18.232,22.142Z"
        data-name="Path 46020"
        fill="currentColor"
        id="Path_46020"
      />
      <path
        d="M45.249,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141S70.207,177.79,60.831,171.93c-10.119-6.325-20.582-12.865-20.582-26.381S50.713,125.5,60.831,119.171c9.376-5.859,18.232-11.395,18.232-22.138S70.207,80.755,60.831,74.895C50.712,68.571,40.249,62.03,40.249,48.516S50.713,28.462,60.831,22.138C70.207,16.279,79.063,10.743,79.063,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139C73.6,76.979,84.063,83.519,84.063,97.033S73.6,117.087,63.481,123.411c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382S73.6,214.127,63.481,220.452C54.105,226.313,45.249,231.849,45.249,242.594Z"
        data-name="Path 46021"
        fill="currentColor"
        id="Path_46021"
      />
      <path
        d="M65.373,242.594h-5c0-13.517,10.464-20.057,20.583-26.382,9.376-5.86,18.232-11.4,18.232-22.141S90.332,177.79,80.955,171.93c-10.118-6.325-20.582-12.865-20.582-26.381S70.837,125.5,80.956,119.171c9.376-5.859,18.232-11.395,18.232-22.138S90.332,80.755,80.956,74.895C70.837,68.571,60.373,62.03,60.373,48.516S70.837,28.462,80.956,22.138C90.332,16.279,99.188,10.743,99.188,0h5c0,13.515-10.464,20.054-20.583,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.583,26.379s-10.464,20.054-20.583,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.583,26.382s-10.464,20.056-20.582,26.381C74.229,226.313,65.373,231.849,65.373,242.594Z"
        data-name="Path 46022"
        fill="currentColor"
        id="Path_46022"
      />
      <path
        d="M85.5,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141s-8.856-16.281-18.232-22.142C90.961,165.6,80.5,159.065,80.5,145.549S90.962,125.5,101.08,119.171c9.376-5.859,18.232-11.395,18.232-22.138s-8.856-16.278-18.232-22.139C90.961,68.571,80.5,62.03,80.5,48.516S90.962,28.462,101.08,22.138C110.456,16.279,119.312,10.743,119.312,0h5c0,13.515-10.464,20.054-20.582,26.378C94.354,32.238,85.5,37.773,85.5,48.516s8.856,16.278,18.232,22.139c10.119,6.324,20.582,12.864,20.582,26.379s-10.464,20.054-20.582,26.378C94.354,129.271,85.5,134.806,85.5,145.549s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382s-10.463,20.056-20.582,26.381C94.354,226.313,85.5,231.849,85.5,242.594Z"
        data-name="Path 46023"
        fill="currentColor"
        id="Path_46023"
      />
      <path
        d="M105.622,242.594h-5c0-13.517,10.464-20.057,20.583-26.382,9.376-5.86,18.232-11.4,18.232-22.141S130.581,177.79,121.2,171.93c-10.118-6.325-20.582-12.865-20.582-26.381s10.464-20.054,20.583-26.378c9.376-5.859,18.232-11.395,18.232-22.138s-8.856-16.278-18.232-22.139c-10.119-6.324-20.583-12.864-20.583-26.379s10.464-20.054,20.583-26.378C130.581,16.279,139.437,10.743,139.437,0h5c0,13.515-10.464,20.054-20.583,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.583,26.379s-10.464,20.054-20.583,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.583,26.382s-10.464,20.056-20.582,26.381c-9.377,5.86-18.233,11.4-18.233,22.142Z"
        data-name="Path 46024"
        fill="currentColor"
        id="Path_46024"
      />
      <path
        d="M125.747,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141S150.7,177.79,141.329,171.93c-10.119-6.325-20.582-12.865-20.582-26.381s10.464-20.054,20.582-26.378c9.376-5.859,18.232-11.395,18.232-22.138S150.7,80.755,141.329,74.895c-10.119-6.324-20.582-12.864-20.582-26.379s10.464-20.054,20.582-26.378C150.7,16.279,159.561,10.743,159.561,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.582,12.864,20.582,26.379s-10.464,20.054-20.582,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382s-10.463,20.056-20.582,26.381c-9.376,5.86-18.232,11.4-18.232,22.142Z"
        data-name="Path 46025"
        fill="currentColor"
        id="Path_46025"
      />
      <path
        d="M145.871,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141s-8.856-16.281-18.232-22.142c-10.119-6.325-20.582-12.865-20.582-26.381s10.464-20.054,20.582-26.378c9.376-5.859,18.232-11.395,18.232-22.138s-8.855-16.279-18.232-22.139c-10.118-6.324-20.582-12.864-20.582-26.379s10.464-20.054,20.582-26.378C170.829,16.279,179.685,10.743,179.685,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.582,26.379s-10.464,20.054-20.582,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.582,26.382s-10.463,20.056-20.582,26.381C154.727,226.313,145.871,231.849,145.871,242.594Z"
        data-name="Path 46026"
        fill="currentColor"
        id="Path_46026"
      />
      <path
        d="M165.995,242.594h-5c0-13.517,10.464-20.057,20.583-26.382,9.376-5.86,18.232-11.4,18.232-22.141s-8.856-16.281-18.233-22.142C171.459,165.6,161,159.065,161,145.549s10.464-20.054,20.583-26.378c9.376-5.859,18.232-11.395,18.232-22.138s-8.856-16.278-18.232-22.139c-10.119-6.324-20.583-12.864-20.583-26.379s10.464-20.054,20.583-26.378C190.954,16.279,199.81,10.743,199.81,0h5c0,13.515-10.464,20.054-20.583,26.378C174.851,32.238,166,37.773,166,48.516s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.583,26.379s-10.464,20.054-20.583,26.378C174.851,129.271,166,134.806,166,145.549s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.583,26.382s-10.464,20.056-20.582,26.381c-9.377,5.86-18.233,11.4-18.233,22.142Z"
        data-name="Path 46027"
        fill="currentColor"
        id="Path_46027"
      />
      <path
        d="M186.12,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141S211.078,177.79,201.7,171.93c-10.119-6.325-20.582-12.865-20.582-26.381S191.584,125.5,201.7,119.171c9.376-5.859,18.232-11.395,18.232-22.138S211.078,80.755,201.7,74.895C191.583,68.571,181.12,62.03,181.12,48.516S191.584,28.462,201.7,22.138C211.078,16.279,219.934,10.743,219.934,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.582,12.864,20.582,26.379s-10.464,20.054-20.582,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382s-10.463,20.056-20.582,26.381C194.976,226.313,186.12,231.849,186.12,242.594Z"
        data-name="Path 46028"
        fill="currentColor"
        id="Path_46028"
      />
      <path
        d="M206.244,242.594h-5c0-13.517,10.464-20.057,20.583-26.382,9.376-5.86,18.232-11.4,18.232-22.141S231.2,177.79,221.826,171.93c-10.118-6.325-20.582-12.865-20.582-26.381s10.464-20.054,20.583-26.378c9.376-5.859,18.232-11.395,18.232-22.138S231.2,80.755,221.827,74.895c-10.119-6.324-20.583-12.864-20.583-26.379s10.464-20.054,20.583-26.378C231.2,16.279,240.059,10.743,240.059,0h5c0,13.515-10.464,20.054-20.583,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.583,12.864,20.583,26.379s-10.464,20.054-20.583,26.378c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.119,6.325,20.583,12.865,20.583,26.382s-10.464,20.056-20.582,26.381c-9.377,5.86-18.233,11.4-18.233,22.142Z"
        data-name="Path 46029"
        fill="currentColor"
        id="Path_46029"
      />
      <path
        d="M226.369,242.594h-5c0-13.517,10.464-20.057,20.582-26.382,9.376-5.86,18.232-11.4,18.232-22.141s-8.856-16.281-18.232-22.142c-10.119-6.325-20.582-12.865-20.582-26.381s10.464-20.054,20.582-26.378c9.376-5.859,18.232-11.395,18.232-22.138s-8.856-16.278-18.232-22.139c-10.119-6.324-20.582-12.864-20.582-26.379s10.464-20.054,20.582-26.378C251.327,16.279,260.183,10.743,260.183,0h5c0,13.515-10.464,20.054-20.582,26.378-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.278,18.232,22.139c10.119,6.324,20.582,12.864,20.582,26.379S254.72,117.087,244.6,123.411c-9.376,5.859-18.232,11.395-18.232,22.138s8.856,16.28,18.232,22.141c10.118,6.325,20.582,12.865,20.582,26.382s-10.463,20.056-20.582,26.381C235.225,226.313,226.369,231.849,226.369,242.594Z"
        data-name="Path 46030"
        fill="currentColor"
        id="Path_46030"
      />
    </>
  ),
  'wave-vertical-small': (
    <>
      <g id="wave-small-vertical-icon" transform="translate(0 2.5)">
        <path
          d="M0,223.869c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46043"
          fill="none"
          id="Path_46043"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,203.744c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46044"
          fill="none"
          id="Path_46044"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,183.62c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46045"
          fill="none"
          id="Path_46045"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,163.495c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46046"
          fill="none"
          id="Path_46046"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,143.371c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46047"
          fill="none"
          id="Path_46047"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,123.247c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46048"
          fill="none"
          id="Path_46048"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,103.122c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46049"
          fill="none"
          id="Path_46049"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,83c13.474,0,13.474,12,26.948,12S40.424,83,53.9,83,67.375,95,80.851,95s13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46050"
          fill="none"
          id="Path_46050"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,62.873c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46051"
          fill="none"
          id="Path_46051"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,42.749c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46052"
          fill="none"
          id="Path_46052"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,22.624c13.474,0,13.474,12,26.948,12s13.475-12,26.951-12,13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46053"
          fill="none"
          id="Path_46053"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
        <path
          d="M0,2.5c13.474,0,13.474,12,26.948,12S40.424,2.5,53.9,2.5s13.476,12,26.952,12,13.478-12,26.955-12,13.478,12,26.955,12,13.477-12,26.955-12,13.478,12,26.956,12,13.48-12,26.96-12,13.48,12,26.96,12"
          data-name="Path 46054"
          fill="none"
          id="Path_46054"
          stroke="currentColor"
          stroke-width="5"
          transform="translate(0 -2.5)"
        />
      </g>
    </>
  ),
  'zig-zag-horizontal-large': (
    <>
      <path
        d="M50.283,276.957,0,226.671l3.535-3.535,46.748,46.751L98.8,221.368l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751-48.523,48.519L98.8,228.438Z"
        data-name="Path 46055"
        fill="currentColor"
        id="Path_46055"
      />
      <path
        d="M50.283,256.833,0,206.547l3.535-3.535,46.748,46.751L98.8,201.244l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751-48.523,48.519L98.8,208.314Z"
        data-name="Path 46056"
        fill="currentColor"
        id="Path_46056"
      />
      <path
        d="M50.283,236.709,0,186.423l3.535-3.535,46.748,46.751L98.8,181.12l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535L195.839,188.19l-48.523,48.519L98.8,188.19Z"
        data-name="Path 46057"
        fill="currentColor"
        id="Path_46057"
      />
      <path
        d="M50.283,216.584,0,166.3l3.535-3.535,46.748,46.751L98.8,160.995l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751-48.523,48.519L98.8,168.065Z"
        data-name="Path 46058"
        fill="currentColor"
        id="Path_46058"
      />
      <path
        d="M50.283,196.46,0,146.174l3.535-3.535L50.283,189.39,98.8,140.871l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751L147.316,196.46,98.8,147.941Z"
        data-name="Path 46059"
        fill="currentColor"
        id="Path_46059"
      />
      <path
        d="M50.283,176.335,0,126.049l3.535-3.535,46.748,46.751L98.8,120.746l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751-48.523,48.519L98.8,127.816Z"
        data-name="Path 46060"
        fill="currentColor"
        id="Path_46060"
      />
      <path
        d="M50.283,156.211,0,105.925l3.535-3.535,46.748,46.751L98.8,100.622l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535-46.755-46.751-48.523,48.519L98.8,107.692Z"
        data-name="Path 46061"
        fill="currentColor"
        id="Path_46061"
      />
      <path
        d="M50.283,136.088,0,85.8l3.535-3.535,46.748,46.751L98.8,80.5l48.516,48.52L195.839,80.5l50.29,50.287-3.535,3.535L195.839,87.567l-48.523,48.52L98.8,87.568Z"
        data-name="Path 46062"
        fill="currentColor"
        id="Path_46062"
      />
      <path
        d="M50.283,115.962,0,65.676l3.535-3.535,46.748,46.751L98.8,60.373l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535L195.839,67.443l-48.523,48.519L98.8,67.443Z"
        data-name="Path 46063"
        fill="currentColor"
        id="Path_46063"
      />
      <path
        d="M50.283,95.838,0,45.552l3.535-3.535L50.283,88.768,98.8,40.249l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535L195.839,47.319,147.316,95.838,98.8,47.319Z"
        data-name="Path 46064"
        fill="currentColor"
        id="Path_46064"
      />
      <path
        d="M50.283,75.713,0,25.427l3.535-3.535L50.283,68.643,98.8,20.124l48.516,48.519,48.523-48.519,50.29,50.286-3.535,3.535L195.839,27.194,147.316,75.713,98.8,27.194Z"
        data-name="Path 46065"
        fill="currentColor"
        id="Path_46065"
      />
      <path
        d="M50.283,55.589,0,5.3,3.535,1.768,50.283,48.519,98.8,0l48.516,48.519L195.839,0l50.29,50.286-3.535,3.535L195.839,7.07,147.316,55.589,98.8,7.07Z"
        data-name="Path 46066"
        fill="currentColor"
        id="Path_46066"
      />
    </>
  ),
  'zig-zag-vertical-large': (
    <>
      <path
        d="M5.3,246.129l-3.535-3.535,46.751-46.755L0,147.316,48.519,98.8,0,50.283,50.286,0l3.535,3.535L7.07,50.283,55.589,98.8,7.07,147.316l48.519,48.523Z"
        data-name="Path 46067"
        fill="currentColor"
        id="Path_46067"
      />
      <path
        d="M25.427,246.129l-3.535-3.535,46.751-46.755L20.124,147.316,68.643,98.8,20.124,50.283,70.41,0l3.535,3.535L27.194,50.283,75.713,98.8,27.194,147.316l48.519,48.523Z"
        data-name="Path 46068"
        fill="currentColor"
        id="Path_46068"
      />
      <path
        d="M45.552,246.129l-3.535-3.535,46.751-46.755L40.249,147.316,88.768,98.8,40.249,50.283,90.535,0,94.07,3.535,47.319,50.283,95.838,98.8,47.319,147.316l48.519,48.523Z"
        data-name="Path 46069"
        fill="currentColor"
        id="Path_46069"
      />
      <path
        d="M65.676,246.129l-3.535-3.535,46.751-46.755L60.373,147.316,108.892,98.8,60.373,50.283,110.659,0l3.535,3.535L67.443,50.283,115.962,98.8,67.443,147.316l48.519,48.523Z"
        data-name="Path 46070"
        fill="currentColor"
        id="Path_46070"
      />
      <path
        d="M85.8,246.129l-3.535-3.535,46.752-46.755L80.5,147.316,129.017,98.8,80.5,50.283,130.784,0l3.535,3.535L87.567,50.283,136.087,98.8l-48.52,48.515,48.52,48.523Z"
        data-name="Path 46071"
        fill="currentColor"
        id="Path_46071"
      />
      <path
        d="M105.925,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L149.141,98.8,100.622,50.283,150.908,0l3.535,3.535L107.692,50.283,156.211,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46072"
        fill="currentColor"
        id="Path_46072"
      />
      <path
        d="M126.049,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L169.265,98.8,120.746,50.283,171.032,0l3.535,3.535L127.816,50.283,176.335,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46073"
        fill="currentColor"
        id="Path_46073"
      />
      <path
        d="M146.174,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L189.39,98.8,140.871,50.283,191.157,0l3.535,3.535L147.941,50.283,196.46,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46074"
        fill="currentColor"
        id="Path_46074"
      />
      <path
        d="M166.3,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L209.514,98.8,160.995,50.283,211.281,0l3.535,3.535L168.065,50.283,216.584,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46075"
        fill="currentColor"
        id="Path_46075"
      />
      <path
        d="M186.423,246.129l-3.535-3.535,46.751-46.755L181.12,147.316,229.639,98.8,181.12,50.283,231.406,0l3.535,3.535L188.19,50.283,236.709,98.8,188.19,147.316l48.519,48.523Z"
        data-name="Path 46076"
        fill="currentColor"
        id="Path_46076"
      />
      <path
        d="M206.547,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L249.763,98.8,201.244,50.283,251.53,0l3.535,3.535L208.314,50.283,256.833,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46077"
        fill="currentColor"
        id="Path_46077"
      />
      <path
        d="M226.671,246.129l-3.535-3.535,46.751-46.755-48.519-48.523L269.887,98.8,221.368,50.283,271.654,0l3.535,3.535L228.438,50.283,276.957,98.8l-48.519,48.515,48.519,48.523Z"
        data-name="Path 46078"
        fill="currentColor"
        id="Path_46078"
      />
    </>
  ),
  'zig-zag-horizontal-small': (
    <>
      <path
        d="M49.468,246.777,0,226.385l1.906-4.623,47.562,19.607,48.518-20,48.516,20,48.522-20L244.5,241.762l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46079"
        fill="currentColor"
        id="Path_46079"
      />
      <path
        d="M49.468,226.653,0,206.261l1.906-4.623,47.562,19.607,48.518-20,48.516,20,48.522-20L244.5,221.638l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46080"
        fill="currentColor"
        id="Path_46080"
      />
      <path
        d="M49.468,206.528,0,186.136l1.906-4.623L49.468,201.12l48.518-20,48.516,20,48.522-20L244.5,201.513l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46081"
        fill="currentColor"
        id="Path_46081"
      />
      <path
        d="M49.468,186.4,0,166.012l1.906-4.623L49.468,181l48.518-20L146.5,181l48.522-20L244.5,181.389l-1.906,4.623L195.023,166.4l-48.522,20-48.516-20Z"
        data-name="Path 46082"
        fill="currentColor"
        id="Path_46082"
      />
      <path
        d="M49.468,166.279,0,145.887l1.906-4.623,47.562,19.607,48.518-20,48.516,20,48.522-20L244.5,161.264l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46083"
        fill="currentColor"
        id="Path_46083"
      />
      <path
        d="M49.468,146.155,0,125.763l1.906-4.623,47.562,19.607,48.518-20,48.516,20,48.522-20L244.5,141.14l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46084"
        fill="currentColor"
        id="Path_46084"
      />
      <path
        d="M49.468,126.03,0,105.638l1.906-4.623,47.562,19.607,48.518-20,48.516,20,48.522-20L244.5,121.015l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46085"
        fill="currentColor"
        id="Path_46085"
      />
      <path
        d="M49.468,105.906,0,85.514l1.906-4.623L49.468,100.5l48.518-20,48.516,20,48.522-20L244.5,100.891l-1.906,4.623-47.57-19.608-48.522,20-48.516-20Z"
        data-name="Path 46086"
        fill="currentColor"
        id="Path_46086"
      />
      <path
        d="M49.468,85.782,0,65.39l1.906-4.623L49.468,80.374l48.518-20,48.516,20,48.522-20L244.5,80.767l-1.906,4.623-47.57-19.607-48.522,20-48.516-20Z"
        data-name="Path 46087"
        fill="currentColor"
        id="Path_46087"
      />
      <path
        d="M49.468,65.657,0,45.265l1.906-4.623L49.468,60.249l48.518-20,48.516,20,48.522-20L244.5,60.642l-1.906,4.623-47.57-19.607-48.522,20-48.516-20Z"
        data-name="Path 46088"
        fill="currentColor"
        id="Path_46088"
      />
      <path
        d="M49.468,45.533,0,25.141l1.906-4.623L49.468,40.125l48.518-20,48.516,20,48.522-20L244.5,40.518l-1.906,4.623-47.57-19.607-48.522,20-48.516-20Z"
        data-name="Path 46089"
        fill="currentColor"
        id="Path_46089"
      />
      <path
        d="M49.468,25.408,0,5.016,1.906.393,49.468,20,97.985,0,146.5,20,195.023,0,244.5,20.393l-1.906,4.623L195.023,5.408l-48.522,20-48.516-20Z"
        data-name="Path 46090"
        fill="currentColor"
        id="Path_46090"
      />
    </>
  ),
  'zig-zag-vertical-small': (
    <>
      <path
        d="M5.016,244.5.393,242.594,20,195.024,0,146.5,20,97.986,0,49.469,20.393,0l4.623,1.906L5.408,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46091"
        fill="currentColor"
        id="Path_46091"
      />
      <path
        d="M25.14,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L40.517,0,45.14,1.906,25.532,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46092"
        fill="currentColor"
        id="Path_46092"
      />
      <path
        d="M45.265,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L60.642,0l4.623,1.906L45.657,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46093"
        fill="currentColor"
        id="Path_46093"
      />
      <path
        d="M65.389,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L80.766,0l4.623,1.906L65.781,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46094"
        fill="currentColor"
        id="Path_46094"
      />
      <path
        d="M85.514,244.5l-4.623-1.906,19.607-47.57L80.5,146.5l20-48.516-20-48.517L100.891,0l4.623,1.906L85.906,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46095"
        fill="currentColor"
        id="Path_46095"
      />
      <path
        d="M105.638,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L121.015,0l4.623,1.906L106.03,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46096"
        fill="currentColor"
        id="Path_46096"
      />
      <path
        d="M125.762,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L141.139,0l4.623,1.906L126.154,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46097"
        fill="currentColor"
        id="Path_46097"
      />
      <path
        d="M145.887,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L161.264,0l4.623,1.906L146.279,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46098"
        fill="currentColor"
        id="Path_46098"
      />
      <path
        d="M166.011,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L181.388,0l4.623,1.906L166.4,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46099"
        fill="currentColor"
        id="Path_46099"
      />
      <path
        d="M186.136,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L201.513,0l4.623,1.906L186.528,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46100"
        fill="currentColor"
        id="Path_46100"
      />
      <path
        d="M206.26,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L221.637,0l4.623,1.906L206.652,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46101"
        fill="currentColor"
        id="Path_46101"
      />
      <path
        d="M226.385,244.5l-4.623-1.906,19.607-47.57-20-48.522,20-48.516-20-48.517L241.762,0l4.623,1.906L226.777,49.469l20,48.517-20,48.516,20,48.522Z"
        data-name="Path 46102"
        fill="currentColor"
        id="Path_46102"
      />
    </>
  ),
};
