import { CircularProgressbar } from 'react-circular-progressbar';

import { ProgressCircleProps } from './ProgressCircle.interface';

import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = ({ percentageCompleted, size = 'md' }: ProgressCircleProps) => {
  const sizeMap = {
    sm: '3rem',
    md: '4.5rem',
  };

  return (
    <CircularProgressbar
      styles={{
        root: {
          backgroundColor: percentageCompleted === 100 ? 'var(--color-primary)' : 'var(--g-color-white)',
          borderRadius: '50%',
          overflow: 'visible',
          maxHeight: sizeMap[size],
          maxWidth: sizeMap[size],

          width: '100%',
        },
        path: {
          stroke: percentageCompleted === 100 ? 'var(--color-primary)' : 'var(--color-accent)',
          strokeWidth: 13,
        },
        trail: {
          stroke: percentageCompleted === 0 ? 'var(--grey-500)' : 'var(--grey-100)',
          strokeWidth: 12,
        },
        text: {
          fill: percentageCompleted === 100 ? 'var(--g-color-white)' : '#1B1B46',
          fontFamily: 'var(--font-header)',
          fontSize: '1.4rem',
          fontWeight: '800',
        },
      }}
      text={`${Number(percentageCompleted)}%`}
      value={Number(percentageCompleted)}
    />
  );
};

export { ProgressCircle };
