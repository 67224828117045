import { IReactChildren } from '@netfront/common-library';
import NextLink from 'next/link';

import { UserAvatar } from '../UserAvatar';

import { UserDetailsHeaderProps } from './UserDetailsHeader.interfaces';

export const UserDetailsHeader = ({
  additionalTitleContent,
  avatar,
  description,
  displayName,
  href,
  isModerator = false,
  isOnline = false,
  size,
}: UserDetailsHeaderProps) => {
  const Component = ({ children }: IReactChildren) =>
    href ? (
      <NextLink href={href}>
        <span aria-label="User details" className="c-user-details-header">
          {children}
        </span>
      </NextLink>
    ) : (
      <div aria-label="User details" className="c-user-details-header">
        {children}
      </div>
    );

  return (
    <>
      <Component>
        <div className="c-user-details-header__avatar">
          <UserAvatar avatar={avatar} displayName={displayName} isModerator={isModerator} isOnline={isOnline} size={size} />
        </div>
      </Component>
      <div className="c-user-details-header__details">
        <div>
          <span className="c-user-details-header__display-name">{displayName}</span>
          {additionalTitleContent}
        </div>
        {description && <span className="c-user-details-header__description">{description}</span>}
      </div>
    </>
  );
};
