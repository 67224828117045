import { useEffect, useState } from 'react';

import {
  CMSContentPage,
  IDBContentPage,
  IUseContentPageOnCompletedResponse,
  IUseGetAnswersOnCompletedResponse,
  IUseStartUserFlowStepUsingUrlsOnCompletedResponse,
  useContentPage,
  useContentPageContext,
  useGetAnswers,
  useTracking,
} from '@netfront/ekardo-content-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Skeleton, Button, SingleFormPage } from 'components';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { useCurrentAgency, useProtectedRoute, useToast } from '../../../hooks';

const DeclineConsentPage = () => {
  const { isAuthenticated } = useProtectedRoute();
  const { push } = useRouter();
  const { handleToastError, handleToastCustomError, handleToastSuccess } = useToast();
  const { dispatch } = useContentPageContext();

  const { handleLogout } = useLogout();
  const { removeAgency } = useCurrentAgency();
  const { getDomain } = useDomain();

  const [contentPage, setContentPage] = useState<IDBContentPage>();
  const [userFlowStepTrackId, setUserFlowStepTrackId] = useState<number>();

  const onSubmitRequest = () => {
    handleGetAnswers({
      userFlowStepTrackId: Number(userFlowStepTrackId),
    });
  };

  const handleStartUserFlowStepUsingUrlsCompleted = (data: IUseStartUserFlowStepUsingUrlsOnCompletedResponse) => {
    const { userFlowStepTrackId: returnedUserFlowStepTrackId } = data;

    setUserFlowStepTrackId(returnedUserFlowStepTrackId);

    handleGetContentPage({
      contentPageId: 124723,
      userFlowStepTrackId: returnedUserFlowStepTrackId,
      withAnswers: true,
    });

    handleEndUserFlowStep({
      userFlowStepTrackId: returnedUserFlowStepTrackId,
    });
  };

  const handleGetContentPageCompleted = (data?: IUseContentPageOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { contentPage: returnedContentPage } = data;
    setContentPage(returnedContentPage);
  };

  const handleGetAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { answers } = data;

    const declineResponses = answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === 24609)?.selectedAnswers;

    if (isEmpty(declineResponses)) {
      handleToastCustomError({
        message: 'Please select at least one reason for declining to participate in the study',
      });
      return;
    }

    handleToastSuccess({
      message: 'You have successfully declined from particpating in the study',
    });

    removeAgency();
    handleLogout({ domain: getDomain() });

    push('/').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const { handleGetContentPage } = useContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetContentPageCompleted,
  });

  const { handleGetAnswers } = useGetAnswers({
    onCompleted: handleGetAnswersCompleted,
  });

  const { handleStartUserFlowStepUsingUrls, handleEndUserFlowStep } = useTracking({
    onStartUserFlowStepUsingUrlsCompleted: handleStartUserFlowStepUsingUrlsCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleStartUserFlowStepUsingUrls({
      userFlowUrl: 'dashboard',
      userFlowStepUrl: 'withdrawal',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!contentPage) {
      return;
    }

    dispatch({
      payload: {
        contentPage: contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  useEffect(() => {
    if (!userFlowStepTrackId) {
      return;
    }
  }, [userFlowStepTrackId]);

  return (
    <SingleFormPage title="Withdraw from the study" hasPrivateLayout>
      <h1 className="color-accent h4 mb-2 weight-800">Confirmation</h1>
      <h2 className="color-blue-900 h2 mb-10">Decline to participate in the study</h2>

      {contentPage && userFlowStepTrackId ? (
        <>
          <CMSContentPage contentPage={contentPage} userFlowStepTrackId={userFlowStepTrackId} />
          <div className="text-center">
            <Button theme="pink" onPress={onSubmitRequest}>
              I decline to participate in the study
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="ek-section">
            <Skeleton borderRadius="15px" height="8rem" width="100%" />
          </div>
          <div className="ek-section">
            <Skeleton borderRadius="15px" height="16rem" width="100%" />
          </div>
          <div className="ek-section">
            <Skeleton borderRadius="15px" height="4rem" width="100%" />
          </div>
        </>
      )}
    </SingleFormPage>
  );
};

export { DeclineConsentPage };
