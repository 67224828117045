import { Button } from '@netfront/ui-library';

import { useGetRegisterUrl } from '../../hooks';

const CTASection = () => {
  const { registerUrl: accessTheProgramUrl } = useGetRegisterUrl();

  return (
    <section className="bg-white py-24 relative before:cta-circle">
      <div className="container relative">
        <h2 className="mb-6">Get started</h2>
        <p className="mb-4">To access our full range of resources, register for a confidential account. <br />Here&apos;s what you can expect:</p>
        <ul className='list-disc ml-4 mb-8'>
          <li>Anonymous access: Gain full access to all features without sharing your personal details</li>
          <li>Personalisation: Gain insights and personalised feedback direct from your dashboard</li>
          <li>Accessible: Access the portal on any device, including desktop, tablet and mobile</li>
        </ul>
        <Button linkButton={{ id: 'register', url: accessTheProgramUrl }} text="Register now" variant="cta" />
      </div>
      {/* <div className="bg-yellow-200 max-w-full absolute rounded-circle" role="presentation" /> */}
    </section>
  );
};

export { CTASection };
