import NextLink from 'next/link';

import { FOOTER_MENU_ITEMS_PRIVATE } from '../Footer.constants';

import { getValidClassNames } from '../../../utils';
import { Link } from '../../Link';
import { LogoLink } from '../../LogoLink';

const PublicFooter = () => {
  return (
    <footer className="relative bg-white mt-auto no-print py-16 border-b-10 border-primary">
      <div className="container-lg flex flex-col-reverse lg:items-center lg:flex-row items-center">
        <div className="w-full lg:w-1/3 flex flex-col items-center lg:items-start">
          <div className="mb-6">
            <LogoLink size="md" />
          </div>

          <p className="block text-sm mb-2">
            STABLE GROUND is a trade mark of The University of Sydney, <br />
            Stable Ground website &copy; {new Date().getFullYear()} The University of Sydney, <br />
            All rights reserved. 
          </p>
          <span className="text-sm">
            Crafted by{' '}
            <NextLink href="https://netfront.com.au/" target="_blank">
              <span className='color-primary weight-600'>Netfront</span>
            </NextLink>
          </span>
          <p>
            The STABLE GROUND™ website and resources have been developed as part of an ongoing research study. They are not intended for commercial use. For more information please see About, Contact, FAQ and our Terms &amp; Conditions
          </p>
        </div>

        <div className="w-full lg:w-1/3 flex items-center justify-center mb-8 lg:mb-0 px-6">
          <div className="flex flex-wrap lg:flex-row -mx-8">
            <div className="items-center flex justify-center px-8 mb-8 lg:mb-0 w-full">
              <Link className="" href="https://www.sydney.edu.au/matilda-centre/" rel="noreferrer" target="_blank">
                <span className="sr-only">Link to University of Sydney website</span>
                <img
                  alt="University of Sydney logo"
                  className="w-full max-w-48"
                  loading="lazy"
                  src="/images/logo-university-of-sydney-black.png"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/3 flex flex-col lg:flex-row mb-8 lg:mb-0 lg:justify-end">
          {FOOTER_MENU_ITEMS_PRIVATE.map(({ title, items }, menuIndex) => (
            <nav
              key={`footermenu-${String(title)}`}
              aria-label="Primary footer navigation"
              className={getValidClassNames({
                'lg:mb-0 text-center lg:text-left': true,
                'mb-8 lg:mr-12': menuIndex < items.length - 1,
              })}
            >
              <h4 className="font-header mb-6">{title}</h4>
              <ul className="m-0">
                {items.map(({ href, id, label }, index) => (
                  <li key={`${id}-${label}`} className="font-body ml-0">
                    <Link
                      className={getValidClassNames({
                        'block color-primary text-sm text-underline': true,
                        'mb-3': index < items.length,
                      })}
                      href={String(href)}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          ))}
        </div>
      </div>
    </footer>
  );
};

export { PublicFooter };
