import { EAgency } from 'hooks';

const SMART_CODE_NOT_FOUND = "The code that you provided is invalid.";
const USER_NOT_LINKED_TO_ANY_AGENCY = "Your account isn't linked to any agency. Please contact the support.";

const AGENCY_OPTIONS = [
  {
    type: EAgency.ambulance,
    label: 'Ambulance',
    activeImage: '/images/ambulance-icon-active.svg',
    inactiveImage: '/images/ambulance-icon-inactive.svg',
  },
  // {
  //   type: EAgency.police,
  //   label: 'Police',
  //   activeImage: '/images/police-icon-active.svg',
  //   inactiveImage: '/images/police-icon-inactive.svg',
  // },
  // {
  //   type: EAgency.fire,
  //   label: 'Fire & Rescue',
  //   activeImage: '/images/fire-rescue-icon-active.svg',
  //   inactiveImage: '/images/fire-rescue-icon-inactive.svg',
  // },
  {
    type: EAgency.communities,
    label: 'Corrective services',
    activeImage: '/images/community-justice-active.svg',
    inactiveImage: '/images/community-justice-inactive.svg',
  },
];

const policeGroup = Number(process.env.REACT_APP_POLICE_GROUP);
const ambulance = Number(process.env.REACT_APP_AMBULANCE_GROUP);
const fireAndRescue = Number(process.env.REACT_APP_FIRE_AND_RESCUE);
const communityAndJustice = Number(process.env.REACT_APP_COMMUNITY_AND_JUSTICE);

const AGENCIES_MAPPING = [
  { type: EAgency.police, id: policeGroup },
  { type: EAgency.ambulance, id: ambulance },
  { type: EAgency.fire, id: fireAndRescue },
  { type: EAgency.communities, id: communityAndJustice },
];

export { SMART_CODE_NOT_FOUND, AGENCY_OPTIONS, AGENCIES_MAPPING, USER_NOT_LINKED_TO_ANY_AGENCY };
