import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_MAIL_TO_CURRENT_USER_MUTATION } from './useSendMailToTheCurrentUser.graphql';
import {
  IHandleSendMailToTheCurrentUserParams,
  ISendMailToTheCurrentUserMutationGraphQLResponse,
  ISendMailToTheCurrentUserMutationVariables,
  IUseSendMailToTheCurrentUserOptions,
  IUseSendMailToTheCurrentUser,
} from './useSendMailToTheCurrentUser.interfaces';

const useSendMailToTheCurrentUser = (options?: IUseSendMailToTheCurrentUserOptions): IUseSendMailToTheCurrentUser => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSendMailToTheCurrentUserOptions);

  const [executeSendMailToTheCurrentUser, { loading: isLoading }] = useHowlerMutation<
    ISendMailToTheCurrentUserMutationGraphQLResponse,
    ISendMailToTheCurrentUserMutationVariables
  >({
    mutation: mutation ?? SEND_MAIL_TO_CURRENT_USER_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { sendMailToCurrentUser: isSuccess },
        } = data;

        onCompleted({
          result: isSuccess,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSendMailToTheCurrentUser = async ({ subject, templateId, date }: IHandleSendMailToTheCurrentUserParams) => {
    await executeSendMailToTheCurrentUser({
      variables: {
        date,
        subject,
        templateId,
      },
    });
  };

  return {
    handleSendMailToTheCurrentUser,
    isLoading,
  };
};

export { useSendMailToTheCurrentUser };
