import { BreadcrumbHomeLink, Page } from '../../../components';

const ToolboxPage = () => {
  const PAGE_TITLE = 'Toolbox';

  return (
    <Page
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/dashboard" />, key: 'home' },
          { content: PAGE_TITLE, key: PAGE_TITLE },
        ],
      }}
      meta={{ seoDescription: 'Toolbox', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    ></Page>
  );
};

export { ToolboxPage };
