import { Skeleton , SelfAssessmentIndicator } from 'components';
import { format, getYear } from 'date-fns';

import { LatestSelfAssessmentResultCardProps } from './LatestSelfAssessmentResultCard.interfaces';


export const LatestSelfAssessmentResultCard = ({ date, indicator, subTitle, title }: LatestSelfAssessmentResultCardProps) => {
  return (
    <div className="bg-white p-8 border-2 border-athens rounded-lg h-full">
      <div className="flex items-start">
        <div className="flex-1 mr-4">
          <p className="weight-600 color-secondary mb-2">{subTitle}</p>
          {title ? (
            <p className="h5 weight-800 color-secondary mb-10">{title}</p>
          ) : (
            <div className="mb-6">
              <Skeleton height="1.5rem" width="5rem" />
            </div>
          )}
        </div>
        <span className="color-primary weight-800 block">
          {' '}
          {date ? `${format(new Date(date), 'do')} of ${format(new Date(date), 'MMMM')} ${getYear(new Date(date))}` : null}
        </span>
      </div>
      <SelfAssessmentIndicator {...indicator} />
    </div>
  );
};
