import { gql } from '@apollo/client';

const GET_ANNOUNCE_QUERY = gql`
  query getAnnounce($projectId: String!, $location: String!) {
    announce {
      byLocation(projectId: $projectId, location: $location) {
        end
        id
        isActive
        start
        content
        location
        title
      }
    }
  }
`;

export { GET_ANNOUNCE_QUERY };
