import { useEffect, useState } from 'react';

import { Button } from '@netfront/ui-library';
import cx from 'classnames';
import { useGetAnnounce } from 'hooks';
import { IDBAnnounce } from 'interfaces';
import Modal from 'react-modal';

import { IAnnounceProps } from './Announce.interfaces';

export const Announce = ({ location }: IAnnounceProps) => {
  const COOKIE_VALUE = 'announce';

  const [announce, setAnnounce] = useState<IDBAnnounce>();
  const { handleGetAnnounce } = useGetAnnounce({
    onCompleted(data) {
      const { announce: loadedAnnounce } = data;
      setAnnounce(loadedAnnounce);

      const cookieValue = localStorage.getItem(COOKIE_VALUE);
      if (!cookieValue) {
        localStorage.setItem(COOKIE_VALUE, JSON.stringify({ [location]: new Date() }));
      } else {
        const values = JSON.parse(cookieValue) as unknown as { location: Date };
        values[location] = new Date();
        localStorage.setItem(COOKIE_VALUE, JSON.stringify(values));
      }
    },
  });

  useEffect(() => {
    const cookieValue = localStorage.getItem(COOKIE_VALUE);
    if (cookieValue) {
      const values = JSON.parse(cookieValue) as unknown as { location: Date };
      const value = values[location] as string;
      const lastVisitedLocation = new Date(value);
      lastVisitedLocation.setHours(lastVisitedLocation.getHours() + 1);
      if (new Date() <= lastVisitedLocation) {
        return;
      }
    }

    handleGetAnnounce({
      location,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      {announce && (
        <Modal
          bodyOpenClassName="h-no-scroll"
          className={cx('c-dialog')}
          isOpen={true}
          overlayClassName="c-dialog-overlay"
          onRequestClose={() => {
            setAnnounce(undefined);
          }}
        >
          <div className="w-11/12 max-w-176 flex items-center justify-end mb-4">
            <Button size='xs' text="X" variant='secondary' onClick={() => setAnnounce(undefined)} />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: announce.content,
            }}
          />
          <div className="w-11/12 max-w-176 flex items-center justify-center mt-10">
            <Button text="Close" onClick={() => setAnnounce(undefined)} />
          </div>
        </Modal>
      )}
    </>
  );
};
