import { useState } from 'react';

import { useRouter } from 'next/router';

import { Button, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const PAGE_TITLE = 'Important note';

const ImportantNotePage = () => {
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [isRedirectLoading, setIsRedirectloading] = useState<boolean>(false);

  const handleRedirect = () => {
    setIsRedirectloading(true);

    setTimeout(() => {
      push('/consent/introduction').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    }, 3000);
  };

  return (
    <SingleFormPage title={PAGE_TITLE}>
      <div className="bg-white p-4 mb-4">
        <h1 className="color-accent h4 mb-2 weight-800">Thank you</h1>
        <h2 className="color-blue-700 h2 mb-0">Important note</h2>
      </div>

      <div className="ek-section">
        <p>
        
        </p>
      </div>
      <div className="text-center">
        <Button isLoading={isRedirectLoading} theme="green" onPress={handleRedirect}>
          Get started
        </Button>
      </div>
    </SingleFormPage>
  );
};

export { ImportantNotePage };
