import uuid4 from 'uuid4';

import { EPostFeeling } from '../../../../services';

export const BASE_POLL_ITEMS = [
  { id: uuid4(), title: '' },
  { id: uuid4(), title: '' },
];

export const POLL_TITLE_ERROR_MESSAGE = 'A poll title is required';

export const POLL_ITEM_MINIMUM_ERROR_MESSAGE = 'A poll requires at least 2 response options';

export const POST_FEELINGS_OPTIONS = [
  { name: 'Grateful', emoji: '🙏', type: EPostFeeling.Grateful },
  { name: 'Inspired', emoji: '🤩', type: EPostFeeling.Inspired },
  { name: 'Strong', emoji: '😎', type: EPostFeeling.Strong },
  { name: 'Happy', emoji: '😊', type: EPostFeeling.Happy },
  { name: 'Tired', emoji: '😴', type: EPostFeeling.Tired },
  { name: 'Lonely', emoji: '😟', type: EPostFeeling.Lonely },
  { name: 'Anxious', emoji: '😰', type: EPostFeeling.Anxious },
  { name: 'Angry', emoji: '😠', type: EPostFeeling.Angry },
  { name: 'Sad', emoji: '🙁', type: EPostFeeling.Sad },
  { name: 'Afraid', emoji: '😨', type: EPostFeeling.Afraid },
];
