import { useEffect, useState } from 'react';

import { getAccessTokenCookie, getTemporaryTokenCookie } from '@netfront/common-library';
import { useGetUserFlowStepTracksWithAnswers } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { BreadcrumbLink, InformationBanner, Page, SelfAssessmentFeedback, SelfAssessmentFeedbackFollowup } from '../../../components';
import { useGetRootBreadcrumbItems, useHasPrivateLayout } from '../../../hooks';

const SelfAssessmentResultsPage = () => {
  const PAGE_TITLE = 'Results';

  const {
    asPath,
    query: { trackingId: trackingIdParam, version },
  } = useRouter();
  const accessToken = getAccessTokenCookie();
  const { hasPrivateLayout } = useHasPrivateLayout();
  const temporaryToken = getTemporaryTokenCookie();

  const [lastestTrackingId, setLastestTrackingId] = useState<number>();
  const [previousTrackingId, setPreviousTrackingId] = useState<number>();

  const rootBreadcrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: Boolean(accessToken),
  });

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: ({ userFlowStepTracks }) => {
      const sortedCompletedTrackings = [...userFlowStepTracks].sort((a, b) => b.id - a.id);

      setLastestTrackingId(trackingIdParam ? Number(trackingIdParam) : Number(sortedCompletedTrackings[0].id));
      if (sortedCompletedTrackings.length > 1) {
        setPreviousTrackingId(sortedCompletedTrackings[1].id);
      }
    },
  });

  useEffect(() => {
    if (!temporaryToken && !accessToken) {
      return;
    }

    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['self-assessment'],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, temporaryToken, trackingIdParam]);

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadcrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      hasPrivateLayout={hasPrivateLayout && Boolean(accessToken)}
      meta={{ seoDescription: 'Self assessment', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      percentageSlider={{ percentage: 100 }}
      title={PAGE_TITLE}
    >
      <div className="container pb-12">
        <InformationBanner>
          <p>Review your self-assessment results.</p>
          <p className='mb-0'>You can also find this personalised feedback in the <strong>Recent results</strong> page under the <strong>Self-assessment</strong> tab above.</p>
        </InformationBanner>

        {version && version === '1' && lastestTrackingId ? (
          <SelfAssessmentFeedback trackingId={lastestTrackingId} shouldIncludeGoalCTA />
        ) : null}

        {version && version === '2' && lastestTrackingId && previousTrackingId ? (
          <SelfAssessmentFeedbackFollowup prevTrackingId={previousTrackingId} trackingId={lastestTrackingId} shouldIncludeGoalCTA />
        ) : null}

        {version ? null : lastestTrackingId ? (
          previousTrackingId ? (
            <SelfAssessmentFeedbackFollowup prevTrackingId={previousTrackingId} trackingId={lastestTrackingId} shouldIncludeGoalCTA />
          ) : (
            <SelfAssessmentFeedback trackingId={lastestTrackingId} shouldIncludeGoalCTA />
          )
        ) : null}
      </div>
    </Page>
  );
};

export { SelfAssessmentResultsPage };
