import { useBonoboMutation } from 'hooks/useBonoboMutation';

import { UPDATE_COMMUNITY_STATUS } from './useUpdateCommunityStatus.graphql';
import {
  IHandleUpdateStatusParams,
  IUpdateStatusMutationGraphQLResponse,
  IUpdateStatusMutationVariables,
  IUseUpdateStatusOptions,
  IUseUpdateStatus,
} from './useUpdateCommunityStatus.interfaces';

const useUpdateCommunityStatus = (options?: IUseUpdateStatusOptions): IUseUpdateStatus => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateStatusOptions);

  const [executeUpdateStatus, { loading: isLoading }] = useBonoboMutation<
  IUpdateStatusMutationGraphQLResponse,
  IUpdateStatusMutationVariables
  >({
    mutation: mutation ?? UPDATE_COMMUNITY_STATUS,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: {  updateStatus: isSuccess },
        } = data;

        onCompleted({
          result: isSuccess,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateStatus = async ({ communityId, status, userId }: IHandleUpdateStatusParams) => {
    await executeUpdateStatus({
      variables: {
        communityId,
        userId,
        status
      },
    });
  };

  return {
    handleUpdateStatus,
    isLoading,
  };
};

export { useUpdateCommunityStatus };
