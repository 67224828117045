import NextLink from 'next/link';

import { EAssistItem, EK10DistressCategory, ERiskCategory } from './SelfAssessmentResultsPage.interfaces';

import { isBetween } from '../../../utils';

export const getMaxKey = (o) =>
  Object.entries(o)
    .reverse()
    .reduce((r: [string, string], e: [string, string]) => (e[1] > r[1] ? e : r))[0];

export const getReadinessToChangeInterpretation = (value: number) => {
  if (isBetween(value, 0, 3)) {
    return {
      label: 'Not ready to change',
      description: 'You may be interested in considering some of the suggestions below.',
    };
  }

  if (isBetween(value, 4, 6)) {
    return {
      label: 'Uncertain',
      description:
        'It seems you are feeling uncertain about changing your alcohol or other drug use right now. We encourage you to consider some of our suggestions below.',
    };
  }

  if (isBetween(value, 7, 8)) {
    return {
      label: 'Trying to change',
      description:
        'It seems that you are feeling ready to change or you have made a change in your alcohol or other drug use. You may be interested and find some of the suggestions below helpful.',
    };
  }

  if (isBetween(value, 9, 10)) {
    return {
      label: 'I have made a change',
      description:
        'It seems that you are feeling ready to change or you have made a change in your alcohol or other drug use. You may be interested and find some of the suggestions below helpful.',
    };
  }
};

export const getK10DistressCategory = (score: number) => {
  if (isBetween(score, 10, 15)) {
    return EK10DistressCategory.low;
  }

  if (isBetween(score, 16, 21)) {
    return EK10DistressCategory.moderate;
  }

  if (isBetween(score, 22, 29)) {
    return EK10DistressCategory.high;
  }

  if (isBetween(score, 30, 50)) {
    return EK10DistressCategory.very;
  }
};

export const getScoreAndRiskText = (substance: EAssistItem, score: number, risk: ERiskCategory, isGPContext?: boolean) => {
  return (
    <p className="mb-0">
      {isGPContext ? 'Their' : 'Your'} score for {substance === EAssistItem.other ? 'other substances' : substance} {` `}
      was {score} out of 39, which places {isGPContext ? 'them' : 'you'} in the <strong>{risk} risk</strong> category.
    </p>
  );
};

export const getSingleSubstanceAssistFeedback = (substance: EAssistItem, score: number, risk: ERiskCategory) => {
  const assitItem = substance === EAssistItem.other ? 'other substances' : substance;

  if (risk === ERiskCategory.no) {
    return (
      <>
        <p>
          You indicated that you have not used alcohol or other drugs in the last three months, which places you in the{' '}
          <strong>no risk</strong> category.
        </p>
        <p>
          This means that your current pattern of alcohol or other drug use is <strong>not currently</strong> putting you at risk of
          experiencing problems in the physical health, mental health, relationship, work, financial, and legal areas of your life.
        </p>
        <p>
          However, it is important to note that this can change if you begin to drink alcohol or use other drugs. If you are struggling with
          your mental health, you might be more likely to engage in risky use of alcohol or other drugs.
        </p>
      </>
    );
  }

  if (risk === ERiskCategory.low) {
    return (
      <>
        <p>
          Your score for {assitItem} {` `}
          was {score} out of 39, which places you in the {risk} risk category.
        </p>
        <p>
          This means that your current pattern of {assitItem} use is putting you at <strong>low risk</strong> of experiencing problems in
          the physical health, mental health, relationship, work, financial and legal areas of your life.
        </p>
        <p>
          Your current pattern of {assitItem} use suggests it is unlikely you are experiencing dependence at the moment. However, it is
          important to know that anyone can develop tolerance and dependence on alcohol and other drugs, both physically and
          psychologically.
        </p>
      </>
    );
  }

  if (risk === ERiskCategory.moderate) {
    return (
      <>
        <p>
          Your score for {assitItem} {` `}
          was {score} out of 39, which places you in the {risk} risk category.
        </p>
        <p>
          This means that your current pattern of {assitItem} use is putting you at <strong>moderate risk</strong> of experiencing problems
          in the physical health, mental health, relationship, work, financial and legal areas of your life.
        </p>
        <p>
          Your current {assitItem} use indicates you are at risk of experiencing dependence. It is important to know that anyone can develop
          tolerance and dependence on alcohol and other drugs, both physically and psychologically.
        </p>
      </>
    );
  }

  if (risk === ERiskCategory.high) {
    return (
      <>
        <p>
          Your score for {assitItem} {` `}
          was {score} out of 39, which places you in the {risk} risk category.
        </p>
        <p>
          This means that your current pattern of {assitItem} use is putting you at <strong>high risk</strong> of experiencing severe
          problems. Your substance use could be having an impact on your physical and mental health, your living situation, your employment
          and your social relationships.
        </p>
        <p>
          You are also likely to currently be experiencing dependence. If you do choose to stop or reduce your {assitItem} use, it is strongly that
          you seek medical advice from a health practitioner first.
        </p>
      </>
    );
  }
};

export const getSubstanceRiskCategory = (substance: EAssistItem, score: number) => {
  // Smoking
  if (substance === EAssistItem.smoking) {
    return score === 0 ? ERiskCategory.no : ERiskCategory.high;
  }

  // Alcohol
  if (substance === EAssistItem.alcohol) {
    if (score === 0) {
      return ERiskCategory.no;
    }

    if (isBetween(score, 1, 10)) {
      return ERiskCategory.low;
    }

    if (isBetween(score, 11, 26)) {
      return ERiskCategory.moderate;
    }

    if (isBetween(score, 27, 39)) {
      return ERiskCategory.high;
    }
  }

  // All other sustances
  if (score === 0) {
    return ERiskCategory.no;
  }

  if (isBetween(score, 1, 3)) {
    return ERiskCategory.low;
  }

  if (isBetween(score, 4, 26)) {
    return ERiskCategory.moderate;
  }

  if (isBetween(score, 27, 39)) {
    return ERiskCategory.high;
  }
};

export const getMaximumRiskScoreFeedback = (risk: ERiskCategory) => {
  if (risk === ERiskCategory.low) {
    return (
      <>
        <p>
          Overall, this means that your current pattern of drug use is putting you at <strong>low risk</strong> of experiencing problems in
          the physical health, mental health, relationship, work, financial and legal areas of your life.
        </p>
        <p>
          Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances, it
          can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks. Learn
          more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.
        </p>
        <p>
          Your current pattern of alcohol or other drug use suggests it is unlikely you are experiencing dependence at the moment. However,
          it is important to know that anyone can develop tolerance and dependence on alcohol and other drugs, both physically and
          psychologically.
        </p>
      </>
    );
  }

  if (risk === ERiskCategory.moderate) {
    return (
      <>
        <p>
          Overall, this means that your current pattern of drug use is putting you at <strong>moderate risk</strong> of experiencing
          problems in the physical health, mental health, relationship, work, financial and legal areas of your life.
        </p>
        <p>
          Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances, it
          can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks. Learn
          more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.
        </p>
        <p>
          For at least one drugs you use, you are at risk of experiencing dependence. It is important to know that anyone can develop
          tolerance and dependence on drugs, both physically and psychologically.
        </p>
      </>
    );
  }

  if (risk === ERiskCategory.high) {
    return (
      <>
        <p>
          Overall, this means that for at least one of the drugs you use, your current pattern of use is putting you at{' '}
          <strong>high risk</strong> of experiencing severe problems. Your drug use could be having an impact on your physical and mental
          health, your living situation, your employment and your social relationships.
        </p>
        <p>
          Using more than one drug at the same time or in close proximity can be dangerous and unpredictable and, in some circumstances, it
          can be fatal. You may be at increased risk of experiencing an overdose, poisoning, loss of consciousness and heart attacks. Learn
          more about mixing drugs <NextLink href='/about-drugs'>here</NextLink>.
        </p>
        <p>
          You are also likely to currently be experiencing dependence on at least one of the drugs you use. If you do choose to stop or
          reduce your drug use, it is highly recommended you seek medical advice from a health practitioner first.
        </p>
      </>
    );
  }
};

export const getSingleSubstanceK10FeedBack = (category: EK10DistressCategory, supplementaryScore: number) => {
  if (category === EK10DistressCategory.low) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>low</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>low</strong> psychological distress, which appears to be impacting your work, study or daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.moderate) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>moderate</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>moderate</strong> psychological distress, which appears to be impacting your work, study or daily
        life.
      </p>
    );
  }

  if (category === EK10DistressCategory.high) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>high</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>high</strong> psychological distress, which appears to be impacting your work, study or daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.very) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>very high</strong> psychological distress, which could impact on your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>very high</strong> psychological distress, which appears to be impacting your work, study or daily
        life.
      </p>
    );
  }
};

export const getPolySubstanceK10FeedBack = (category: EK10DistressCategory, supplementaryScore: number) => {
  if (category === EK10DistressCategory.low) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>low</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>low</strong> psychological distress, which appears to be impacting your work, study or daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.moderate) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>moderate</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>moderate</strong> psychological distress, which appears to be impacting your work, study or daily
        life.
      </p>
    );
  }

  if (category === EK10DistressCategory.high) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>high</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>high</strong> psychological distress, which appears to be impacting your work, study or daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.very) {
    return supplementaryScore === 0 ? (
      <p>
        You may be experiencing <strong>very high</strong> psychological distress, which could impact on your work, study or daily life.
      </p>
    ) : (
      <p>
        You may be experiencing <strong>very high</strong> psychological distress, which appears to be impacting your work, study or daily
        life.
      </p>
    );
  }
};

export const getRecommendations = (category: ERiskCategory) => {
  if (category === ERiskCategory.no) {
    return (
      <>
        <h4 className="h6 color-secondary weight-800 mb-1">Create your own goals</h4>
        <p>
          Set or update some personal goals to help maintain any changes you have made to your alcohol or other drug use, mental health, or
          any other part of your life using our structured goal setting exercise.
        </p>
        <h4 className="h6 color-secondary weight-800 mb-1 mt-4">Learn more about alcohol and other drugs <NextLink href='/about-drugs'>here</NextLink></h4>
        <strong className="block mt-4 mb-2">There are also a range of other options available for you to consider:</strong>
        <ul className="list-style-disc">
          <li>Speak to your GP</li>
          <li>Connect with a peer support group</li>
          <li>Try our Steady online program</li>
        </ul>
      </>
    );
  }

  if (category === ERiskCategory.low) {
    return (
      <>
        <h4 className="h6 color-secondary weight-800 mb-1">Create your own goals</h4>
        <p>
          Set some personal goals for your alcohol or other drug use, mental health, or any other part of your life using our structured
          goal setting exercise.
        </p>
        <h4 className="h6 color-secondary weight-800 mb-1 mt-4">Learn more about alcohol and other drugs <NextLink href='/about-drugs'>here</NextLink></h4>

        <strong className="block mt-8 mb-2">There are also a range of other options available for you to consider:</strong>
        <ul className="list-style-disc">
          <li>Speak to your GP</li>
          <li>Connect with a peer support group</li>
          <li>Try our Steady online program</li>
        </ul>
      </>
    );
  }

  if (category === ERiskCategory.moderate) {
    return (
      <>
        <h4 className="h6 color-secondary weight-800 mb-1">Speak to your GP</h4>
        <p>
          GPs are often a good place to begin the conversation about issues impacting your health, including the use of alcohol and other
          drugs. Download and bring your personalised GP letter to your health practitioner. This letter contains all your results and can
          help start the conversation with your doctor around your drug use and mental health.
        </p>
        <h4 className="h6 color-secondary weight-800 mb-1 mt-4">Connect with a peer support group</h4>
        <p>
          Peer support involves people with shared experiences providing help and support to each other. This could involve sharing
          knowledge or providing emotional support with a community of people who have gone through similar things to you.
        </p>
        <p>
          Peer support can happen in person, in one-on-one or in a group settings, as well as online. Some workplaces have peer support
          networks for employees to reach out to. You can learn more about peer support here.
        </p>
        <p>
          Alternatively, Stable Ground also offers an <strong>online community forum</strong> where you can <strong>anonymously</strong>{' '}
          connect with other NSW Emergency Services employees. You can chat and share your thoughts, or even just read about other
          peoples&#39; experiences. <NextLink href='/community/communities/stable-ground'>Click here</NextLink> to access the Stable Ground Community.
        </p>
        <h4 className="h6 color-secondary weight-800 mb-1 mt-4">Try out our Steady online program</h4>
        <p>
          In this brief, four module series, you will be guided through interactive exercises that aim to enhance your problem-solving
          skills, and also learn some techniques to create new habits and routines. If you are feeling unsure about changing your alcohol or
          other drug use, these activities can help you explore your thoughts.
        </p>
        <strong className="block mt-8 mb-2">There are also a range of other options available for you to consider:</strong>
        <ul className="list-style-disc">
          <li>Create your own goals</li>
          <li>Learn more about alcohol and other drugs <NextLink href='/about-drugs'>here</NextLink></li>
        </ul>
      </>
    );
  }

  if (category === ERiskCategory.high) {
    return (
      <>
        <h4 className="h6 color-secondary weight-800 mb-1">Specialist referral</h4>
        <p>
          You may benefit from seeking out practitioners and services that specialise in helping people address their alcohol or other drug
          use. These include psychologists, psychiatrists, residential rehabilitation (“rehab”), medication-assisted treatment, withdrawal
          management and more.
        </p>
        <p>
        For more detailed information about the differences between the many treatment options available, and what you can expect from these services, view our <NextLink href='/support-options'>&#39;Support Options&#39;</NextLink> page.
        </p>
        <strong className="block mt-8 mb-2">There are also a range of other options available for you to consider:</strong>
        <ul className="list-style-disc">
          <li>Speak to your GP</li>
          <li>Connect with a peer support group</li>
          <li>Try our Steady online program</li>
        </ul>
      </>
    );
  }
};
