import { gql } from '@apollo/client';

const SEND_INTERNAL_NOTIFICATION_TO_CURRENT_USER_MUTATION = gql`
mutation sendInternalNotification($templateId: Int!, $date: DateTime) {
  notification {
    sendInternalNotificationToCurrentUser(
      date: $date
      templateId: $templateId
      projectId: "${process.env.REACT_APP_PROJECT_ID}"
    )
  }
}

`;

export { SEND_INTERNAL_NOTIFICATION_TO_CURRENT_USER_MUTATION };
