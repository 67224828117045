import { gql } from '@apollo/client';

const SEND_MAIL_TO_CURRENT_USER_MUTATION = gql`
mutation sendNotificationToCurrentUser(
  $templateId: Int!
  $subject: String!
  $date:DateTime
) {
  notification {
    sendMailToCurrentUser(
      date:$date
      templateId: $templateId
      subject: $subject
      projectId: "${process.env.REACT_APP_PROJECT_ID}"
      )
  }
}

`;

export { SEND_MAIL_TO_CURRENT_USER_MUTATION };
