import { ChangeEvent, useEffect, useState } from 'react';

import { createTemporaryTokenCookie } from '@netfront/common-library';
import { useDomain, useTemporaryToken } from '@netfront/gelada-identity-library';
import { Button, Dialog, Input } from '@netfront/ui-library';
import { EAgency, IGetSmartCodeOnCompletedResponse, useCurrentAgency, useGetSmartCode, useToast } from 'hooks';
import NextLink from 'next/link';
import { capitalizeFirstLetter } from 'utils';

import { AGENCIES_MAPPING, AGENCY_OPTIONS, SMART_CODE_NOT_FOUND } from './AgencySelectionSection.constants';
import { AgencySelectionSectionCard } from './AgencySelectionSectionCard';

const AgencySelectionSection = () => {
  const { getAgency, setAgency } = useCurrentAgency();
  const { handleToastSuccess } = useToast();
  const { isDomainReady, getDomain } = useDomain();

  const [code, setCode] = useState<string>('');
  const [selectedAgency, setSelectedAgency] = useState<EAgency>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [isProcessSucceed, setIsProcessSucceed] = useState<boolean>(false);

  const { handleGetTemporaryToken } = useTemporaryToken({
    onCompleted: ({ temporaryToken: returnTemporaryToken }) => {
      createTemporaryTokenCookie({
        value: returnTemporaryToken,
        optionalCookieAttributesInput: {
          domain: getDomain(),
        },
      });
    },
  });

  /** Check that the code entered by the user is a valid */
  const isCodeValid = code && code.length > 0;

  /** Callback from the api to check if the code is valid and linked to a valid agency */
  const onGetSmartCodeCompleted = (data: IGetSmartCodeOnCompletedResponse) => {
    setErrorMessage('');
    const { smartCode } = data;
    if (!smartCode) {
      setErrorMessage(SMART_CODE_NOT_FOUND);
      return;
    }

    const { groups } = smartCode;

    if (!groups) {
      setErrorMessage(SMART_CODE_NOT_FOUND);
      return;
    }

    const [firstGroup] = groups;

    if (!firstGroup) {
      setErrorMessage(SMART_CODE_NOT_FOUND);
      return;
    }

    const { id } = firstGroup;
    const chosenAgency = AGENCIES_MAPPING.find(g => g.type === selectedAgency);
    const agency = AGENCIES_MAPPING.find((r) => r.id === id && id === chosenAgency?.id );

    if (!agency) {
      setErrorMessage(SMART_CODE_NOT_FOUND);
      return;
    }

    setAgency(agency.type, code);
    setIsProcessSucceed(true);
    handleToastSuccess({ message: 'Agency linked! your results are now being personalised' });

    handleGetTemporaryToken()

    // setIsDialogOpen(false);
  };

  const { handleGetSmartCode, isLoading } = useGetSmartCode({
    onCompleted: onGetSmartCodeCompleted,
  });

  /** Fires when the user click on one of the agency
   *  Open the pop-up for the user to enter the code
   */
  const onAgencyClick = (type: EAgency) => {
    setSelectedAgency(type);
    setIsDialogOpen(true);
  };

  /** Fire when the user clicks on the Submit button in the pop-up */
  const onValidateAgencyCodeClick = () => {
    handleGetSmartCode({ code, projectId: String(process.env.REACT_APP_PROJECT_ID) });
  };

  /** Fires when the user changes the code input in the modal */
  const onValidateAgencyCodeChange = (props: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = props;

    setCode(value);
  };

  /** Verify that the user doesn't already have a cookie with the agency in */
  useEffect(() => {
    const agency = getAgency();
    if (agency) {
      setIsProcessSucceed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <>
      <Dialog
        additionalClassNames="w-11/12 max-w-176"
        isOpen={isDialogOpen}
        title={isProcessSucceed ? 'Thank you' : `Confirm the ${capitalizeFirstLetter(selectedAgency)} agency code`}
        onClose={() => setIsDialogOpen(false)}
      >
        {isProcessSucceed ? (
          <>
            <p>
              Thank you for putting in your agency code. You now have access to the Stable Ground self-assessment and fact sheets. For other
              features, please register for an account.
            </p>
            <div className="text-right mt-8">
              <Button text="Close" variant="secondary" onClick={() => setIsDialogOpen(false)} />
              <Button additionalClassNames="ml-2" linkButton={{ id: 'register', url: '/register' }} text="Register now" />
            </div>
          </>
        ) : (
          <>
            <p>Please enter the agency code that can be found on your staff portal. If you are having trouble finding this, please contact <NextLink href='mailto:info@stableground.org.au' target='_blank'>info@stableground.org.au</NextLink>.</p>
            <Input id="agency-code-input" labelText="Agency code" name="Agency code" type="text" onChange={onValidateAgencyCodeChange} />
            <div className="text-right mt-8">
              <Button isDisabled={!isCodeValid} isLoading={isLoading} text="Confirm" onClick={onValidateAgencyCodeClick} />
            </div>
          </>
        )}

        {errorMessage ? <p>{errorMessage}</p> : null}
      </Dialog>
      {!isProcessSucceed && (
        <div className="relative py-18" id="agencies">
          <div className="container container-sm text-center">
            <h2 className="mb-3">Select your agency</h2>
            <p className="h5 mb-8">
              Please read our <NextLink href='/terms-and-conditions'><span className="h5 mb-8">terms and conditions</span></NextLink> before using Stable Ground
            </p>

          </div>
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              {AGENCY_OPTIONS.map(({ activeImage, inactiveImage, label, type }) => (
                <AgencySelectionSectionCard
                  key={type}
                  activeImage={activeImage}
                  inactiveImage={inactiveImage}
                  label={label}
                  selectedAgency={selectedAgency}
                  type={type}
                  onAgencyClick={onAgencyClick}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { AgencySelectionSection };
