import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getParsedQuery, useCookie } from '@netfront/common-library';
import {
  IGetLoggedUserOnCompletedResponse,
  saveAuthenticationData,
  useGetLoggedUser,
} from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { useToast } from '../useToast';

import { IUseProtectedRoute } from './useProtectedRoute.interfaces';

const useProtectedRoute = (): IUseProtectedRoute => {
  const { getAccessTokenCookie } = useCookie();
  /**@todo Can use useAuthentication() in future projects.
   * Below removes temporary token from condition as users get a temporary token when visiting register 
   * */
  const isAuthenticated = Boolean(getAccessTokenCookie());

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [isAuthenticatedWithToken, setIsAuthenticatedWithToken] = useState<boolean>(false);

  const handleGetLoggedUserCompleted = ({ user }: IGetLoggedUserOnCompletedResponse) => {
    saveAuthenticationData({
      user,
    });

    setIsAuthenticatedWithToken(true);
  };

  const handleGetLoggedUserError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetLoggedUser } = useGetLoggedUser({
    onCompleted: handleGetLoggedUserCompleted,
    onError: handleGetLoggedUserError,
  });

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const {
      location: { href, pathname: requestedRoute },
    } = window;

    const { auth: token, authenticatedPrint } = getParsedQuery(href);

    if (authenticatedPrint === '1') {
      /*
       * The user data needs to be retrieved and saved to local storage so
       * that the usePerson hook can get the user's full name
       */

      handleGetLoggedUser();

      return;
    }

    if (token) {
      /*
       * A token will exist in the querystring when Lemur is generating a PDF
       * as it needs a token to be able to access a page for printing
       *
       * Redirect to an authenticate page that will save the access token in
       * local storage which is needed for the useGetLoggedUser hook to work
       *
       * The authenticate page will redirect back to the requested route with
       * an authenticatedPrint=1 querystring parameter
       */

      push(`/authenticate?requestedRoute=${requestedRoute}&token=${String(token)}`).catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    push(`/login?requestedRoute=${requestedRoute}`).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return {
    isAuthenticated: isAuthenticatedWithToken || isAuthenticated,
  };
};

export { useProtectedRoute };
