import { useState, useEffect } from 'react';

import { useCookie } from '@netfront/common-library';
import { useDomain } from '@netfront/gelada-identity-library';

export enum EAgency {
  ambulance = 'ambulance',
  police = 'police',
  fire = 'fire',
  communities = 'communities',
}
const supportOptionsContentGroups = Object.freeze([
  { type: EAgency.ambulance, id: 1048 },
  { type: EAgency.police, id: 1342 },
  { type: EAgency.fire, id: 1343 },
  { type: EAgency.communities, id: 1344 },
]);

const resourcesContentGroups = Object.freeze([
  { type: EAgency.ambulance, id: 1047 },
  { type: EAgency.police, id: 1339 },
  { type: EAgency.fire, id: 1340 },
  { type: EAgency.communities, id: 1341 },
]);

const useCurrentAgency = () => {
  const AGENCY_COOKIE = 'agency';
  const SMART_CODE_COOKIE = 'smartCode';
  const { createCookie, getCookieValue, setCookie, deleteCookie } = useCookie();
  const { isDomainReady, getDomain } = useDomain();
  const [currentAgency, setCurrentAgency] = useState<EAgency | undefined>();
  const [smartCode, setSmartCode] = useState<string | undefined>();

  const [supportOptionsContentGroupId, setSupportOptionsContentGroupId] = useState<number | undefined>();
  const [resourceContentGroupId, setResourceContentGroupId] = useState<number | undefined>();

  useEffect(() => {
    if (!currentAgency) {
      setResourceContentGroupId(undefined);
      return;
    }

    const contentGroup = resourcesContentGroups.find((r) => r.type == currentAgency);
    if (!contentGroup) {
      setResourceContentGroupId(undefined);
      return;
    }
    const { id } = contentGroup;
    setResourceContentGroupId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgency]);

  useEffect(() => {
    if (!currentAgency) {
      setSupportOptionsContentGroupId(undefined);
      return;
    }

    const contentGroup = supportOptionsContentGroups.find((r) => r.type == currentAgency);
    if (!contentGroup) {
      setSupportOptionsContentGroupId(undefined);
      return;
    }
    const { id } = contentGroup;
    setSupportOptionsContentGroupId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAgency]);

  const setAgency = (agency: EAgency, code: string) => {
    setCurrentAgency(agency);
    setSmartCode(code);
    const agencyCookie = createCookie(AGENCY_COOKIE, agency, {
      domain: getDomain(),
      storageExpiryOptions: {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 6,
        },
      },
    });

    const smartCodeCookie = createCookie(SMART_CODE_COOKIE, code, {
      domain: getDomain(),
      storageExpiryOptions: {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 6,
        },
      },
    });

    setCookie(agencyCookie);
    setCookie(smartCodeCookie);
  };

  const getSmartCode = (): string | undefined => {
    return getCookieValue(SMART_CODE_COOKIE);
  };

  const getAgency = (): EAgency | undefined => {
    return getCookieValue(AGENCY_COOKIE) as EAgency | undefined;
  };

  const removeAgency = () => {
    deleteCookie(AGENCY_COOKIE, { domain: getDomain() });
    deleteCookie(SMART_CODE_COOKIE, { domain: getDomain() });
  };

  useEffect(() => {
    if (!isDomainReady) return;
    const agency = getCookieValue(AGENCY_COOKIE) as EAgency;
    setCurrentAgency(agency);
    const code = getCookieValue(SMART_CODE_COOKIE);
    setSmartCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return {
    setAgency,
    getAgency,
    getSmartCode,
    currentAgency,
    smartCode,
    removeAgency,
    supportOptionsContentGroupId,
    resourceContentGroupId,
  };
};

export { useCurrentAgency };
