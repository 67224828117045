/* eslint-disable @typescript-eslint/indent */
import { ApolloQueryResult } from '@apollo/client';
import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_PAGES } from './useGetContentPages.graphql';
import {
  IGetContentPagesQueryGraphQLResponse,
  IGetContentPagesQueryVariables,
  IHandleFetchMoreGetContentPagesParams,
  IHandleGetContentPagesParams,
  IUseGetContentPages,
  IUseGetContentPagesOptions,
} from './useGetContentPages.interfaces';

const useGetContentPages = (options?: IUseGetContentPagesOptions): IUseGetContentPages => {
  const { fetchPolicy, onCompleted, onCompletedAsync, onError, onErrorAsync, query, token } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeGetContentPages, { fetchMore, loading: isLoading }] = useEkardoLazyQuery<
    IGetContentPagesQueryGraphQLResponse,
    IGetContentPagesQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ contentPage: { search } }) => {
        if (onCompleted) {
          onCompleted({
            contentPages: search,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            contentPages: search,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    query: query ?? GET_CONTENT_PAGES,
    token,
  });

  const handleFetchMoreContentPages = ({
    after,
    first,
    projectId,
    contentGroupId,
    tags,
    text,
  }: IHandleFetchMoreGetContentPagesParams): Promise<ApolloQueryResult<IGetContentPagesQueryGraphQLResponse>> => {
    return fetchMore({
      updateQuery: (
        previousQueryResult,
        {
          fetchMoreResult: {
            contentPage: { search: newlyFetchedContentPagesConnection },
          },
        },
      ) => {
        const { edges: newlyFetchedEdges } = newlyFetchedContentPagesConnection;

        if (!newlyFetchedEdges.length) {
          return previousQueryResult;
        }

        const {
          contentPage: { search: previouslyFetchedContentPagesConnection },
        } = previousQueryResult;

        const { edges: previouslyFetchedEdges } = previouslyFetchedContentPagesConnection;

        return {
          ...previousQueryResult,
          contentPage: {
            ...previouslyFetchedContentPagesConnection,
            search: {
              ...newlyFetchedContentPagesConnection,
              edges: [...previouslyFetchedEdges, ...newlyFetchedEdges],
            },
          },
        };
      },
      variables: {
        after,
        first,
        projectId,
        contentGroupId,
        tags,
        text,
      },
    });
  };

  const handleGetContentPages = async ({ after, first, projectId, contentGroupId, tags, text }: IHandleGetContentPagesParams) => {
    await executeGetContentPages({
      variables: {
        after,
        first,
        projectId,
        contentGroupId,
        tags,
        text,
      },
    });
  };

  return {
    handleFetchMoreContentPages,
    handleGetContentPages,
    isLoading,
  };
};

export { useGetContentPages };
