import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { getValidClassNames } from 'utils';

import { CommunityMenuProps } from './CommunityMenu.interfaces';

const CommunityMenu = ({ memberCount, embargoedPostsCount, reportedPostsCount, isModerator }: CommunityMenuProps) => {
  const {
    query: { communityKey },
    asPath,
  } = useRouter();

  const BASE_URL = `/community/communities/${String(communityKey)}`;

  return (
    <>
      <nav aria-label="Community navigation" className="c-community-menu">
        <ul className="c-community-menu__list">
          <li className="c-community-menu__item">
            <NextLink href={`${BASE_URL}`}>
              <span
                className={getValidClassNames({
                  'c-community-menu__link': true,
                  'c-community-menu__link--active': asPath === `${BASE_URL}`,
                })}
              >
                Community feed
              </span>
            </NextLink>
          </li>
          <li className="c-community-menu__item">
            <NextLink href={`${BASE_URL}/members`}>
              <span
                className={getValidClassNames({
                  'c-community-menu__link': true,
                  'c-community-menu__link--active': asPath === `${BASE_URL}/members`,
                })}
              >
                {`Members ${memberCount ? memberCount : ''}`}
              </span>
            </NextLink>
          </li>
          {isModerator ? (
            <li className="c-community-menu__item">
              <NextLink href={`${BASE_URL}/embargoed`}>
                <span
                  className={getValidClassNames({
                    'c-community-menu__link': true,
                    'c-community-menu__link--active': asPath === `${BASE_URL}/embargoed`,
                  })}
                >
                  {`Embargoed ${embargoedPostsCount ? embargoedPostsCount : ''}`}
                </span>
              </NextLink>
            </li>
          ) : null}

          {isModerator ? (
            <li className="c-community-menu__item">
              <NextLink href={`${BASE_URL}/reported`}>
                <span
                  className={getValidClassNames({
                    'c-community-menu__link': true,
                    'c-community-menu__link--active': asPath === `${BASE_URL}/reported`,
                  })}
                >
                  {`Reported ${reportedPostsCount ? reportedPostsCount : ''}`}
                </span>
              </NextLink>
            </li>
          ) : null}

          {isModerator ? (
            <li className="c-community-menu__item">
              <NextLink href={`${BASE_URL}/unpublished`}>
                <span
                  className={getValidClassNames({
                    'c-community-menu__link': true,
                    'c-community-menu__link--active': asPath === `${BASE_URL}/unpublished`,
                  })}
                >
                  {`Unpublished ${embargoedPostsCount ? embargoedPostsCount : ''}`}
                </span>
              </NextLink>
            </li>
          ) : null}


        </ul>
      </nav>
    </>
  );
};

export { CommunityMenu };
