import { IAdditionalClassNames } from '@netfront/ui-library';

const IconPin = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg
      className={additionalClassNames}
      fill="currentColor"
      height="18"
      viewBox="0 0 12.217 18"
      width="12.217"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="pin-icon" transform="translate(0)">
        <path
          d="M18.756,16.485l-6.008-3.469a1.541,1.541,0,1,0-1.542,2.669l6.007,3.469a1.541,1.541,0,0,0,1.542-2.669ZM13.8,12.735l4.673,2.7,1.589-4.423L16.836,9.147,13.8,12.735ZM10.356,24.1l4.47-5.43-2-1.156ZM21.994,8.564l-4-2.312a1.156,1.156,0,0,0-1.156,2l4.005,2.312a1.156,1.156,0,1,0,1.155-2Z"
          data-name="Icon metro-pin"
          id="Icon_metro-pin"
          transform="translate(-10.356 -6.096)"
        />
      </g>
    </svg>
  );
};

export { IconPin };
