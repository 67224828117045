import { CommentsQueryResult, IGetCommentsOptions } from './useGetComments.interface';

import { bonoboClient } from '../../apollo';
import { useGetCommentsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetComments = (options?: IGetCommentsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommentsOptions);

  const [getComments, { loading: isLoading, error, fetchMore, refetch }] = useGetCommentsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data.comment?.getComments as unknown as CommentsQueryResult);
    },
    onError,
  });

  return { getComments, isLoading, error, fetchMore, refetch };
};
