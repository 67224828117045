import { gql } from '@apollo/client';

const GET_CONTENT_PAGES = gql`
  query ($after: String, $first: Int, $projectId: String!, $tags: [Int], $contentGroupId: Int!, $text: String) {
    contentPage {
      search(
        after: $after
        first: $first
        projectId: $projectId
        message: $text
        tags: $tags
        contentGroupId: $contentGroupId
        sorting: SORT
      ) {
        totalCount
        edges {
          cursor
          node {
            asset {
              presignedUrl
            }
            id
            title
            seoDescription
            seoTitle
            sort
            contentPageType {
              id
              name
            }
            url
          }
        }
      }
    }
  }
`;

export { GET_CONTENT_PAGES };
