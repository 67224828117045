import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IForgotPasswordOnCompletedResponse, ForgotPasswordForm, useForgotPassword } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { BreadcrumbHomeLink, BreadcrumbLink, BUTTON_CLASSES, InformationBanner, Link, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const ForgotPasswordPage = () => {
  const PAGE_TITLE = 'Forgot password';
  const { asPath } = useRouter();

  const [isForgotPasswordCompleted, setIsForgotPasswordCompleted] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleForgotPasswordCompleted = ({ isCompleted }: IForgotPasswordOnCompletedResponse) => {
    setIsForgotPasswordCompleted(isCompleted);
  };

  const handleForgotPasswordError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleForgotPassword, isLoading = false } = useForgotPassword({
    onCompleted: handleForgotPasswordCompleted,
    onError: handleForgotPasswordError,
  });

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/" />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
    >
      <InformationBanner title="Forgotten your password?">
        <p className="mb-0">Receive a reset link</p>
      </InformationBanner>
      {!isForgotPasswordCompleted ? (
        <ForgotPasswordForm
          buttonClassName={BUTTON_CLASSES.default}
          buttonText="Submit"
          isSubmitting={isLoading}
          onForgotPassword={(email) =>
            handleForgotPassword({
              email,
            })
          }
        />
      ) : (
        <>
          <div className="mb-8 md:mb-8">An email has been sent with a reset password link to your nominated email.</div>

          <div className="mb-8 md:mb-8">
            To finalise your password reset, click the link within the email and follow the prompts to complete the reset password process.
          </div>

          <Link href="/login" theme="default">
            Return to login
          </Link>
        </>
      )}
    </SingleFormPage>
  );
};

export { ForgotPasswordPage };
