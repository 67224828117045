import { gql } from '@apollo/client';

export const GET_CONTENT_GROUP = gql`
  query getContentGroup($contentGroupId: Int!, $tags: [Int]) {
    contentGroup {
      getContentGroup(contentGroupId: $contentGroupId) {
        contentPages(tags: $tags) {
          id
          title
          seoDescription
          contentPageType {
            name
          }
          asset {
            presignedUrl
          }
          url
        }
      }
    }
  }
`;
