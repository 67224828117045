import { InformationBanner } from "components"

export const EmbargoPostMessage = () => {
  return(
    <InformationBanner additionalClassNames="items-start">
      <p>Hello,</p>
      <p>
      We noticed you recently made a post with content related to suicide or self-harm and wanted to provide some resources that can support you if you need it. One of our moderators will also be in touch soon to check up on how you are doing.
      </p>
      <p>
      As a reminder, we don’t provide counselling or crisis support, but here are a range of 24/7 options (phone numbers and online resources) that we recommend you access for immediate help and support.
      </p>
      <ul className="list-disc">
        <li>
          Emergency: If you or someone around you is in immediate danger dial Triple Zero as soon as possible at 000.
        </li>
        <li>
          Lifeline: If you are in crisis, feel suicidal or would like support call 13 11 14 or visit www.lifeline.org.au for online chat.
        </li>
        <li>
          Suicide Call Back Service: If you have been affected by suicide and want support or after care, call 1300 659 467 or visit www.suicidecallbackservice.org.au for online chat.
        </li>
        <li>
          National Alcohol and Other Drug Hotline: If you need help with alcohol or other drug issues call 1800 250 015
        </li>
      </ul>
      <p className="mb-0">
        Kind regards, <br />
        The Stable Ground team
      </p>
    </InformationBanner>
  )
};