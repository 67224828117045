import { IBreadcrumbItem } from '@netfront/ui-library';

import { BreadcrumbHomeLink, BreadcrumbLink } from '../../components';

export const useGetRootBreadcrumbItems = ({ shouldIncludeDashboard = false }: { shouldIncludeDashboard: boolean }): IBreadcrumbItem[] => {
  const items: IBreadcrumbItem[] = shouldIncludeDashboard
    ? [
        { content: <BreadcrumbHomeLink href="/" />, key: 'Home' },
        { content: <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>, key: 'Dashboard' },
      ]
    : [{ content: <BreadcrumbHomeLink href="/" />, key: 'Home' }];

  return items;
};
