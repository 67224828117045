import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_STEP_PROGRESSION } from './useGetUserFlowStepProgression.graphql';
import {
  IGetUserFlowStepProgressionQueryGraphQLResponse,
  IHandleGetUserFlowStepProgressionParams,
  IUseGetUserFlowStepProgression,
  IUseGetUserFlowStepProgressionOptions,
} from './useGetUserFlowStepProgression.interfaces';

const useGetUserFlowStepProgression = (options?: IUseGetUserFlowStepProgressionOptions): IUseGetUserFlowStepProgression => {
  const { fetchPolicy, onCompleted, onError, product, token } = options ?? ({} as IUseGetUserFlowStepProgressionOptions);

  const [executeGetUserFlowStepProgression, { loading: isLoading }] = useEkardoLazyQuery<IGetUserFlowStepProgressionQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStep: {
            getStepByUrl: { percentageCompleted },
          },
        } = data;

        onCompleted({
          percentageCompleted: percentageCompleted * 100,
        });
      },
      onError,
    },
    product,
    query: GET_STEP_PROGRESSION,
    token,
  });

  const handleGetUserFlowStepProgression = async ({ projectId, stepUrl, userFlowUrl }: IHandleGetUserFlowStepProgressionParams) => {
    await executeGetUserFlowStepProgression({
      variables: {
        projectId,
        stepUrl,
        userFlowUrl,
      },
    });
  };

  return {
    handleGetUserFlowStepProgression,
    isLoading,
  };
};

export { useGetUserFlowStepProgression };
