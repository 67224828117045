import { Fragment, useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useWindowSize } from 'hooks';
import isEmpty from 'lodash.isempty';

import { AvatarBuilder } from 'components/AvatarBuilder/AvatarBuilder';
import { MessagesMenu } from 'components/Social/MessagesMenu';

import { CaretDownIcon, Header, MobileMenu, MobileMenuTrigger, PRVIATE_HEADER_MENU_ITEMS } from '../../../components';
import { NotificationsMenu, UserMenu } from '../../../components/Social';
import { useSocialContext } from '../../../contexts';
import { getValidClassNames } from '../../../utils';

const PrivateHeader = () => {
  const { user, notifications, messages } = useSocialContext();

  const { width } = useWindowSize();
  const { toggle } = useToggle();
  const { isToggled: isSubMenuToggled, toggle: toggleSubMenu } = useToggle();
  const { isToggled: isMobileMenuOpen, toggle: toggleIsMobileMenuOpen } = useToggle();

  const [activeMenuItemId, setActiveMenuItemId] = useState<string>();

  const onMouseEnter = (id: string) => {
    setActiveMenuItemId(id);
    toggleSubMenu();
  };

  const onMouseLeave = (event: React.MouseEvent) => {
    const menuLink = event.target as HTMLElement;

    const bounds = menuLink.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    const w = bounds.width;

    if (!isSubMenuToggled) {
      return;
    }

    if (x < 0) {
      toggleSubMenu();
    } else if (x > w) {
      toggleSubMenu();
    } else if (y < 0) {
      toggleSubMenu();
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  return (
    <div className='relative index-2 spotlight-item--1'>
      <Header logo={{ href: '/dashboard' }}>

        <MessagesMenu messages={messages.filter(({ unReadCount }) => Number(unReadCount) > 0)} />

        <NotificationsMenu notifications={notifications.filter(({ seen }) => !seen)} />

        {user && Object.keys(user).length !== 0 && !user.profileImage && <AvatarBuilder isVisible={false} />}

        {user && <UserMenu avatar={user.profileImage?.presignedUrl} displayName={user.displayedName} isOnline={user.isOnline} />}
              
        <MobileMenuTrigger additionalClassNames="ml-4 xl:none spotlight-item--1 spotlight-item--8" isOpen={isMobileMenuOpen} onPress={toggleIsMobileMenuOpen} />
      </Header>
      
      {isMobileMenuOpen && <MobileMenu />}

      <div className='bg-white py-6 relative'>
        <nav aria-label="Main navigation" className={getValidClassNames({
            "none xl:flex justify-center flex-1": true,
            "spotlight-item--1": Number(width) > 1280
          })}>
            <ul className="m-0 flex items-center">
              {PRVIATE_HEADER_MENU_ITEMS.map(({ href, id, label, subItems }) => (
                <Fragment key={`menuitem-${id}`}>
                  <li
                    className={getValidClassNames({
                      'color-secondary hover:color-primary m-0 flex items-center px-2 mx-2 pointer': true,
                      'spotlight-item--8': id === 'self-assessment' && Number(width) > 1280
                    })}
                    {...(subItems && subItems.length && { onMouseEnter: () => onMouseEnter(id) })}
                    {...(subItems && subItems.length && { onMouseLeave })}
                  >
                    <a className="color-inherit weight-600 mr-1 text-center line-1.2" href={href}>
                      {label}
                    </a>
                    {Boolean(subItems?.length) && (
                      <button
                        className={getValidClassNames({
                          'bg-transparent color-inherit border-none rounded bg-primary flex items-center justify-center p-0 transition-200 pointer':
                            true,
                          'rotate-180':  Boolean(activeMenuItemId === id && isSubMenuToggled && subItems?.length),
                        })}
                        onClick={toggle}
                      >
                        <span className="sr-only">Hover to toggle menu</span>
                        <CaretDownIcon />
                      </button>
                    )}
                  </li>
                  {activeMenuItemId === id && isSubMenuToggled && subItems?.length ? (
                    <div
                      key={`submenu-${id}`}
                      className="bg-white py-6 absolute w-full left-0 top-full border-b border-t border-athens"
                      onMouseLeave={toggleSubMenu}
                    >
                      <div className="container animate__fadeIn">
                        <div className="flex items-start">
                          {subItems.map(({ className, description, href: subHref, label: subLabel }) => (
                            <a
                              key={`submenu-item-${id}`}
                              className={getValidClassNames({
                                'color-primary w-1/4 mr-8 p-4 px-6 flex flex-col justify-center': true,
                                [String(className)]: Boolean(className),
                              })}
                              href={String(subHref)}
                            >
                              <p className="text-underline weight-600 mb-1">{subLabel}</p>
                              <p className="color-secondary text-sm mb-0">{description}</p>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </ul>
          </nav>
        </div>
    </div>
  );
};

export { PrivateHeader };
