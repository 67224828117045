import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  IBookmark,
  IDBUserFlowStep,
  IUseGetBookmarksForUserOnCompletedResponse,
  useGetBookmarksForUser,
} from '@netfront/ekardo-content-library';
import { Select } from '@netfront/ui-library';
import { Skeleton , BreadcrumbHomeLink, BreadcrumbLink, CaretRightIcon, Link, Page } from 'components';
import { sortBy } from 'lodash';
import uniqBy from 'lodash.uniqby';
import { useRouter } from 'next/router';

import { useHasPrivateLayout, useToast, useProtectedRoute } from '../../../hooks';

const BookmarksPage = () => {
  const PAGE_TITLE = 'Bookmarks';

  const { asPath } = useRouter();
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();
  const { hasPrivateLayout } = useHasPrivateLayout();

  const [allBookmarks, setAllBookmarks] = useState<IBookmark[]>([]);
  const [selectedModule, setSelectedModule] = useState<number>();
  const [userFlowSteps, setUserFlowSteps] = useState<IDBUserFlowStep[]>([]);

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleModuleClick = (id: number) => {
    setSelectedModule(id);
  };

  const handleGetBookmarksForUserCompleted = ({ bookmarks }: IUseGetBookmarksForUserOnCompletedResponse) => {
    if (!bookmarks.length) {
      return;
    }

    const sortedBookmarks = sortBy(bookmarks, ({ contentPage: { userFlowStep } }) => userFlowStep?.sort);

    const uniqueUserFlowSteps = uniqBy([...sortedBookmarks], ({ contentPage: { userFlowStep } }) => userFlowStep?.id).map(
      ({ contentPage: { userFlowStep } }) => userFlowStep,
    ) as IDBUserFlowStep[];

    setAllBookmarks(sortedBookmarks);
    setUserFlowSteps(uniqueUserFlowSteps.filter((r) => r !== null));
    setSelectedModule(uniqueUserFlowSteps[0].id);
  };

  const { handleGetBookmarksForUser, isLoading: isGetBookmarksForUserLoading } = useGetBookmarksForUser({
    onCompleted: handleGetBookmarksForUserCompleted,
    onError: handleApolloError,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetBookmarksForUser({
      shouldIncludeContentPage: true,
    }).catch((error) => {
      handleToastError({
        error,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getUserFlowStepById = (stepId: number): IBookmark[] => {
    return allBookmarks.filter(({ contentPage: { userFlowStep } }) => userFlowStep?.id === stepId);
  };

  return (
    <Page
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href={hasPrivateLayout ? '/dashboard' : '/'} />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      hasPrivateLayout={hasPrivateLayout}
      meta={{ seoDescription: 'Your saved educational resources', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
    >
      <div className="container">
        {isGetBookmarksForUserLoading ? (
          <div className="ek-section">
            <Skeleton borderRadius="10px" height="3rem" width="100%" />
          </div>
        ) : allBookmarks.length ? (
          <>
            <div className="ek-section py-4">
              <div className="w-full md:w-1/5">
                <Select
                  additionalClassNames="bg-white"
                  id="module"
                  labelText="Select module"
                  name="module"
                  options={userFlowSteps.map(({ id, stepName }) => ({ id, name: stepName, value: id }))}
                  value={selectedModule}
                  onChange={({ target }) => handleModuleClick(parseInt(target.value))}
                />
              </div>
            </div>

            <div className="flex flex-col">
              {getUserFlowStepById(Number(selectedModule)).map(({ contentPage: { id, title, url, userFlowStep } }) => (
                <Link
                  key={id}
                  className="flex items-center bg-white p-4 color-blue-700 rounded mb-4 weight-600 transition-200 hover:shadow-xl"
                  href={`/dashboard/${String(userFlowStep?.url)}/${url}`}
                >
                  <div className="flex items-center justify-center rounded-circle p-2 bg-blue-200 color-primary mr-4">
                    <svg
                      className="h-5 w-5"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <span className="flex-1 mb-0">{title}</span>
                  <div className="flex items-center color-primary">
                    <CaretRightIcon />
                  </div>
                </Link>
              ))}
            </div>
            <div className="ek-section py-4">
              <div className="w-full md:w-1/5">
                <h2>Saved resources</h2>
              </div>
            </div>
            <div className="flex flex-col">
              {allBookmarks
                .filter((r) => !r.contentPage.userFlowStep)
                .map(({ contentPage: { id, title, url } }) => (
                  <Link
                    key={id}
                    className="flex items-center bg-white p-4 color-blue-700 rounded mb-4 weight-600 transition-200 hover:shadow-xl"
                    href={`/about-drugs/${url}`}
                  >
                    <div className="flex items-center justify-center rounded-circle p-2 bg-blue-200 color-primary mr-4">
                      <svg
                        className="h-5 w-5"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <span className="flex-1 mb-0">{title}</span>
                    <div className="flex items-center color-primary">
                      <CaretRightIcon />
                    </div>
                  </Link>
                ))}
            </div>
          </>
        ) : (
          <div className="ek-section">
            <p>No pages have been bookmarked</p>
          </div>
        )}
      </div>
    </Page>
  );
};

export { BookmarksPage };
