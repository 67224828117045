import { useEffect, useState } from 'react';

import { Button, Dialog } from '@netfront/ui-library';
import { BreadcrumbLink, CommunityFeedHeader, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { DBCommunity, DBTagsByCommunity } from '../../../../interfaces';
import { EConnectionStatus, useGetCommunity, useGetTagsByCommunity } from '../../../../services';
import { CommunityModeratorButtons, EmptyMessage, IconLock, ListCard, ListCardSkeleton, PageBanner } from '../../../Social';

const CommunityHashtagsPage = () => {
  const PAGE_TITLE = 'Hashtag';

  const {
    asPath,
    query: { communityKey },
  } = useRouter();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<DBCommunity>();

  const [tags, setTags] = useState<DBTagsByCommunity[]>([]);

  const closeConnectDialog = () => {
    setIsConnectDialogOpen(false);
  };

  const onConnectRequest = () => {
    setIsConnected(true);
    closeConnectDialog();
  };

  const getCommunityCompleted = (returnedCommunity: DBCommunity) => {
    const { userConnection } = returnedCommunity;

    if (userConnection) {
      setIsConnected(userConnection.status === EConnectionStatus.Accepted);
    }

    setCommunity(returnedCommunity);
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getCommunity } = useGetCommunity({
    onCompleted: getCommunityCompleted,
  });

  const handleGetTagsByCommunityCompleted = (tagsByCommunity: DBTagsByCommunity[]) => {
    setTags(tagsByCommunity);
  };

  const { getTagsByCommunity, isLoading: isGetCommunityTagsLoading } = useGetTagsByCommunity({
    onCompleted: handleGetTagsByCommunityCompleted,
  });

  useEffect(() => {
    if (community || !communityKey) {
      return;
    }

    getCommunity({ variables: { key: String(communityKey), shouldIncludeUserConnection: true } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityKey, isConnected]);

  useEffect(() => {
    if (!community) return;

    if (isConnected) {
      getTagsByCommunity({
        variables: {
          communityId: Number(community.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, isConnected]);

  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community/communities">Communities</BreadcrumbLink>, key: 'Communities' },
          {
            content: <BreadcrumbLink href={`/community/communities/${String(communityKey)}`}>{String(community?.title)}</BreadcrumbLink>,
            key: String(community?.title),
          },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `View hashtags in ${String(community?.title)}`,
        seoTitle: `Hashtags in ${community?.title ?? ''}`,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {community && (
        <>
          <PageBanner
            bannerImage={community.bannerImage?.presignedUrl}
            profileImage={community.profileImage?.presignedUrl}
            title={community.title}
          >
            {isConnected ? (
              <CommunityModeratorButtons community={community} />
            ) : (
              <Button text="Connect" onClick={() => setIsConnectDialogOpen(true)} />
            )}
          </PageBanner>

          <div className="c-community-hastags-page__container">
            {isConnected ? (
              <div>
                <CommunityFeedHeader community={community} />

                {isGetCommunityTagsLoading && [...Array(3)].map((_, key) => <ListCardSkeleton key={key} />)}

                {!isGetCommunityTagsLoading && Boolean(tags.length) && (
                  <>
                    {tags.map(({ count, key }) => (
                      <ListCard key={key} displayName={`${key}`} href={`/community/communities/${String(communityKey)}?hashtag=${key.replace('#', '')}`} shouldHideAvatar>
                        {count}
                      </ListCard>
                    ))}
                  </>
                )}
                {!isGetCommunityTagsLoading && Boolean(!tags.length) && <EmptyMessage message="No hashtags found" />}
              </div>
            ) : (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(community.title)}'s feed`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${community.title}`}
            onCancel={closeConnectDialog}
            onClose={closeConnectDialog}
            onConfirm={onConnectRequest}
          >
            <p>Are you sure you want to connect with {community.title}?</p>
          </Dialog>
        </>
      )}
    </Page>
  );
};

export { CommunityHashtagsPage };
