import { gql } from '@apollo/client';

const GET_TRACKINGS = gql`
  query getTrackings(
    $projectId: String!
    $userFlowUrl: String!
    $includeUserFlowStep: Boolean = false
    $includeAnswers: Boolean = false
    $userFlowStepUrls: [String]
  ) {
    userFlowStepTrack {
      getTrackings(projectId: $projectId, userFlowUrl: $userFlowUrl, userFlowStepUrls: $userFlowStepUrls) {
        id
        completed
        started
        isMarked
        isCompleted
        answers @include(if: $includeAnswers) {
          id
          contentSnippetQuestionId
          ... on QuestionAnswerRadioType {
            questionResponseRadioId
          }
          ... on QuestionAnswerCalendarType {
            dateAnswered
          }
          ... on QuestionAnswerMultilineTextType {
            text
          }
          ... on QuestionAnswerSinglelineTextType {
            text
          }
        }
        userFlowStep @include(if: $includeUserFlowStep) {
          id
          stepName
          url
          contentGroupId
          lastViewedContentPage {
            url
          }
        }
      }
    }
  }
`;

export { GET_TRACKINGS };
