import { useEffect, useState } from 'react';

import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { CommunityConnection, CommunityEdge, CommunityGraphType, useGetAllPublicCommunities } from '../../../../services';
import { EmptyMessage, ListCard, ListCardSkeleton, useSearchInput } from '../../../Social';

const PublicCommunitiesPage = () => {
  const PAGE_TITLE = 'Public communities';

  const { asPath } = useRouter()
  const { isSearchActive, searchValue } = useSearchInput();

  const [allPublicCommunities, setAllPublicCommunities] = useState<CommunityConnection>({} as CommunityConnection);
  const [isLoadingAllPublicCommunities, setIsLoadingAllPublicCommunities] = useState<boolean>(true);


  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const handleGetAllPublicCommunitiesCompleted = (publicCommunities: CommunityConnection) => {
    setAllPublicCommunities(publicCommunities);
    setIsLoadingAllPublicCommunities(false);
  };

  const { getAllPublicCommunities } = useGetAllPublicCommunities({
    onCompleted: handleGetAllPublicCommunitiesCompleted,
  });

  useEffect(() => {
    getAllPublicCommunities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchActive) {
      getAllPublicCommunities({
        variables: {
          filter: searchValue,
        },
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive, searchValue]);

  const canRenderAllPublicCommunities = !isLoadingAllPublicCommunities && !isEmpty(allPublicCommunities);
  const hasNoPublicCommunities = !isLoadingAllPublicCommunities && isEmpty(allPublicCommunities);

  const allNonConnectedPublicCommunities = allPublicCommunities.edges?.filter(
    ({ node }) => node && !node.userConnection,
  ) as CommunityEdge[];

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href='/community/communities'>Communities</BreadcrumbLink>, key: PAGE_TITLE }, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{ seoDescription: 'View all your communities', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-communities-page__container">

        <h2 className='mb-4'>Explore public communities {canRenderAllPublicCommunities && `(${allNonConnectedPublicCommunities.length})`}</h2>

        {isLoadingAllPublicCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderAllPublicCommunities &&
          allNonConnectedPublicCommunities.map(({ node }) => {
            const community = node as CommunityGraphType;
            return (
              <ListCard
                key={community.id}
                avatarImage={community.profileImage?.presignedUrl}
                avatarTitle={community.title}
                description={community.description}
                displayName={community.title}
                href={`/community/communities/${String(community.key)}`}
                hasArrow
              />
            );
          })}

        {hasNoPublicCommunities && <EmptyMessage message="No communities available" />}
      </div>
    </Page>
  );
};

export { PublicCommunitiesPage };
