import { Skeleton , ArrowRightIcon, Link, ProgressCircle } from 'components';

import { ModuleCardProps } from './ModuleCard.interfaces';

import { getValidClassNames } from '../../utils';

const ModuleCard = ({
  description,
  estimatedTime,
  isComplete,
  isDisabled,
  isInProgress,
  isLoading,
  percentageCompleted = 0,
  title,
  url,
}: ModuleCardProps) => {
  return isLoading ? (
    <Skeleton
      baseColor="var(--grey-500)"
      borderRadius="20px"
      containerClassName="w-full"
      height="10rem"
      highlightColor="var(--color-athens)"
    />
  ) : (
    <Link
      className="flex h-full bg-white border-2 border-grey-500 rounded-lg pointer transition-200 hover:shadow-xl"
      href={String(url)}
      isDisabled={isDisabled}
    >
      <div className="flex p-5 border-r-2 border-grey-500">
        <ProgressCircle percentageCompleted={percentageCompleted} />
      </div>

      <div className="flex flex-col flex-1  p-5">
        <p className="h5 weight-800 color-secondary mb-1">{title}</p>
        <div className="color-black mb-3 line-1.2">
          {description && <p>{description}</p> } 
          {estimatedTime !== undefined && <i className="weight-600">{`Estimated time to complete: ${estimatedTime} minutes.`}</i> }
        </div>
        <div
          className={getValidClassNames({
            'flex items-baseline font-header text-sm weight-600 mb-0': true,
            // 'color-primary': Boolean(!isDisabled) && (Boolean(isInProgress) || Boolean(isComplete)),
            'color-primary': Boolean(!isDisabled) && (Boolean(!isInProgress) || Boolean(!isComplete)),
            'color-black': Boolean(isDisabled),
          })}
        >
          <span className="mb-0 mr-1">{isComplete ? 'Review' : isInProgress ? 'In progress' : isDisabled ? 'Locked' : 'Not started'}</span>
          <div className="color-primary border-2 border-primary rounded-circle h-4 w-4 flex items-center justify-center">
            <ArrowRightIcon additionalClassNames="w-2 h-2" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export { ModuleCard };
