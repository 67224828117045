import { Skeleton } from 'components';

export const ResourceCardSkeleton = () => {
  return (
    <div className="bg-white rounded p-4">
      <Skeleton height="1rem" width="20%" />
      <Skeleton height="1rem" width="80%" />
      <Skeleton height="1rem" width="80%" />
    </div>
  );
};
