import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_INTERNAL_NOTIFICATION_TO_CURRENT_USER_MUTATION } from './useSendInternalNotificationToTheCurrentUser.graphql';
import {
  IHandleSendInternalNotificationToTheCurrentUserParams,
  ISendInternalNotificationToTheCurrentUserMutationGraphQLResponse,
  ISendInternalNotificationToTheCurrentUserMutationVariables,
  IUseSendInternalNotificationToTheCurrentUserOptions,
  IUseSendInternalNotificationToTheCurrentUser,
} from './useSendInternalNotificationToTheCurrentUser.interfaces';

const useSendInternalNotificationToTheCurrentUser = (
  options?: IUseSendInternalNotificationToTheCurrentUserOptions,
): IUseSendInternalNotificationToTheCurrentUser => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSendInternalNotificationToTheCurrentUserOptions);

  const [executeSendInternalNotificationToTheCurrentUser, { loading: isLoading }] = useHowlerMutation<
    ISendInternalNotificationToTheCurrentUserMutationGraphQLResponse,
    ISendInternalNotificationToTheCurrentUserMutationVariables
  >({
    mutation: mutation ?? SEND_INTERNAL_NOTIFICATION_TO_CURRENT_USER_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { sendInternalNotificationToCurrentUser: isSuccess },
        } = data;

        onCompleted({
          result: isSuccess,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSendInternalNotificationToTheCurrentUser = async ({
    templateId,
    date,
  }: IHandleSendInternalNotificationToTheCurrentUserParams) => {
    await executeSendInternalNotificationToTheCurrentUser({
      variables: {
        date,
        templateId,
      },
    });
  };

  return {
    handleSendInternalNotificationToTheCurrentUser,
    isLoading,
  };
};

export { useSendInternalNotificationToTheCurrentUser };
