import { IDBUserFlowStepTrack } from "@netfront/ekardo-content-library";
import { getDayDifference, isDaysBetween } from "utils";


export interface IHandleCanAccessFollowupParams {
  followUpStep: IDBUserFlowStepTrack;
  initialisingStep: IDBUserFlowStepTrack
}

export interface IUseAccessFollowupParams {
  onIsCompleted?: () => void;
  onShouldClose?: (steps: IHandleCanAccessFollowupParams) => void;
  onShouldContinue?: () => void;
  onShouldRedirect?: () => void;
  onShouldStart?: () => void;
}

export interface IOpenEvaluationMonth { 
  close: number, 
  month: number, 
  open: number 
};

export interface IUseAccessFollowup {
  handleCanAccessFollowup: (steps: IHandleCanAccessFollowupParams) => void;
}

export const getMonthTimePoint = (date: Date): IOpenEvaluationMonth | undefined => {
  if(isDaysBetween(date, 0, 14)) return { month: 0, open: 0, close: 14 };
  if(isDaysBetween(date, 90, 104)) return { month: 3, open: 90, close: 104 };
  if(isDaysBetween(date, 180, 194)) return { month: 6, open: 180, close: 194 };
  if(isDaysBetween(date, 270, 284)) return { month: 9, open: 270, close: 284 };
  if(isDaysBetween(date, 360, 374)) return { month: 12, open: 360, close: 374 };
};

export const useAccessFollowup = ({ onIsCompleted, onShouldContinue, onShouldClose, onShouldRedirect, onShouldStart }: IUseAccessFollowupParams): IUseAccessFollowup => { 
  const handleCanAccessFollowup = ({ initialisingStep, followUpStep }: IHandleCanAccessFollowupParams) => {
    if(!initialisingStep.isCompleted) return;

    let followUpStepStarted = new Date();

    if(followUpStep) {
      followUpStepStarted = new Date(String(followUpStep.started))
    }

    // Find date diff between initialisingStep complete and most recent followUpStep start date
    const initialisingAndFollowupDiff = getDayDifference(new Date(String(initialisingStep.completed)), new Date(String(followUpStepStarted)));

    const followUpTimePoint = getMonthTimePoint(new Date(initialisingStep.completed));

    if(!followUpTimePoint) {
      if(onShouldRedirect) {
        onShouldRedirect()
      }
      return;
    }


    // Is initialisingStep in between timepoint (days)
   
    // if no followUpStep instance, send to followUpStep
    if(!followUpStep) {
      if(onShouldStart) {
        onShouldStart();
      }
      return;
    }

    // Do we have an Evaluation that falls in this time point available 
    const isFollowUpInstanceInTimePoint = initialisingAndFollowupDiff >= followUpTimePoint.open && initialisingAndFollowupDiff <= followUpTimePoint.close

    // The current open followUp instance does not fall within the timepoint
    if(!isFollowUpInstanceInTimePoint) {
      // Is the last screen closed? NO
      if(!followUpStep.isCompleted) {
        if(onShouldClose) {
          onShouldClose({
            followUpStep,
            initialisingStep
          });
        }
      }

      // yes
      if(onShouldStart) {
        onShouldStart();
      }
      return;
    }

    if(!followUpStep.isCompleted) {
      if(onShouldContinue) {
        onShouldContinue();
      }
      return;
    }

    if(onIsCompleted) {
      onIsCompleted();
    }

  };


  return {
    handleCanAccessFollowup
  }
};