import { Button } from '@netfront/ui-library';

export const CheckinCardEmpty = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center h-full w-full md:w-1/2 mx-auto">
      <div className="mb-2">
        <img alt="Empty" className="max-w-20" src="/images/no-checkin-icon.svg" />
      </div>
      <span className="color-black h5 weight-800 mb-1 block">No check-in yet</span>
      <p className="mb-8">
      It looks like you haven&apos;t checked in recently. Complete your weekly check-in now.
      </p>
      <Button linkButton={{ id: 'checkin', url: '/checkin' }} text="Go to Check-in" />
    </div>
  );
};
