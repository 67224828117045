import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getTransformedUserFlowSteps, IDBUserFlowStep, IUserFlowStep } from '@netfront/ekardo-content-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import { sortBy } from 'lodash';
import last from 'lodash.last';
import { useRouter } from 'next/router';

import {
  IUseGetUserFlowByUrlOnCompletedResponse,
  useGetAgencyUserFlowUrl,
  useGetUserFlowByUrl,
  useProtectedRoute,
  useToast,
} from '../../hooks';

export const useGetCurrentModule = (): { hasStartedUserflow: boolean; lastViewedStep: IUserFlowStep | undefined } => {
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();
  const { handleLogout } = useLogout();
  const { getDomain, isDomainReady } = useDomain();
  const { push } = useRouter();

  const [lastViewedStep, setLastViewedStep] = useState<IUserFlowStep>();
  const [hasStartedUserflow, setHasStartedUserflow] = useState<boolean>(false);

  const { userFlowUrl: PROGRAM_USERFLOW__URL } = useGetAgencyUserFlowUrl();

  const handleApolloError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetUserFlowByUrlCompleted = ({ userFlow: { userFlowSteps } }: IUseGetUserFlowByUrlOnCompletedResponse) => {
    const transformedModuleUserFlowSteps = getTransformedUserFlowSteps(
      userFlowSteps as unknown as IDBUserFlowStep[],
      PROGRAM_USERFLOW__URL,
    );

    const sortedUserFlowSteps = sortBy(transformedModuleUserFlowSteps, 'sort');
    // const lastViewUserflowStep = parseInt(String(localStorage.getItem('lastViewedUserflowStep')));

    const lastViewedModule =
      last(sortedUserFlowSteps.filter((userFlowStep) => userFlowStep.lastViewedContentPage).map((userFlowStep) => userFlowStep)) ??
      sortedUserFlowSteps[0];

    setLastViewedStep(lastViewedModule);
    setHasStartedUserflow(sortedUserFlowSteps.some(({ percentageCompleted }) => percentageCompleted !== 0));
  };

  const { handleGetUserFlowByUrl } = useGetUserFlowByUrl({
    onCompleted: handleGetUserFlowByUrlCompleted,
    onError: handleApolloError,
  });

  useEffect(() => {
    if (!isAuthenticated || !isDomainReady) {
      return;
    }
    if (!PROGRAM_USERFLOW__URL) {
      handleLogout({ domain: getDomain() });
      push('/login').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
      return;
    }

    handleGetUserFlowByUrl({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      url: PROGRAM_USERFLOW__URL,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return {
    lastViewedStep,
    hasStartedUserflow,
  };
};
