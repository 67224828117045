export const ALCOHOL_QUESTION_ID = 26756;
export const CANNABIS_QUESTION_ID = 26976;
export const COCAINE_QUESTION_ID = 26977;
export const AMPHETAMINE_QUESTION_ID = 26978;
export const INHALANTS_QUESTION_ID = 26979;
export const SEDATIVES_QUESTION_ID = 26980;
export const HALLUCINOGENS_QUESTION_ID = 26981;
export const OPIODS_QUESTION_ID = 26982;
export const OTHER_QUESTION_ID = 26983;

export const DAYS_ALCOHOL_QUESTION_ID = 25980;
export const DAYS_CANNABIS_QUESTION_ID = 25988;
export const DAYS_COCAINE_QUESTION_ID = 26002;
export const DAYS_AMPHETAMINE_QUESTION_ID = 26005;
export const DAYS_SEDATIVES_QUESTION_ID = 26013;
export const DAYS_HALLUCINOGENS_QUESTION_ID = 26016;
export const DAYS_OTHER_QUESTION_ID = 26772;
export const DAYS_OPIODS_QUESTION_ID = 26019;
export const DAYS_INHALANTS_QUESTION_ID = 26009;
