import { useRef } from 'react';

import { useButton } from '@react-aria/button';
import cx from 'classnames';

import { BUTTON_CLASSES } from './Button.constants';
import { ButtonProps } from './Button.interfaces';

const Button = (props: ButtonProps) => {
  const { children, className = '', isLoading = false, onMouseEnter, onMouseLeave, theme } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { buttonProps } = useButton(props, buttonRef);

  const buttonTheme = theme ? BUTTON_CLASSES[theme] : '';

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      type="button"
      {...buttonProps}
      ref={buttonRef}
      className={cx(buttonTheme, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading ? (
        <svg height="40px" id="loader-1" version="1.1" viewBox="0 0 50 50" width="40px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
          <path
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            fill="#fff"
          >
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            />
          </path>
        </svg>
      ) : (
        children
      )}
    </button>
  );
};

export { Button };
