import { useEffect, useState } from 'react';

import { useSocialContext } from 'contexts';
import { useUpdateCommentStatus, useUpdatePostStatus } from 'hooks';
import noop from 'lodash.noop';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';

import { recurseComments } from './Feed.helpers';
import { EFeedQuery, FeedProps, IPost } from './Feed.interfaces';

import { DBPost } from '../../../interfaces';
import {
  CommentConnection,
  CommentEdge,
  CommentGraphType,
  CreateCommentMutation,
  CreateSubCommentMutation,
  ECommunityRole,
  EEntryStatus,
  EPostSorting,
  PollGraphType,
  PostGraphType,
  PostInterfaceConnection,
  TogglePinnedPostMutation,
  VoteAPollMutation,
  useCommentRelate,
  useCreateComment,
  useCreatePostReport,
  useCreateSharePost,
  useCreateSubComment,
  useDeleteComment,
  useDeleteCommentRelateByCommentId,
  useDeletePost,
  useDeletePostRelate,
  useGetComments,
  useGetConnectionPosts,
  useGetModeratorsPosts,
  useGetPost,
  useGetPosts,
  useGetSubComments,
  usePostRelate,
  useReportComment,
  useTogglePinnedPost,
  useVoteAPoll,
} from '../../../services';
import { EmptyMessage, IUpdateRelateParams, IconCommunity, LoadMore, MAX_NESTED_COMMENT_DEPTH, Post, PostSkeleton, REPLIES_FIRST } from '../../Social';

const Feed = ({
  authorId,
  communityId,
  first = 15,
  commentsFirst = 1,
  getLatestPosts,
  isPreviewMode = false,
  message,
  postId: postIdProp,
  postSkeletonCount = 2,
  query = EFeedQuery.All,
  shouldShowCommunitiesMessage = false,
  sort= EPostSorting.DescendingDate,
  tags,
}: FeedProps) => {
  const { user: loggedUser } = useSocialContext();

  const { push } = useRouter();
  const loggedUserId = loggedUser?.id;

  const [posts, setPosts] = useState<IPost[]>([]);
  const [totalPostsCount, setTotalPostsCount] = useState<number>(0);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const { ref: loadMoreRef, inView: shouldFetchMore } = useInView();

  const { postRelate } = usePostRelate();
  const { deletePostRelate } = useDeletePostRelate();
  const { commentRelate } = useCommentRelate();
  const { deleteCommentRelateByCommentId } = useDeleteCommentRelateByCommentId();

  // Event handlers

  const onPostRelate = ({ action, hasRelated, id: postId, relateType }: IUpdateRelateParams) => {
    switch (action) {
      case 'ADD':
        postRelate({ variables: { postId, type: relateType } });
        break;
      case 'CHANGE':
        postRelate({ variables: { postId, type: relateType } });
        break;
      case 'REMOVE':
        deletePostRelate({ variables: { postId } });
        break;
    }

    setPosts(
      posts.map((post: IPost) =>
        post.id === postId
          ? {
              ...post,
              hasRelated,
              relateCount:
                action !== 'CHANGE' ? (hasRelated ? Number(post.relateCount) + 1 : Number(post.relateCount) - 1) : post.relateCount,
            }
          : post,
      ),
    );
  };

  const onCommentRelate = ({ action, hasRelated, id: commentId, relateType }: IUpdateRelateParams) => {
    switch (action) {
      case 'ADD':
        commentRelate({ variables: { commentId, type: relateType } });
        break;
      case 'CHANGE':
        commentRelate({ variables: { commentId, type: relateType } });
        break;
      case 'REMOVE':
        deleteCommentRelateByCommentId({ variables: { commentId } });
        break;
    }

    const updatedFeed = posts.map((post) => {
      const edges = recurseComments(post.comments, ({ cursor, node }) => {
        if (node?.id === commentId) {
          node.hasRelated = hasRelated;
        }

        return { cursor, node };
      });

      return {
        ...post,
        comments: {
          ...post.comments,
          edges,
        },
      };
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const onEdit = (postId: number) => {
    push(`/community/create-post?edit=${postId}`);
  };

  // Api hook handlers
  const handleGetFeedPostsCompleted = (postConnection: PostInterfaceConnection) => {
    setPosts(postConnection.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(postConnection.totalCount));
  };

  const handleGetConnectionPostsCompleted = (returnedConnectionPosts: PostInterfaceConnection) => {
    setPosts(returnedConnectionPosts.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(returnedConnectionPosts.totalCount));
  };

  const handleGetModeratorsPostsCompleted = (returnedModeratorPosts: PostInterfaceConnection) => {
    setPosts(returnedModeratorPosts.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(returnedModeratorPosts.totalCount));
  };

  const handleOnGetMoreSubComments = async (postId: number, commentId: number, endCursor: string) => {
    
    const subCommentsResult = await getSubComments({
      variables: { commentId, first: REPLIES_FIRST, after: endCursor},
    });
    const subCommentsConnection = subCommentsResult.data?.comment?.getSubComments;

      const updatedFeed = posts.map((post) => {
        if (post.id === postId && post.comments) {
          let newComments = [...(post.comments.edges as CommentEdge[])];

          // eslint-disable-next-line no-inner-declarations
          function updateComments(comments: CommentEdge[], depth = 0): CommentEdge[] {
            const updatedComments = comments.map((edge) => {
              if (edge.node?.id === commentId) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    comments: {
                      ...edge.node.comments,
                      edges: [...(edge.node.comments?.edges as CommentEdge[]), ...subCommentsConnection?.edges as CommentEdge[]],
                      pageInfo: subCommentsConnection?.pageInfo,
                    },
                  },
                } as CommentEdge;
              } else if (edge.node?.comments?.edges?.length && edge.node.comments.edges.length > 0 && depth < MAX_NESTED_COMMENT_DEPTH) {
                return {
                  ...edge,
                  node: {
                    ...edge.node,
                    comments: {
                      ...edge.node.comments,
                      edges: updateComments(edge.node.comments.edges, depth + 1),
                    },
                  },
                } as CommentEdge;
              }
              return edge;
            });

            return updatedComments;
          }

          newComments = updateComments(newComments);

          return {
            ...post,
            comments: {
              ...post.comments,
              edges: newComments,
            },
          };
        }
        return post;
      });

    setPosts(updatedFeed);
  };

  const handleOnGetMoreComments = async (postId: number, endCursor: string) => {
    const commentsResult = await getComments({
      variables: { postId, first: REPLIES_FIRST, after: endCursor, commentStatus: EEntryStatus.Active },
    });
    const commentsConnection = commentsResult.data?.comment?.getComments;

    const updatedFeed = posts.map((post) => {
      if (post.id === postId) {
        let edges = post.comments?.edges;

        if (edges && commentsConnection?.edges) {
          edges = [...edges, ...(commentsConnection.edges as CommentEdge[])];
        }
        return {
          ...post,
          comments: {
            ...post.comments,
            pageInfo: commentsConnection?.pageInfo,
            edges,
          },
        };
      }

      return post;
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const handleCreateCommentCompleted = (comment: CreateCommentMutation) => {
    const newComment = comment.comment?.create;

    const updatedFeed = posts.map((post) => {
      if (post.id === newComment?.postId) {
        const edges = post.comments?.edges as CommentEdge[];

        return {
          ...post,
          comments: {
            edges: [{ node: newComment }, ...edges],
          },
        };
      }

      return post;
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const handleCreateSubCommentCompleted = (comment: CreateSubCommentMutation) => {
    const newComment = comment.comment?.createSubComment as CommentGraphType;

    const updatedFeed = posts.map((post) => {
      const edges = recurseComments(post.comments, ({ cursor, node }) => {
        if (!node) return;
        if (node.id === newComment.parentCommentId) {
          node.comments = {
            ...node.comments,
            edges: [...(node.comments?.edges as CommentEdge[]), { cursor: '', node: newComment }],
          } as CommentConnection;
        }

        return { cursor, node };
      });

      return {
        ...post,
        comments: {
          ...post.comments,
          edges,
        },
      };
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const handleVoteAPollCompleted = (poll: VoteAPollMutation) => {
    const newPoll = poll.poll?.vote as PollGraphType;
    setPosts(posts.map((post) => (post.id === newPoll.postId ? { ...post, poll: newPoll } : post)));
  };

  const handleTogglePinnedPostCompleted = (returnedPost: TogglePinnedPostMutation) => {
    const currentPost = returnedPost.post?.togglePinned;
    setPosts(posts.map((post) => (post.id === currentPost?.id ? ({ ...post, pinned: currentPost.pinned } as IPost) : post)));
  };

  const handleDeletePostCompleted = () => {
    refetchPosts();
  };

  const handleDeleteCommentCompleted = () => {
    refetchPosts();
  };

  const refetchPosts = () => {
    setIsRefetchLoading(true);

    switch (query) {
      case EFeedQuery.Members:
        refetchConnectionPosts({
          shouldIncludeComments: true,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
        break;
      case EFeedQuery.Moderators:
        refetchModeratorPosts({
          shouldIncludeComments: true,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
        break;
      default:
        refetchAllPosts({
          shouldIncludeComments: true,
          authorId,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
    }
  };

  const handleSharePostCompleted = () => {
    refetchPosts();
  };

  const handleReportPostCompleted = () => noop;

  const handleGetPostCompleted = (post: DBPost) => {
    setPosts([...posts, post as unknown as IPost]);
  };

  // Api hooks

  const { getPost, isLoading: isGetPostLoading } = useGetPost({
    onCompleted: handleGetPostCompleted,
  });

  const {
    getFeedPosts,
    isLoading: isGetFeedPostsLoading,
    fetchMore: fetchMoreAllPosts,
    refetch: refetchAllPosts,
  } = useGetPosts({
    onCompleted: handleGetFeedPostsCompleted,
  });

  const {
    getConnectionPosts,
    isConnectionsPostsLoading,
    fetchMore: fetchMoreConnectionPosts,
    refetch: refetchConnectionPosts,
  } = useGetConnectionPosts({
    onCompleted: handleGetConnectionPostsCompleted,
  });

  const {
    getModeratorsPosts,
    isModeratorsPostsLoading,
    fetchMore: fetchMoreModeratorPosts,
    refetch: refetchModeratorPosts,
  } = useGetModeratorsPosts({
    onCompleted: handleGetModeratorsPostsCompleted,
  });

  const { getComments, isLoading: isLoadingComments } = useGetComments({});
  const { getSubComments, isLoading: isLoadingSubComments,  } = useGetSubComments({});

  const { createComment, isCreateCommentLoading } = useCreateComment({
    onCompleted: handleCreateCommentCompleted,
  });

  const { createSubComment } = useCreateSubComment({
    onCompleted: handleCreateSubCommentCompleted,
  });

  const { voteAPoll } = useVoteAPoll({
    onCompleted: handleVoteAPollCompleted,
  });

  const { togglePinnedPost } = useTogglePinnedPost({
    onCompleted: handleTogglePinnedPostCompleted,
  });

  const { deletePostMutation: deletePost } = useDeletePost({
    onCompleted: handleDeletePostCompleted,
  });

  const { deleteCommentMutation: deleteComment } = useDeleteComment({
    onCompleted: handleDeleteCommentCompleted,
  });

  const { createSharePostMutation: sharePost } = useCreateSharePost({
    onCompleted: handleSharePostCompleted,
  });

  const { createSharePostMutation: reportPost } = useCreatePostReport({
    onCompleted: handleReportPostCompleted,
  });

  const { reportComment } = useReportComment({});

  const { handleUpdatePostStatus } = useUpdatePostStatus({
    onCompleted() {
      refetchPosts();
    },
  });

  const { handleUpdateCommentStatus } = useUpdateCommentStatus({
    onCompleted() {
      refetchPosts();
    },
  });

  useEffect(() => {
    if (postIdProp) {
      getPost({
        variables: {
          postId: postIdProp,
        },
      });
      return;
    }

    const queryVariables = {
      shouldIncludeComments: true,
      authorId,
      communityId,
      tags,
      first,
      message,
      commentsFirst,
      repliesFirst: REPLIES_FIRST,
      sort
    };

    switch (query) {
      case EFeedQuery.Members:
        getConnectionPosts({
          variables: queryVariables,
        });
        break;
      case EFeedQuery.Moderators:
        getModeratorsPosts({
          variables: queryVariables,
        });
        break;
      default:
        getFeedPosts({
          variables: queryVariables,
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorId, communityId, postIdProp, message, query, sort, tags]);

  useEffect(() => {
    if (!shouldFetchMore) {
      return;
    }

    if (totalPostsCount === posts.length) {
      return;
    }

    if (postIdProp) return;

    switch (query) {
      case EFeedQuery.Members:
        fetchMoreConnectionPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.connectionPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
        break;
      case EFeedQuery.Moderators:
        fetchMoreModeratorPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.moderatorsPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
        break;
      default:
        fetchMoreAllPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.getFeedPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchMore, query]);

  const isPostsLoading =
    isGetFeedPostsLoading || isConnectionsPostsLoading || isModeratorsPostsLoading || isRefetchLoading || isGetPostLoading;

  return (
    <div>
      {isPostsLoading && [...Array(postSkeletonCount)].map((_, key) => <PostSkeleton key={key} />)}

      {!isPostsLoading &&
        Boolean(posts.length) &&
        !shouldShowCommunitiesMessage &&
        posts
          .map(
            ({
              assets,
              author,
              authorCommunityRole,
              comments,
              community,
              createdDateTime,
              feeling,
              hasRelated,
              id,
              ipAddress,
              message: postMessage,
              pinned: isPinned,
              poll,
              isNegative,
              relate,
              relateCount,
              shareCount,
              status,
              originalPost,
            }) => {
              const { edges, pageInfo: commentsPageInfo, totalCount: totalCommentsCount = 0 } = comments ?? ({} as CommentConnection);
              const postComments = edges?.map(({ node }) => node);

              return (
                <Post
                  key={id}
                  assets={assets}
                  avatar={author?.profileImage?.presignedUrl}
                  comments={postComments}
                  commentsPageInfo={commentsPageInfo}
                  community={community}
                  communityRole={authorCommunityRole}
                  date={createdDateTime}
                  displayName={author?.displayedName}
                  feeling={feeling}
                  hasRelated={hasRelated}
                  id={id}
                  ipAddress={ipAddress}
                  isCommentsLoading={isLoadingComments || isLoadingSubComments}
                  isCommentSubmitting={isCreateCommentLoading}
                  isNegative={isNegative ?? false}
                  isOnline={
                    authorCommunityRole === ECommunityRole.Moderator || authorCommunityRole === ECommunityRole.Owner
                      ? author?.isOnline
                      : author?.hasInteracted
                  }
                  isOwner={loggedUserId === author?.id}
                  isPinned={isPinned}
                  isPreviewMode={isPreviewMode}
                  loggedUser={loggedUser}
                  message={postMessage}
                  poll={poll}
                  relate={relate}
                  relateCount={relateCount}
                  shareCount={shareCount}
                  sharedPost={originalPost}
                  status={status}
                  totalCommentsCount={totalCommentsCount}
                  userProfileLink={`/community/profile/${author?.id === loggedUserId ? '' : String(author?.key)}`}
                  shouldShowPostDetails
                  onComment={(postId, commentMessage) =>
                    createComment({
                      variables: {
                        request: {
                          message: commentMessage,
                          postId,
                        },
                      },
                    })
                  }
                  onCommentDelete={(deleteCommentId) => {
                    deleteComment({
                      variables: {
                        commentId: deleteCommentId,
                      },
                    });
                  }}
                  onCommentRelate={onCommentRelate}
                  onCommentReply={(commentId, replyMessage) =>
                    createSubComment({
                      variables: {
                        request: {
                          commentId,
                          message: replyMessage,
                        },
                      },
                    })
                  }
                  onDelete={(postId) => {
                    deletePost({
                      variables: {
                        postId,
                      },
                    });
                  }}
                  onEdit={onEdit}
                  onGetMoreComments={handleOnGetMoreComments}
                  onGetMoreSubComments={(commentId, endCursor) => handleOnGetMoreSubComments(id, commentId, endCursor)}
                  onPostRelate={onPostRelate}
                  onReport={(postId, type) => {
                    reportPost({
                      variables: {
                        postId,
                        type,
                      },
                    });
                  }}
                  onReportComment={(commentId, type) => {
                    reportComment({
                      variables: {
                        commentId,
                        type,
                      },
                    });
                  }}
                  onSharePost={({
                    message: postShareMessage,
                    postId,
                    postType,
                    shareOption,
                    communities,
                    communityId: sharePostCommunityId,
                  }) => {
                    sharePost({
                      variables: {
                        request: {
                          message: postShareMessage,
                          postId,
                          postType,
                          shareOption,
                          communities,
                          communityId: communityId ? communityId : sharePostCommunityId,
                        },
                      },
                    });
                  }}
                  onTogglePin={(postId) =>
                    togglePinnedPost({
                      variables: {
                        postId,
                      },
                    })
                  }
                  onUnPublish={(postId) => {
                    handleUpdatePostStatus({ postId, status: EEntryStatus.Unpublished });
                  }}
                  onUnPublishComment={(commentId) => {
                    handleUpdateCommentStatus({ commentId, status: EEntryStatus.Unpublished });
                  }}
                  onVotePoll={({ id: pollId, userVote }) => {
                    voteAPoll({
                      variables: {
                        itemId: Number(userVote?.pollItemId),
                        pollId,
                      },
                    });
                  }}
                />
              );
            },
          )
          .slice(0, getLatestPosts)}
      {!isPostsLoading && Boolean(!posts.length) && !shouldShowCommunitiesMessage && <EmptyMessage message="No post's found" />}

      {!isPostsLoading && shouldShowCommunitiesMessage && (
        <EmptyMessage
          icon={() => <IconCommunity />}
          message={
            <>
              It looks like you aren’t connected to a community,{' '}
              <NextLink href="/community/communities">
                <span>click here to connect.</span>
              </NextLink>{' '}
            </>
          }
        />
      )}

      {!isPostsLoading && Boolean(posts.length) && !postIdProp && !shouldShowCommunitiesMessage && !getLatestPosts && (
        <LoadMore
          ref={loadMoreRef}
          fetchText="Loading more"
          finishedText="No more posts"
          hasReachedTotal={totalPostsCount === posts.length}
        />
      )}
    </div>
  );
};

export { Feed };
