import { EAssistItem, EK10DistressCategory, ERiskCategory } from 'components';

export const getAssistItemDifferenceFeedback = ({
  substance,
  latestRisk,
  latestScore,
  previousRisk,
  previousScore,
}: {
  latestRisk?: ERiskCategory;
  latestScore?: number;
  previousRisk?: ERiskCategory;
  previousScore?: number;
  substance?: EAssistItem;
}) => {
  return (
    <>
      {latestRisk === ERiskCategory.no && previousRisk === ERiskCategory.no ? null : (
        <>
          <p className="mb-0">
            Your current score for <strong>{substance}</strong> is {latestScore} out of 39, which places you in the{' '}
            <strong>{latestRisk}</strong> risk category.{' '}
          </p>
          <p className="mb-0">
            Your previous score for {substance} was {previousScore} out of 39, which placed you in the {previousRisk} risk category.
          </p>
          {Number(latestScore) > Number(previousScore) ? (
            <p>
              Since your last self-assessment, your risk for experiencing problems related to your use of {substance} has{' '}
              <strong>increased</strong>.
            </p>
          ) : null}

          {Number(latestScore) < Number(previousScore) ? (
            <p>
              Since your last self-assessment, your risk for experiencing problems related to your use of {substance} has{' '}
              <strong>decreased</strong>.
            </p>
          ) : null}

          {Number(latestScore) === Number(previousScore) ? (
            <p>
              Since your last self-assessment, your risk for experiencing problems related to your use of {substance} has{' '}
              <strong>stayed the same</strong>.
            </p>
          ) : null}
        </>
      )}
    </>
  );
};

export const getK10Feedback = (category: EK10DistressCategory, supplementaryScore: number) => {
  if (category === EK10DistressCategory.low) {
    return supplementaryScore === 0 ? (
      <p>
        You may currently be experiencing <strong>low</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may currently be experiencing <strong>low</strong> psychological distress, which appears to be impacting your work, study or
        daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.moderate) {
    return supplementaryScore === 0 ? (
      <p>
        You may currently be experiencing <strong>moderate</strong> psychological distress, which could impact your work, study or daily
        life.
      </p>
    ) : (
      <p>
        You may currently be experiencing <strong>moderate</strong> psychological distress, which appears to be impacting your work, study
        or daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.high) {
    return supplementaryScore === 0 ? (
      <p>
        You may currently be experiencing <strong>high</strong> psychological distress, which could impact your work, study or daily life.
      </p>
    ) : (
      <p>
        You may currently be experiencing <strong>high</strong> psychological distress, which appears to be impacting your work, study or
        daily life.
      </p>
    );
  }

  if (category === EK10DistressCategory.very) {
    return supplementaryScore === 0 ? (
      <p>
        You may currently be experiencing <strong>very high</strong> psychological distress, which could impact on your work, study or daily
        life.
      </p>
    ) : (
      <p>
        You may currently be experiencing <strong>very high</strong> psychological distress, which appears to be impacting your work, study
        or daily life.
      </p>
    );
  }
};

export const getAssistCategoryDifference = ({
  latestCategory,
  previousCategory,
}: {
  latestCategory: ERiskCategory;
  previousCategory: ERiskCategory;
}) => {
  if (
    (previousCategory === ERiskCategory.high && latestCategory === ERiskCategory.moderate) ||
    (previousCategory === ERiskCategory.moderate && latestCategory === ERiskCategory.low) ||
    (previousCategory === ERiskCategory.high && latestCategory === ERiskCategory.low)
  ) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has moved from {previousCategory} to {latestCategory}. This is great
        to see. Based on this change, you may be interested in some of our updated suggestions below.{' '}
      </p>
    );
  }

  if (
    (previousCategory === ERiskCategory.low && latestCategory === ERiskCategory.moderate) ||
    (previousCategory === ERiskCategory.high && latestCategory === ERiskCategory.high) ||
    (previousCategory === ERiskCategory.moderate && latestCategory === ERiskCategory.high)
  ) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has moved from {previousCategory} to {latestCategory}. This suggests
        that you may need more support for your substance use than you did previously. Based on this change, you may be interested in some
        of our updated suggestions below.
      </p>
    );
  }

  if (
    (previousCategory === ERiskCategory.no && latestCategory === ERiskCategory.low) ||
    (previousCategory === ERiskCategory.no && latestCategory === ERiskCategory.moderate) ||
    (previousCategory === ERiskCategory.no && latestCategory === ERiskCategory.high)
  ) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has moved from no risk to {latestCategory} risk. This suggests you
        may now need some support for your substance use. Based on this change, you may be interested in some of the suggestions below.
      </p>
    );
  }

  if (
    (previousCategory === ERiskCategory.low && latestCategory === ERiskCategory.no) ||
    (previousCategory === ERiskCategory.moderate && latestCategory === ERiskCategory.no) ||
    (previousCategory === ERiskCategory.high && latestCategory === ERiskCategory.no)
  ) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has moved from {previousCategory} risk to no risk. This is really
        great to see, and we hope you have experienced some of the positive effects from this change in your life. You may be interested in
        some of the suggestions below to help you maintain this change.
      </p>
    );
  }

  if (previousCategory === ERiskCategory.no && latestCategory === ERiskCategory.no) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has stayed at <strong>no risk</strong>. This is great to see and we
        encourage you to maintain this no risk level of substance use. To do this, you may be interested in some of our suggestions below.
      </p>
    );
  }

  if (previousCategory === ERiskCategory.low && latestCategory === ERiskCategory.low) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has stayed at low risk. This is great to see and we encourage you to
        maintain this low risk level of substance use. You may be interested in some of our suggestions below to do this.
      </p>
    );
  }

  if (previousCategory === ERiskCategory.moderate && latestCategory === ERiskCategory.moderate) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has stayed at moderate risk. You may still be experiencing some
        problems with your substance use since your last self-assessment. You may be interested in some of our suggestions below.
      </p>
    );
  }

  if (previousCategory === ERiskCategory.high && latestCategory === ERiskCategory.high) {
    return (
      <p>
        It looks like your risk category for alcohol and/or other drugs has stayed at high risk. You may still be experiencing severe
        problems with your substance use since your last self-assessment and require more specialist support. You may be interested in some
        of our suggestions below.
      </p>
    );
  }
};
