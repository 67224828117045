import { useToggle } from "@netfront/common-library";

import { CardTooltipProps } from "./CardTooltip.interfaces"

export const CardTooltip = ({ text, isFitContentWidth = false, isDynamicallyPositioned = false }: CardTooltipProps) => {
  const { isToggled, toggle } = useToggle();

  const positionClasses = isDynamicallyPositioned ? 'bottom-0 right-13 md:bottom-0 md:right-13 lg:bottom-unset lg:top-0 lg:right-0': 'top-0 right-0';

  return(
    <div className={`relative pointer ${isFitContentWidth ? 'w-fit-content' : ''}`} onMouseEnter={toggle} onMouseLeave={toggle}>
      <div className='bg-secondary color-white h-6 w-6 rounded-circle flex items-center justify-center weight-600'>
        ?
      </div>
      {isToggled && (
        <div className={`absolute bg-white border border-athens p-4 rounded text-sm w-48 ${positionClasses} translate-x-100`}>{text}</div>
      )}
    </div>
  )
};
