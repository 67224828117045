import { IconCross } from '../Icons';

import { PostAssestPreviewProps } from './PostAssestPreview.interfaces';

export const PostAssestPreview = ({ children, onClick }: PostAssestPreviewProps) => {
  return (
    <div className="c-image-preview">
      {children}
      <button className="c-image-preview__button" title="Remove image" onClick={onClick}>
        <IconCross additionalClassNames="c-image-preview__icon" />
        <span className="h-hide-visually">Cancel image</span>
      </button>
    </div>
  );
};
