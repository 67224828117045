import { PageHeadingProps } from './PageHeading.interfaces';

import { getValidClassNames } from '../../utils';

const PageHeading = ({ additionalClassNames, title }: PageHeadingProps) => {
  return (
    <div className="py-8">
      <div
        className={getValidClassNames({
          container: true,
          [String(additionalClassNames)]: Boolean(additionalClassNames),
        })}
      >
        <h1 className="h2 color-primary mb-0">{title}</h1>
      </div>
    </div>
  );
};

export { PageHeading };
