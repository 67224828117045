import { gql } from '@apollo/client';

const UPDATE_STATUS = gql`
  mutation updateStatus($postId: Int!, $status: EEntryStatus!) {
    post {
      updateStatus(postId: $postId, status: $status)
    }
  }
`;

export { UPDATE_STATUS };
