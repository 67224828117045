import { RelateIcon } from '../RelateIcon';

import { NotificationItemProps } from './NotificationItem.interfaces';

import { getValidClassNames } from '../../../utils';

export const NotificationItem = ({ date, description, displayName, relateType, isRead, tag, onClick }: NotificationItemProps) => {
  return (
    <div className="c-notification-item" onClick={onClick}>
      {relateType && (
        <div className="c-notification-item__relate">
          <RelateIcon relateType={relateType} />
        </div>
      )}

      <p className="c-notification-item__message">
        <span className="c-notification-item__display-name">{displayName} </span>
        <span className="c-notification-item__description">{description} </span>
      </p>

      {tag && (
        <div className='c-notification-item__tag'>{tag}</div>
      )}

      <span
        className={getValidClassNames({
          'c-notification-item__status': true,
          'c-notification-item__status--read': Boolean(isRead),
        })}
      >
        {isRead ? 'Read' : 'Unread'}
      </span>

      <span className="c-notification-item__date">{date}</span>
    </div>
  );
};
