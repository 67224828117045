import { Select } from '@netfront/ui-library';

import { FILTER_BY_TIME } from './TimelineSelect.constants';
import { TimelineSelectProps } from './TimelineSelect.interfaces';

export const TimelineSelect = ({ value: selectedTimeId, onChange }: TimelineSelectProps) => {
  return (
    <Select
      id="date"
      labelText="Date range"
      name="date"
      options={FILTER_BY_TIME.map(({ label, value }) => ({
        id: value,
        name: label,
        value,
      }))}
      value={selectedTimeId}
      isLabelHidden
      onChange={onChange}
    />
  );
};
