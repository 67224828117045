import { useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Button, Dialog, RadioButtonGroup } from '@netfront/ui-library';
import pluralize from 'pluralize';

import { CommentBox } from '../CommentBox';
import { IconLike } from '../Icons';
import { IUpdateRelateParams, getMessageMarkup } from '../Post';
import { MAX_NESTED_COMMENT_DEPTH, REPORT_LABELS } from '../Post/Post.constants';
import { ReactionToolbar, useReactionToolbar } from '../ReactionToolbar';
import { UserDetailsHeader } from '../UserDetailsHeader';

import { CommentProps } from './Comment.interfaces';

import { CommentConnection, CommentGraphType, ECommunityRole, ERelateType, EReportType } from '../../../services';
import { formatDate, formatTime, getValidClassNames } from '../../../utils';

const Comment = ({
  additionalClassNames,
  canDelete,
  canUnpublish,
  commentor,
  community,
  shouldHideReplyButton,
  comments,
  subCommentsPageInfo,
  date,
  depth,
  hasRelated: hasRelatedProp = false,
  message,
  maxNestedReplyLevel = 0,
  id,
  isCommentsLoading,
  onCommentDelete,
  onCommentRelate,
  onCommentReply,
  onGetMoreSubComments,
  isInReview,
  onPublish,
  onUnPublish,
  onReport,
  totalRepliesCount = 0
}: CommentProps) => {
  const { isReactionToolbarOpen, closeReactToolBar, openReactToolBar } = useReactionToolbar();

  const { isToggled: isDeleteCommentDialogOpen, toggle: toggleDeleteCommentDialog } = useToggle();
  const { isToggled: isUnpublishCommentDialogOpen, toggle: toggleUnpublishCommentDialog } = useToggle();

  const [hasRelated, setHasRelated] = useState<boolean>(hasRelatedProp);
  const [isReplyVisible, setIsReplyVisible] = useState<boolean>(false);
  const { isToggled: isReportPostDialogOpen, toggle: toggleReportPostDialog } = useToggle();
  const [reportType, setReportType] = useState<EReportType>(EReportType.Harassment);
  const [reply, setReply] = useState<string>('');

  const currentDepth = depth + 1;

  const onRelateClick = ({ action, hasRelated: hasRelatedToComment, id: commentId, relateType }: IUpdateRelateParams) => {
    if (onCommentRelate) {
      onCommentRelate({
        action,
        hasRelated: hasRelatedToComment,
        id: commentId,
        relateType,
      });
    }
    setHasRelated(hasRelatedToComment);
  };

  const onReplyChange = (value: string) => {
    setReply(value);
  };

  const onReplyClick = () => {
    if (onCommentReply) {
      onCommentReply(id, reply);
    }

    setIsReplyVisible(false);
    setReply('');
  };

  const toggleReply = () => setIsReplyVisible(!isReplyVisible);

  return (
    <div
      className={getValidClassNames({
        'c-comment': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
    >
      <div className="h-flex">
        <div className="c-comment__avatar">
          <UserDetailsHeader {...commentor} displayName="" />
        </div>

        <div className="c-comment__content">
          <span className="c-comment__display-name">{commentor.displayName}</span>

          <span className="c-comment__date">{date && ` - ${formatDate(date)} ${formatTime(date)}`}</span>

          <div className="c-comment__message">
            <p
              dangerouslySetInnerHTML={{
                __html: getMessageMarkup(String(message).replaceAll('\n', '<br />'), commentor.isModerator ?? false),
              }}
            ></p>
          </div>

          <div className="h-flex">
            {isInReview ? (
              <>
                {onPublish && (
                  <button className="c-comment__button" onClick={() => onPublish(id)}>
                    Publish
                  </button>
                )}

                {onUnPublish && (
                  <button
                    className="c-comment__button"
                    onClick={() => {
                      onUnPublish(id);
                    }}
                  >
                    Unpublish
                  </button>
                )}
              </>
            ) : (
              <>
                <ReactionToolbar
                  isReactionToolbarOpen={isReactionToolbarOpen}
                  onMouseEnter={openReactToolBar}
                  onMouseLeave={closeReactToolBar}
                  onRelateClick={(relateType) => {
                    onRelateClick({
                      action: hasRelated ? 'CHANGE' : 'ADD',
                      hasRelated: true,
                      id,
                      relateType,
                    });

                    closeReactToolBar();
                  }}
                >
                  <button
                    className="c-comment__button"
                    onClick={() => {
                      onRelateClick({
                        action: hasRelated ? 'REMOVE' : 'ADD',
                        hasRelated: !hasRelated,
                        id,
                        relateType: ERelateType.Like,
                      });
                    }}
                  >
                    <IconLike additionalClassNames="c-comment__icon" isActive={hasRelated} />
                    {hasRelated ? 'Related' : 'Relate'}
                  </button>
                </ReactionToolbar>

                {!shouldHideReplyButton && (
                  <button className="c-comment__button" onClick={toggleReply}>
                    Reply {Boolean(comments?.length) && `(${Number(totalRepliesCount)})`}
                  </button>
                )}

                {canDelete ? (
                  <button
                    className="c-comment__button"
                    onClick={() => {
                      toggleDeleteCommentDialog();
                    }}
                  >
                    Delete
                  </button>
                ) : canUnpublish ? (
                  <>
                    <button
                      className="c-comment__button"
                      onClick={() => {
                        toggleReportPostDialog();
                      }}
                    >
                      Report
                    </button>
                    <div style={{ marginRight: 10 }} />
                    <button
                      className="c-comment__button"
                      onClick={() => {
                        toggleUnpublishCommentDialog();
                      }}
                    >
                      Unpublish
                    </button>
                  </>
                ) : (
                  <button
                    className="c-comment__button"
                    onClick={() => {
                      toggleReportPostDialog();
                    }}
                  >
                    Report
                  </button>
                )}
              </>
            )}
          </div>

          {isReplyVisible && (
            <form className="c-comment__reply">
              <CommentBox
                id="reply"
                labelText="Reply"
                name="reply"
                placeholder={`Reply to ${String(commentor.displayName)}...`}
                value={reply}
                isLabelHidden
                onChange={onReplyChange}
              />

              <Button isDisabled={Boolean(!reply)} size="xs" text="Reply" onClick={onReplyClick} />
            </form>
          )}
        </div>
      </div>
      {comments && (
        <div className='mt-4'>
          {comments.map(
            (
              {
                author,
                comments: subComments,
                createdDateTime,
                hasRelated: hasCommentRelated,
                message: subMessage,
                id: commentId,
              }: CommentGraphType,
              key,
            ) => {
              const { edges, pageInfo: replyPageInfo, totalCount: repliesCount = 0 } = subComments ?? ({} as CommentConnection);
              const replies = edges?.map(({ node }) => node);

              const commentorRole = author?.communityConnections?.edges
                ?.map(({ node }) => node)
                .find((c) => c?.communityId === community?.id)?.role;

              return (
                <Comment
                  key={key}
                  additionalClassNames="c-comment--sub"
                  canDelete={canDelete}
                  commentor={{
                    avatar: author?.profileImage?.presignedUrl,
                    displayName: String(author?.displayedName),
                    isOnline: author?.isOnline,
                    size: 'small',
                    isModerator: commentorRole === ECommunityRole.Moderator,
                  }}
                  comments={replies}
                  community={community}
                  date={createdDateTime}
                  depth={currentDepth}
                  hasRelated={hasCommentRelated}
                  id={commentId}
                  maxNestedReplyLevel={MAX_NESTED_COMMENT_DEPTH}
                  message={subMessage}
                  shouldHideReplyButton={currentDepth >= maxNestedReplyLevel}
                  subCommentsPageInfo={replyPageInfo}
                  totalRepliesCount={repliesCount}
                  onCommentDelete={onCommentDelete}
                  onCommentRelate={onCommentRelate}
                  onCommentReply={onCommentReply}
                  onGetMoreSubComments={onGetMoreSubComments}
                />
              );
            },
          )}
          {currentDepth > 0 && subCommentsPageInfo && subCommentsPageInfo.hasNextPage && (
            <Button
              additionalClassNames={`c-comment-sub ml-24 p-0 bg-transparent color-grey-300 hover:bg-transparent`}
              isLoading={isCommentsLoading}
              size="xs"
              text={`View ${totalRepliesCount - comments.length} more ${pluralize('reply', totalRepliesCount - comments.length)}`}
              onClick={() => onGetMoreSubComments?.(id, subCommentsPageInfo.endCursor as string)}
            />
          )}
        </div>
      )}

      {isDeleteCommentDialogOpen && (
        <Dialog
          title="Delete comment"
          isOpen
          onCancel={toggleDeleteCommentDialog}
          onClose={toggleDeleteCommentDialog}
          onConfirm={() => {
            if (onCommentDelete) {
              onCommentDelete(id);
            }
          }}
        >
          Are you sure you want to delete this comment?
        </Dialog>
      )}
      {isUnpublishCommentDialogOpen && (
        <Dialog
          title="Unpublish comment"
          isOpen
          onCancel={toggleUnpublishCommentDialog}
          onClose={toggleUnpublishCommentDialog}
          onConfirm={() => {
            if (onUnPublish) {
              onUnPublish(id);
            }
          }}
        >
          Are you sure you want to unpublish this comment?
        </Dialog>
      )}
      {isReportPostDialogOpen && (
        <Dialog
          title="Report comment"
          isOpen
          onCancel={toggleReportPostDialog}
          onClose={toggleReportPostDialog}
          onConfirm={() => {
            if (onReport) {
              onReport(id, reportType);
              toggleReportPostDialog();
            }
          }}
        >
          Are you sure you want to report this comment?
          <RadioButtonGroup
            items={Object.keys(REPORT_LABELS).map((type, key) => ({
              id: `report-${String(key)}`,
              labelText: REPORT_LABELS[type],
              value: type,
            }))}
            name="reportType"
            selectedValue={reportType}
            onChange={({ target: { value } }) => setReportType(value as EReportType)}
          />
        </Dialog>
      )}
    </div>
  );
};

export { Comment };
