import { Button } from "@netfront/ui-library"

export const LatestSelfAssessmentResultCardEmpty = () => {
  return(
    <div className="bg-white p-8 border-2 border-athens rounded-lg h-full">
      <div className="flex flex-col xl:flex-row items-center h-full">
        <div className="mb-2 xl:mb-0">
          <img alt="Exclamation mark" className="max-w-20" src="/images/no-goal-icon.svg" />
        </div>

        <div className="flex flex-col xl:flex-row flex-1">
          <div className="px-6 flex-1">
            <span className="color-black h5 weight-800 mb-0 block">No self-assessment results yet.</span>
            <p className="mb-4 xl:mb-0">Complete your first self-assessment to receive your personalised feedback.</p>
          </div>
          <Button linkButton={{ id: 'self-assessment', url: '/self-assessment/introduction' }} text="Go to self-assessment" />
        </div>
      </div>
    </div>
  )
}