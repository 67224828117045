import NextLink from 'next/link';

import { DashboardCardProps } from './DashboardCard.interfaces';

import { ArrowRightIcon, CardTooltip } from '../../components';
import { getValidClassNames } from '../../utils';

export const DashboardCard = ({ cardTooltip, children, href, isCustomCard = false, linkLabel }: DashboardCardProps) => {
  return (
    <div
      className={getValidClassNames({
        'flex flex-col h-full': !isCustomCard,
      })}
    >
      <div className='flex items-center mb-3'>
        {linkLabel && (
          <NextLink href={String(href)}>
            <span className="h6 color-secondary weight-800 flex items-center mr-3 mb-0">
              {linkLabel}
              <div className="bg-primary color-white rounded-circle h-4 w-4 flex items-center justify-center ml-2 -mt-3px">
                <ArrowRightIcon additionalClassNames="w-2 h-2" />
              </div>
            </span>
          </NextLink>
        )}

        {cardTooltip && (
          <CardTooltip
            text={cardTooltip.text}
          />
        )}
      </div>

      {isCustomCard ? children : <div className="bg-white relative p-8 border-2 border-athens rounded-lg h-full">{children}</div>}
    </div>

  );
};
