export enum EAssistItem {
  smoking = 'smoking',
  alcohol = 'alcohol',
  cannabis = 'cannabis',
  cocaine = 'cocaine',
  amphetamine = 'amphetamine',
  inhalants = 'inhalants',
  sedatives = 'sedatives',
  hallucinogens = 'hallucinogens',
  opioids = 'opioids',
  other = 'other',
}

export interface IAssistScore extends Record<EAssistItem, number | null> {}

export enum ERiskCategory {
  no = 'no',
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export enum EK10DistressCategory {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
  very = 'very',
}
