import { IReportCommentOptions } from './useReportComment.interface';

import { bonoboClient } from '../../apollo';
import { useReportCommentMutation } from '../../bonobo/generated.graphql';

export const useReportComment = (options?: IReportCommentOptions) => {
  const { onCompleted, onError } = options ?? ({} as IReportCommentOptions);

  const [reportComment, { loading: isLoading, error }] = useReportCommentMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { reportComment, isLoading, error };
};
