import { IconLikeProps } from './IconLike.interfaces';

const IconLike = ({ additionalClassNames, isActive = false }: IconLikeProps) => {
  return isActive ? (
    <svg
      className={additionalClassNames}
      height="18.586"
      id="icon-relate-active"
      viewBox="0 0 18.586 18.586"
      width="18.586"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.586,9.293A9.293,9.293,0,1,1,9.293,0a9.293,9.293,0,0,1,9.293,9.293"
        data-name="Path 529"
        fill="#2251AC"
        id="Path_529"
      ></path>
      <g data-name="Group 48771" id="Group_48771" transform="translate(0 -2.707)">
        <path
          className="c-icon__path"
          d="M8.314,6.1V4.329A1.329,1.329,0,0,0,6.986,3L5.214,6.986v4.871h5A.886.886,0,0,0,11.1,11.1l.611-3.986A.886.886,0,0,0,10.821,6.1Zm-3.1,5.757H3.886A.886.886,0,0,1,3,10.971v-3.1a.886.886,0,0,1,.886-.886H5.214"
          data-name="Icon feather-thumbs-up"
          fill="none"
          id="Icon_feather-thumbs-up"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(2.143 4.572)"
        ></path>
      </g>
    </svg>
  ) : (
    <svg
      className={additionalClassNames}
      height="18.586"
      id="icon-relate-inactive"
      viewBox="0 0 18.586 18.586"
      width="18.586"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.586,9.293A9.293,9.293,0,1,1,9.293,0a9.293,9.293,0,0,1,9.293,9.293" data-name="Path 529" fill="#fff" id="Path_529" />
      <g data-name="Group 48552" id="Group_48552" transform="translate(0 -2.707)">
        <path
          className="c-icon__path"
          d="M8.314,6.1V4.329A1.329,1.329,0,0,0,6.986,3L5.214,6.986v4.871h5A.886.886,0,0,0,11.1,11.1l.611-3.986A.886.886,0,0,0,10.821,6.1Zm-3.1,5.757H3.886A.886.886,0,0,1,3,10.971v-3.1a.886.886,0,0,1,.886-.886H5.214"
          data-name="Icon feather-thumbs-up"
          fill="none"
          id="Icon_feather-thumbs-up"
          stroke="#2251AC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          transform="translate(2.143 4.572)"
        />
      </g>
    </svg>
  );
};

export { IconLike };
