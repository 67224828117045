import { Skeleton } from 'components';

import { ESelfAssessmentIndicatorTheme, SelfAssessmentIndicatorProps } from './SelfAssessmentIndicator.interfaces';

import { getValidClassNames } from '../../utils';

const SelfAssessmentIndicator = ({
  additionalClassNames,
  isLoading,
  max,
  min = 0,
  theme = ESelfAssessmentIndicatorTheme.blue,
  values,
}: SelfAssessmentIndicatorProps) => {
  return isLoading ? (
    <>
      <Skeleton borderRadius="40px" height="1.5rem" width="100%" />
    </>
  ) : (
    <div
      className={getValidClassNames({
        'relative rounded-full w-full h-5 bg-blue-gradient flex items-center justify-between p-3': true,
        [`${String(additionalClassNames)}`]: Boolean(additionalClassNames),
        ['bg-blue-gradient color-white']: theme === ESelfAssessmentIndicatorTheme.blue,
        ['bg-yellow-gradient color-black']: theme === ESelfAssessmentIndicatorTheme.yellow,
      })}
    >
      <span className="text-sm font-header weight-600 block mb-0 line-1 mt-4px">{min}</span>
      <span className="text-sm font-header weight-600 block mb-0 line-1 mt-4px">{max}</span>
      {values?.map(({ label, value }) => {
        const percentageScore = ((Number(value) - min) / (Number(max) - Number(min))) * 100;

        return (
          <div
            key={`${String(label)}-marker`}
            className={getValidClassNames({
              'absolute rounded-circle h-12 w-12 flex items-center justify-center top-1/2 -translate-x-50': true,
              ['bg-blue-300 color-white']: theme === ESelfAssessmentIndicatorTheme.blue,
              ['bg-yellow-500 color-black']: theme === ESelfAssessmentIndicatorTheme.yellow,
            })}
            style={{ left: `${percentageScore === 100 ? 98 : value === min ? 2 : percentageScore}%` }}
          >
            {label && (
              <span className="text-xs absolute top-0 left-1/2 color-secondary bg-white px-1 rounded translate-top-center">
                {label}
              </span>
            )}

            <span className="block mb-0 h5 weight-500 mt-4px">{value}</span>
          </div>
        );
      })}
    </div>
  );
};

export { SelfAssessmentIndicator };
