import { Accept } from 'react-dropzone';

import { DROPZONE_FILE_PATHS } from './DropzoneFileUpload.constants';

export const getAcceptedFileTypeMap = (acceptedFileTypes: string[] = []) => {
  const mappedFileTypes = Object.keys(DROPZONE_FILE_PATHS).reduce((acc, key) => {
    const isGeneralFilterType = acceptedFileTypes.includes(key);

    const matchingTypes = DROPZONE_FILE_PATHS[key as keyof Accept].filter((fileType) => {
      if (acceptedFileTypes.includes(fileType)) return fileType;
      return;
    });

    if (matchingTypes.length && !isGeneralFilterType) {
      acc = {
        ...acc,
        [key]: matchingTypes,
      };
    }

    if (isGeneralFilterType) {
      acc = {
        ...acc,
        [key]: DROPZONE_FILE_PATHS[key as keyof Accept],
      };
    }
    return acc;
  }, {});
  return mappedFileTypes;
};
