import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { DEFINE_IS_MARKED } from './useDefineIsMarked.graphql';
import {
  IHandleDefineIsMarkedParams,
  IDefineIsMarkedQueryGraphQLResponse,
  IUseDefineIsMarked,
  IUseDefineIsMarkedOptions,
} from './useDefineIsMarked.interfaces';

const useDefineIsMarked = (options?: IUseDefineIsMarkedOptions): IUseDefineIsMarked => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseDefineIsMarkedOptions);

  const [executeDefineIsMarked, { loading: isLoading }] = useEkardoMutation<IDefineIsMarkedQueryGraphQLResponse>({
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userFlowStepTrack: { defineIsMarked },
        } = data;

        onCompleted({
          tracking: defineIsMarked,
        });
      },
      onError,
    },
    product,
    mutation: DEFINE_IS_MARKED,
    token,
  });

  const handleDefineIsMarked = async (params: IHandleDefineIsMarkedParams) => {
    await executeDefineIsMarked({
      variables: params,
    });
  };

  return {
    handleDefineIsMarked,
    isLoading,
  };
};

export { useDefineIsMarked };
