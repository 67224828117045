import { gql } from '@apollo/client';

const ADD_CUSTOM_EVENT = gql`
  mutation addCustomEvent($metadata: String, $projectId: String!, $type: String!) {
  events {
    addCustom(metadata: $metadata, projectId: $projectId, type: $type)
  }
}`;

export { ADD_CUSTOM_EVENT };
