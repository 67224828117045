import { isWithinInterval, subHours, subWeeks } from "date-fns";

export const getWelcomeTag = (date: Date): string => {
  if (isWithinInterval(date, {
    start: subHours(new Date(), 24),
    end: new Date(),
  })) {
    return 'Joined in the last 24 hours'
  }

  if (isWithinInterval(date, {
    start: subWeeks(new Date(), 1),
    end: new Date(),
  })) {
    return 'Joined in the last week'
  }

  return ''
};