import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Button, Dialog } from '@netfront/ui-library';
import { Announce, BreadcrumbLink, CommunityFeedHeader, CommunityWarningPrompt, InformationBanner, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { DBCommunity } from '../../../../interfaces';
import {
  EAssetType,
  ECommunityAssetUsage,
  ECommunityPrivacy,
  ECommunityRole,
  EConnectionStatus,
  JoinCommunityMutation,
  useCreateCommunityAsset,
  useGetCommunity,
  useJoinCommunity,
  useToggleAlumni,
} from '../../../../services';
import {
  CommunityModeratorButtons,
  EmptyMessage,
  Feed,
  IconLock,
  PageBanner,
  ProfileAvatarDialog,
  ProfileCoverDialog,
} from '../../../Social';

const CommunityPage = () => {
  const PAGE_TITLE = 'Community feed';

  const {
    asPath,
    push,
    query: { communityKey, hashtag },
  } = useRouter();

  const { isToggled: isAvatarDialogOpen, toggle: toggleAvatarDialog } = useToggle();
  const { isToggled: isCoverDialogOpen, toggle: toggleCoverDialog } = useToggle();

  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<DBCommunity>();

  const [connectionStatus, setConnectionStatus] = useState<EConnectionStatus | null>();

  const hasEditRole =
    community?.userConnection?.role === ECommunityRole.Moderator || community?.userConnection?.role === ECommunityRole.Owner;
  
  const isAlumni = community?.userConnection?.status === EConnectionStatus.Alumni;



  const getCommunityCompleted = (returnedCommunity: DBCommunity) => {
    const { userConnection } = returnedCommunity;

    setCommunity(returnedCommunity);
    setConnectionStatus(userConnection?.status ?? null);
  };

  const handleJoinCommunityCompleted = (communityConnection: JoinCommunityMutation) => {
    const newConnection = communityConnection.communityConnection?.joinCommunity;

    setConnectionStatus(newConnection?.status);

    closeConnectDialog();
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getCommunity } = useGetCommunity({
    onCompleted: getCommunityCompleted,
    onError: () => push('/community/communities'),
  });

  const { joinCommunity } = useJoinCommunity({
    onCompleted: handleJoinCommunityCompleted,
    onError: () => setIsConnectDialogOpen(false),
  });

  const { createCommunityAsset } = useCreateCommunityAsset();

  const { toggleAlumni, isLoading: isToggleLoading } = useToggleAlumni({
    onCompleted: () => {
      push('/community/communities')
    }
  });

  const closeConnectDialog = () => {
    setIsConnectDialogOpen(false);
  };

  const onConnectRequest = () => {
    if(isAlumni){
      toggleAlumni({ variables: { communityId: community.id, isAlumni: false }})
    }
    else{

      joinCommunity({
        variables: {
          communityId: Number(community?.id),
        },
      });
    }
  };

  useEffect(() => {
    if (community || !communityKey) {
      return;
    }

    getCommunity({ variables: { key: String(communityKey), shouldIncludeUserConnection: true } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityKey, connectionStatus]);


  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community/communities">Communities</BreadcrumbLink>, key: 'Communities' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `View all posts within ${String(community?.title)}`,
        seoTitle: `${community?.title ?? ''} community feed`,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <Announce location="community" />

      <CommunityWarningPrompt />
      
      {community && (
        <>
          <PageBanner
            bannerImage={community.bannerImage?.presignedUrl}
            canEdit={hasEditRole}
            options={[
              { label: 'Leave community ', action: () => toggleAlumni( { variables : {communityId: Number(community.id), isAlumni: true} }) }
            ]}
            profileImage={community.profileImage?.presignedUrl}
            title={community.title}
            onAvatarClick={toggleAvatarDialog}
            onBannerClick={toggleCoverDialog}
          >
            {connectionStatus === EConnectionStatus.Accepted ? (
              <CommunityModeratorButtons community={community} />
            ) : (
              <Button
                isDisabled={connectionStatus === EConnectionStatus.Requested && community.privacy !== ECommunityPrivacy.Public || isToggleLoading}
                isLoading={isToggleLoading}
                text={connectionStatus === EConnectionStatus.Requested ? 'Request sent' : 'Connect'}
                onClick={() => setIsConnectDialogOpen(true)}
              />
            )}
          </PageBanner>
          <div className="c-community-page__container">
  
            {connectionStatus === EConnectionStatus.Accepted || community.privacy === ECommunityPrivacy.Public ? (
              <>
                <CommunityFeedHeader community={community} />
                <InformationBanner theme='hint'>
                  <p>Moderation hours are from 3-7am and 3-7pm, 7 days a week.</p>
                </InformationBanner>
                <Feed communityId={Number(community.id)} status="ACTIVE" tags={hashtag ? [`#${String(hashtag)}`] : []} />
              </>
            ) : (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(community.title)}'s feed`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${community.title}`}
            onCancel={closeConnectDialog}
            onClose={closeConnectDialog}
            onConfirm={onConnectRequest}
          >
            <p>Are you sure you want to connect with {community.title}?</p>
          </Dialog>

          <ProfileAvatarDialog
            isOpen={isAvatarDialogOpen}
            title="Change the community avatar"
            onClose={toggleAvatarDialog}
            onConfirm={(imageFile) => {
              createCommunityAsset({
                variables: {
                  alt: community.title,
                  communityId: community.id,
                  contentType: imageFile.type,
                  fileName: imageFile.name,
                  fileSizeInBytes: imageFile.size,
                  type: EAssetType.Image,
                  usage: ECommunityAssetUsage.Profile,
                  shouldIncludeAsset: true,
                },
              }).then(async ({ data }) => {
                const presignedUrl = data?.asset?.createCommunityAsset?.asset?.presignedUrl;
                const signedUrl = data?.asset?.createCommunityAsset?.signedUrl;

                await fetch(signedUrl as string, {
                  method: 'PUT',
                  body: imageFile,
                }).then(() => {
                  setCommunity({
                    ...community,
                    profileImage: {
                      presignedUrl,
                    },
                  });

                  toggleAvatarDialog();
                });
              });
            }}
          />

          <ProfileCoverDialog
            isOpen={isCoverDialogOpen}
            title="Change your community cover image"
            onClose={toggleCoverDialog}
            onConfirm={(imageFile) => {
              createCommunityAsset({
                variables: {
                  alt: community.title,
                  communityId: community.id,
                  contentType: imageFile.type,
                  fileName: imageFile.name,
                  fileSizeInBytes: imageFile.size,
                  type: EAssetType.Image,
                  usage: ECommunityAssetUsage.Banner,
                  shouldIncludeAsset: true,
                },
              }).then(async ({ data }) => {
                const presignedUrl = data?.asset?.createCommunityAsset?.asset?.presignedUrl;
                const signedUrl = data?.asset?.createCommunityAsset?.signedUrl;

                await fetch(signedUrl as string, {
                  method: 'PUT',
                  body: imageFile,
                }).then(() => {
                  setCommunity({
                    ...community,
                    bannerImage: {
                      presignedUrl,
                    },
                  });

                  toggleCoverDialog();
                });
              });
            }}
          />
        </>
      )}
    </Page>
  );
};

export { CommunityPage };
