import React from 'react';

import { HomeIcon } from '../Icons';

import { BreadcrumbItemLink } from './BreadcrumbItemLink';

const BreadcrumbItemDashboardPageLink = () => {
  return (
    <BreadcrumbItemLink
      content={
        <>
          <span className="sr-only">Go to dashboard</span>
          <HomeIcon />
        </>
      }
      href="/dashboard"
    />
  );
};

export { BreadcrumbItemDashboardPageLink };
