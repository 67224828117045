import { gql } from '@apollo/client';

const SET_USER_ANSWER_CHECKBOX = gql`
  mutation setUserAnswer(
    $contentPageId: Int
    $contentSnippetFormId: Int!
    $contentSnippetQuestionId: Int!
    $status: EQuestionAnswerStatus
    $selectedAnswers: [QuestionAnswerCheckboxResponseCheckboxInputType]
    $userFlowStepTrackId: Int
  ) {
    questionAnswer {
      setUserAnswer(
        contentSnippetFormId: $contentSnippetFormId
        answerCheckbox: {
          contentPageId: $contentPageId
          contentSnippetQuestionId: $contentSnippetQuestionId
          selectedAnswers: $selectedAnswers
          status: $status
          userFlowStepTrackId: $userFlowStepTrackId
        }
      ) {
        id
      }
    }
  }
`;

export { SET_USER_ANSWER_CHECKBOX };
