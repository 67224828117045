import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { DBUser, DBUserConnection } from '../../../../interfaces';
import { CreateUserConnectionMutation, EConnectionStatus, useCreateUserConnection, useGetUserByKey } from '../../../../services';
import { EmptyMessage, Feed, IconLock, PageBanner, UserConnections } from '../../../Social';

const SocialUserProfilePage = () => {
  const {
    asPath,
    push,
    query: { userKey },
  } = useRouter();

  const loggedUserId = useUser().getUser();
  const { isToggled: isConnectDialogOpen, toggle: toggleConnectDialog } = useToggle();

  const [user, setUser] = useState<DBUser>();

  const handleGetUserInformationCompleted = (returnedUser: DBUser) => {
    if (user) {
      return;
    }

    setUser(returnedUser);
  };

  const handleCreateUserConnectionCompleted = (userConnection: CreateUserConnectionMutation) => {
    setUser({
      ...user,
      userConnection: userConnection.userConnection?.create as DBUserConnection,
    } as DBUser);

    toggleConnectDialog();
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getUserByKey, isLoading: isGetUserByKeyLoading } = useGetUserByKey({
    onCompleted: handleGetUserInformationCompleted,
  });

  const { createUserConnection } = useCreateUserConnection({
    onCompleted: handleCreateUserConnectionCompleted,
  });

  useEffect(() => {
    if (user) {
      return;
    }

    getUserByKey({
      variables: {
        key: String(userKey),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey]);

  useEffect(() => {
    if (!loggedUserId || !userKey) {
      return;
    }

    if (loggedUserId.id === Number(userKey)) {
      push(`/community/profile`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserId, userKey]);

  const PAGE_TITLE = `${user?.displayedName ?? 'User profile'}`;

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription: `Your profile`,
        seoTitle: PAGE_TITLE,
      }}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      {user && (
        <>
          <PageBanner
            bannerImage={user.coverImage?.presignedUrl}
            description={user.about}
            profileImage={user.profileImage?.presignedUrl}
            title={user.displayedName}
          >
            <UserConnections communitiesCount={user.activeCommunities.totalCount} connectionsCount={user.connections.totalCount} />

            {!isGetUserByKeyLoading && !user.userConnection && <Button text="Connect" onClick={toggleConnectDialog} />}

            {!isGetUserByKeyLoading && user.userConnection?.connectionStatus === EConnectionStatus.Requested && (
              <Button text="Request sent" isDisabled onClick={toggleConnectDialog} />
            )}
          </PageBanner>

          <div className="c-user-profile-page__container">
            {user.userConnection?.connectionStatus === EConnectionStatus.Accepted && <Feed authorId={Number(user.id)} status="ACTIVE" />}

            {!isGetUserByKeyLoading && user.userConnection?.connectionStatus !== EConnectionStatus.Accepted && (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(user.displayedName)}'s activity`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${String(user.displayedName)}`}
            onCancel={toggleConnectDialog}
            onClose={toggleConnectDialog}
            onConfirm={() => {
              createUserConnection({
                variables: {
                  friendId: user.id,
                },
              });
            }}
          >
            <p>Are you sure you want to connect with {user.displayedName}?</p>
          </Dialog>
        </>
      )}
    </Page>
  );
};

export { SocialUserProfilePage };
