import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Button, Dialog, RadioButtonGroup, VideoPlayer } from '@netfront/ui-library';
import { EmbargoPostMessage, InformationBanner } from 'components';
import NextLink from 'next/link';
import pluralize from 'pluralize';

import { Comment } from '../Comment';
import { CommentBox } from '../CommentBox';
import { DropdownMenu } from '../DropdownMenu';
import { IconComment, IconDocument, IconEllipsis, IconLike, IconPin, IconShare } from '../Icons';
import { getFileType } from '../Pages';
import { Poll } from '../Poll';
import { PostButton } from '../PostButton';
import { PostFeeling } from '../PostFeeling';
import { PostRelatesDialog } from '../PostRelatesDialog';
import { PostShareDialog } from '../PostShareDialog';
import { ReactionToolbar, useReactionToolbar } from '../ReactionToolbar';
import { SharedPost } from '../SharedPost';
import { UserDetailsHeader } from '../UserDetailsHeader';

import { getMessageMarkup } from './Post.helpers';
import { IUpdateRelateParams, PostProps } from './Post.interfaces';

import { MAX_NESTED_COMMENT_DEPTH, REPORT_LABELS } from '.';
import { CommentConnection, CommentGraphType, EAssetType, ECommunityRole, ERelateType, EReportType } from '../../../services';
import { formatDate, formatTime, getValidClassNames } from '../../../utils';

const Post = ({
  assets,
  avatar,
  comments,
  commentsPageInfo,
  community,
  communityRole,
  displayName,
  date,
  feeling,
  hasRelated: hasRelatedProp = false,
  message,
  id,
  isCommentsLoading,
  ipAddress,
  isCommentSubmitting,
  isNegative,
  isPinned,
  isPreviewMode = false,
  isOnline = false,
  isOwner = false,
  loggedUser,
  poll,
  relate,
  relateCount,
  onPostRelate,
  onPostRelatesClick: onPostRelatesClickProp,
  onComment,
  onCommentDelete,
  onCommentRelate,
  onCommentReply,
  onVotePoll,
  userProfileLink,
  onDelete,
  onGetMoreComments,
  onGetMoreSubComments,
  onUnPublish,
  onUnPublishComment,
  onPublish,
  onEdit,
  onTogglePin,
  onReport,
  onReportComment,
  onSharePost,
  shareCount = 0,
  sharedPost,
  shouldHidePostOptions = false,
  shouldShowPostDetails = false,
  status,
  totalCommentsCount = 0,
  isInReview
}: PostProps) => {

  const { isReactionToolbarOpen, closeReactToolBar, openReactToolBar } = useReactionToolbar();
  const { isToggled: isDeletePostDialogOpen, toggle: toggleDeletePostDialog } = useToggle();
  const { isToggled: isReportPostDialogOpen, toggle: toggleReportPostDialog } = useToggle();

  const [comment, setComment] = useState<string>('');
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(!isPreviewMode && Boolean(comments?.length));
  const [isPostRelatesDialogOpen, setIsPostRelatesDialogOpen] = useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);
  const [activeRelate, setActiveRelate] = useState<ERelateType>();

  const [hasRelated, setHasRelated] = useState<boolean>(hasRelatedProp);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [reportType, setReportType] = useState<EReportType>(EReportType.Harassment);

  const onDisplayOptions = (isDisplay: boolean) => setIsOptionsOpen(isDisplay);

  const toggleCommentsVisibility = () => setIsCommentsOpen(!isCommentsOpen);

  const getTotalComments = () => {
    if (!comments) return 0;

    const mainArrayLength = comments.length;

    const commentsTotal = comments.reduce((total, item) => {
      return total + (item?.comments?.edges?.length ?? 0);
    }, 0);

    return mainArrayLength + commentsTotal;
  };

  const openPostRelatesDialog = () => setIsPostRelatesDialogOpen(true);
  const closePostRelatesDialog = () => setIsPostRelatesDialogOpen(false);

  const openPostShareDialog = () => setIsShareDialogOpen(true);
  const closePostShareDialog = () => setIsShareDialogOpen(false);

  const onPostRelatesClick = () => {
    openPostRelatesDialog();

    if (onPostRelatesClickProp) {
      onPostRelatesClickProp(id);
    }
  };

  const onCommentChange = (value: string) => {
    setComment(value);
  };

  const onCommentSubmit = () => {
    if (onComment) {
      onComment(id, comment);
    }

    setComment('');
  };

  const onRelateClick = ({ action, hasRelated: hasRelatedToPost, id: postId, relateType }: IUpdateRelateParams) => {
    if (onPostRelate) {
      onPostRelate({ action, hasRelated: hasRelatedToPost, id: postId, relateType });
    }

    setHasRelated(hasRelatedToPost);
  };

  const isModerator = communityRole === ECommunityRole.Moderator;
  const messageHtml = getMessageMarkup(String(message), isModerator);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const loggedUserCommunityRole = loggedUser?.activeCommunities?.edges
    ?.map(({ node }) => node)
    .find((c) => c?.id === community?.id)?.userConnection?.role;


  const isLoggedUserCommunityModerator = loggedUserCommunityRole === ECommunityRole.Moderator || loggedUserCommunityRole === ECommunityRole.Owner;


  useEffect(() => {
    setActiveRelate(relate?.type);
  }, []);

  return (
    <div
      className={getValidClassNames({
        'c-post': true,
        'c-post--highlighted': Boolean(isPinned),
      })}
    >
      <div className="c-post-user">
        <UserDetailsHeader
          avatar={avatar}
          description={`${date ? `${formatDate(date)} ${formatTime(date)}` : ''} ${
            isLoggedUserCommunityModerator && ipAddress ? ` - IP: ${ipAddress}` : ''
          }`}
          displayName={displayName}
          href={userProfileLink}
          isModerator={isModerator}
          isOnline={isOnline}
          size="medium"
          {...(community && {
            additionalTitleContent: (
              <NextLink href={`/community/communities/${String(community.key)}`}>
                <span className="c-post__community">
                  <span>
                    posted in <strong className="color-primary"> {community.title}</strong>
                  </span>
                </span>
              </NextLink>
            ),
          })}
        />

        <div aria-label="Post options" className="c-post-user__settings">
          {isPinned && (
            <div aria-label="Pinned post" className="c-post-_pin" title="Pinned post">
              <IconPin additionalClassNames="c-post__pin" />
            </div>
          )}
          {isInReview && (
            <DropdownMenu
              additionalClassNames="c-post-options"
              dropdownId="options"
              isDisplayContent={isOptionsOpen}
              trigger={
                <>
                  <span className="h-hide-visually">Post options</span>
                  <IconEllipsis />
                </>
              }
              onDisplayContent={onDisplayOptions}
            >
              <ul>
                {onUnPublish && (
                  <li>
                    <button
                      onClick={() => {
                        onUnPublish(id);
                        setIsOptionsOpen(false);
                      }}
                    >
                      Unpublish
                    </button>
                  </li>
                )}
                {onPublish && (
                  <li>
                    <button
                      onClick={() => {
                        onPublish(id);
                        setIsOptionsOpen(false);
                      }}
                    >
                      Publish
                    </button>
                  </li>
                )}
              </ul>
            </DropdownMenu>
          )}

          {!shouldHidePostOptions && !isInReview && (
            <DropdownMenu
              additionalClassNames="c-post-options"
              dropdownId="options"
              isDisplayContent={isOptionsOpen}
              trigger={
                <>
                  <span className="h-hide-visually">Post options</span>
                  <IconEllipsis />
                </>
              }
              onDisplayContent={onDisplayOptions}
            >
              {!isOwner && (
                <ul>
                  {(community?.userConnection?.role === ECommunityRole.Owner ||
                    community?.userConnection?.role === ECommunityRole.Moderator) && (
                    <>
                      <li>
                        <button
                          onClick={() => {
                            if (onUnPublish) {
                              onUnPublish(id);
                              setIsOptionsOpen(false);
                            }
                          }}
                        >
                          Unpublish
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            if (onTogglePin) {
                              onTogglePin(id, !isPinned);
                              setIsOptionsOpen(false);
                            }
                          }}
                        >
                          Pin
                        </button>
                      </li>
                    </>
                  )}
                  <li>
                    <button
                      onClick={() => {
                        if (onReport) {
                          toggleReportPostDialog();
                          setIsOptionsOpen(false);
                        }
                      }}
                    >
                      Report
                    </button>
                  </li>
                </ul>
              )}

              {isOwner && (
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        if (onEdit) {
                          onEdit(id);
                          setIsOptionsOpen(false);
                        }
                      }}
                    >
                      Edit
                    </button>
                  </li>
                  <li>
                    <button onClick={toggleDeletePostDialog}>Delete</button>
                  </li>
                  {communityRole !== ECommunityRole.Member && (
                    <li>
                      <button
                        onClick={() => {
                          if (onTogglePin) {
                            onTogglePin(id, !isPinned);
                            setIsOptionsOpen(false);
                          }
                        }}
                      >
                        {isPinned ? 'Unpin' : 'Pin'}
                      </button>
                    </li>
                  )}
                  <li>
                    <button onClick={toggleReportPostDialog}>Report</button>
                  </li>
                </ul>
              )}
            </DropdownMenu>
          )}
        </div>
      </div>

      <PostFeeling feeling={feeling} />

      {message && (
        <div className='c-post-message flex'>
          <div
            className={getValidClassNames({
              "ellipsis mr-2": isPreviewMode
            })}
            dangerouslySetInnerHTML={{
              __html: messageHtml,
            }}
          />
          {isPreviewMode && (
            <NextLink href={`/community/post/${id}`}>
              <span className='color-primary nowrap'>See post</span>
            </NextLink>
          )}
        </div>
      )}

      {isNegative && (
        <div className="px-4">
          <EmbargoPostMessage />
        </div>
      )}

      {isOwner && status === 'EMBARGO' && (
        <div className="px-4">
          <InformationBanner theme="subtle">
            <p>
              Your post is currently under review, and cannot be seen by other members yet. A moderator will review your post as soon as
              possible.
            </p>
          </InformationBanner>
        </div>
      )}

      {assets &&
        assets.length > 0 &&
        assets.map(({ assetId, contentType, fileName, presignedUrl }) => (
          <>
            {getFileType(contentType) === EAssetType.Image && (
              <div className={getValidClassNames({
                "c-post-image": true,
                "c-post-image__preview": isPreviewMode
              })}>
                <img key={assetId} alt={fileName} src={presignedUrl} />
              </div>
            )}
            {getFileType(contentType) === EAssetType.Video && (
              <VideoPlayer key={assetId} additionalClassNames="c-post-video" videoSrc={String(presignedUrl)} />
            )}
            {getFileType(contentType) === EAssetType.Document && (
              <NextLink key={assetId} href={String(presignedUrl)} download>
                <span className="c-post-document">
                  <IconDocument />
                  {fileName}
                </span>
              </NextLink>
            )}
          </>
        ))}

      {poll && <Poll {...poll} isOwner={isOwner} onVotePoll={onVotePoll} />}

      {sharedPost && (
        <SharedPost
          post={{
            assets: sharedPost.assets,
            avatar: sharedPost.author?.profileImage?.presignedUrl,
            id: sharedPost.id,
            date: sharedPost.createdDateTime,
            displayName: sharedPost.author?.displayedName,
            message: sharedPost.message,
            isOnline: sharedPost.author?.isOnline,
            poll: sharedPost.poll,
            onVotePoll: onVotePoll,
            userProfileLink: `/community/profile/${String(sharedPost.author?.key)}`,
          }}
        />
      )}

      {shouldShowPostDetails && (
        <div className="c-post-details">
          <div className="c-post-details__item">
            <ReactionToolbar
              isReactionToolbarOpen={isReactionToolbarOpen}
              selectedReaction={activeRelate}
              onMouseEnter={openReactToolBar}
              onMouseLeave={closeReactToolBar}
              onRelateClick={(relateType) => {
                setActiveRelate(relateType);
                onRelateClick({
                  action: hasRelated ? 'CHANGE' : 'ADD',
                  hasRelated: true,
                  id,
                  relateType,
                });

                closeReactToolBar();
              }}
            >
              <PostButton
                count={relateCount}
                icon={() => <IconLike additionalClassNames="c-post-button__icon" isActive={hasRelated} />}
                supportiveText={`${hasRelated ? 'Unlike' : 'Like'} post`}
                onClick={() => {
                  onRelateClick({
                    action: hasRelated ? 'REMOVE' : 'ADD',
                    hasRelated: !hasRelated,
                    id,
                    relateType: ERelateType.Like,
                  });
                }}
              />
            </ReactionToolbar>
          </div>
          <div className="c-post-details__item">
            <PostButton
              count={getTotalComments()}
              icon={() => <IconComment additionalClassNames="c-post-button__icon" />}
              supportiveText="Comment on post"
              onClick={() => {
                toggleCommentsVisibility();
              }}
            />
          </div>

          <div className="c-post-details__item">
            <PostButton
              count={shareCount}
              icon={() => <IconShare additionalClassNames="c-post-button__icon" />}
              isDisabled={Boolean(sharedPost)}
              supportiveText="Share post"
              onClick={() => openPostShareDialog()}
            />
            {isShareDialogOpen && (
              <PostShareDialog
                isOpen={isShareDialogOpen}
                post={{ assets, avatar, id, date, displayName, message, poll, userProfileLink }}
                title="Share post"
                onClose={closePostShareDialog}
                onShare={(params) => {
                  if (onSharePost) {
                    onSharePost(params);
                    closePostShareDialog();
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="c-post-footer">
        <div className="h-flex">
          {Boolean(relateCount) && (
            <>
              <button className="c-post-relates" title="See post relates" onClick={onPostRelatesClick}>
                <IconLike additionalClassNames="c-post-button__icon" isActive />
                <span>{relateCount}</span>
              </button>
              {isPostRelatesDialogOpen && (
                <PostRelatesDialog isOpen={isPostRelatesDialogOpen} postId={id} onClose={closePostRelatesDialog} />
              )}
            </>
          )}

          {comments && comments.length > 0 && (
            <button className="c-post-footer__comment-button" onClick={toggleCommentsVisibility}>
              {`${isCommentsOpen ? 'Hide' : 'Show'} comments (${totalCommentsCount})`}
            </button>
          )}
        </div>

        {isCommentsOpen && (
          <>
            <form className="c-post-reply">
              <CommentBox
                id="comment"
                isDisabled={isCommentSubmitting}
                labelText="Comment"
                name="comment"
                placeholder={`Reply to ${String(displayName)}...`}
                value={comment}
                isLabelHidden
                onChange={onCommentChange}
              />

              <Button
                isDisabled={Boolean(!comment) || isCommentSubmitting}
                isLoading={isCommentSubmitting}
                size="xs"
                text="Reply"
                onClick={onCommentSubmit}
              />
            </form>

            {comments && (
              <div className="c-post-comments">
                {comments.map(
                  ({
                    author,
                    createdDateTime,
                    comments: postComment,
                    hasRelated: hasCommentRelated,
                    id: commentId,
                    message: commentMessage,
                  }: CommentGraphType) => {
                    const { edges, pageInfo: replyPageInfo, totalCount: totalRepliesCount = 0 } = postComment ?? ({} as CommentConnection);
                    const replies = edges?.map(({ node }) => node);

                    const commentorRole = author?.communityConnections?.edges
                      ?.map(({ node }) => node)
                      .find((c) => c?.communityId === community?.id)?.role;

                    return (
                      <Comment
                        key={commentId}
                        canDelete={loggedUser?.id === author?.id}
                        canUnpublish={
                          loggedUserCommunityRole === ECommunityRole.Moderator || loggedUserCommunityRole === ECommunityRole.Owner
                        }
                        commentor={{
                          avatar: author?.profileImage?.presignedUrl,
                          displayName: String(author?.displayedName),
                          isOnline: author?.isOnline,
                          size: 'small',
                          isModerator: commentorRole === ECommunityRole.Moderator,
                        }}
                        comments={replies}
                        community={community}
                        date={createdDateTime}
                        depth={0}
                        hasRelated={hasCommentRelated}
                        id={commentId}
                        isCommentsLoading={isCommentsLoading}
                        maxNestedReplyLevel={MAX_NESTED_COMMENT_DEPTH}
                        message={commentMessage}
                        subCommentsPageInfo={replyPageInfo}
                        totalRepliesCount={totalRepliesCount}
                        onCommentDelete={onCommentDelete}
                        onCommentRelate={onCommentRelate}
                        onCommentReply={onCommentReply}
                        onGetMoreSubComments={onGetMoreSubComments}
                        onReport={onReportComment}
                        onUnPublish={onUnPublishComment}
                      />
                    );
                  },
                )}
                {commentsPageInfo?.hasNextPage && (
                  <Button
                    additionalClassNames={`c-post-comments ml-16 p-0 bg-transparent color-grey-300 hover:bg-transparent`}
                    size="xs"
                    text={`View ${totalCommentsCount - comments.length} more ${pluralize('comment', totalCommentsCount - comments.length)}`}
                    onClick={() => onGetMoreComments?.(id, commentsPageInfo.endCursor as string)}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>

      {isDeletePostDialogOpen && (
        <Dialog
          title="Delete post"
          isOpen
          onCancel={toggleDeletePostDialog}
          onClose={toggleDeletePostDialog}
          onConfirm={() => {
            if (onDelete) {
              onDelete(id);
              toggleDeletePostDialog();
              setIsOptionsOpen(false);
            }
          }}
        >
          Are you sure you want to delete this post?
        </Dialog>
      )}

      {isReportPostDialogOpen && (
        <Dialog
          title="Report post"
          isOpen
          onCancel={toggleReportPostDialog}
          onClose={toggleReportPostDialog}
          onConfirm={() => {
            if (onReport) {
              onReport(id, reportType);
              toggleReportPostDialog();
              setIsOptionsOpen(false);
            }
          }}
        >
          Are you sure you want to report this post?
          <RadioButtonGroup
            items={Object.keys(REPORT_LABELS).map((type, key) => ({
              id: `report-${String(key)}`,
              labelText: REPORT_LABELS[type],
              value: type,
            }))}
            name="reportType"
            selectedValue={reportType}
            onChange={({ target: { value } }) => setReportType(value as EReportType)}
          />
        </Dialog>
      )}
    </div>
  );
};

export { Post };
