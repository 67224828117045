import { Headerprops, LogoLink } from '../../components';

const Header = ({ children, logo }: Headerprops) => {
  return (
    <header className="relative bg-white border-b-10 border-secondary index-2">
      <div className="container-lg py-6 flex items-center">
        {logo && (
          <LogoLink {...logo} />
        )}
        {children}
      </div>
    </header>
  );
};

export { Header };
