import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Button, ToggleSwitch } from '@netfront/ui-library';
import { INotification, useSocialContext } from 'contexts';
import { format, getYear } from 'date-fns';
import { DBMetadata } from 'interfaces';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { ERelateType, useGetNotifications, useMarkANotificationSeen, useMarkAllNotificationSeen } from 'services';

import {
  EmptyMessage,
  getNotificationLink,
  getNotificationMessage,
  ListCardSkeleton,
  LoadMore,
  QUERY_FIRST_NOTIFICATIONS_COUNT,
  NotificationItem,
  SearchInput,
  useSearchInput,
} from 'components/Social';

import { NotificationConnection, NotificationGraphType } from 'services/howler';

import { BreadcrumbLink, Page } from '../../../../components';
import { useGetRootBreadcrumbItems } from '../../../../hooks';

const NotificationsPage = () => {
  const PAGE_TITLE = 'Notifications';

  const { asPath, push } = useRouter();

  const { notifications: headerNotifications, updateNotifications } = useSocialContext();

  const { isSearchActive, onSearchClear, onSearchSubmit } = useSearchInput();
  const { isToggled: isShowUnreadChecked, toggle: toggleIsShowUnreadChecked } = useToggle();
  const { ref: loadMoreRef, inView: shouldFetchMore } = useInView();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState<number>(0);

  const handleGetNotificationsCompleted = (returnedNotifications: NotificationConnection) => {
    setNotifications(
      returnedNotifications.edges?.map(({ cursor, node }) => ({ cursor, ...(node as NotificationGraphType) })) as INotification[],
    );
    setTotalNotificationsCount(Number(returnedNotifications.totalCount));
  };

  const {
    getNotifications,
    isLoading: isLoadingNotifications,
    fetchMore: fetchMoreNotifications,
    refetch: refetchNotifications
  } = useGetNotifications({
    onCompleted: handleGetNotificationsCompleted,
  });

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: false,
  });

  useEffect(() => {
    getNotifications({
      variables: {
        dateTo: new Date(),
        first: QUERY_FIRST_NOTIFICATIONS_COUNT,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { markANotificationSeen } = useMarkANotificationSeen();
  const { markAllNotificationSeen, isLoading: isMarkAllSeenLoading } = useMarkAllNotificationSeen({
    onCompleted: () => refetchNotifications()
  });
  const handleMarkAllNotificationSeen = () => {
    markAllNotificationSeen({variables: {
      ipAddress: '',
      userAgent: ''
    }})
  }

  useEffect(() => {
    if (!shouldFetchMore || totalNotificationsCount === notifications.length) {
      return;
    }

    fetchMoreNotifications({
      variables: {
        after: notifications[notifications.length - 1].cursor,
      },
    }).then(({ data }) => {
      setNotifications([
        ...notifications,
        ...(data.notification?.getForConnectedUser?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as INotification[]),
      ]);
    });
  }, [fetchMoreNotifications, notifications, shouldFetchMore, totalNotificationsCount]);

  const notificationsToRender = isShowUnreadChecked ? notifications.filter(({ seen }) => !seen) : notifications;

  const hasNoNotifications = notifications.length === 0 && !isLoadingNotifications;

  const groupedNotifications = notificationsToRender.reduce((group: INotification, notification) => {
    const year = String(getYear(new Date(notification.sendingDate as Date)));
    const month = format(new Date(notification.sendingDate as Date), 'LLLL');
    const day = `${new Date(notification.sendingDate as Date).toDateString().split(' ')[2]} ${new Date(
      notification.sendingDate as Date,
    ).toLocaleDateString('en-us', {
      weekday: 'long',
    })} ${month}`;

    if (!group[year]) {
      group[year] = [];
    }

    if (!group[year][month]) {
      group[year][month] = [];
    }

    if (!group[year][month][day]) {
      group[year][month][day] = [];
    }

    const dayItems = group[year][month][day] as INotification[];
    dayItems.push(notification);

    return group;
  }, {} as INotification);



  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{ seoDescription: 'View all your notifications', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-notifications-page">
        <div className="c-container c-container--sm pb-12">
          <div className="c-notifications-page__title">
            <ToggleSwitch
              additionalClassNames="c-notifications-page__toggle"
              id="unread"
              isChecked={isShowUnreadChecked}
              labelText="Show unread"
              onChange={() => toggleIsShowUnreadChecked()}
            />
            <Button  isDisabled={isMarkAllSeenLoading} text="Read all" onClick={handleMarkAllNotificationSeen}/>
          </div>

          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search notifications"
            name="search"
            placeholder="Search notifications"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />

          {Object.keys(groupedNotifications).map((year) => {
            const months = groupedNotifications[year];

            return (
              <div key={year}>
                <h2>{year}</h2>
                {Object.keys(months).map((month) => {
                  const days = groupedNotifications[year][month];

                  return (
                    <div key={month} className="c-notifications-page__section">
                      <h3>{month}</h3>
                      {Object.keys(days).map((day) => {
                        const items = days[day] as INotification[];
                        return (
                          <>
                            <h4>{day}</h4>

                            {items.map((notification) => {
                              const { id, seen: isSeen, metadata, sendingDate, guid, content } = notification;
                              let relateType;
                              let parsedMetaData;

                              if (metadata) {
                                parsedMetaData = JSON.parse(String(metadata)) as DBMetadata;
                                relateType = parsedMetaData.relateType && ERelateType[parsedMetaData.relateType];
                              }

                              return (
                                <NotificationItem
                                  key={id}
                                  date={new Date(sendingDate as Date).toDateString()}
                                  description={
                                    parsedMetaData ? getNotificationMessage(parsedMetaData.type, parsedMetaData.relateType) : content
                                  }
                                  displayName={parsedMetaData ? parsedMetaData.displayedName : ''}
                                  isRead={isSeen}
                                  relateType={relateType}
                                  tag={parsedMetaData && parsedMetaData.community}
                                  onClick={() => {
                                    markANotificationSeen({
                                      variables: {
                                        ipAddress: '',
                                        notificationGuid: String(guid),
                                        userAgent: '',
                                      },
                                    }).finally(() => {
                                      updateNotifications(
                                        headerNotifications.map((message) =>
                                          message.guid === guid ? ({ ...message, seen: true } as INotification) : message,
                                        ),
                                      );
                                      push(`${getNotificationLink(notification, String(guid), parsedMetaData)}`);
                                    });
                                  }}
                                />
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {!isLoadingNotifications &&
            Boolean(notificationsToRender.length) &&
            notificationsToRender.length >= QUERY_FIRST_NOTIFICATIONS_COUNT && (
            <LoadMore
              ref={loadMoreRef}
              fetchText="Loading more"
              finishedText="You're all caught up!"
              hasReachedTotal={totalNotificationsCount === notifications.length}
            />
          )}

          {isLoadingNotifications || isMarkAllSeenLoading  && (
            <>
              <ListCardSkeleton />
              <ListCardSkeleton />
            </>
          )}

          {hasNoNotifications && <EmptyMessage message="No notifications found" />}
        </div>
      </div>
    </Page>
  );
};

export { NotificationsPage };
