/* eslint-disable @typescript-eslint/indent */
import { validateOnCompletedAndOnErrorOptions } from '@netfront/common-library';

import { useBonoboLazyQuery } from 'hooks/useBonoboLazyQuery';

import { GET_EMBARGO_POSTS_COUNT } from './useGetEmbargoPostsCount.graphql';
import {
  IGetEmbargoPostsCountQueryGraphQLResponse,
  IGetEmbargoPostsCountQueryVariables,
  IHandleGetEmbargoPostsCountParams,
  IUseGetEmbargoPostsCount,
  IUseGetEmbargoPostsCountOptions,
} from './useGetEmbargoPostsCount.interfaces';

const useGetEmbargoPostsCount = (options?: IUseGetEmbargoPostsCountOptions): IUseGetEmbargoPostsCount => {
  const { fetchPolicy, onCompleted, onCompletedAsync, onError, onErrorAsync } = options ?? {};

  validateOnCompletedAndOnErrorOptions({
    onCompleted,
    onCompletedAsync,
    onError,
    onErrorAsync,
  });

  const [executeGetEmbargoPostsCount, { loading: isLoading }] = useBonoboLazyQuery<
    IGetEmbargoPostsCountQueryGraphQLResponse,
    IGetEmbargoPostsCountQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ post: { embargoedCount } }) => {
        if (onCompleted) {
          onCompleted({
            count: embargoedCount,
          });
        }

        if (onCompletedAsync) {
          void onCompletedAsync({
            count: embargoedCount,
          });
        }
      },
      onError: (error) => {
        if (onError) {
          onError(error);
        }

        if (onErrorAsync) {
          void onErrorAsync(error);
        }
      },
    },
    query: GET_EMBARGO_POSTS_COUNT,
  });

  const handleGetEmbargoPostsCount = async ({ communityId }: IHandleGetEmbargoPostsCountParams) => {
    await executeGetEmbargoPostsCount({
      variables: {
        communityId,
      },
    });
  };

  return {
    handleGetEmbargoPostsCount,
    isLoading,
  };
};

export { useGetEmbargoPostsCount };
