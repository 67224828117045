import { useEffect } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { ContentPageProps } from './ContentPage.interfaces';

import { BreadcrumbHomeLink, BreadcrumbLink, Page } from '../../../components';
import { useHasPrivateLayout } from '../../../hooks';

const ContentPage = ({ contentPage }: ContentPageProps) => {
  const { dispatch } = useContentPageContext();
  const { asPath } = useRouter();

  const { hasPrivateLayout } = useHasPrivateLayout();

  const { seoDescription, seoTitle, title } = contentPage;

  const {
    query: { pageUrl },
  } = useRouter();

  useEffect(() => {
    if (!pageUrl) {
      return;
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl]);

  return (
    <Page
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href={hasPrivateLayout ? '/dashboard' : '/'} />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{title}</BreadcrumbLink>, key: title },
        ],
      }}
      hasPrivateLayout={hasPrivateLayout}
      meta={{ seoDescription, seoTitle }}
      pageHeading={title}
      pageHeadingContainerClassNames="container-sm"
      title={title}
    >
      <div className={`container container-sm pb-12`}>
        <CMSContentPage />
      </div>
    </Page>
  );
};

export { ContentPage };
