import { useRouter } from 'next/router';

import { BreadcrumbHomeLink, BreadcrumbLink, Page } from '../../../components';

const FeedbackPage = () => {
  const PAGE_TITLE = 'Feedback';
  const { asPath } = useRouter();

  return (
    <Page
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/dashboard" />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{ seoDescription: 'Feedback', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    ></Page>
  );
};

export { FeedbackPage };
