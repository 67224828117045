import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getAccessTokenCookie, getTemporaryTokenCookie } from '@netfront/common-library';
import {
  CMSContentPage,
  DBContentPageNavigationDirectionType,
  getUpdatedContentGroupingElementsFromQuestionActionConditions,
  IContentPage,
  IHandleGetNextPageParams,
  IHandleGetPreviousPageParams,
  IUseContentPageNavigationOnCompletedResponse,
  IUseContentPageOnCompletedResponse,
  IUseGetAnswersOnCompletedResponse,
  IUseGetUserFlowStepContentPagesOnCompletedResponse,
  IUseStartContentPageCompletionOnCompletedResponse,
  useContentPage,
  useContentPageContext,
  useContentPageNavigation,
  useGetAnswers,
  useGetUserFlowStepContentPages,
  useTracking,
} from '@netfront/ekardo-content-library';
import { Skeleton , BreadcrumbLink, NavigationButton, Page } from 'components';
import first from 'lodash.first';
import { useRouter } from 'next/router';

import { GoalContentPageProps } from './GoalContentPage.interfaces';

import { useGetRootBreadcrumbItems, useHasPrivateLayout, useToast } from '../../../hooks';

const GoalContentPage = ({
  additionalClassNames,
  additionalControls,
  basePath,
  breadcrumbItems = [],
  contentPageUrl,
  isNavigationHidden = false,
  isCompletingTracking = true,
  placeholders = [],
  returnUrl,
  userFlowStepTrackingId,
  userFlowStepUrl,
  userFlowUrl,
}: GoalContentPageProps) => {
  const { asPath, push } = useRouter();
  const { dispatch } = useContentPageContext();

  const { handleToastError, handleToastCustomError } = useToast();
  const accessToken = getAccessTokenCookie();
  const { hasPrivateLayout } = useHasPrivateLayout();
  const temporaryToken = getTemporaryTokenCookie();

  const [contentPages, setContentPages] = useState<IContentPage[]>([]);
  const [lastContentPageInSession, setLastContentPageInSession] = useState<IContentPage>();
  const [contentPage, setContentPage] = useState<IContentPage>();
  const [contentPageId, setContentPageId] = useState<number>();
  const [isBackButtonEnabled, setIsBackButtonEnabled] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [isLastContentPageInSession, setIsLastContentPageInSession] = useState<boolean>(false);
  const [stepPercentageCompleted, setStepPercentageCompleted] = useState<number>(0);
  const [contentPageCompletionId, setContentPageCompletionId] = useState<number>();
  const [hasContentPageData, setHasContentPageData] = useState<boolean>(false);
  const [nextContentPageUrl, setNextContentPageUrl] = useState<string>('');

  /**
   * 
   * @todo remove this implementation once a system is available through the CMS 
   */
  const handleResourceDrugForms = () => {
    const yesRadio = document.querySelector('#radio-button-26725-3788');
    if(!yesRadio) return;

    const yesRadioOption = yesRadio as HTMLInputElement

    const handleFormInstanceShowHide = () => {
      if(!yesRadioOption.checked) return;

      const countInput = document.querySelector('#input-number-31399') as HTMLInputElement;
      const drugFormsSections = document.querySelector('#ek-section-13959')?.querySelectorAll('.c-cms-section');
      drugFormsSections?.forEach((section) => section.classList.add('none'));

      drugFormsSections?.forEach((section, index) => {
        if(index  > Number(countInput.value) - 1) {
          section.classList.add('none')
        } else {
          section.classList.remove('none')
        }
      })

      countInput.addEventListener('input', () => {
        drugFormsSections?.forEach((section, index) => {
          if(index  > Number(countInput.value) - 1) {
            section.classList.add('none')
          } else {
            section.classList.remove('none')
          }
        })
      })
    }

    handleFormInstanceShowHide();
    
    yesRadioOption.addEventListener('change', () => {
      handleFormInstanceShowHide();
    })
  }


  const handleGetUserFlowStepContentPagesCompleted = (data: IUseGetUserFlowStepContentPagesOnCompletedResponse) => {
    const {
      userFlowStep: { contentGroup, lastContentPage },
    } = data;
    const { contentPages: groupContentPages = [] } = contentGroup ?? {};

    setContentPages(groupContentPages);
    setLastContentPageInSession(lastContentPage);
  };

  const handleGetUserFlowStepContentPagesError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };


  const handleGetContentPageCompleted = async (data: IUseContentPageOnCompletedResponse) => {
    const { contentPage: currentContentPage } = data;

    const { id: returnedContentPageId } = currentContentPage;

    const { contentSectionContainers, contentSections, contentSnippets, sectionGroups } =
      getUpdatedContentGroupingElementsFromQuestionActionConditions(currentContentPage);

    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      contentPageConfigurationNavigation: { enableBackButton = true, enableNextButton = true },
    } = contentPages.find(({ id }) => id === returnedContentPageId) as IContentPage;

    const currentContentPageIndex = contentPages.findIndex(({ id }) => id === returnedContentPageId);
    const isLastContentPage = Boolean(lastContentPageInSession && lastContentPageInSession.id === returnedContentPageId);
    const percentageCompleted = Math.ceil((currentContentPageIndex / contentPages.length) * 100);

    setContentPage(currentContentPage);
    setContentPageId(returnedContentPageId);
    setIsBackButtonEnabled(enableBackButton);
    setIsLastContentPageInSession(isLastContentPage);
    setIsNextButtonEnabled(enableNextButton);
    setStepPercentageCompleted(percentageCompleted);

    dispatch({
      payload: {
        contentPage: currentContentPage,
      },
      type: 'loadContentPage',
    });

    dispatch({
      payload: {
        contentSectionContainers,
        contentSections,
        contentSnippets,
        sectionGroups,
      },
      type: 'applyQuestionActions',
    });

    await handleStartContentPageCompletion({
      contentPageId: returnedContentPageId,
      userFlowStepTrackId: Number(userFlowStepTrackingId),
    });
  };

  const handleGetContentPageError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleStartContentPageCompletionCompleted = (data: IUseStartContentPageCompletionOnCompletedResponse) => {
    const {
      contentPageCompletion: { id },
    } = data;

    setContentPageCompletionId(id);
    setHasContentPageData(true);

    if (placeholders.length > 0) {
      handleGetAnswers({
        userFlowStepTrackId: userFlowStepTrackingId,
      });
    }

    handleResourceDrugForms();

  };

  const handleStartContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetAnswersCompleted = (data?: IUseGetAnswersOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { answers: returnedAnswers } = data;

    const ph = {};
    placeholders.map((placeholder) => {
      ph[placeholder.key] = returnedAnswers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === placeholder.questionId)?.text;
    });

    dispatch({
      payload: {
        placeholders: ph,
      },
      type: 'savePlaceholders',
    });

  };



  const handleEndContentPageCompletionCompleted = async () => {
    setContentPageCompletionId(undefined);

    setContentPages((previousContentPages) =>
      previousContentPages.map((page) => {
        const { id } = page;

        if (id !== contentPageId) {
          return page;
        }

        return {
          ...page,
          isCompleted: true,
        };
      }),
    );

    if (!isLastContentPageInSession) {
      if (basePath) {
        push(
          `${String(basePath)}/${String(
            nextContentPageUrl,
          )}?userFlowStepUrl=${userFlowStepUrl}&userFlowStepTrackingId=${userFlowStepTrackingId}`,
          `${String(basePath)}/${String(nextContentPageUrl)}`,
        ).catch((error) =>
          handleToastError({
            error,
            shouldUseFriendlyErrorMessage: true,
          }),
        );
      } else {
        push(
          `/${String(userFlowUrl)}/${String(
            nextContentPageUrl,
          )}?userFlowStepUrl=${userFlowStepUrl}&userFlowStepTrackingId=${userFlowStepTrackingId}`,
          `/${String(userFlowUrl)}/${String(nextContentPageUrl)}`,
        ).catch((error) =>
          handleToastError({
            error,
            shouldUseFriendlyErrorMessage: true,
          }),
        );
      }
      return;
    }

    if (isCompletingTracking) {
      await handleEndUserFlowStep({
        userFlowStepTrackId: Number(userFlowStepTrackingId),
      });
    } else {
      push(returnUrl).catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    }
  };

  const handleEndContentPageCompletionError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleGetNextOrPreviousContentPageCompleted = (data: IUseContentPageNavigationOnCompletedResponse) => {
    const { nextOrPreviousContentPage } = data;

    if (nextOrPreviousContentPage) {
      setNextContentPageUrl(nextOrPreviousContentPage.url);
    }

    handleEndContentPageCompletion({
      contentPageCompletionId: Number(contentPageCompletionId),
    });
  };

  const handleGetNextOrPreviousContentPageError = () => {
    setHasContentPageData(true);

    handleResourceDrugForms();

    handleToastCustomError({
      message: 'Please answer all questions on this page before proceeding',
    });

  };

  const handleGetNextOrPreviousPage = (direction: DBContentPageNavigationDirectionType) => {
    setHasContentPageData(false);

    const params = {
      contentPageId: Number(contentPageId),
      userFlowStepTrackId: Number(userFlowStepTrackingId),
    };

    if (direction === 'NEXT') {
      handleGetNextPage(params as IHandleGetNextPageParams);
      return;
    }

    handleGetPreviousPage(params as IHandleGetPreviousPageParams);
  };

  const handleEndUserFlowStepCompleted = () => {
    if(userFlowStepUrl === 'consent') {
      handleGetConsentAnswers({
        userFlowStepTrackId: userFlowStepTrackingId,
      });
      return;
    }

    push(returnUrl).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const handleEndUserFlowStepError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetAnswers } = useGetAnswers({
    onCompleted: handleGetAnswersCompleted,
  });

  const { handleGetAnswers: handleGetConsentAnswers } = useGetAnswers({
    onCompleted: ({ answers: returnedAnswers }) => {
      const consentAnswer = returnedAnswers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === 33006)?.questionResponseRadioId;
      
      if(consentAnswer !== 3788) {
        push('/dashboard').catch((error) =>
          handleToastError({
            error,
            shouldUseFriendlyErrorMessage: true,
          }),
        );

        return;
      }

      push(returnUrl).catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  const rootBreadcrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: Boolean(accessToken),
  });

  const { handleGetNextPage, handleGetPreviousPage } = useContentPageNavigation({
    onCompleted: handleGetNextOrPreviousContentPageCompleted,
    onError: handleGetNextOrPreviousContentPageError,
  });

  const { handleEndContentPageCompletion, handleEndUserFlowStep, handleStartContentPageCompletion } = useTracking({
    onEndContentPageCompletionCompleted: handleEndContentPageCompletionCompleted,
    onEndContentPageCompletionError: handleEndContentPageCompletionError,
    onEndUserFlowStepCompleted: handleEndUserFlowStepCompleted,
    onEndUserFlowStepError: handleEndUserFlowStepError,
    onStartContentPageCompletionCompleted: handleStartContentPageCompletionCompleted,
    onStartContentPageCompletionError: handleStartContentPageCompletionError,
    token: accessToken ?? temporaryToken,
  });

  const { handleGetUserFlowStepContentPages } = useGetUserFlowStepContentPages({
    onCompleted: handleGetUserFlowStepContentPagesCompleted,
    onError: handleGetUserFlowStepContentPagesError,
  });

  const { handleGetContentPage } = useContentPage({
    fetchPolicy: 'no-cache',
    onCompleted: handleGetContentPageCompleted,
    onError: handleGetContentPageError,
  });

  useEffect(() => {
    handleGetUserFlowStepContentPages({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      topLevelOnly: false,
      userFlowStepTrackId: userFlowStepTrackingId,
      userFlowStepUrl: userFlowStepUrl,
      userFlowUrl: userFlowUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFlowStepTrackingId]);

  useEffect(() => {
    if (!(userFlowStepTrackingId && contentPages.length)) {
      return;
    }

    let id;
    if (contentPageUrl) {
      const { id: pageId } = contentPages.find(({ url }) => url === contentPageUrl) as IContentPage;
      id = pageId;
    } else {
      const [firstPage] = contentPages;
      const { id: pageId } = firstPage;
      id = pageId;
    }

    handleGetContentPage({
      contentPageId: id,
      userFlowStepTrackId: Number(userFlowStepTrackingId),
      withAnswers: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPageUrl, userFlowStepTrackingId, contentPages.length]);

  return (
    <Page
      bodyClassName={additionalClassNames}
      breadcrumbs={{
        items: [
          ...rootBreadcrumbItems,
          ...breadcrumbItems,
          { content: <BreadcrumbLink href={asPath}>{contentPage?.title ?? ''}</BreadcrumbLink>, key: contentPage?.url ?? '' },
        ],
      }}
      hasPrivateLayout={hasPrivateLayout && Boolean(accessToken)}
      meta={{ seoDescription: 'Self assessment', seoTitle: contentPage?.title }}
      pageHeading={contentPage?.title}
      percentageSlider={{ percentage: stepPercentageCompleted }}
      title={contentPage?.title ?? ''}
    >
      <div className="container">
        {additionalControls && <div className="additional-controls">{additionalControls}</div>}

        {hasContentPageData ? (
          <CMSContentPage userFlowStepTrackId={Number(userFlowStepTrackingId)} />
        ) : (
          <div className="ek-section">
            <Skeleton height="5rem" width="100%" />
          </div>
        )}

        {!isNavigationHidden && (
          <div className="items-center flex mb-8 no-print">

            {!isLastContentPageInSession ? (
              <>
                {contentPageId !== first(contentPages)?.id && (
                  <NavigationButton
                    direction={'PREVIOUS'}
                    isDisabled={!isBackButtonEnabled}
                    isLoading={!hasContentPageData}
                    onPress={() => handleGetNextOrPreviousPage('PREVIOUS')}
                  />
                )}

                <NavigationButton
                  direction={'NEXT'}
                  isDisabled={!isNextButtonEnabled}
                  isLoading={!hasContentPageData}
                  onPress={() => handleGetNextOrPreviousPage('NEXT')}
                />
              </>
            ) : (
              <NavigationButton
                direction={'NEXT'}
                isDisabled={!isNextButtonEnabled}
                isLoading={!hasContentPageData}
                onPress={() => handleGetNextOrPreviousPage('NEXT')}
              />
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export { GoalContentPage };
