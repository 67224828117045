import { useEffect, useState } from 'react';

import {
  IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse,
  IUserFlowStep,
  IUserFlowStepTrack,
  IUseStartInstanceDateUserFlowStepOnCompletedResponse,
  useGetUserFlowStepTracksWithAnswers,
  useStartInstanceDateUserFlowStep,
} from '@netfront/ekardo-content-library';
import { Button } from '@netfront/ui-library';
import { ArrowRightIcon, BreadcrumbLink, InformationBanner, Page } from 'components';
import { format } from 'date-fns';
import { IUseGetUserFlowStepWithPagesOnCompletedResponse, useGetRootBreadcrumbItems, useGetUserFlowStepWithPages } from 'hooks';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { getValidClassNames } from 'utils';

export function getFirstAndLastDatesOfMonth(date: Date): { firstDate: Date; lastDate: Date } {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDate = new Date(year, month, 1);
  const lastDate = new Date(year, month + 1, 0);
  return { firstDate, lastDate };
}

export const ACTIVITY_QUESTION = 25960;
export const MOOD_QUESTION = 25948;

const CalendarPage: NextPage = () => {
  const PAGE_TITLE = 'Mood and activity tracker';
  const DAYS_KEY = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { asPath, push } = useRouter();

  const [userFlowStep, setUserFlowStep] = useState<IUserFlowStep | undefined>();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [userFlowStepTracks, setUserFlowStepTracks] = useState<IUserFlowStepTrack[]>([]);

  const onGetUserFlowStepTracksWithAnswersCompleted = (data: IUseGetUserFlowStepTracksWithAnswersOnCompletedResponse) => {
    const { userFlowStepTracks: trackings } = data;
    setUserFlowStepTracks(trackings);
  };

  const onGetUserFlowStepCompleted = (data: IUseGetUserFlowStepWithPagesOnCompletedResponse) => {
    const { userFlowStep: step } = data;
    setUserFlowStep(step);
  };

  const { handleGetUserFlowStepWithPages } = useGetUserFlowStepWithPages({ onCompleted: onGetUserFlowStepCompleted });

  const { handleGetUserFlowStepTracksWithAnswers, isLoading: isGetUserFlowStepTracksWithAnswersLoading = false } =
    useGetUserFlowStepTracksWithAnswers({
      onCompleted: onGetUserFlowStepTracksWithAnswersCompleted,
    });

  const onStartInstanceDateUserFlowStepCompleted = (data: IUseStartInstanceDateUserFlowStepOnCompletedResponse) => {
    const {
      userFlowStepTrack: { id },
    } = data;

    if (!userFlowStep) return;

    const { url: userFlowStepUrl, contentGroup } = userFlowStep;

    if (!contentGroup) return;
    const { contentPages } = contentGroup;
    const [firstPage] = contentPages;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!firstPage) return;
    const { url: contentPageUrl } = firstPage;
    push(`/mood-and-activity-tracker/${contentPageUrl}?userFlowStepTrackingId=${id}&userFlowStepUrl=${userFlowStepUrl}`, `/mood-and-activity-tracker/${contentPageUrl}`);
  };

  const { handleStartInstanceDateUserFlowStep } = useStartInstanceDateUserFlowStep({
    onCompleted: onStartInstanceDateUserFlowStepCompleted,
  });

  const onDateClicked = (date: Date) => {
    handleStartInstanceDateUserFlowStep({ userFlowStepId: 264, instanceDate: getCurrentDate(date) });
  };

  useEffect(() => {
    const { firstDate: start, lastDate: end } = getFirstAndLastDatesOfMonth(currentDate);
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'calendar',
      start,
      end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  useEffect(() => {
    handleGetUserFlowStepWithPages({ projectId: String(process.env.REACT_APP_PROJECT_ID), stepUrl: 'calendar', userFlowUrl: 'calendar' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCurrentDate(date: Date) {
    // Get the day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();

    // Add leading zeros if necessary
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Format the date as dd-mm-yyyy
    return `${formattedMonth}-${formattedDay}-${year}`;
  }

  function DaysOfMonth() {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [] as JSX.Element[];

    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(year, month, i);

      const tracking = userFlowStepTracks.find((r) => getCurrentDate(new Date(r.instanceDate)) === getCurrentDate(date));

      const answer = tracking?.answers.find((r) => r.contentSnippetQuestion?.id === ACTIVITY_QUESTION);

      const { answers } = answer ?? {};
      const morning = answers?.find((r) => r.definedPosition === 0);
      const afternoon = answers?.find((r) => r.definedPosition === 1);
      const evening = answers?.find((r) => r.definedPosition === 2);

      const mood = tracking?.answers.find((r) => r.contentSnippetQuestion?.id === MOOD_QUESTION);

      const day = format(new Date(date), 'EEEE');
     
      if(i === 1) {
        const dayIndex = DAYS_KEY.findIndex(d => d === day);
        const emptyCardsAmount = dayIndex;

        const emptyCards = Array.from({ length: emptyCardsAmount }).map((_, index) => (
          <div key={`empty-${index}`} className="calendar-day-container">
            <div className='calendar-day-container__card' />
          </div>
        ));

        days.push(...emptyCards);
      }

      days.push(
        <div key={`day-${i}`} className="calendar-day-container" onClick={() => onDateClicked(date)}>
          <div className='calendar-day-container__card'>
            <p className="day">{i}</p>
            {mood && (
              <div className="entry entry--mood">
                <div className="mood--value">{mood.value}</div>
              Mood
              </div>
            )}
            {morning && (
              <>
                <div className="entry entry--morning">
                  <img alt="Morning entry" src="/images/morning-icon.svg" />
                  {morning.questionResponse?.label}
                </div>
                <div
                  className={getValidClassNames({
                    'entry--tooltip': true,
                    'entry--tooltip--bottom': i <= 7,
                  })}
                >
                  <div className="flex items-center ">
                    <img alt="Morning entry" src="/images/morning-icon.svg" />
                    <p>Morning activity</p>
                  </div>
                  <p className="calendar--tooltip--value">{morning.questionResponse?.label}</p>
                </div>
              </>
            )}
            {afternoon && (
              <>
                <div className="entry entry--afternoon">
                  <img alt="Afternoon entry" src="/images/day-icon.svg" />

                  {afternoon.questionResponse?.label}
                </div>
                <div
                  className={getValidClassNames({
                    'entry--tooltip': true,
                    'entry--tooltip--bottom': i <= 7,
                  })}
                >
                  <div className="flex items-center ">
                    <img alt="Afternoon entry" src="/images/day-icon.svg" />
                    <p>Afternoon activity</p>
                  </div>
                  <p className="calendar--tooltip--value">{afternoon.questionResponse?.label}</p>
                </div>
              </>
            )}
            {evening && (
              <>
                <div className="entry entry--evening">
                  <img alt="Evening entry" src="/images/afternoon-icon.svg" />

                  {evening.questionResponse?.label}
                </div>
                <div
                  className={getValidClassNames({
                    'entry--tooltip': true,
                    'entry--tooltip--bottom': i <= 7,
                  })}
                >
                  <div className="flex items-center ">
                    <img alt="Evening entry" src="/images/afternoon-icon.svg" />
                    <p>Evening activity</p>
                  </div>
                  <p className="calendar--tooltip--value">{evening.questionResponse?.label}</p>
                </div>
              </>
            )}
          </div>
        </div>,
      );
    }

    return (
      <>
        <div className="calendar">
          {DAYS_KEY.map((day) => (
            <div key={String(day)} className="calendar-day-container">
              <span className='calendar-day__day'>{day}</span>
            </div>
          ))}
        </div>
        <div className="calendar">
          {days}
        </div>
      </>
    )
 
  }

  const incrementCurrentDate = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const decrementCurrentDate = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      isPreloaderVisible={isGetUserFlowStepTracksWithAnswersLoading}
      meta={{ seoDescription: 'Monitor your mood and activities', seoTitle: 'Mood and activity tracker – Resources | Stable Ground' }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="container pb-16">
        <InformationBanner>
          <p className="mb-0">Welcome to the Stable Ground Mood and activity tracker.</p>
          <p className="mb-0">
            This calendar will allow you to record and keep track of your <strong>mood</strong> and your{' '}
            <strong>day-to-day activities</strong>.
          </p>
          <p className="mb-0">
            To add a new entry to your calendar, you can click the ‘Add new’ button below, which will add an entry to today&apos;s date. Or,
            <strong>click on a specific day</strong> on the calendar to add an entry for a different date.
          </p>
        </InformationBanner>
        <div className="c-calendar-header">
          <div></div>
          <div className="flex items-center justify-center">
            <div
              className="bg-transparent border-2 border-secondary color-secondary rounded-circle h-4 w-4 flex items-center justify-center pointer rotate-180 -mt-4px"
              onClick={decrementCurrentDate}
            >
              <ArrowRightIcon additionalClassNames="w-2 h-2" />
            </div>

            <p className="h6 color-secondary weight-800 mb-0 mx-4">{formatDate(currentDate)}</p>
            <div
              className="bg-transparent border-2 border-secondary color-secondary rounded-circle h-4 w-4 flex items-center justify-center pointer -mt-4px"
              onClick={incrementCurrentDate}
            >
              <ArrowRightIcon additionalClassNames="w-2 h-2" />
            </div>
          </div>
          <div className="c-calendar-header--button-container">
            <Button text="Add new" variant="secondary" onClick={() => onDateClicked(new Date())} />
          </div>
        </div>
        {!isGetUserFlowStepTracksWithAnswersLoading && <div className="overflow-x-auto">{DaysOfMonth()}</div>}
      </div>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default CalendarPage;
