import { Avatar } from '@netfront/ui-library';

import { OnlineStatus } from '../OnlineStatus';

import { UserAvatarProps } from './UserAvatar.interfaces';

const UserAvatar = ({ avatar, displayName, isModerator, isOnline, size = 'small' }: UserAvatarProps) => {
  return (
    <div className="c-user-avatar">
      {isOnline && <OnlineStatus size="sm" />}
      <Avatar {...(avatar && { image: <img alt={displayName} src={avatar} /> })} size={size} title={displayName} />
      {isModerator ? (
        <div className="c-user-avatar__icon" title="Moderator">
          <span className="sr-only">Moderator</span>
          <svg fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden>
            <path
              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
};

export { UserAvatar };
