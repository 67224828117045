import { IGetTagsByCommunityOption } from './useGetTagsByCommunity.interface';

import { DBTagsByCommunity } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetTagsByCommunityLazyQuery } from '../../bonobo/generated.graphql';

export const useGetTagsByCommunity = (options?: IGetTagsByCommunityOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetTagsByCommunityOption);

  const [getTagsByCommunity, { loading: isLoading, error, refetch }] = useGetTagsByCommunityLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.community?.tagsByCommunity as DBTagsByCommunity[]);
    },
    onError,
  });

  return { getTagsByCommunity, isLoading, error, refetch };
};
