import { useEffect, useState } from 'react';

import {
  IBookmark,
  IDBContentGroup,
  IUseCreateBookmarkOnCompletedResponse,
  IUseGetBookmarksForUserOnCompletedResponse,
  IUseGetContentGroupOnCompletedResponse,
  useCreateBookmark,
  useDeleteBookmark,
  useGetBookmarksForUser,
} from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { useCurrentAgency, useGetContentGroup } from 'hooks';

import { ResourceCard } from 'components/ResourceCard';

const Resources = () => {
  const [contentGroup, setContentGroup] = useState<IDBContentGroup>();
  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const { resourceContentGroupId } = useCurrentAgency();
  const { isAuthenticated } = useAuthentication();

  const onGetContentGroupCompleted = (data: IUseGetContentGroupOnCompletedResponse) => {
    const { contentGroup: cg } = data;
    setContentGroup(cg);
  };

  const { handleGetContentGroup } = useGetContentGroup({
    onCompleted: onGetContentGroupCompleted,
  });

  useEffect(() => {
    if (!resourceContentGroupId) return;
    handleGetContentGroup({ contentGroupId: resourceContentGroupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceContentGroupId]);

  const onGetBookmarksForUserCompleted = (data: IUseGetBookmarksForUserOnCompletedResponse) => {
    const { bookmarks: fetchedBookmarks } = data;
    setBookmarks(fetchedBookmarks);
  };

  const onCreateBookmarkOnCompletedResponse = (data: IUseCreateBookmarkOnCompletedResponse) => {
    const { bookmark } = data;
    setBookmarks([...bookmarks, bookmark]);
  };

  const { handleGetBookmarksForUser } = useGetBookmarksForUser({
    onCompleted: onGetBookmarksForUserCompleted,
  });

  const { handleCreateBookmark } = useCreateBookmark({
    onCompleted: onCreateBookmarkOnCompletedResponse,
  });
  const { handleDeleteBookmarkByContentPageId } = useDeleteBookmark();

  useEffect(() => {
    if (!isAuthenticated) return;
    handleGetBookmarksForUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const onLikePageClick = (contentPageId: number) => {
    handleCreateBookmark({ contentPageId });
  };

  const onUnLikePageClick = (contentPageId: number) => {
    handleDeleteBookmarkByContentPageId({ contentPageId });
    const updatedBookmarks = bookmarks.filter((bookmark) => bookmark.contentPageId !== contentPageId);
    setBookmarks(updatedBookmarks);
  };

  return (
    <>
      {contentGroup && (
        <section className="relative bg-white py-16 md:py-32" id="resources">
          <div className="container">
            <div className="flex items-center mb-6">
              <div className="flex-1">
                <h2 className="mb-2 mr-2">Resources</h2>
                <p className="h5 mb-0">Explore our free resources</p>
              </div>
              <Button linkButton={{ id: 'about-drugs', url: '/about-drugs' }} text="View all" variant="secondary" />
            </div>

            <div className="flex flex-wrap -mx-2">
              {contentGroup.contentPages.map((contentPage) => {
                const { asset, seoDescription, title, id, url } = contentPage;
                return (
                  <div key={`content-page-${id}`} className="w-full md:w-1/2 lg:w-1/4 p-2">
                    <ResourceCard
                      contentPageId={id}
                      description={seoDescription}
                      href={`/about-drugs/${url}/`}
                      image={asset?.presignedUrl}
                      isLiked={Boolean(bookmarks.find((r) => r.contentPageId === id))}
                      title={title}
                      onLikeClicked={onLikePageClick}
                      onUnlikeClicked={onUnLikePageClick}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Resources;
