import { useToggle } from "@netfront/common-library";
import { Button, Dialog } from "@netfront/ui-library";
import { CommunityCodeOfConduct } from "components";

export const ModeratorInformation = () => {
  const { isToggled: isModeratorInfoDialogOpen, toggle: toggleModeratorInfoDialog } = useToggle();

  return (
    <>
      <Button
        additionalClassNames="nowrap ml-0 md:ml-4"
        size='small'
        text="What is a moderator?"
        variant='secondary'
        onClick={toggleModeratorInfoDialog}

      />

      <Dialog
        isOpen={isModeratorInfoDialogOpen}
        title='What is a moderator?'
        onCancel={toggleModeratorInfoDialog}
        onClose={toggleModeratorInfoDialog}
      >
        <p>Moderators are people with professional experience who work to keep the Community a safe space for everyone. They do this by ensuring all members are following the Code of Conduct, providing support and advice on posts and reaching out to members who may need additional support.
        Moderators will actively monitor the site 7 days a week, from 3am to 7am and 3pm to 7pm. </p>
      </Dialog>

      <CommunityCodeOfConduct />

    </>
  )
}