import { useToggle } from '@netfront/common-library';
import { Button, Dialog } from '@netfront/ui-library';

export const GetHelpButton = () => {
  const { isToggled: isHelpDialogOpen, toggle: toggleHelpDialog } = useToggle();

  return (
    <div>
      <Button
        additionalClassNames="c-get-help-button rounded-t spotlight-item--6"
        size="small"
        text="Get help now"
        variant="cta"
        onClick={toggleHelpDialog}
      />

      <Dialog
        confirmText='Close'
        isOpen={isHelpDialogOpen}
        title="Get help now"
        onClose={toggleHelpDialog}
        onConfirm={() => {
          toggleHelpDialog();
        }}
      >
        <div className='ek-section'>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1 mr-4'>
              <h2 className='mb-4'>Emergency</h2>
              <p>If you or someone around you is in immediate danger dial <strong>Triple Zero (000)</strong> as soon as possible.</p>
            </div>
            <div className='flex items-center'>
              <a className='c-button c-button--secondary' href="tel:+000">000</a>
            </div>
          </div>
        </div>

        <div className='ek-section'>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1 mr-4'>
              <h2 className='mb-4'>Lifeline</h2>
              <p>If you are in crisis, feel suicidal or would like support call 13 11 14</p>
              <p>You can also text or chat with someone on <a className="c-text-snippet__link--target" href="https://www.lifeline.org.au/" rel="noreferrer" target="_blank">https://www.lifeline.org.au/<span><svg fill="none" stroke="currentColor" stroke-width="1.8" style={{ marginLeft: '.25rem' }} viewBox="0 0 24 24" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg></span></a>. </p>
            </div>
            <div className='flex items-center'>
              <a className='c-button c-button--secondary' href="tel:+ 13 11 14">13 11 14</a>
            </div>
          </div>
        </div>


        <div className='ek-section'>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1 mr-4'>
              <h2 className='mb-4'>National Alcohol and Other Drug Hotline</h2>
              <p>If you need help with alcohol or other drug issues, call <strong>1800 250 015</strong></p>
            </div>
            <div className='flex items-center'>
              <a className='c-button c-button--secondary' href="tel: +1800 250 015">1800 250 015</a>
            </div>
          </div>
        </div>

        <div className='ek-section'>
          <div className='flex flex-col md:flex-row'>
            <div className='flex-1 mr-4'>
              <h2 className='mb-4'>Suicide Callback Service:</h2>
              <p>If you are struggling with situations in your life, from feeling low or stressed, to the more complex issues of suicidal thoughts and grief arising from suicide call <strong>1300 659 467</strong>. </p>
              <p>You can also access free online counselling on <a className="c-text-snippet__link--target" href="https://www.suicidecallbackservice.org.au/" rel="noreferrer" target="_blank">https://www.suicidecallbackservice.org.au/<span><svg fill="none" stroke="currentColor" stroke-width="1.8" style={{ marginLeft: '.25rem' }} viewBox="0 0 24 24" width="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg></span></a>. </p>
            </div>
            <div className='flex items-center'>
              <a className='c-button c-button--secondary' href="tel:+1300 659 467">1300 659 467</a>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
