import { useEffect } from 'react';

import { Link, MEDIA_TYPES, ToolboxPageLayout } from '../../../components';
import { useProtectedRoute } from '../../../hooks';

const MediaPage = () => {
  const PAGE_TITLE = 'Media';

  const { isAuthenticated } = useProtectedRoute();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <ToolboxPageLayout title={PAGE_TITLE}>
      <div className="flex flex-wrap pb-12 -mx-4">
        {MEDIA_TYPES.map(({ description, href, key, icon, title }) => {
          return (
            <div key={key} className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
              <Link
                className="flex flex-col items-start cursor block h-full bg-white p-6 rounded pointer transition-200 hover:shadow-xl"
                href={href}
              >
                <div className="mb-6 flex items-center justify-center rounded-circle p-3 bg-blue-200 color-primary">{icon}</div>
                <div>
                  <span className="h4 weight-700 mb-4 block color-blue-700">{title}</span>
                  <p className="color-black mb-0">{description}</p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </ToolboxPageLayout>
  );
};

export { MediaPage };
