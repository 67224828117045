import { useCallback, useState, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

const getSize = () => {
  if (isBrowser) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return {
    width: undefined,
    height: undefined,
  };
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize);
  const handleResize = useCallback(() => setWindowSize(getSize()), [setWindowSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};