import { IGetUserInformationOption } from './useGetUserInformation.interface';

import { DBUser } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetUserInformationLazyQuery } from '../../bonobo/generated.graphql';

export const useGetUserInformation = (options?: IGetUserInformationOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetUserInformationOption);

  const [getUserInformation, { loading: isLoading, error, refetch }] = useGetUserInformationLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.user?.getUserInformation as DBUser);
    },
    onError,
  });

  return { getUserInformation, isLoading, error, refetch };
};
