import { IDBQuestionAnswer } from '@netfront/ekardo-content-library';

import { QUESTION_1_2_CONTENT_SNIPPET_QUESTION_IDS, QUESTION_1_2_RESPONSES_RADIO_NEVER_ID } from './SelfAssessmentPage.constants';

export const getQuestion_1_2Responses = (answers: IDBQuestionAnswer[]): IDBQuestionAnswer[] => {
  return QUESTION_1_2_CONTENT_SNIPPET_QUESTION_IDS.map(
    (questionId) =>
      answers.find(({ contentSnippetQuestionId }) => contentSnippetQuestionId === questionId) ?? {
        contentSnippetQuestionId: questionId,
        questionResponseRadioId: QUESTION_1_2_RESPONSES_RADIO_NEVER_ID,
      },
  ) as IDBQuestionAnswer[];
};

export const shouldSkipAssistSection = (answers: IDBQuestionAnswer[]): boolean => {
  if (
    !getQuestion_1_2Responses(answers)
      .filter(({ contentSnippetQuestionId }) => contentSnippetQuestionId !== 25747)
      .filter(({ questionResponseRadioId }) => questionResponseRadioId !== QUESTION_1_2_RESPONSES_RADIO_NEVER_ID).length
  ) {
    return true;
  }

  return false;
};

export const hasSelectedAllAssitItems = (contentPageId: number) => {
  const pageIds = [124871, 124872, 124873, 124874, 124875, 124876];

  if (!pageIds.includes(contentPageId)) {
    return true;
  }

  const selectedCount = document.querySelectorAll('input[type="radio"]:checked').length;
  const questionCount = document.querySelectorAll('tbody tr:not(.none)').length;

  if (selectedCount === questionCount) {
    return true;
  }

  return false;
};
