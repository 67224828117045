import { gql } from '@apollo/client';

export const GET_SMART_CODE = gql`
  query getSmartCode($code: String!, $projectId: String!) {
    getSmartCode(code: $code, projectId: $projectId) {
      id
      code
      groups {
        name
        id
      }
    }
  }
`;
