import { useAuthentication } from '@netfront/gelada-identity-library';

import { useIsMounted } from '../../hooks';

export const useHasPrivateLayout = (): { hasPrivateLayout: boolean } => {
  const { isAuthenticated } = useAuthentication();
  const { isMounted } = useIsMounted();

  return {
    hasPrivateLayout: isMounted && isAuthenticated,
  };
};
