import { useEffect, useState } from 'react';

import { Button, PlusIcon } from '@netfront/ui-library';
import { BreadcrumbLink, Page } from 'components';
import { useGetRootBreadcrumbItems } from 'hooks';
import { useRouter } from 'next/router';

import { ConversationsQueryResult, ECommunityRole, MessageListGraphType, useGetConversations } from '../../../../services';
import { EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const MessagesPage = () => {
  const PAGE_TITLE = 'Messages';

  const { asPath } = useRouter();

  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const [userConversations, setUserConversations] = useState<MessageListGraphType[]>([]);

  const handleGetConversationsCompleted = (conversations: ConversationsQueryResult[]) => {
    setUserConversations(conversations.map(({ node }) => node));
  };

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { getConversations, isLoading: isGetConversationsLoading } = useGetConversations({
    onCompleted: handleGetConversationsCompleted,
  });

  useEffect(() => {
    if (userConversations.length) {
      return;
    }

    getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conversationsWithFilter = userConversations.filter((conversation) =>
    searchValue && isSearchActive
      ? String(conversation.receiver?.displayedName?.toLowerCase()).includes(searchValue.toLowerCase())
      : conversation,
  );

  const hasConversations = !isGetConversationsLoading && userConversations.length > 0;
  const hasNoConversations = !isGetConversationsLoading && userConversations.length === 0;

  const unreadMessages = conversationsWithFilter.filter(({ unReadCount }) => Number(unReadCount) > 0);
  const readMessages = conversationsWithFilter.filter(({ unReadCount }) => Number(unReadCount) === 0);

  return (
    <Page
      breadcrumbs={{
        items: [
          ...rootBreadCrumbItems,
          { content: <BreadcrumbLink href="/community">Community</BreadcrumbLink>, key: 'Community' },

          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{
        seoDescription: `View all your conversations`,
        seoTitle: PAGE_TITLE,
      }}
      pageHeading={PAGE_TITLE}
      pageHeadingContainerClassNames="container-sm"
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="c-messages-page__container">
        <div className="c-messages-page__search">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search messages"
            name="search"
            placeholder="Search your message history"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />
          <Button icon={PlusIcon} linkButton={{ id: 'create', url: '/community/messages/new' }} text="Message" />
        </div>

        {isGetConversationsLoading && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {hasConversations && unreadMessages.length > 0 && (
          <div className="mb-8">
            <h2>Unread messages</h2>
            {conversationsWithFilter
              .filter(({ unReadCount }) => Number(unReadCount) > 0)
              .map(({ lastMessage, receiver, unReadCount }, key) => (
                <ListCard
                  key={key}
                  avatarImage={receiver?.profileImage?.presignedUrl}
                  avatarTitle={receiver?.displayedName}
                  description={lastMessage?.text}
                  displayName={receiver?.displayedName}
                  href={`/community/messages/${String(receiver?.key)}`}
                  isModerator={receiver?.activeCommunities?.edges?.some(
                    ({ node }) =>
                      node?.userConnection?.role === ECommunityRole.Member || node?.userConnection?.role === ECommunityRole.Owner,
                  )}
                  isOnline={receiver?.isOnline}
                  hasArrow
                >
                  <div className="c-list-card__message">
                    {Boolean(unReadCount) && <div className="c-list-card__unread-count">{unReadCount}</div>}
                  </div>
                </ListCard>
              ))}
          </div>
        )}

        {hasConversations && readMessages.length > 0 && (
          <div className="mb-8">
            <h2>Read messages</h2>
            {conversationsWithFilter
              .filter(({ unReadCount }) => Number(unReadCount) === 0)
              .map(({ lastMessage, receiver, unReadCount }, key) => (
                <ListCard
                  key={key}
                  avatarImage={receiver?.profileImage?.presignedUrl}
                  avatarTitle={receiver?.displayedName}
                  description={lastMessage?.text}
                  displayName={receiver?.displayedName}
                  href={`/community/messages/${String(receiver?.key)}`}
                  isModerator={receiver?.activeCommunities?.edges?.some(
                    ({ node }) =>
                      node?.userConnection?.role === ECommunityRole.Member || node?.userConnection?.role === ECommunityRole.Owner,
                  )}
                  isOnline={receiver?.isOnline}
                  hasArrow
                >
                  <div className="c-list-card__message">
                    {Boolean(unReadCount) && <div className="c-list-card__unread-count">{unReadCount}</div>}
                  </div>
                </ListCard>
              ))}
          </div>
        )}

        {hasNoConversations && <EmptyMessage message="No conversations found" />}
      </div>
    </Page>
  );
};

export { MessagesPage };
