import { useEffect, useState } from 'react';

import { IDBResultType, IUserFlowStepTrack, useGetUserFlowStepTracksWithAnswers } from '@netfront/ekardo-content-library';
import { LineChart } from '@netfront/ui-library';
import { differenceInDays, format, getYear, isWithinInterval, subWeeks } from 'date-fns';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { capitalizeFirstLetter } from 'utils';

import {
  BreadcrumbLink,
  CLUSTER_IDS,
  CLUSTER_ID_ALCOHOL,
  CLUSTER_ID_AMPHETAMINE,
  CLUSTER_ID_CANNABIS,
  CLUSTER_ID_COCAINE,
  CLUSTER_ID_HALLUCINOGENS,
  CLUSTER_ID_INHALANTS,
  CLUSTER_ID_OPIOIDS,
  CLUSTER_ID_OTHER,
  CLUSTER_ID_SEDATIVES,
  CLUSTER_ID_SMOKING,
  EAssistItem,
  ExternalLinkIcon,
  FILTER_BY_TIME,
  InformationBanner,
  NoData,
  Page,
  RESULT_ITEMS_MAP,
  SelfAssessmentPrompt,
  SUBSTANCE_THEME_KEYS,
  TimelineSelect,
} from '../../../components';
import { useGetRootBreadcrumbItems } from '../../../hooks';

const FeedbackResultsOverTimePage = () => {
  const PAGE_TITLE = 'Results overtime';
  const { asPath } = useRouter();

  const [results, setResults] = useState<IUserFlowStepTrack[]>([]);
  const [selectedTimeId, setSelectedTimeId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const { handleGetUserFlowStepTracksWithAnswers } = useGetUserFlowStepTracksWithAnswers({
    onCompleted: ({ userFlowStepTracks }) => {
      const sortedCompletedTrackings = [...userFlowStepTracks].filter(({ isCompleted }) => isCompleted).sort((a, b) => a.id - b.id);
      setResults(sortedCompletedTrackings);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    handleGetUserFlowStepTracksWithAnswers({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userFlowUrl: 'self-assessment',
      userFlowStepUrls: ['self-assessment'],
      clusterIds: CLUSTER_IDS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateFilter = FILTER_BY_TIME.find(({ value }) => value === selectedTimeId);

  const filteredResults =
    dateFilter &&
    results.length &&
    results
      .filter(({ completedLongDate }) =>
        isWithinInterval(new Date(completedLongDate), {
          start: subWeeks(new Date(), dateFilter.amount),
          end: new Date(),
        }),
      )
      .sort((a, b) => a.id - b.id);

  const getSubstanceSums = (id: number) => {
    if (!filteredResults) {
      return [];
    }

    const sums = filteredResults
      .map(({ clustersResult }) => clustersResult.find(({ clusterId }) => clusterId === id))
      .map(({ sum }: IDBResultType) => Number(sum));

    const hasValues = sums.filter((sum) => sum !== 0);

    return hasValues.length ? sums : [];
  };

  const clusterIdsWithResults = CLUSTER_IDS.filter((id) => getSubstanceSums(id).length);

  return (
    <Page
      breadcrumbs={{
        items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
      }}
      meta={{
        seoDescription: 'View and track how your substance use and mental health is changing over time',
        seoTitle: 'Results over time – Self-assessment | Stable Ground',
      }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
      hasPrivateLayout
    >
      <div className="container pb-12">
        <InformationBanner>
          <p>Review your self-assessment results over time.</p>
          <p className="mb-0">
            Below, you will see graphs that show your substance use and mental health results over time. Use the button above the graphs to
            change the length of time.
          </p>
        </InformationBanner>

        {!isLoading ? (
          <div className="flex items-center flex-1 mb-4">
            <div className="flex-1">
              {results.length ? (
                <SelfAssessmentPrompt daysElapsed={differenceInDays(new Date(), new Date(results[results.length - 1].completedLongDate))} />
              ) : null}
            </div>
            <div>
              <TimelineSelect value={selectedTimeId} onChange={({ currentTarget: { value } }) => setSelectedTimeId(Number(value))} />
            </div>
          </div>
        ) : null}

        {!isLoading && filteredResults && !filteredResults.length && <NoData message="There appears to be no results for these dates" />}

        {!isLoading && filteredResults && filteredResults.length ? (
          <>
            <div className="flex flex-wrap -mx-2 mb-4">
              <div className="w-full md:w-1/2 px-2">
                <div className="bg-white border border-athens p-6 rounded">
                  <h2 className="h6 color-secondary mb-4">Substance use</h2>
                  <LineChart
                    datasets={[
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.alcohol],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.alcohol],
                        data: getSubstanceSums(CLUSTER_ID_ALCOHOL),
                        label: 'Alcohol',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.amphetamine],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.amphetamine],
                        data: getSubstanceSums(CLUSTER_ID_AMPHETAMINE),
                        label: 'Amphetamine',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.cannabis],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.cannabis],
                        data: getSubstanceSums(CLUSTER_ID_CANNABIS),
                        label: 'Cannabis',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.cocaine],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.cocaine],
                        data: getSubstanceSums(CLUSTER_ID_COCAINE),
                        label: 'Cocaine',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.hallucinogens],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.hallucinogens],
                        data: getSubstanceSums(CLUSTER_ID_HALLUCINOGENS),
                        label: 'Hallucinogens',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.inhalants],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.inhalants],
                        data: getSubstanceSums(CLUSTER_ID_INHALANTS),
                        label: 'Inhalants',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.opioids],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.opioids],
                        data: getSubstanceSums(CLUSTER_ID_OPIOIDS),
                        label: 'Opioids',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.other],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.other],
                        data: getSubstanceSums(CLUSTER_ID_OTHER),
                        label: 'Other',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.sedatives],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.sedatives],
                        data: getSubstanceSums(CLUSTER_ID_SEDATIVES),
                        label: 'Sedatives',
                      },
                      {
                        backgroundColor: SUBSTANCE_THEME_KEYS[EAssistItem.smoking],
                        borderColor: SUBSTANCE_THEME_KEYS[EAssistItem.smoking],
                        data: getSubstanceSums(CLUSTER_ID_SMOKING),
                        label: 'Smoking',
                      },
                    ]}
                    labels={filteredResults.map(({ completedLongDate }) => format(new Date(completedLongDate), 'dd/MM/yyyy'))}
                    options={{
                      clip: false,
                      scales: {
                        y: {
                          max: 39,
                          min: 0,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-2">
                <div className="bg-white border border-athens p-6 rounded">
                  <h2 className="h6 color-secondary mb-4">Mental health</h2>

                  <LineChart
                    datasets={[
                      {
                        data: filteredResults.map(({ clustersResult }) => Number(clustersResult[0].sum)),
                        label: 'Mental health',
                        backgroundColor: '#FEB800',
                        borderColor: '#FEB800',
                      },
                    ]}
                    labels={filteredResults.map(({ completedLongDate }) => format(new Date(completedLongDate), 'dd/MM/yyyy'))}
                    options={{
                      clip: false,
                      scales: {
                        y: {
                          max: 50,
                          min: 10,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white px-4 py-3 border border-athens rounded mb-4 flex items-center">
              <span className="text-sm mb-0 mr-4 block">Substance keys:</span>
              <div className="flex items-center">
                {RESULT_ITEMS_MAP.filter(({ clusterId }) => clusterIdsWithResults.includes(clusterId)).map(({ clusterId, item, theme }) => (
                  <div key={`cluster-${clusterId}-legend`} className="flex items-center mr-5">
                    <div className="w-3 h-3 rounded-circle mr-1" style={{ backgroundColor: theme }} />
                    <p className="mb-0 text-sm">{capitalizeFirstLetter(String(item))}</p>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : null}

        {filteredResults && filteredResults.length ? (
          <>
            <div className="flex mb-1">
              <div className="flex flex-1 weight-800 px-6">Date</div>
              <div className="flex weight-800 px-6">Review</div>
            </div>
            {filteredResults.reverse().map(({ completedLongDate, id }) => (
              <NextLink key={id} href={`/self-assessment/results?trackingId=${id}`} target="_blank">
                <span
                  className="flex items-center bg-white border-2 border-athens rounded flex mb-2 py-3 hover:border-primary transition-200"
                >
                  <div className="flex-1 px-6">
                    <p className="color-secondary mb-0">
                      {`${format(new Date(completedLongDate), 'do')} of ${format(new Date(completedLongDate), 'MMMM')} ${getYear(
                        new Date(completedLongDate),
                      )}`}
                    </p>
                  </div>

                  <div className="flex items-center color-primary px-6 justify-end">
                    <ExternalLinkIcon />
                  </div>
                </span>
              </NextLink>
            ))}
          </>
        ) : null}
      </div>
    </Page>
  );
};

export { FeedbackResultsOverTimePage };
