import { IAcceptTermsAndConditionsMutationOptions } from './useAcceptTermsAndConditions.interface';

import { bonoboClient } from '../../apollo';
import { useAcceptTermsAndConditionsMutation } from '../../bonobo/generated.graphql';

export const useAcceptTermsAndConditions = (options?: IAcceptTermsAndConditionsMutationOptions) => {
  const { onCompleted, onError } = options ?? ({} as IAcceptTermsAndConditionsMutationOptions);

  const [acceptTermsAndConditions, { loading: isAcceptTermsAndConditionsLoading, error }] = useAcceptTermsAndConditionsMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { acceptTermsAndConditions, isAcceptTermsAndConditionsLoading, error };
};
