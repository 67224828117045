import { FlexContainer } from '@netfront/ui-library';
import { CardTooltip } from 'components';

import { INFORMATIONBANNER_ICONS } from './InformationBanner.constants';
import { InformationBannerProps } from './InformationBanner.interfaces';

import { getValidClassNames } from '../../utils';

const InformationBanner = ({ additionalClassNames, children, theme = 'info', title, tooltipText, isDynamicallyPositioned = false }: InformationBannerProps) => {

  const TitleWithTooltip = () => {
    if (title && tooltipText) return (
      <FlexContainer gap="medium" justifyContent="flex-start">
          <h2 className="h5 mb-1">{title}</h2>
          <CardTooltip isDynamicallyPositioned={isDynamicallyPositioned} text={tooltipText} />
      </FlexContainer>
    );

    if (title && !tooltipText) return <h2 className="h5 mb-1">{title}</h2>;

    if (!title && tooltipText) return <CardTooltip text={tooltipText} isFitContentWidth />

    return <></>;
  };

  return (
    <div
      className={getValidClassNames({
        'c-information-banner border-3 border-accent rounded flex mb-8': true,
        'bg-accent-light border-accent': theme === 'info',
        'bg-primary border-secondary': theme === 'hint',
        'bg-grey-100 border-grey-500': theme === 'subtle',
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
    >
      <div
        className={getValidClassNames({
          'flex p-4 rounded': true,
          'bg-accent-light color-white': theme === 'info',
          'bg-primary': theme === 'hint',
        })}
      >
        <img alt={theme} className="max-w-16" src={INFORMATIONBANNER_ICONS[theme]} />
      </div>
      <div
        className={getValidClassNames({
          'flex flex-col justify-center flex-1 p-4 md:pl-0': true,
          'color-secondary': theme === 'info',
          'color-white': theme === 'hint',
        })}
      >
        <TitleWithTooltip />
        {children}
      </div>
    </div>
  );
};

export { InformationBanner };
