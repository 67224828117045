export * from './capitalizeFirstLetter';
export * from './displayName';
export * from './getValidClassNames';
export * from './isBetween';
export * from './isBetween';

export const formatDate = (date: Date) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [day, monthName, dateNumber] = new Date(date).toDateString().split(' ');
  return `${dateNumber} ${monthName}`;
};

export const formatTime = (date: Date) => {
  return `${new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export const scrollToTop = () => {
  const isBrowser = () => typeof window !== 'undefined';

  if (!isBrowser()) return;
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const isDaysBetween = (date: Date, minDays: number, maxDays: number): boolean => {
  const today = new Date();
  const timeDifference = Math.abs(today.getTime() - date.getTime());
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference >= minDays && daysDifference <= maxDays;
}

export const getDayDifference = (date1: Date, date2: Date): number => {
  const timeDifference = date2.getTime() - date1.getTime();
  const dayDifference = timeDifference / (1000 * 3600 * 24);
  return Math.abs(dayDifference);
}