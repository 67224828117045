import React from 'react';

import { getValidClassNames } from 'utils';

import { SingleFormPageProps } from './SingleFormPage.interfaces';

import { Page } from '../../../components';

const SingleFormPage = ({ containerSize = 'xs', children, pageHeadingContainerClassNames, title, ...rest }: SingleFormPageProps) => {
  return (
    <Page
      className="pt-8"
      pageHeadingContainerClassNames={getValidClassNames({
        [`container-${containerSize}`]: Boolean(containerSize),
        [String(pageHeadingContainerClassNames)]: Boolean(pageHeadingContainerClassNames),
      })}
      title={title}
      {...rest}
    >
      <section className="pb-24">
        <div className={`container container-${containerSize}`}>{children}</div>
      </section>
    </Page>
  );
};

export { SingleFormPage };
