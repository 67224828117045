import { useEffect, useState } from 'react';

import { Button, Select, ColorPicker } from '@netfront/ui-library';
import {ColorPickerPreview} from 'components';
import { useSocialContext } from 'contexts';
import { capitalizeFirstLetter } from 'utils';

import { AvatarPattern, EAvatarPattern } from './AvatarPattern';

import { useToast } from '../../hooks';
import { DBAsset } from '../../interfaces';
import { useCreateUserAsset, useUpdateUserImages, EAssetType } from '../../services';

export interface AvatarBuilderProps {
  autoGenerate?: boolean;
  isVisible?: boolean;
}
export const AvatarBuilder = ({ isVisible = true, autoGenerate = false }: AvatarBuilderProps) => {
  const { user, updateUser } = useSocialContext();

  const { createUserAsset } = useCreateUserAsset();
  const { updateUserImages } = useUpdateUserImages();
  const { handleToastSuccess } = useToast();

  const [isFontColorOpen, setIsFontColorOpen] = useState<boolean>(false);
  const [isBackgroundColorOpen, setIsBackgroundColorOpen] = useState<boolean>(false);
  const [isMiddleColorOpen, setIsMiddleColorOpen] = useState<boolean>(false);
  const [fontColor, setFontColor] = useState<string>('#fff');
  const [backgroundColor, setBackgroundColor] = useState<string>('#dfdfec');
  const [middleColor, setMiddleColor] = useState<string>('#f0f0f7');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [avatarPattern, setAvatarPattern] = useState<EAvatarPattern>(EAvatarPattern['horizontal-line']);

  function getRandomAvatarPattern() {
    const values = Object.values(EAvatarPattern);
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
  }

  function getRandomHexColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    if (!autoGenerate) return;

    setFontColor(getRandomHexColor());
    setMiddleColor(getRandomHexColor());
    setBackgroundColor(getRandomHexColor());
    setAvatarPattern(getRandomAvatarPattern());
    setTimeout(() => {
      onAvatarSave();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoGenerate]);

  const onAvatarSave = () => {
    setIsLoading(true);
    const svgString = document.querySelector('#js-avatar-pattern')?.outerHTML;

    const blob = new Blob([String(svgString)], { type: 'image/svg+xml' });
    const file = new File([blob], 'my-file.svg', { type: blob.type });

    createUserAsset({
      variables: {
        alt: 'Avatar',
        contentType: 'image/svg+xml',
        fileName: `avatar-${avatarPattern}.svg`,
        fileSizeInBytes: file.size,
        type: EAssetType.Image,
        shouldIncludeAsset: true,
      },
    }).then(async ({ data }) => {
      const assetId = data?.asset?.createUserAsset?.asset?.assetId;
      const signedUrl = data?.asset?.createUserAsset?.signedUrl;

      await fetch(signedUrl as string, {
        method: 'PUT',
        body: file,
      }).then(() => {
        updateUserImages({
          variables: {
            profileImage: assetId,
          },
        })
          .then(() => {
            if (user) {
              updateUser({
                ...user,
                profileImage: {
                  ...user.profileImage,
                  presignedUrl: String(URL.createObjectURL(file)),
                } as DBAsset,
              });
            }
            handleToastSuccess({ message: 'Avatar updated' });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    });
  };

  const onFontColorUpdate = (newColor: string) => {
    setFontColor(newColor);
  };

  const onMiddleColorUpdate = (newColor: string) => {
    setMiddleColor(newColor);
  };

  const onBackgroundColorUpdate = (newColor: string) => {
    setBackgroundColor(newColor);
  };

  return (
    <>
      {isVisible && (
        <>
          {user && (
            <div className="relative w-20 h-20 rounded-circle overflow-hidden border-2 border-white mb-4">
              <AvatarPattern
                backgroundColor={backgroundColor}
                color={fontColor}
                middleColor={middleColor}
                pattern={avatarPattern}
                text={String(
                  String(user.displayedName)
                    .split(' ')
                    .slice(0, 2)
                    .map((item) => item.charAt(0).toUpperCase())
                    .join(''),
                )}
              />
            </div>
          )}

          <div className="flex items-end mb-8">
            <div className="flex items-end">
              <div className="flex flex-col items-start mr-4">
                <span>Colour</span>
                <div className="relative">
                  <ColorPickerPreview
                    additionalClassNames="border-2 border-athens pointer"
                    selectedColor={fontColor}
                    onClick={() => {
                      setIsMiddleColorOpen(false);
                      setIsBackgroundColorOpen(false);
                      setIsFontColorOpen(!isFontColorOpen);
                    }}
                  />
                  {isFontColorOpen && (
                    <ColorPicker
                      additionalClassNames="absolute index-2"
                      color={fontColor}
                      onHexChange={onFontColorUpdate}
                      onOutsideClick={() => setIsFontColorOpen(false)}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col items-start mr-4">
                <span>Middle</span>
                <div className="relative">
                  <ColorPickerPreview
                    additionalClassNames="border-2 border-athens pointer"
                    selectedColor={middleColor}
                    onClick={() => {
                      setIsFontColorOpen(false);
                      setIsBackgroundColorOpen(false);
                      setIsMiddleColorOpen(!isMiddleColorOpen);
                    }}
                  />
                  {isMiddleColorOpen && (
                    <ColorPicker
                      additionalClassNames="absolute index-2"
                      color={middleColor}
                      onHexChange={onMiddleColorUpdate}
                      onOutsideClick={() => setIsMiddleColorOpen(false)}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-col items-start">
                <span>Background</span>
                <div className="relative">
                  <ColorPickerPreview
                    additionalClassNames="border-2 border-athens pointer"
                    selectedColor={backgroundColor}
                    onClick={() => {
                      setIsMiddleColorOpen(false);
                      setIsBackgroundColorOpen(false);
                      setIsBackgroundColorOpen(!isBackgroundColorOpen);
                    }}
                  />
                  {isBackgroundColorOpen && (
                    <ColorPicker
                      additionalClassNames="absolute index-2"
                      color={backgroundColor}
                      onHexChange={onBackgroundColorUpdate}
                      onOutsideClick={() => setIsBackgroundColorOpen(false)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="w-full max-w-92 px-4 ml-auto">
              <Select
                id="pattern"
                labelText="Pattern"
                name="pattern"
                options={Object.keys(EAvatarPattern).map((pattern, key) => ({
                  id: key,
                  name: capitalizeFirstLetter(pattern.split('-').join(' ')),
                  value: pattern,
                }))}
                value={avatarPattern}
                onChange={({ target: { value } }) => setAvatarPattern(value as EAvatarPattern)}
              />
            </div>
            <Button isLoading={isLoading} text="Save avatar" onClick={onAvatarSave} />
          </div>
        </>
      )}
    </>
  );
};
