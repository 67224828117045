import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  IToolBoxContentPage,
  IToolBoxMediaContentSnippet,
  IToolBoxUserFlowStep,
  IUseToolBoxMediaOnCompletedResponse,
  // useToolBoxMedia,
} from '@netfront/ekardo-content-library';
import { Select } from '@netfront/ui-library';
import { CaretRightIcon, Link, ToolboxPageLayout, Skeleton } from 'components';
// import uniqBy from 'lodash.uniqby';

import { useToast, useToolBoxMedia } from '../../../hooks';
import { useProtectedRoute } from '../../../hooks/useProtectedRoute';

const PAGE_TITLE = 'Audio';

const MediaAudioPage = () => {
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();

  const [allAudios, setAllAudios] = useState<IToolBoxMediaContentSnippet[]>([]);
  const [allUserFlowSteps, setAllUserFlowSteps] = useState<IToolBoxUserFlowStep[]>([]);
  const [allContentPages, setAllContentPages] = useState<IToolBoxContentPage[]>();
  const [selectedModule, setSelectedModule] = useState<number>();

  const handleModuleClick = (id: number) => {
    setSelectedModule(id);
  };

  const handleGetToolBoxMediaCompleted = ({
    toolboxData: { contentPages, contentSnippets, userFlowSteps },
  }: IUseToolBoxMediaOnCompletedResponse) => {
    // setAllAudios(uniqBy(contentSnippets, ({ contentPageId }) => contentPageId));
    setAllAudios(contentSnippets);

    setAllUserFlowSteps(userFlowSteps);
    setAllContentPages(contentPages);

    if (userFlowSteps.length) {
      setSelectedModule(userFlowSteps[0].id);
    }
  };

  const handleGetToolBoxMediaError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleGetToolBoxMedia, isLoading } = useToolBoxMedia({
    mediaType: 'audio',
    onCompleted: handleGetToolBoxMediaCompleted,
    onError: handleGetToolBoxMediaError,
    projectId: String(process.env.REACT_APP_PROJECT_ID),
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetToolBoxMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <ToolboxPageLayout meta={{ seoDescription: 'Audio files', seoTitle: PAGE_TITLE }} title={PAGE_TITLE}>
      {isLoading ? (
        <div className="ek-section">
          <Skeleton borderRadius="10px" height="3rem" width="100%" />
        </div>
      ) : allAudios.length ? (
        <>
          <div className="ek-section py-4">
            <div className="w-full md:w-1/5">
              <Select
                additionalClassNames="bg-white"
                id="module"
                labelText="Select module"
                name="module"
                options={allUserFlowSteps.map(({ id, stepName }) => ({ id, name: stepName, value: id }))}
                value={selectedModule}
                onChange={({ target }) => handleModuleClick(parseInt(target.value))}
              />
            </div>
          </div>

          <div className="flex flex-col">
            {allAudios
              .filter(({ userFlowStepId }) => userFlowStepId === selectedModule)
              .map(({ audio, contentPageId, id, userFlowStepId }) => {
                const page = allContentPages?.find(({ id: pageId }) => pageId === contentPageId);
                const stepUrl = allUserFlowSteps.find(({ id: stepId }) => stepId === userFlowStepId)?.url;

                return (
                  <Link
                    key={id}
                    className="flex items-center bg-white p-4 color-blue-700 rounded mb-4 weight-600 transition-200 hover:shadow-xl"
                    href={`/dashboard/${String(stepUrl)}/${String(page?.url)}`}
                  >
                    <div className="flex items-center justify-center rounded-circle p-2 bg-blue-200 color-primary mr-4">
                      <svg
                        className="h-5 w-5"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="flex-1 mb-0">{audio?.fileName}</span>
                    <div className="flex items-center color-primary">
                      <CaretRightIcon />
                    </div>
                  </Link>
                );
              })}
          </div>
        </>
      ) : (
        <div className="ek-section">
          <p>No audio files have been played yet</p>
        </div>
      )}
    </ToolboxPageLayout>
  );
};

export { MediaAudioPage };
