import { useCallback, useState } from "react";

import { useAnswerCustomFields, useUpdateUser, useUser } from "@netfront/gelada-identity-library";
import { Dialog, Preloader } from "@netfront/ui-library";
import { useSocialContext } from "contexts";
import { useToast } from "hooks";
import { DBUser, } from "interfaces";
import noop from "lodash.noop";
import { useAcceptTermsAndConditions } from "services";

import { CodeOfConduct } from "../CommunityCodeOfConduct";

import { CUSTOM_FIELD_ID, INVALID_PHONE_NUMBER } from "./CommunityWarningPrompt.constants";

const acceptingDateString = new Date().toUTCString();

export const CommunityWarningPrompt = () => {
  const { user, updateUser } = useSocialContext();
  const { handleToastError, handleToastCustomError } = useToast();
  const { id: userId, isTermsAndConditionsAccepted } = user ?? ({} as DBUser)
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [phoneNumber, setPhoneNumber] = useState<string>(useUser().getUser()?.credential.phoneNumber as string);

  const { acceptTermsAndConditions, isAcceptTermsAndConditionsLoading } = useAcceptTermsAndConditions({
    onCompleted: () => {
      const updatedUser = {...user} as DBUser;
      updatedUser.isTermsAndConditionsAccepted = true;
      updateUser(updatedUser)
    }
  })

  const { handleUpdateUser, isLoading: isUserUpdating } = useUpdateUser({
    onCompleted: () => {
      handleAnswerCustomFields({
        projectId: process.env.REACT_APP_PROJECT_ID as string,
        userId,
        responses: [{ customFieldId: CUSTOM_FIELD_ID, value: acceptingDateString }],
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    }
  });

  const { handleAnswerCustomFields, isLoading: isCustomAnswerSaving } = useAnswerCustomFields({
    onCompleted: () => {
      setIsOpen(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    }
  });

  const validatePhoneNumber = useCallback(() => {
    const pattern = /^(?:\+61\s?1?-?(?:[23478]\s?\d{2}|\d{4})\s?\d{3}\s?\d{3}|\(0[23478]\)\d{8}|0[23478]\s?\d{8})$/;
    return pattern.test(phoneNumber);
  },[phoneNumber]);

  const handleConfirm = () => {
    if(!user) return;

    if (!phoneNumber) {
      handleAnswerCustomFields({
        projectId: process.env.REACT_APP_PROJECT_ID as string,
        userId,
        responses: [{ customFieldId: CUSTOM_FIELD_ID, value: acceptingDateString }],
      });
      acceptTermsAndConditions({})
      return;
    }

    if (!validatePhoneNumber()) {
      handleToastCustomError({ message: INVALID_PHONE_NUMBER });
      return;
    }

    handleUpdateUser({
      email: String(user.email),
      firstName: String(user.firstName),
      lastName: String(user.lastName),
      phoneNumber,
    });
  };

  const isLoading = isUserUpdating ?? isCustomAnswerSaving ?? isAcceptTermsAndConditionsLoading;

  return !isTermsAndConditionsAccepted ? (
    <>
      <Dialog
        confirmText="I have read and will comply to the Code of Conduct."
        isOpen={isOpen}
        title="User Code of Conduct (Stable Ground Community)"
        onClose={noop}
        onConfirm={handleConfirm}
      >
        <Preloader isLoading={isLoading } />
        <CodeOfConduct phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}/>
      </Dialog>
    </>
  ) : null
}