export const MODULE_COMPLETION_EMAIL_TEMPLATE_ID = 155;
export const PROGRAM_COMPLETION_EMAIL_TEMPLATE_ID = 158;

export const FOCUS_SUBSTANCE_RESPONSE_ID_OTHER = 3676;

export const FOCUS_SUBSTANCE_RESPONSE_ID_MAP = {
  3667: 'alcohol',
  3668: 'cannabis',
  3669: 'cocaine',
  3670: 'amphetamine',
  3671: 'inhalants',
  3672: 'sedatives and sleeping pills',
  3673: 'hallucinogens',
  3674: 'opioids',
  3675: 'all my substances',
  [FOCUS_SUBSTANCE_RESPONSE_ID_OTHER]: 'other',
};
