import { EAgency } from "hooks";

export const PAGE_TITLE = 'Register for a free account';

// TODO: Worth moving this to an environment variable?
export const REDIRECT_TO_HOME_PAGE_AFTER_TIME_IN_MILLISECONDS = 30000;

export const CONSENT_USERFLOW_STEP_ID = 227;

export const CURRENT_STEP_EMAIL = 0;
export const CURRENT_STEP_DISPLAY_NAME = 1;

export const COMMUNITY_NOT_FOUND = 'Community not found. Contact an administrator.';

export const COMMUNITY_IDS: Record<EAgency, string | undefined> = {
  police: process.env.REACT_APP_POLICE_GROUP,
  ambulance: process.env.REACT_APP_AMBULANCE_GROUP,
  fire: process.env.REACT_APP_FIRE_AND_RESCUE,
  communities: process.env.REACT_APP_COMMUNITY_AND_JUSTICE,
}

export const COMMUNITY_MAPPING = [
  { id: COMMUNITY_IDS.ambulance, key: 'stable-ground', agency: EAgency.ambulance },
  { id: COMMUNITY_IDS.police, key: 'nsw-police-anonymous-community', agency: EAgency.police },
  { id: COMMUNITY_IDS.fire, key: 'nsw-fire-and-rescue-anonymous-community', agency: EAgency.fire },
  { id: COMMUNITY_IDS.communities, key: 'nsw-communities-and-justice-anonymous-community', agency: EAgency.communities },
];