import { Button } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { BreadcrumbHomeLink, BreadcrumbLink, InformationBanner, SingleFormPage } from '../../../components';


const ActivateAccountPage = () => {
  const PAGE_TITLE = 'Activate your account';
  const { asPath } = useRouter();

  return (
    <SingleFormPage
      breadcrumbs={{
        items: [
          { content: <BreadcrumbHomeLink href="/" />, key: 'home' },
          { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE },
        ],
      }}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      pageHeading={PAGE_TITLE}
      title={PAGE_TITLE}
    >
      <InformationBanner>
        <p className="mb-0">
          We have sent you an email with a link to activate your account. This should arrive in the next 10 minutes.
          If you cannot see this email, please also check your junk mail. For any problems, please email us at <NextLink href='mailto:info@stableground.org.au' target='_blank'>info@stableground.org.au</NextLink> or use the Contact form <NextLink href="/contact-us" target='_blank'>here</NextLink>.
        </p>
        {/* <p className="mb-0">We&#39;ve sent you an email with a link to verify your account</p> */}
      </InformationBanner>

      <div className="text-right">
        <p>Already activated your account?</p>
        <Button linkButton={{ id: 'login', url: '/login' }} text="Login" />
      </div>
    </SingleFormPage>
  );
};

export { ActivateAccountPage };
