import { OnlineStatusProps } from './OnlineStatus.interfaces';

import { getValidClassNames } from '../../../utils';

const OnlineStatus = ({ size }: OnlineStatusProps) => {
  return (
    <div
      className={getValidClassNames({
        'c-online-status': true,
        [`c-online-status--${String(size)}`]: Boolean(size),
      })}
      title="Online now"
    >
      <span className="h-hide-visually">Is active</span>
    </div>
  );
};

export { OnlineStatus };
