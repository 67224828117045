import { createContext, useContext, useEffect, useState } from 'react';

import { IReactChildren } from '@netfront/common-library';
import { useUser } from '@netfront/gelada-identity-library';
import isEmpty from 'lodash.isempty';

import { DBUser } from '../../interfaces';
import {
  ConversationsQueryResult,
  MessageListGraphType,
  useGetConversations,
  useGetNotifications,
  useGetUserInformation,
} from '../../services';
import { NotificationConnection, NotificationGraphType } from '../../services/howler';

export interface INotification extends NotificationGraphType {
  cursor: string;
}

export interface ISocialContext {
  messages: MessageListGraphType[];
  notifications: INotification[];
  updateMessages: (updatedMessages: MessageListGraphType[]) => void;
  updateNotifications: (updatedNotifications: INotification[]) => void;
  updateUser: (updatedUser: DBUser) => void;
  user?: DBUser;
}

export const SocialContext = createContext<ISocialContext | null>(null);

export const useSocialContext = () => {
  const context = useContext(SocialContext);

  if (context === null) {
    throw new Error('SocialContext provider is missing');
  }
  return context;
};

interface ISocialContextProvider extends IReactChildren {}

export const SocialContextProvider = ({ children }: ISocialContextProvider) => {
  const userId = useUser().getUser()?.id;
  const [user, setUser] = useState<DBUser>({} as DBUser);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [messages, setMessages] = useState<MessageListGraphType[]>([]);

  const updateUser = (updatedUser: DBUser) => {
    setUser(updatedUser);
  };

  const updateNotifications = (updatedNotifications: INotification[]) => {
    setNotifications(updatedNotifications);
  };

  const updateMessages = (updatedMessages: MessageListGraphType[]) => {
    setMessages(updatedMessages);
  };

  const handleGetUserInformationCompleted = (returnedUser: DBUser) => {
    if (!isEmpty(user)) return;
    updateUser(returnedUser);
  };

  const handleGetNotificationsCompleted = (returnedNotifications: NotificationConnection) => {
    updateNotifications(
      returnedNotifications.edges?.map(({ cursor, node }) => ({ cursor, ...(node as NotificationGraphType) })) as INotification[],
    );
  };

  const handleGetConversationsCompleted = (conversations: ConversationsQueryResult[]) => {
    setMessages(conversations.map(({ node }) => node));
  };

  const { getUserInformation } = useGetUserInformation({
    onCompleted: handleGetUserInformationCompleted,
  });

  const { getNotifications } = useGetNotifications({
    onCompleted: handleGetNotificationsCompleted,
  });

  const { getConversations } = useGetConversations({
    onCompleted: handleGetConversationsCompleted,
  });

  useEffect(() => {
    getUserInformation({
      variables: {
        userId: Number(userId),
      },
    });

    getNotifications({
      variables: {
        dateTo: new Date(),
      },
    });

    getConversations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocialContext.Provider value={{ user, notifications, updateUser, updateNotifications, messages, updateMessages }}>
      {children}
    </SocialContext.Provider>
  );
};
