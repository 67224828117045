import { Button } from '@netfront/ui-library';

import { CurrentModuleCardProps } from './CurrentModuleCard.interfaces';

import { ProgressCircle } from '../../components';

const CurrentModuleCard = ({ hasStartedUserflow, percentageCompleted, title, url }: CurrentModuleCardProps) => {
  return (
    <div className="bg-white p-8 border-2 border-athens rounded-lg flex flex-col xl:flex-row items-center h-full">
      <div className="mb-4 xl:mb-0">
        <ProgressCircle percentageCompleted={Number(percentageCompleted)} />
      </div>

      <div className="flex flex-col xl:flex-row flex-1 items-center">
        <div className="px-6 flex-1">
          <span className="color-primary h6 weight-600 mb-1 block">
            {hasStartedUserflow ? 'Continue where you left off' : 'Steady program'}
          </span>
          <p className="h3 mb-4 xl:mb-0">{hasStartedUserflow ? title : 'Try our guided activities to help you change your substance use.'}</p>
        </div>
        <Button linkButton={{ id: 'current', url: String(url) }} text={percentageCompleted === 0 ? 'Start' : 'Continue'} />
      </div>
    </div>
  );
};

export { CurrentModuleCard };
