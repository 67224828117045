import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_CONTENT_GROUP } from './useGetContentGroup.graphql';
import {
  IGetContentGroupQueryGraphQLResponse,
  IGetContentGroupQueryVariables,
  IHandleGetContentGroupParams,
  IUseGetContentGroupOptions,
} from './useGetContentGroup.interfaces';

const useGetContentGroup = (options?: IUseGetContentGroupOptions) => {
  const { fetchPolicy, onCompleted, onError } = options ?? ({} as IUseGetContentGroupOptions);

  const [executeGetPaginatedGroups, { loading: isLoading }] = useEkardoLazyQuery<
    IGetContentGroupQueryGraphQLResponse,
    IGetContentGroupQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data: IGetContentGroupQueryGraphQLResponse) => {
        if (!onCompleted) {
          return;
        }

        const {
          contentGroup: { getContentGroup },
        } = data;

        onCompleted({
          contentGroup: getContentGroup,
        });
      },
      onError,
    },
    query: GET_CONTENT_GROUP,
  });

  const handleGetContentGroup = async ({ contentGroupId, tags }: IHandleGetContentGroupParams) => {
    await executeGetPaginatedGroups({
      variables: {
        contentGroupId,
        tags,
      },
    });
  };

  return {
    handleGetContentGroup,
    isLoading,
  };
};

export { useGetContentGroup };
