import { IDeleteCommentOptions  } from './useDeleteComment.interface';

import { bonoboClient } from '../../apollo';
import { useDeleteCommentMutation } from '../../bonobo/generated.graphql';

export const useDeleteComment = (options?: IDeleteCommentOptions) => {
  const { onCompleted, onError, commentId } = options ?? ({} as IDeleteCommentOptions);

  const [deleteCommentMutation, { loading: isDeleteLoading, error }] = useDeleteCommentMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      onCompleted(commentId as number);
    },
    onError,
  });

  return { deleteCommentMutation, isDeleteLoading, error };
};
