import { useState } from "react"

import { useUpdateUser } from "@netfront/gelada-identity-library";
import { Button, Dialog } from "@netfront/ui-library";
import { useSocialContext } from "contexts";

import { CodeOfConduct } from "./CodeOfConduct";

export const CommunityCodeOfConduct = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const { user } = useSocialContext();

  const { handleUpdateUser } = useUpdateUser({
    onCompleted: () => setIsOpen(false)
  });

  return (
    <>
      <Button
        additionalClassNames="nowrap ml-0 md:ml-4"
        size='small'
        text="Code of conduct"
        variant='secondary'
        onClick={()=> setIsOpen(prev => !prev)}

      />

      <Dialog
        confirmText="I have read and will comply to the Code of Conduct."
        isOpen={isOpen}
        title="User Code of Conduct (Stable Ground Community)"
        onClose={()=> setIsOpen(prev => !prev)}
        onConfirm={() => {
          if(!phoneNumber) {
            setIsOpen(false);
            return;
          }
          handleUpdateUser({
            email: String(user?.email),
            firstName: String(user?.firstName),
            lastName: String(user?.lastName),
            phoneNumber
          });
        }}
      >
        <CodeOfConduct phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}/>
      </Dialog>
    </>
  )
}