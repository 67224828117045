import { useEffect, useState } from 'react';

import { Button, IOption, PlusIcon, Select, Spinner } from '@netfront/ui-library';
import { DBCommunity } from 'interfaces';
import { useRouter } from 'next/router';
import { EPostSorting } from 'services';

import { BreadcrumbLink, CommunityWarningPrompt, Page } from '../../../../components';
import { useGetRootBreadcrumbItems } from '../../../../hooks';
import { GetUserCommunitiesQueryResult, useGetCommunitiesByUser } from '../../../../services/hooks';
import { Feed, EFeedQuery } from '../../../Social';

const FeedPage = () => {
  const PAGE_TITLE = 'Community';

  const { asPath, push } = useRouter();
  const [hasConnectedCommunities, setHasConnectedCommunities] = useState<boolean>(false);
  const [hasMultipleCommunities, setHasMultipleCommunities] = useState<boolean | undefined>();
  const [communities, setCommunities] = useState<DBCommunity[]>([]);
  const [selectedCommunityId, setSelectedCommunityId] = useState<number | undefined>();
  const [selectedSortType, setSelectedSortType] = useState<EPostSorting>(EPostSorting.DescendingDate);

  const {
    query: { hashtag },
  } = useRouter();

  const rootBreadCrumbItems = useGetRootBreadcrumbItems({
    shouldIncludeDashboard: true,
  });

  const handleGetUserCommunitiesCompleted = (returnedCommunities: GetUserCommunitiesQueryResult[], totalCount: number) => {
    if (totalCount === 1) {
      const [community] = returnedCommunities;
      push(`/community/communities/${community.node.key}`);
      return;
    }

    const communityNodes = returnedCommunities.map(({ node }) => node);
    setCommunities(communityNodes);
    setHasMultipleCommunities(totalCount > 1);
    setHasConnectedCommunities(Boolean(totalCount));
  };

  const { getUserCommunities, isLoading = false } = useGetCommunitiesByUser({
    onCompleted: handleGetUserCommunitiesCompleted,
  });

  useEffect(() => {
    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const communityOptions: IOption[] = [
    { id: 'all', name: 'All communities', value: '' },
    ...communities.map(({ id, title }) => ({ id, name: title, value: id })),
  ];

  const sortOptions: IOption[] = [
    { id: EPostSorting.DescendingDate, name: 'Descending date', value: EPostSorting.DescendingDate },
    { id: EPostSorting.RecentActivity, name: 'Recent activity', value: EPostSorting.RecentActivity },
    { id: EPostSorting.Popular, name: 'Most viewed', value: EPostSorting.Popular },
  ];

  return (
    <>
      {isLoading && (
        <>
          <Spinner isLoading />
        </>
      )}
      {!isLoading && (
        <>
          <CommunityWarningPrompt />

          <Page
            breadcrumbs={{
              items: [...rootBreadCrumbItems, { content: <BreadcrumbLink href={asPath}>{PAGE_TITLE}</BreadcrumbLink>, key: PAGE_TITLE }],
            }}
            isPreloaderVisible={isLoading}
            meta={{
              seoDescription: 'Share and interact with other Stable Ground members in this anonymous, confidential online community',
              seoTitle: 'Community | Stable Ground',
            }}
            pageHeading={PAGE_TITLE}
            pageHeadingContainerClassNames="container-sm"
            title={PAGE_TITLE}
            hasPrivateLayout
          >
            <div className="c-feed-page__container">
              <div className="c-feed-page__search">
                <div className="w-auto flex flex-col md:flex-row items-center gap-4">
                  {hasMultipleCommunities && (
                    <Select
                      id="communities"
                      labelDefaultText="Go to community"
                      labelText="Go to community"
                      name="communities"
                      options={communityOptions}
                      value={selectedCommunityId ?? communityOptions[0].value}
                      isLabelHidden
                      onChange={({ currentTarget: { value } }) => setSelectedCommunityId(Number(value))}
                    />
                  )}

                  <Select
                    id="post-filter"
                    labelDefaultText="Filter posts"
                    labelText="Sort posts"
                    name="post-filter"
                    options={sortOptions}
                    value={selectedSortType}
                    isLabelHidden
                    onChange={({ currentTarget: { value } }) => {
                      setSelectedSortType(value as EPostSorting);
                    }}
                  />
                </div>
                <Button
                  additionalClassNames="ml-auto"
                  icon={PlusIcon}
                  isDisabled={!hasConnectedCommunities}
                  text="New post"
                  variant="secondary"
                  onClick={() => {
                    push('/community/create-post');
                  }}
                />
              </div>

              <Feed
                {...(selectedCommunityId && {
                  communityId: selectedCommunityId,
                })}
                query={EFeedQuery.All}
                shouldShowCommunitiesMessage={!hasConnectedCommunities}
                sort={selectedSortType}
                status="ACTIVE"
                tags={hashtag ? [`#${String(hashtag)}`] : []}
              />
            </div>
          </Page>
        </>
      )}
    </>
  );
};

export { FeedPage };
